import _ from "lodash";

import { dec2bin } from "../helpers/functions";

export const routes = {
  login: "/login",
  recovery: "/recovery",
  companies: "/companies",
  log: "/log",
  reports: "/reports",
  clients: "/clients",
  client: {
    home: "/client",
    buildings: "/buildings",
  },
  teams: "/teams",
  roles: "/roles",
  users: "/users",
  settings: {
    home: "/settings",
    personalization: "/settings/personalization",
    notificationTypes: "/settings/notification-types",
    fields: "/settings/fields",
    products: "/settings/products",
    protocols: "/settings/protocols",
  },
  library: {
    home: "/library/home",
    files: "/library/files",
    upload: "/library/upload",
  },
  userProfile: "/user/profile",
  biling: "/biling",
  analyze: "/analyze",
};

export const getFirstAvailableRoute = (permissionState) => {
  const mainRoutes = _.without(
    Object.keys(routes),
    "login",
    "recovery",
    "client",
    "userProfile",
    "library"
  );

  const availableRoutes = _.compact(
    mainRoutes.map((item) => {
      const readPermission = !!+dec2bin(permissionState[item]).split("")[0];
      if (readPermission) return item;
    })
  );
  const firstRoute = availableRoutes[0]
    ? `/${availableRoutes[0]}`
    : "/library/home";

  return firstRoute;
};
