import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import { Controller } from "react-hook-form";
import PermissionTable from "./PermissionTable";
import { dec2bin } from "../helpers/functions";

const PermissionInput = ({ form, items }) => {
  const { control, setValue } = form;

  const columns = useMemo(
    () => [
      {
        Header: "Moduł",
        accessor: "name",
      },
      {
        Header: "Podgląd",
        accessor: "read",
      },
      {
        Header: "Edycja",
        accessor: "edit",
      },
      {
        Header: "Dodawanie",
        accessor: "create",
      },
    ],
    []
  );

  const data = useMemo(() => {
    return items.map((item) => {
      const { slug } = item;

      return {
        ...item,
        read: (
          <Controller
            render={({ ref, ...rest }) => (
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked === false) {
                    setValue(`permission.${slug}`, {
                      edit: false,
                      create: false,
                      delete: false,
                    });
                  }
                  rest.field.onChange(e.target.checked);
                }}
                center
                {...rest}
              />
            )}
            defaultValue={false}
            control={control}
            name={`permission.${slug}.read`}
          />
        ),
        edit: (
          <Controller
            render={({ ref, ...rest }) => (
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setValue(`permission.${slug}`, {
                      read: true,
                    });
                  }
                  setValue(`permission.${slug}`, {
                    delete: e.target.checked,
                  });
                  rest.field.onChange(e.target.checked);
                }}
                center
                {...rest}
              />
            )}
            defaultValue={false}
            control={control}
            name={`permission.${slug}.edit`}
          />
        ),
        create: (
          <>
            <Controller
              render={({ ref, ...rest }) => (
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setValue(`permission.${slug}`, {
                        read: true,
                      });
                    }
                    rest.field.onChange(e.target.checked);
                  }}
                  center
                  {...rest}
                />
              )}
              defaultValue={false}
              control={control}
              name={`permission.${slug}.create`}
            />
            <div style={{ display: "none" }}>
              <Controller
                render={({ ref, ...rest }) => <Checkbox {...rest} />}
                defaultValue={false}
                control={control}
                name={`permission.${slug}.delete`}
              />
            </div>
          </>
        ),
      };
    });
  }, [items, control, setValue]);

  useEffect(() => {
    items.forEach((item) => {
      const { permission, slug } = item;

      const [read, edit, create, del] = dec2bin(permission)
        .split("")
        .map((item) => !!+item);
      setValue(`permission.${slug}`, {
        read,
        edit,
        create,
        delete: del,
      });
    });
  }, [items, setValue]);

  return <PermissionTable data={data} columns={columns} />;
};

PermissionInput.propTypes = {
  form: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      permission: PropTypes.number,
    })
  ).isRequired,
};

export default PermissionInput;
