import styled, { css } from "styled-components";

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.color.black};
  font-size: 14px;
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  text-transform: capitalize;
`;

export const CalendarHeader = styled.span`
  font-size: 16px;
  margin-right: auto;
`;

export const CalendarArrowButtonContainer = styled.div`
  display: flex;
`;

export const CalendarTaskButtonAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px RGB(242, 244, 246) solid;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 18px;
  color: ${({ theme }) => theme.color.font200};

  :hover {
    color: ${({ theme }) => theme.color.blue};
    border-color: ${({ theme }) => theme.color.blue};
  }
`;

export const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const DaysOfTheWeek = styled.span`
  color: ${({ theme }) => theme.color.font200};
  font-size: 14px;
`;

export const Day = styled.div`
  width: 14.2%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.isToday &&
    css`
      border: 1px solid ${({ theme }) => theme.color.primaryColor};
      border-radius: 50%;
      color: ${({ theme }) => theme.color.primaryColor};
    `}

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${({ theme }) => theme.color.primaryColor};
      border-radius: 50%;
      color: white;
    `}

  ${(props) =>
    props.isReport &&
    css`
      :after {
        position: absolute;
        border-width: 1px 1px 0 0;
        content: " ";
        background-color: ${({ theme }) => theme.color.primaryColor};
        display: block;
        height: 5px;
        position: absolute;
        width: 5px;
        bottom: 4px;
        left: 50%;
        margin-left: -3px;
        border-radius: 50%;
      }
    `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 104px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin: 32px 0;
`;

export const ButtonSelectToday = styled.div`
  width: 100%;
`;
