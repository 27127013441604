import { useEffect, useState } from "react";

import { usePermissionState } from "../context/permission";
import { getPermissionObject } from "../helpers/functions";

const usePermissionObject = (permissionName) => {
  const [permissionObject, setPermissionObject] = useState({});
  const permission = usePermissionState();
  const permissionDec = permission[permissionName];

  useEffect(() => {
    setPermissionObject(getPermissionObject(permissionDec));
  }, [permission, permissionDec]);

  return permissionObject;
};

export default usePermissionObject;
