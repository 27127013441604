import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";
import { isEmpty, some } from "lodash";
import { AngleDownIcon, FilterIcon, TimesCircleIcon } from "react-line-awesome";
import gsap from "gsap";

import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import Loader from "../Loader";
import Checkbox from "../Checkbox";
import SearchInput from "./SearchInput";
import { useGetSearchDropdownItems } from "../../hooks/useGetSearchDropdownItems";

const Container = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  height: 20px;
  z-index: 4;
  @media only screen and (max-width: 600px) {
    height: 22px;
  }
`;
const Selected = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.medium};
  margin: 0 12px;
`;
const TextBox = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font100};
  margin-right: 16px;
  cursor: pointer;
`;
const IconBox = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 6px;
`;
const Text = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
`;
const Arrow = styled.div`
  font-size: 16px;
  transition: transform 0.16s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
const DropdownBox = styled.div`
  position: absolute;
  top: 26px;
  width: fit-content;
  min-width: 100%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.12);
  overflow: hidden;
  outline: none;
`;
const OptionsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(20px - 12px) 18px;
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ErrorBox = styled(LoadingBox)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.color.toastifyError};
`;
const ErrorIconBox = styled.div`
  font-size: 16px;
  margin-right: 6px;
`;
const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.color.font300};
`;
const Label = styled.label`
  position: relative;
  margin-left: 12px;
  white-space: nowrap;
  cursor: pointer;

  ${({ isCut }) =>
    isCut &&
    css`
      width: 90%;

      overflow-x: hidden;

      ::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
        z-index: 1;
      }
    `}
`;

function enterAnimation(node, done) {
  gsap.from(node, {
    autoAlpha: 0,
    duration: 0.1,
    onComplete: done,
    ease: "Power2.inOut",
  });
}

function exitAnimation(node, done) {
  gsap.to(node, {
    autoAlpha: 0,
    duration: 0.1,
    onComplete: done,
    ease: "Power2.inOut",
  });
}

const defaultValue = {
  name: "WSZYSTKO",
  value: "id",
};

const Dropdown = ({ name, icon, initial = [], config }) => {
  const Icon = icon || FilterIcon;
  const { schema, action, searchable = true, items = [], filter } = config;

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems(initial);
    // eslint-disable-next-line
  }, []);

  const container = useRef(null);
  useDetectOutsideClick(container, setIsOpen);

  const [dropdownItems, currentMachine, { getItemsFromAPI, state }] =
    useGetSearchDropdownItems({
      searchValue,
      selectedItems,
      schema,
      items,
      filter,
    });

  function handleOpen() {
    if (isEmpty(dropdownItems) && isOpen) getItemsFromAPI();
    setIsOpen(!isOpen);
  }

  const handleSelectChange = (item) => {
    const { id: itemId } = item;
    let newSelectedItemsArray;
    if (some(selectedItems, item))
      newSelectedItemsArray = selectedItems.filter(
        (item) => item.id !== itemId
      );
    else newSelectedItemsArray = [...selectedItems, item];

    setSelectedItems([...newSelectedItemsArray]);
  };

  useEffect(() => {
    if (isEmpty(selectedItems)) action();
    else action(selectedItems);
  }, [selectedItems]);

  return (
    <Container ref={container}>
      <TextBox onClick={handleOpen}>
        <IconBox>
          <Icon />
        </IconBox>
        <Text>{name}:</Text>
        <Selected>
          {isEmpty(selectedItems)
            ? defaultValue.name
            : selectedItems
                .map(({ name }) => name)
                .slice(0, 2)
                .join(", ")}
        </Selected>
        <Arrow isOpen={isOpen}>
          <AngleDownIcon />
        </Arrow>
      </TextBox>
      <Transition
        timeout={150}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <DropdownBox>
          {searchable ? (
            <SearchInput value={searchValue} setValue={setSearchValue} />
          ) : null}
          <OptionsBox>
            {currentMachine.matches(state.isLoading) && (
              <LoadingBox>
                <Loader loading={true} />
              </LoadingBox>
            )}
            {currentMachine.matches(state.hasError) && (
              <ErrorBox>
                <ErrorIconBox>
                  <TimesCircleIcon />
                </ErrorIconBox>
                Error
              </ErrorBox>
            )}
            {currentMachine.matches(state.hasLoaded) && (
              <>
                {dropdownItems.map((item) => (
                  <CheckboxItem key={item.id}>
                    <Checkbox
                      htmlFor={item.id}
                      field={{
                        value: item.selected,
                        onChange: () => handleSelectChange(item),
                      }}
                      checked={some(selectedItems, item)}
                      style={{ width: 13, height: 13 }}
                      iconBoxStyles={{
                        top: 2,
                        left: 1,
                        transform: "scale(.7)",
                      }}
                    />
                    <Label htmlFor={item.id} isCut={item.name.length > 22}>
                      {item.name}
                    </Label>
                  </CheckboxItem>
                ))}
              </>
            )}
          </OptionsBox>
        </DropdownBox>
      </Transition>
    </Container>
  );
};

export default Dropdown;
