import {
  CREATE_FIELD_ITEM_STARTED,
  CREATE_FIELD_ITEM_SUCCESS,
  CREATE_FIELD_ITEM_FAILURE,
  LIST_FIELD_ITEMS_STARTED,
  LIST_FIELD_ITEMS_SUCCESS,
  LIST_FIELD_ITEMS_FAILURE,
  GET_FIELD_ITEM_STARTED,
  GET_FIELD_ITEM_SUCCESS,
  GET_FIELD_ITEM_FAILURE,
  UPDATE_FIELD_ITEM_STARTED,
  UPDATE_FIELD_ITEM_SUCCESS,
  UPDATE_FIELD_ITEM_FAILURE,
  DELETE_FIELD_ITEM_STARTED,
  DELETE_FIELD_ITEM_SUCCESS,
  DELETE_FIELD_ITEM_FAILURE,
} from "../../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  fieldItems: [],
  fieldItem: {},
  error: null,
};

const fieldItemReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ----------------------------- Tworzenie pola ----------------------------- */
    case CREATE_FIELD_ITEM_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_FIELD_ITEM_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        fieldItems: [action.payload, ...state.fieldItems],
      };
    case CREATE_FIELD_ITEM_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    /* ----------------------- Listowanie wszystkich pól ---------------------- */
    case LIST_FIELD_ITEMS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_FIELD_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fieldItems: action.payload,
      };
    case LIST_FIELD_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    /* ----------------------- Pobieranie pojedyńczego pola ---------------------- */
    case GET_FIELD_ITEM_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_FIELD_ITEM_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        fieldItem: action.payload,
      };
    case GET_FIELD_ITEM_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    /* ----------------------- Edycja pola ---------------------- */
    case UPDATE_FIELD_ITEM_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_FIELD_ITEM_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        fieldItem: action.payload,
        fieldItems: state.fieldItems.map((fieldItem) =>
          fieldItem.id === action.payload.id ? action.payload : fieldItem
        ),
      };
    case UPDATE_FIELD_ITEM_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    /* ----------------------- Usuwnaie pola ---------------------- */
    case DELETE_FIELD_ITEM_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_FIELD_ITEM_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        fieldItems: state.fieldItems.filter(
          (fieldItem) => fieldItem.id !== action.payload
        ),
      };
    case DELETE_FIELD_ITEM_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default fieldItemReducer;
