import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import Input from "../../../components/Input";
import Submit from "../../../components/Submit";
import { Title } from "../../../components/Title";
import { Button } from "../../../components/Button";
import { routes } from "../../../routes";

const Form = styled.form`
  min-width: 360px;
`;
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`;
const ButtonBox = styled.div`
  width: 116px;
`;

export default function Step3({
  setStep,
  recoveryForm,
  controlledSubmit,
  setCodeError,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = recoveryForm;

  const onSubmit = async (data) => {
    if (controlledSubmit) {
      controlledSubmit(data);
      return;
    }

    let { email, pin, password } = data;
    pin = Object.values(pin).join("");
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(email, pin, password);

      history.push({
        pathname: routes.login,
        state: {
          email: data.email,
        },
      });
    } catch (err) {
      if (err.code === "SerializationException") {
        setCodeError("Podaj poprawny kod z maila");
        setValue("pin", { p1: "", p2: "", p3: "", p4: "", p5: "", p6: "" });
        setStep(2);
      } else {
        console.log(err);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Title>Zmiana hasła</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Nowe hasło"
          placeholder="Wprowadź nowe hasło"
          type="password"
          error={errors?.password?.message}
          {...register("password", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Powtórzenie nowego hasła"
          placeholder="Wprowadź ponownie nowe hasło"
          type="password"
          error={errors?.confirmPassword?.message}
          {...register("confirmPassword", {
            required: { value: true, message: "Pole nie może być puste" },
            validate: (value) =>
              value === watch("password") || "Hasła różnią się",
          })}
        />
        <ButtonsRow>
          <ButtonBox>
            <Button as={Link} to={routes.login} dark="1" big="1">
              Wróć
            </Button>
          </ButtonBox>
          <ButtonBox>
            <Submit value="Dalej" isHeight loader={loading} />
          </ButtonBox>
        </ButtonsRow>
      </Form>
    </>
  );
}
