import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "react-line-awesome";

import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

const InputBox = styled.div`
  height: 36px;
  width: 442px;
  border-radius: 19px;
  background-color: ${({ theme }) => theme.color.separator};
  display: flex;
  align-items: center;
  padding: 0 22px;
`;
const SearchText = styled.div`
  color: ${({ theme }) => theme.color.topBarFontSecondary};
  font-size: 14px;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Input = styled.input`
  background-color: transparent;
  border: none;
  width: calc(100% - 24px - 13px);
  font-size: 14px;
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.weight.regular};
`;
const IconBox = styled.div`
  display: flex;
  font-size: 24px;
  color: ${({ theme }) => theme.color.primaryYellow};
  margin-right: 13px;
`;

const SearchInput = forwardRef(({ dropdownContainerRef, ...rest }, ref) => {
  const [isFocus, setIsFocus] = useState(false);
  useDetectOutsideClick(dropdownContainerRef, setIsFocus);

  let inputIndicator;
  if (!isFocus) inputIndicator = <SearchText>Szukaj zgłoszeń itp.</SearchText>;
  else
    inputIndicator = (
      <InputBox>
        <IconBox>
          <SearchIcon className="la-1x" />
        </IconBox>
        <Input
          ref={ref}
          type="text"
          placeholder="Szukaj słowa kluczowego"
          autoFocus
          autoComplete="off"
          {...rest}
        />
      </InputBox>
    );

  return <div onClick={() => setIsFocus(true)}>{inputIndicator}</div>;
});

export default SearchInput;
