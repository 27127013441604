import {
  LIST_PROTOCOLS_STARTED,
  LIST_PROTOCOLS_SUCCESS,
  LIST_PROTOCOLS_FAILURE,
  CREATE_PROTOCOL_STARTED,
  CREATE_PROTOCOL_SUCCESS,
  CREATE_PROTOCOL_FAILURE,
  DELETE_PROTOCOL_STARTED,
  DELETE_PROTOCOL_SUCCESS,
  DELETE_PROTOCOL_FAILURE,
  UPDATE_PROTOCOL_STARTED,
  UPDATE_PROTOCOL_SUCCESS,
  UPDATE_PROTOCOL_FAILURE,
  GET_PROTOCOL_STARTED,
  GET_PROTOCOL_SUCCESS,
  GET_PROTOCOL_FAILURE,
} from "../../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingDelete: false,
  nextToken: null,
  protocols: [],
  protocol: {},
  error: null,
};

const protocolReducer = (state = initialState, action) => {
  switch (action.type) {
    /* -------------------------- Pobieranie protokołu -------------------------- */
    case GET_PROTOCOL_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_PROTOCOL_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        protocol: action.payload,
      };
    case GET_PROTOCOL_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    /* -------------------------- Listowanie protokołów -------------------------- */
    case LIST_PROTOCOLS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_PROTOCOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        protocols: action.payload.protocols,
      };
    case LIST_PROTOCOLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    /* --------------------------- Dodawanie protokołu --------------------------- */
    case CREATE_PROTOCOL_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_PROTOCOL_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        protocols: [action.payload.protocol, ...state.protocols],
      };
    case CREATE_PROTOCOL_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    /* --------------------------- Usuwanie protokołu --------------------------- */
    case DELETE_PROTOCOL_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_PROTOCOL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        protocols: state.protocols.filter(
          (protocol) => protocol.id !== action.payload
        ),
      };
    case DELETE_PROTOCOL_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    /* --------------------------- Edycja protokołu --------------------------- */
    case UPDATE_PROTOCOL_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_PROTOCOL_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        protocols: state.protocols.map((protocol) =>
          protocol.id === action.payload.id
            ? { ...protocol, ...action.payload }
            : protocol
        ),
        protocol: action.payload,
      };
    case UPDATE_PROTOCOL_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default protocolReducer;
