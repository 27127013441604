import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { googleAutocomplete } from "../graphql/global";

export const useGetAddressViaGoogle = () => {
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const getAddressViaGoogle = async (input) => {
    try {
      setLoading(true);

      const { data } = await API.graphql(
        graphqlOperation(googleAutocomplete, {
          input,
          params: '{"componentRestrictions":{"country":"pl"}}',
        })
      );

      const streetList = JSON.parse(
        JSON.parse(data.googleAutocomplete)
      ).predictions;

      setAddressList(streetList);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setAddressList([]);
      setLoading(false);
    }
  };

  return { getAddressViaGoogle, addressList, loading };
};
