import React from "react";
import styled from "styled-components";
import { BuildingIcon } from "react-line-awesome";
import { Controller } from "react-hook-form";

import FormSectionTitle from "../../FormSectionTitle";
import Input from "../../Input";
import AddressInput from "../../AddressInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Informacje o budynku" icon={BuildingIcon} />
        <Input
          label="Nazwa budynki"
          placeholder="Wprowadź nazwę budynku"
          error={errors?.name?.message}
          isLoading={loading}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Controller
          render={({ ...rest }) => (
            <AddressInput
              isLoading={loading}
              label="Adres budynku"
              placeholder="Wprowadź adres budynku"
              error={errors?.address?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Podaj adres" },
          }}
          control={control}
          name="address"
        />
      </FormSection>
    </>
  );
};

export default FormFields;
