import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const SingleTabIcon = styled.div`
  display: flex;
  font-size: 18px;
  color: ${({ theme }) => theme.color.font100};
  margin-right: 16px;
  margin-top: 3px;
  transition: color 0.14s ease-in-out;
`;
const SingleTabContent = styled.div``;
const SingleTabTitle = styled.div`
  color: ${({ theme }) => theme.color.font300};
  font-size: 16px;
  margin-bottom: 1px;
  transition: color 0.14s ease-in-out;
`;
const SingleTabSubtitle = styled.div`
  color: ${({ theme }) => theme.color.font200};
  font-size: 12px;
  line-height: 18px;
`;
const SingleTabContainer = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 2px solid transparent;
  user-select: none;
  transition: background 0.14s ease-in-out;

  ${({ alt }) =>
    alt &&
    css`
      border-right: none;
      border-left: 2px solid transparent;
    `}

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
    cursor: pointer;

    ${SingleTabIcon} {
      color: ${({ theme }) => theme.color.blue};
    }

    ${SingleTabTitle} {
      color: ${({ theme }) => theme.color.blue};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.color.mainBackground};
    border-top-color: ${({ theme }) => theme.color.secondaryBorder};
    border-bottom-color: ${({ theme }) => theme.color.secondaryBorder};
    border-right-color: ${({ theme }) => theme.color.primaryColor};

    ${({ alt }) =>
      alt &&
      css`
        border-right-color: transparent;
        border-left-color: ${({ theme }) => theme.color.primaryColor};
      `}

    :first-of-type {
      border-top-color: transparent;
    }

    ${SingleTabIcon} {
      color: ${({ theme }) => theme.color.blue};
    }

    ${SingleTabTitle} {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

const SingleTab = ({ title, subtitle, icon: Icon, ...rest }) => {
  return (
    <SingleTabContainer {...rest}>
      <SingleTabIcon>
        <Icon />
      </SingleTabIcon>
      <SingleTabContent>
        <SingleTabTitle>{title}</SingleTabTitle>
        <SingleTabSubtitle>{subtitle}</SingleTabSubtitle>
      </SingleTabContent>
    </SingleTabContainer>
  );
};

export default SingleTab;
