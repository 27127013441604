import React from "react";
import styled from "styled-components";
import { BarsIcon } from "react-line-awesome";

import { useToggleSideBar } from "../../context/sideBar";

const Box = styled.div`
  color: ${({ theme }) => theme.color.topBarFontSecondary};
  margin-right: 24px;
  cursor: pointer;
`;

export default function Burger() {
  const toggleSideBar = useToggleSideBar();

  return (
    <Box onClick={toggleSideBar}>
      <BarsIcon className="la-lg" />
    </Box>
  );
}
