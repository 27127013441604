import React from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.progressBarBackground};
`;
const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ color }) => color};
  transition: 0.2s ease-in-out;
`;

export default function ProgressBar({ step }) {
  const {
    color: { progressBarStep },
  } = useTheme();

  return (
    <Container>
      <Progress width={(step * 100) / 3} color={progressBarStep[step - 1]} />
    </Container>
  );
}
