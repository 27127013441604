import React, {useMemo} from "react";
import styled from "styled-components";
import TopTab from "./TopTab";
import { useHistory } from "react-router-dom";
import {routes} from "../../routes";
import usePermissionObject from "../../hooks/usePermissionObject";


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabsPanel = ({tabs}) => {

    return (
        <Container>
            <TabsWrapper>
                {tabs.map(({tab, route, id}) => (
                    <TopTab
                        title={tab.title}
                        key={id}
                        to={route}
                        activeClassName="active"
                    />
                ))}
            </TabsWrapper>
        </Container>
    );
};

export default TabsPanel;