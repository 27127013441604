import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Styles from "./ReportPending.styles";
import { AngleLeftIcon } from "react-line-awesome";
import { AngleRightIcon } from "react-line-awesome";
import { getTypeIconByValue } from "../../icons/typeIconsList";
import Loader from "../../../components/Loader";
import { UserIcon } from "react-line-awesome";
import { CalendarCheckIcon } from "react-line-awesome";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { searchReportsPending } from "../../../redux/actions/logActions";
import { usePermissionState } from "../../../context/permission";

const ReportPending = () => {
  const [selectedReportPending, setSelectedReportPending] = useState(0);
  const formattedDateWithoutHours = format(new Date(), "yyyy-MM-dd");

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    reportState: state.log,
  }));

  const { reportState } = state;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

    const searchReportsVariablesReportPending = useMemo(
      () => ({
        limit: 100,
        filter: {
          and: [
            { companyId: { eq: companyId } },
            {
              createdAt: {
                gte: formattedDateWithoutHours,
              },
            },
            {
              status: {
                eq: "PENDING",
              },
            },
          ],
        },
        sort: {
          direction: "asc",
          field: "createdAt",
        },
      }),
      []
    );

  useEffect(() => {
    dispatch(searchReportsPending(searchReportsVariablesReportPending))
  }, [searchReportsVariablesReportPending])

  const reportsPendingList = reportState.reportsPending;

  const handleShowNextReportPendingOnClick = () => {
    setSelectedReportPending((prev) => {
      if (prev === reportsPendingList.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const handleShowPreviousReportPendingOnClick = () => {
    setSelectedReportPending((prev) => {
      if (prev === 0) {
        return reportsPendingList.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

    return reportsPendingList.length > 0 ? (
        <Styles.ReportPendingWrapper>
            <Styles.ReportPendingHeaderContainer>
                <Styles.ReportPendingHeader>
                    Robocze zgłoszenie
                </Styles.ReportPendingHeader>
                <Styles.ReportPendingArrowButtonContainer>
                    <Styles.ReportPendingTaskButtonAction onClick={handleShowPreviousReportPendingOnClick}>
                        <AngleLeftIcon />
                    </Styles.ReportPendingTaskButtonAction>
                    <Styles.ReportPendingTaskButtonAction onClick={handleShowNextReportPendingOnClick}>
                        <AngleRightIcon />
                    </Styles.ReportPendingTaskButtonAction>
                </Styles.ReportPendingArrowButtonContainer>
            </Styles.ReportPendingHeaderContainer>
            <Styles.ReportPendingTaskTitleContainer>
                {getTypeIconByValue(reportsPendingList[selectedReportPending].typeItem.icon)}
                  <Styles.ReportPendingTaskTitle>{reportsPendingList[selectedReportPending].type.name}</Styles.ReportPendingTaskTitle>
            </Styles.ReportPendingTaskTitleContainer>
            <Styles.ReportPendingIconWithTextContainer>
                <Styles.ReportPendingIconTask>
                    <UserIcon />
                </Styles.ReportPendingIconTask>
                {(reportsPendingList[selectedReportPending].client && (reportsPendingList[selectedReportPending].client.name)) ? (reportsPendingList[selectedReportPending].client.name) : null}
            </Styles.ReportPendingIconWithTextContainer>
            <Styles.ReportPendingIconCalendarWithText>
                <Styles.ReportPendingCalendarCheckIcon>
                </Styles.ReportPendingCalendarCheckIcon>
                Data dodania
                <Styles.ReportPendingStartDate>
                    {format(new Date(reportsPendingList[selectedReportPending].createdAt), 'dd LLLL yyyy', { locale: pl })}
                </Styles.ReportPendingStartDate>
            </Styles.ReportPendingIconCalendarWithText>
        </Styles.ReportPendingWrapper>
    ) : null;
};

export default ReportPending;
