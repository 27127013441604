import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { usePermissionState } from "../../context/permission";
import { parsePhoneNumber } from "libphonenumber-js";
import { createClient } from "../../redux/actions/clientActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateClient = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.client);
  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    const language = "PL";
    const { location } = data.address.geometry;

    const input = {
      ...data,
      phoneNumber: parsePhoneNumber(data.phoneNumber, language).number,
      address: data.address.formatted_address,
      latitude: location.lat,
      longitude: location.lng,
      companyId,
    };

    dispatch(createClient(input, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateClient.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateClient;
