import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Button } from "../../Button";
import Submit from "../../Submit";
import ProtocolFormFields from "./ProtocolFormFields";
import {
  getProtocol,
  updateProtocol,
} from "../../../redux/actions/settings/protocolActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditProtocol = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const protocolState = useSelector(({ protocol }) => protocol);

  useEffect(() => {
    dispatch(getProtocol(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(protocolState.protocol)) {
      const { name, items } = protocolState.protocol;

      setValue("name", name);
      setValue("items", JSON.parse(items));
    }
  }, [protocolState.protocol, setValue]);

  const onSubmit = (data) => {
    const input = {
      id,
      name: data.name,
      items: JSON.stringify(data.items),
    };

    dispatch(updateProtocol(input));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ProtocolFormFields form={form} loading={protocolState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={protocolState.loadingGet}
            loader={protocolState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditProtocol;
