import React from "react";
import styled, { css } from "styled-components";
import { useSelect } from "downshift";
import { AngleDownIcon, BanIcon, UsersIcon } from "react-line-awesome";

import Loader from "./Loader";
import { Label } from "./Input";
import { isEmpty } from "lodash";
// import CombineImages from "./CombineProfileImages";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;

  * {
    outline: none;
  }
`;
const SelectedItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  padding: 0 44px 0 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      background-color: ${theme.color.mainBackground};
      opacity: 0.6;
      pointer-events: none;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      opacity: 0.5;
      cursor: unset;
    `}

  ${({ theme, error }) =>
    error &&
    css`
      background-color: ${theme.color.inputErrorBackground};
      border-color: ${theme.color.inputErrorBorder};
    `};
`;
const DropdownList = styled.ul`
  position: absolute;
  list-style: none;
  bottom: 0;
  left: -18px;
  transform: translateY(100%);
  width: 100%;
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.05);
  max-height: 220px;
  overflow-y: auto;
  z-index: 3;
`;
const Element = styled.li`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.selectHoverText : theme.color.grey};
  padding: 11px 18px 10px;
  transition: color 0.14s ease-in-out;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.selectHoverText};
  }
`;
const LoaderBox = styled.div`
  position: absolute;
  display: flex;
  top: 17px;
  right: 16px;
  z-index: 5;
`;
const IconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  color: ${({ theme }) => theme.color.font100};
  font-size: 18px;
  transition: transform 0.18s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
`;
const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;
const EmptyBox = styled(LoadingBox)``;
const Placeholder = styled.div`
  color: ${({ theme }) => theme.color.font300};
  white-space: nowrap;
`;
const ErrorIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 16px;
  color: ${({ theme }) => theme.color.inputErrorIcon};
  font-size: 18px;
  cursor: help;
`;
const EmptyButton = styled.div`
  color: ${({ theme }) => theme.color.primaryColor};
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;
const ImageBox = styled.div`
  margin-right: 10px;
  color: ${({ theme }) => theme.color.font100};
  font-size: 18px;
`;
const StyledLabel = styled(Label)`
  margin-bottom: 21px;
`;

const SelectTeam = ({
  field: { onChange, value },
  label,
  placeholder,
  items,
  isLoading,
  error,
  isListLoading,
  getItems,
  name,
  disabled,
  handleEmpty = { text: "Utwórz", action: () => {} },
  noMargin,
}) => {
  const itemToString = (item) => item.name;

  const {
    isOpen,
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem: value || {},
    onSelectedItemChange: (e) => onChange(e.selectedItem),
    itemToString,
    onIsOpenChange: () => {
      if (!items.length > 0 && !isOpen) {
        getItems();
      }
    },
  });

  let listDisplay;
  if (!isListLoading) {
    if (!isEmpty(items)) {
      listDisplay = items.map((item, index) => (
        <Element
          key={`${item.value}${index}`}
          {...getItemProps({
            item,
            index,
            isSelected: highlightedIndex === index,
          })}
        >
          {item.name}
        </Element>
      ));
    } else {
      listDisplay = (
        <EmptyBox>
          <EmptyButton onClick={handleEmpty.action}>
            {handleEmpty.text}
          </EmptyButton>
        </EmptyBox>
      );
    }
  } else {
    listDisplay = (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );
  }

  function getImagesFromUsers(mainObject) {
    const users = mainObject.members?.items;
    if (!users) return [];
    const images = users
      .reduce((acc, item) => (acc = [...acc, item.user.photo]), [])
      .filter((item) => item != null);

    return images;
  }

  return (
    <Container noMargin={noMargin}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <SelectedItem {...getToggleButtonProps({ isLoading, error, disabled })}>
        {/* <ImageBox>
          <CombineImages images={getImagesFromUsers(selectedItem)} />
        </ImageBox> */}
        <ImageBox>
          <UsersIcon />
        </ImageBox>
        {itemToString(selectedItem) || <Placeholder>{placeholder}</Placeholder>}
        <IconBox isOpen={isOpen} isLoading={isLoading || error}>
          <AngleDownIcon size={35} />
        </IconBox>
        {isLoading && (
          <LoaderBox>
            <Loader loading={isLoading} size={18} />
          </LoaderBox>
        )}
        {error && (
          <ErrorIcon title={error}>
            <BanIcon />
          </ErrorIcon>
        )}
      </SelectedItem>
      <DropdownList {...getMenuProps()}>{isOpen && listDisplay}</DropdownList>
    </Container>
  );
};

export default SelectTeam;
