import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import { Title } from "../Title";
import SimpleDropdown from "../SimpleDropdown";
import Loader from "../Loader";
import { EllipsisHIcon } from "react-line-awesome";
import { debounce } from "lodash";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  overflow: hidden;
`;
const DropdownAbsoluteBox = styled.div`
  position: absolute;
  top: 17px;
  right: 21px;
  height: 24px;
  font-size: 22px;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.backgroundDarkOpacity(0)};
`;
const SideBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 524px;
  max-width: 90%;
  padding: 23px 27px 54px 29px;
  background-color: ${({ theme }) => theme.color.modalBackground};
  box-shadow: ${({ theme }) => theme.shadow.sideModal};
  overflow-y: auto;
`;
const StyledTitle = styled(Title)`
  font-size: 20px;
  text-align: left;
  margin-bottom: 36px;
`;

const SideModal = ({
  id,
  children,
  title,
  isOpen,
  setIsOpen,
  dropdownOptions,
  loadingDelete,
}) => {
  const sideBox = useRef(null);

  const [open, setOpen] = useState(isOpen);

  const openDebounce = useCallback(
    debounce((isOpen) => {
      setOpen(isOpen);
    }, 300),
    []
  );
  useEffect(() => {
    openDebounce(isOpen);
  }, [isOpen]);

  // Animations
  function enterAnimation(node, done) {
    gsap.from(node, {
      x: 600,
      duration: 0.2,
      ease: "Power2.inOut",
      onComplete: done,
    });
  }

  function exitAnimation(node, done) {
    gsap.to(node, {
      x: 600,
      duration: 0.2,
      ease: "Power2.inOut",
      onComplete: done,
    });
  }

  let options;
  if (dropdownOptions) {
    if (loadingDelete) {
      options = <Loader loading={loadingDelete} />;
    } else {
      options = (
        <SimpleDropdown
          data={dropdownOptions(id).filter(
            ({ isEditDisplay }) => isEditDisplay
          )}
          icon={EllipsisHIcon}
        />
      );
    }
  }

  return (
    <Transition
      timeout={300}
      mountOnEnter
      unmountOnExit
      in={open}
      onEnter={(node, done) => {
        enterAnimation(node, done);
      }}
      onExit={(node, done) => {
        exitAnimation(node, done);
      }}
    >
      <Container>
        <Background onClick={() => setIsOpen(false)} />
        <SideBox ref={sideBox}>
          <DropdownAbsoluteBox>{options}</DropdownAbsoluteBox>
          {title && <StyledTitle>{title}</StyledTitle>}
          {children}
        </SideBox>
      </Container>
    </Transition>
  );
};

SideModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default SideModal;
