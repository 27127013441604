import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FilterIcon } from "react-line-awesome";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";

const Container = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  height: 20px;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    margin-left: 8px;
  }
`;
const IconBox = styled.div`
  display: flex;
  font-size: 22px;
  margin-right: 6px;
  cursor: pointer;
`;
const Options = styled.div`
  position: absolute;
  right: 0;
  max-height: 220px;
  min-width: 100%;
  width: fit-content;
  min-width: 190px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  min-height: 60px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.12);
  overflow-y: auto;
  outline: none;
`;

const Option = styled.div`
  width: 100%;
  padding: 14px 18px;
  white-space: nowrap;
  transition: background 0.14s ease-in-out;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.font300};
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
  }
`;
const OprionIconBox = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 6px;
  color: ${({ theme }) => theme.color.font100};
`;

const SimpleDropdown = ({ icon, data = [] }) => {
  const Icon = icon || FilterIcon;

  const [isOpen, setIsOpen] = useState(false);
  const [directionStyles, setDirectionStyles] = useState({ top: "26px" });

  // Close when click outside
  const picker = useRef(null);
  useDetectOutsideClick(picker, setIsOpen);

  // Animations
  function enterAnimation(node, done) {
    gsap
      .timeline()
      .from(node, {
        autoAlpha: 0,
        scaleY: 0,
        duration: 0.2,
        ease: "Power2.inOut",
      })
      .from(
        node.childNodes,
        {
          x: -16,
          autoAlpha: 0,
          stagger: 0.06,
          duration: 0.25,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.05"
      );
  }

  function exitAnimation(node, done) {
    gsap.timeline().to(node, {
      autoAlpha: 0,
      duration: 0.3,
      onComplete: done,
      ease: "Power2.inOut",
    });
  }

  function handleOptionClick(handler) {
    handler();
    setIsOpen(false);
  }

  const setDirection = () => {
    if (picker.current) {
      const screen = { h: window.innerHeight };
      const { y } = picker.current.getBoundingClientRect();

      const isEnoughBottomSpace = !(screen.h - y < 220);

      setDirectionStyles(
        isEnoughBottomSpace ? { top: "26px" } : { bottom: "26px" }
      );
    }
  };

  const handleOpen = () => {
    if (!isOpen) setDirection();
    setIsOpen(!isOpen);
  };

  return (
    <Container ref={picker}>
      <IconBox onClick={handleOpen}>
        <Icon />
      </IconBox>
      <Transition
        timeout={300}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <Options style={directionStyles}>
          {data.map(({ name, handler, icon: OptionIcon }) => (
            <Option key={name} onClick={() => handleOptionClick(handler)}>
              {OptionIcon && (
                <OprionIconBox>
                  <OptionIcon />
                </OprionIconBox>
              )}
              {name}
            </Option>
          ))}
        </Options>
      </Transition>
    </Container>
  );
};

export default SimpleDropdown;
