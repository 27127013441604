import styled, { css } from "styled-components";

export const Button = styled.div`
  display: block;
  width: 100%;
  height: ${({ big }) => (big ? "46px" : "38px")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  background-color: ${({ theme }) => theme.color.primaryButtonBackground};
  color: ${({ theme }) => theme.color.primaryButtonText};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 30px;
  border-radius: 4px;
  border: none;
  user-select: none;
  transition: transform 0.18s ease-in-out;

  :hover {
    cursor: pointer;
  }

  ${({ theme, dark }) =>
    dark &&
    css`
      background-color: ${theme.color.darkButtonBackground};
      color: ${theme.color.darkButtonText};
    `}
`;
