import {
  CREATE_USER_STARTED,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LIST_USERS_STARTED,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  SEARCH_USERS_STARTED,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_FULL_STARTED,
  DELETE_USER_FULL_SUCCESS,
  DELETE_USER_FULL_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingSimple: false,
  nextToken: null,
  total: null,
  users: [],
  usersSimple: [],
  user: {},
  error: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        users: [action.payload, ...state.users],
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case LIST_USERS_STARTED:
      return {
        ...state,
        loadingSimple: true,
      };
    case LIST_USERS_SUCCESS:
      return {
        ...state,
        loadingSimple: false,
        error: null,
        usersSimple: action.payload,
      };
    case LIST_USERS_FAILURE:
      return {
        ...state,
        loadingSimple: false,
        error: action.payload.error,
      };

    case SEARCH_USERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.payload.users,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_USER_FULL_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_USER_FULL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        users: state.users.filter((item) => item.id !== action.payload),
      };
    case DELETE_USER_FULL_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_USER_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_USER_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        users: state.users.map((user) =>
          user.id === action.payload.id ? { ...user, ...action.payload } : user
        ),
        user: action.payload,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default roleReducer;
