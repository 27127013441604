import React from "react";
const Pdf_big = (props) => (
    <svg width={106} height={124} {...props}>
        <g fill="none" fillRule="evenodd">
            <rect fill="#FFEEF1" width={105.4} height={124} rx={4} />
            <path
                d="M35 38v47h36V38H35zm3.6 3.615h28.8v39.77H38.6v-39.77zm13.331 9.66c-.548-.007-1.188.19-1.631.565-.457.389-.66.869-.787 1.356-.247.974-.176 1.984.056 3.107.274 1.313 1.054 2.895 1.687 4.406-.323 1.377-.408 2.599-.9 4.011-.422 1.214-.963 1.914-1.462 2.994-1.132.43-2.482.685-3.375 1.243-.964.6-1.807 1.264-2.306 2.203-.5.939-.443 2.267.224 3.22.331.501.767.897 1.35 1.13.584.233 1.217.24 1.744.056 1.062-.367 1.814-1.186 2.532-2.147.667-.89 1.139-2.4 1.743-3.615.907-.304 1.561-.685 2.532-.904 1.012-.226 1.694-.12 2.643-.226.408.466.753 1.215 1.181 1.582.858.749 1.8 1.342 2.925 1.412 1.125.07 2.25-.635 2.87-1.694h.056v-.057c.274-.48.464-1.003.45-1.582-.015-.579-.303-1.186-.675-1.581-.739-.791-1.681-.996-2.7-1.13-.788-.106-1.885.176-2.813.226-.816-1.08-1.624-1.893-2.362-3.277-.401-.748-.507-1.384-.844-2.146.26-1.229.773-2.599.843-3.672.085-1.3.036-2.422-.337-3.446-.19-.516-.492-1.024-.956-1.412a2.685 2.685 0 0 0-1.632-.622h-.056zm1.181 13.388c.324.572.725.933 1.07 1.47-.507.091-.894 0-1.407.112-.084.021-.14.092-.225.113.105-.282.24-.452.338-.734.112-.332.119-.629.224-.96zm6.638 3.672c.605.078.823.191.9.226-.014.029.021.021 0 .057-.225.374-.246.346-.394.339-.12-.007-.576-.254-1.012-.565.126.007.394-.07.506-.057zm-12.6 2.825c-.098.148-.183.494-.281.621-.549.735-1.055 1.074-1.181 1.13-.022-.028.035 0 0-.056h-.057c-.183-.262-.133-.156 0-.396.134-.24.556-.727 1.294-1.186.056-.035.169-.078.225-.113z"
                fill="#FF2D55"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default Pdf_big;