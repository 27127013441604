import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useTheme } from "styled-components";

export default function Loader({ loading, size = 20, color }) {
  const { color: themColor } = useTheme();

  return (
    <ClipLoader
      loading={loading}
      size={size}
      color={color || themColor.primaryColor}
    />
  );
}
