import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

const Box = styled.div`
  padding: 9px 17px;
  width: fit-content;
  border-radius: 4px;
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ status }) => status.color};
  background-color: ${({ status }) => status.background};
  border: 1px dashed ${({ status }) => status.border || "transparent"};
  user-select: none;
`;

const Status = ({ status }) => {
  const { color: themeColor } = useTheme();

  const statusStates = useMemo(
    () => ({
      PENDING: {
        title: "Robocze",
        color: themeColor.reportPending(),
        background: themeColor.reportPending(true),
        border: themeColor.mainBorder,
      },
      SCHEDULED: {
        title: "Zaplanowane",
        color: themeColor.reportScheduled(),
        background: themeColor.reportScheduled(true),
      },
      IN_PROGRESS: {
        title: "W toku",
        color: themeColor.reportInProgress(),
        background: themeColor.reportInProgress(true),
      },
      COMPLETED: {
        title: "Gotowe",
        color: themeColor.reportCompleted(),
        background: themeColor.reportCompleted(true),
      },
    }),
    [themeColor]
  );

  const [currentStatus, setCurrentStatus] = useState(statusStates.PENDING);
  useEffect(() => {
    setCurrentStatus(statusStates[status]);
  }, [status]);

  return <Box status={currentStatus}>{currentStatus.title}</Box>;
};

export default Status;
