import React from "react";
import styled, {css} from "styled-components";
import { useTable } from "react-table";

import Loader from "../Loader";
import { SortAmountDownIcon, SortAmountUpIcon, SortAlphaDownIcon, SortAlphaUpIcon, SortNumericDownIcon, SortNumericUpIcon } from "react-line-awesome";

const Box = styled.div`
  width: 100%;
  flex: 1;
  @media only screen and (max-width: 600px) {
    overflow-x: auto;
  }
`;
const TableBox = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  text-align: left;
  margin: 0;

  thead {
    th {
      height: 50px;
      color: ${({ theme }) => theme.color.font200};
      font-weight: ${({ theme }) => theme.weight.regular};
      font-size: 14px;
      white-space: nowrap;
      padding: 13px 15px;
      border-bottom: 2px solid ${({ theme }) => theme.color.tableHeaderLine};
    }
  }

  tbody {
    
    td,
    th {
      padding: 18px 15px;
    }

    td {
      font-size: 14px;
      color: ${({ theme }) => theme.color.font300};
      font-weight: ${({ theme }) => theme.weight.regular};
      height: 64px;
      letter-spacing: 0.3px;
    }

    tr {
      background-color: white;

      &,
      .rowHover {
        transition: 0.14s ease-in-out;
      }

      :hover {
        background-color: ${({ theme }) => theme.color.mainBackgroundOpacity10};

        .rowHover {
          color: ${({ theme }) => theme.color.primaryColor};
        }
      }
    }

    td {
      border-bottom: 1px solid ${({ theme }) => theme.color.secondaryBorder};
    }
  }

  td:last-of-type {
    width: 100px;
    color: ${({ theme }) => theme.color.font100};
  }
`;

const CenterBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
const InsideHeader = styled.div`
  display: flex;
  cursor: pointer;
  ${({ align }) => align && css`justify-content: center`};
  align-items: center;
  font-size: 14px;
`;
const IconBox = styled.div`
  margin-left: 8px;
  font-size: 17px;
`;

const LibraryTable = ({ data, columns, loading, sorting }) => {
    const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
        useTable({
            columns,
            data,
        });

    let tableInfo;
    if (!rows || loading) {
        tableInfo = (
            <CenterBox>
                <Loader />
            </CenterBox>
        );
    } else if (rows.length === 0) {
        tableInfo = (
            <CenterBox>
                <p>Brak danych</p>
            </CenterBox>
        );
    } else if (rows.length > 0) {
        tableInfo = null;
    }

    let generateDirectionIcon = (column) => {
        if (sorting?.id === column.id) {
            if (sorting?.id === 'name') {
                if (sorting?.direction === "asc") {
                    return <SortAlphaDownIcon />;
                } else {
                    return <SortAlphaUpIcon />;
                }
            } else if (sorting?.id === 'createdAt') {
                if (sorting?.direction === "asc") {
                    return <SortNumericDownIcon />;
                } else {
                    return <SortNumericUpIcon />;
                }
            }

        } else {
            return null;
        }
    };



    return (
        <Box>
            <TableBox {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps({
                                    onClick: () => sorting.onClick(column),
                                    style: { minWidth: column.minWidth, width: column.width },
                                })}
                            >
                                <InsideHeader align={column.align}>
                                    {column.render("Header")}
                                    <IconBox>{generateDirectionIcon(column)}</IconBox>
                                </InsideHeader>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {!tableInfo &&
                rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </TableBox>
            {tableInfo}
        </Box>
    );
};

export default LibraryTable;
