import React from "react";

export default function Wrench({ width = 10, fill = "#FFFFFF" }) {
  return (
    <svg
      width={`${width}px`}
      //   viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.612 7.737L6.055 4.18c.119-.331.195-.683.195-1.055C6.25 1.399 4.85 0 3.125 0c-.387 0-.754.08-1.096.208l1.694 1.694-1.875 1.875L.182 2.112C.072 2.432 0 2.768 0 3.125 0 4.851 1.4 6.25 3.125 6.25c.372 0 .724-.076 1.055-.195l3.557 3.557a1.326 1.326 0 1 0 1.875-1.875z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
