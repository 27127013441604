import {
  GET_ROOTFILE_FAILURE,
  GET_ROOTFILE_STARTED,
  GET_ROOTFILE_SUCCESS,
  SEARCH_ROOTFILES_STARTED,
  SEARCH_ROOTFILES_SUCCESS,
  SEARCH_ROOTFILES_FAILURE,
  DELETE_ROOTFILE_STARTED,
  DELETE_ROOTFILE_SUCCESS,
  DELETE_ROOTFILE_FAILURE,
  CREATE_ROOTFILE_FAILURE,
  CREATE_ROOTFILE_STARTED,
  CREATE_ROOTFILE_SUCCESS,
  SET_PARENT,
} from "../types";
import { format } from "date-fns";
import { API, graphqlOperation } from "aws-amplify";
import * as graphql from "../../graphql/rootFiles";
import { listUsers } from "../../graphql/users";
import _, { isString } from "lodash";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";
import {
  checkIfFileExists,
  deletePhoto,
  emptyBucket,
} from "../../helpers/functions";

const getRootFileSuccess = (role) => ({
  type: GET_ROOTFILE_SUCCESS,
  payload: role,
});
const getRootFileStarted = () => ({
  type: GET_ROOTFILE_STARTED,
});
const getRootFileFailure = (error) => ({
  type: GET_ROOTFILE_FAILURE,
  payload: {
    error,
  },
});

export const getRootFile = (id) => async (dispatch) => {
  try {
    dispatch(getRootFileStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getRootFile, {
        id,
      })
    );
    const role = data.getRootFile;

    dispatch(getRootFileSuccess(role));
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(getRootFileFailure(err.errors));
  }
};

const searchRootFilesSuccess = (rootFiles, nextToken, total) => ({
  type: SEARCH_ROOTFILES_SUCCESS,
  payload: {
    rootFiles,
    nextToken,
    total,
  },
});
const searchRootFilesStarted = () => ({
  type: SEARCH_ROOTFILES_STARTED,
});
const searchRootFilesFailure = (error) => ({
  type: SEARCH_ROOTFILES_FAILURE,
  payload: {
    error,
  },
});

export const searchRootFiles = (variables) => async (dispatch) => {
  try {
    dispatch(searchRootFilesStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchRootFiles, variables)
    );
    const nextToken = data.searchRootFiles.nextToken;
    const total = data.searchRootFiles.total;
    const rootFiles = data.searchRootFiles.items;

    dispatch(searchRootFilesSuccess(rootFiles, nextToken, total));
  } catch (err) {
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(searchRootFilesFailure(err));
  }
};

const deleteRootFileSuccess = (role) => ({
  type: DELETE_ROOTFILE_SUCCESS,
  payload: role,
});
const deleteRootFileStarted = (id) => ({
  type: DELETE_ROOTFILE_STARTED,
  payload: id,
});
const deleteRootFileFailure = (error) => ({
  type: DELETE_ROOTFILE_FAILURE,
  payload: {
    error,
  },
});

export const deleteRootFile = (id, path) => async (dispatch) => {
  try {
    if (path !== undefined) {
      await deletePhoto(path);
    }

    dispatch(deleteRootFileStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteRootFile, {
        input: { id },
      })
    );

    dispatch(deleteRootFileSuccess(id));
    toast.success(<MainToast message="Plik usunięty pomyślnie" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(deleteRootFileFailure(err.errors));
  }
};

export const deleteMultipleRootFiles =
  (folderPath, id, url) => async (dispatch) => {
    try {
      emptyBucket(url);
      dispatch(deleteRootFileStarted(id));
      await API.graphql(
        graphqlOperation(graphql.deleteRootFile, {
          input: { id },
        })
      );
      await API.graphql(
        graphqlOperation(graphql.deleteMultipleRootFiles, {
          parentId: folderPath,
        })
      );

      dispatch(deleteRootFileSuccess(id));
      toast.success(<MainToast message="Plik usunięty pomyślnie" />);
    } catch (err) {
      console.log(err);
      toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
      dispatch(deleteRootFileFailure(err.errors));
    }
  };

const createRootFileSuccess = (role) => ({
  type: CREATE_ROOTFILE_SUCCESS,
  payload: role,
});
const createRootFileStarted = () => ({
  type: CREATE_ROOTFILE_STARTED,
});
const createRootFileFailure = (error) => ({
  type: CREATE_ROOTFILE_FAILURE,
  payload: {
    error,
  },
});

export const createRootFile = (info, callback) => async (dispatch) => {
  try {
    dispatch(createRootFileStarted());

    if (await checkIfFileExists(info.name)) {
      dispatch(createRootFileFailure("file exists"));
      return;
    }

    const inputs = {
      id: info.fileName,
      ref: info.ref,
      name: info.name,
      size: info.size,
      isFolder: info.isFolder,
      parentId: info.parentId,
    };
    const { data } = await API.graphql(
      graphqlOperation(graphql.createRootFile, {
        input: inputs,
      })
    );
    const rootFile = data.createRootFile;
    dispatch(createRootFileSuccess(rootFile));
    callback && callback();
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(createRootFileFailure(err.errors));
  }
};

export const createRootFiles = (filesArray, callback) => async (dispatch) => {
  try {
    const preparedFiles = [];

    for (const file of filesArray) {
      preparedFiles.push({
        id: file.fileName,
        ref: file.ref,
        name: file.name,
        size: file.size,
        parentId: file.parentId,
      });
    }

    dispatch(createRootFileStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.createRootFiles, {
        input: preparedFiles,
      })
    );

    const rootFiles = data.createMultipleRootFiles;

    dispatch(createRootFileSuccess(rootFiles));
    callback && callback();
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(createRootFileFailure(err.errors));
  }
};

export const setParent = (parent) => ({
  type: SET_PARENT,
  payload: parent,
});
