import {
  SortAlphaDownIcon,
  SortAlphaUpIcon,
  SortAmountDownIcon,
} from "react-line-awesome";

import { SET_LIMIT, NEXT_PAGE, PREV_PAGE, SET_SORTING } from "../types";

export const alphabeticallyArray = [
  { name: "Domyślnie", value: "DEFAULT", icon: SortAmountDownIcon },
  { name: "A-Z", value: "asc", icon: SortAlphaUpIcon },
  { name: "Z-A", value: "desc", icon: SortAlphaDownIcon },
];
export const limitArray = [
  // { name: "1", value: 1 },
  { name: "10", value: 10 },
  { name: "25", value: 25 },
  { name: "50", value: 50 },
];

const initialState = {
  pagination: {
    limit: limitArray[0],
    currentPage: 0,
    nextTokenArray: [null],
  },
  sorting: {
    alphabetically: alphabeticallyArray[0],
  },
};

const tableReducer = (state = initialState, action) => {
  const { pagination } = state;
  const { limit, currentPage, nextTokenArray } = pagination;

  switch (action.type) {
    case SET_LIMIT:
      return {
        ...state,
        pagination: { ...initialState.pagination, limit: action.payload },
      };
    case NEXT_PAGE:
      if (limit.value * (currentPage + 1) < action.payload.total) {
        return {
          ...state,
          pagination: {
            ...pagination,
            currentPage: currentPage + 1,
            nextTokenArray: [...nextTokenArray, action.payload.nextToken],
          },
        };
      } else {
        return state;
      }
    case PREV_PAGE:
      if (currentPage > 0) {
        const tokensArray = nextTokenArray;
        tokensArray.pop();

        return {
          ...state,
          pagination: {
            ...pagination,
            currentPage: currentPage - 1,
            nextTokenArray: tokensArray,
          },
        };
      } else {
        return state;
      }
    case SET_SORTING:
      return {
        ...state,
        pagination: {
          ...initialState.pagination,
          limit: state.pagination.limit,
        },
        sorting: { ...state.sorting, ...action.payload },
      };

    default:
      return state;
  }
};

export default tableReducer;
