import React from "react";
import { DownloadIcon } from "react-line-awesome";
import styled from "styled-components";
import { format } from "date-fns";

import icon from "../../assets/images/pdf_icon.png";

import { Label } from "../Input";

const protocolTypeTranslation = {
  TOTAL: "Całkowity",
  PARTIAL: "Częściowy",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;

  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.font400};
  padding-right: 5px;
`;
const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weight.medium};
`;
const DownloadIconBox = styled.div`
  display: flex;
  font-size: 22px;
  color: ${({ theme }) => theme.color.font100};
  padding-right: 5px;

  cursor: pointer;
`;
const Image = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const ProtocolComponent = ({ protocols }) => {
  return (
    <Container>
      <Label>Protokoły</Label>
      <ItemsList>
        {protocols &&
          protocols.map((protocol) => (
            <Item key={protocol.id}>
              <Content>
                <Image src={icon} alt="Ikona" />
                <Text>
                  <Bold>{protocolTypeTranslation[protocol.type]}</Bold> z dnia{" "}
                  <Bold>
                    {format(new Date(protocol.createdAt), "dd.MM.yyyy")}
                  </Bold>
                </Text>
              </Content>
              <DownloadIconBox
                onClick={() =>
                  window.open(
                    `https://montergo-images222525-wip.s3.eu-central-1.amazonaws.com/${protocol.ref}`
                  )
                }
              >
                <DownloadIcon />
              </DownloadIconBox>
            </Item>
          ))}
      </ItemsList>
    </Container>
  );
};

export default ProtocolComponent;
