import React from "react";

export default function Checked({ width = 11, fill = "#FFFFFF" }) {
  return (
    <svg
      width={`${width}px`}
      // viewBox="0 0 11 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Autoryzacja"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Logowanie"
          transform="translate(-546.000000, -555.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Box" transform="translate(443.000000, 208.000000)">
            <g id="Checkbox" transform="translate(98.000000, 341.000000)">
              <path
                d="M5.1571014,10.356278 C4.94763287,10.1468095 4.94763287,9.80718005 5.1571014,9.59769057 L5.91566787,8.83910314 C6.1251364,8.62961366 6.46478678,8.62961366 6.6742553,8.83910314 L9.02299738,11.1878243 L14.0537412,6.1571014 C14.2632097,5.94763287 14.6028601,5.94763287 14.8123286,6.1571014 L15.5708951,6.91568883 C15.7803636,7.12515735 15.7803636,7.46478678 15.5708951,7.67427626 L9.40229109,13.8429012 C9.19280161,14.0523698 8.85317219,14.0523698 8.64370366,13.8428803 L5.1571014,10.356278 Z"
                id="Icon"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
