/* --------------------------------- Queries -------------------------------- */
export const listTypeItems = /* GraphQL */ `
  query ListTypeItems($filter: ModelTypeItemFilterInput) {
    listTypeItems(filter: $filter) {
      items {
        id
        name
        icon
        producerOrder
        specification
        attachments
        customerOrder
        photos
        description
        measurementCard
        notes
        photos
        protocol
        types {
          items {
            id
            type {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const searchTypeItems = /* GraphQL */ `
  query SearchTypeItems($filter: SearchableTypeItemFilterInput, $limit: Int) {
    searchTypeItems(filter: $filter, limit: $limit) {
      items {
        id
        name
        icon
        types {
          items {
            id
            type {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getTypeItem = /* GraphQL */ `
  query GetTypeItem($id: ID!) {
    getTypeItem(id: $id) {
      id
      name
      icon
      producerOrder
      specification
      attachments
      customerOrder
      photos
      description
      measurementCard
      notes
      photos
      protocol
      types {
        items {
          id
          type {
            id
            name
          }
        }
      }
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createTypeItem = /* GraphQL */ `
  mutation CreateTypeItem($input: CreateTypeItemInput!) {
    createTypeItem(input: $input) {
      id
      name
      types {
        items {
          id
          type {
            id
            name
          }
        }
      }
    }
  }
`;

export const createType = /* GraphQL */ `
  mutation CreateType($input: CreateTypeInput!) {
    createType(input: $input) {
      id
      name
    }
  }
`;

export const createTypeConnector = /* GraphQL */ `
  mutation CreateTypeConnector($input: CreateTypeConnectorInput!) {
    createTypeConnector(input: $input) {
      id
    }
  }
`;

export const updateTypeItem = /* GraphQL */ `
  mutation UpdateTypeItem($input: UpdateTypeItemInput!) {
    updateTypeItem(input: $input) {
      id
      name
      icon
      producerOrder
      specification
      attachments
      customerOrder
      photos
      description
      measurementCard
      notes
      photos
      protocol
      types {
        items {
          id
          type {
            id
            name
          }
        }
      }
    }
  }
`;

export const updateTypeItemAll = /* GraphQL */ `
  mutation UpdateTypeItemAll($input: updateTypeItemAll) {
    updateTypeItemAll(input: $input) {
      id
      name
      icon
      producerOrder
      specification
      attachments
      customerOrder
      photos
      description
      measurementCard
      notes
      photos
      protocol
      types {
        items {
          id
          type {
            id
            name
          }
        }
      }
    }
  }
`;

export const deleteTypeItem = /* GraphQL */ `
  mutation DeleteTypeItem($input: DeleteTypeItemInput!) {
    deleteTypeItem(input: $input) {
      id
      types {
        items {
          id
          type {
            id
            name
          }
        }
      }
    }
  }
`;

export const deleteType = /* GraphQL */ `
  mutation DeleteType($input: DeleteTypeInput!) {
    deleteType(input: $input) {
      id
    }
  }
`;

export const deleteTypeConnector = /* GraphQL */ `
  mutation DeleteTypeConnector($input: DeleteTypeConnectorInput!) {
    deleteTypeConnector(input: $input) {
      id
    }
  }
`;
