import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { boolArr2dec, permissionObject2Array } from "../../helpers/functions";
import { getRole, updateRole } from "../../redux/actions/roleActions";
import { roleTranslation } from "../../util/variables";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditRole = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const roleState = useSelector(({ role }) => role);
  const { loadingUpdate, loadingGet } = roleState;

  useEffect(() => {
    dispatch(getRole(id));
  }, [id, dispatch]);

  useEffect(() => {
    const { permissions, name } = roleState.role;
    let permissionArray = [];

    for (const key in permissions) {
      if (Object.hasOwnProperty.call(permissions, key)) {
        const permission = permissions[key];
        const name = roleTranslation[key];

        permissionArray = [...permissionArray, { name, slug: key, permission }];
      }
    }

    setValue("name", name);
  }, [roleState.role, setValue]);

  const onSubmit = (data) => {
    let { name, permission } = data;

    for (const key in permission) {
      if (Object.hasOwnProperty.call(permission, key)) {
        const element = permission[key];

        permission[key] = boolArr2dec(Object.values(element));
      }
    }

    dispatch(updateRole({ id, name, permissions: permission }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields
        form={form}
        permissionData={permissionObject2Array(roleState.role.permissions)}
        loading={loadingGet}
      />
      <Buttons>
        <ButtonBox>
          <Submit value="zapisz" loader={loadingUpdate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

EditRole.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EditRole;
