import {
  SEARCH_ROOTFILES_STARTED,
  SEARCH_ROOTFILES_SUCCESS,
  SEARCH_ROOTFILES_FAILURE,
  LIST_ROOTFILES_STARTED,
  LIST_ROOTFILES_SUCCESS,
  LIST_ROOTFILES_FAILURE,
  CREATE_ROOTFILE_STARTED,
  CREATE_ROOTFILE_SUCCESS,
  CREATE_ROOTFILE_FAILURE,
  GET_ROOTFILE_STARTED,
  GET_ROOTFILE_SUCCESS,
  GET_ROOTFILE_FAILURE,
  DELETE_ROOTFILE_STARTED,
  DELETE_ROOTFILE_SUCCESS,
  DELETE_ROOTFILE_FAILURE,
  SET_PARENT,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  rootFiles: [],
  rootFile: {},
  error: null,
  parentId: "ROTO",
};

const rootfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ROOTFILE_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_ROOTFILE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        rootFiles: [action.payload, ...state.rootFiles],
      };
    case CREATE_ROOTFILE_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case SEARCH_ROOTFILES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ROOTFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        rootFiles: action.payload.rootFiles,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_ROOTFILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case LIST_ROOTFILES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_ROOTFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        rootFiles: action.payload,
      };
    case LIST_ROOTFILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_ROOTFILE_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_ROOTFILE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        rootFiles: state.rootFiles.filter((item) => item.id !== action.payload),
      };
    case DELETE_ROOTFILE_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_ROOTFILE_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_ROOTFILE_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        rootFile: action.payload,
      };
    case GET_ROOTFILE_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };
    case SET_PARENT:
      return {
        ...state,
        parentId: action.payload,
      };
    default:
      return state;
  }
};

export default rootfileReducer;
