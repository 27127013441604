export const searchTicketsForLog = /* GraphQL */ `
  query SearchTicketsForLog(
    $limit: Int
    $filter: SearchableTicketFilterInput
    $sort: SearchableTicketSortInput
  ) {
    searchTickets(
      limit: $limit
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        deadline
        status
        startDate
        createdAt
        notes
        client {
          id
          name
          fullName
          phoneNumber
          latitude
          longitude
        }
        building {
          latitude
          longitude
          address
        }
        typeItem {
          id
          icon
          name
          attachments
          customerOrder
          description
          measurementCard
          notes
          photos
          producerOrder
          protocol
          specification
          types {
            items {
              type {
                id
                name
              }
            }
          }
        }
        type {
          id
          name
        }
        team {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const searchStatusList = /* GraphQL */ `
  query SearchStatusList(
    $limit: Int
    $filter: SearchableTicketFilterInput
  ) {
    searchTickets(
      limit: $limit
      filter: $filter
    ) {
      items {
        status
      }
    }
  }
`;

export const searchReportPending = /* GraphQL */`
  query SearchReportPending(
    $limit: Int
    $filter: SearchableTicketFilterInput
    $sort: SearchableTicketSortInput 
  ) {
    searchTickets(
      limit: $limit
      filter: $filter
      sort: $sort
    ){
      items {
        client {
          name
        }
        type {
          name
        }
        typeItem {
          icon
        }
        createdAt
      }
    }
  }
`;

export const searchReportsDatesForCalendar = /* GraphQL */`
  query SearchReportsDatesForCalendar(
    $limit: Int
    $filter: SearchableTicketFilterInput
    $sort: SearchableTicketSortInput 
  ) {
    searchTickets(
      limit: $limit
      filter: $filter
      sort: $sort
    ) {
      items {
        startDate
      }
    }
  }
`;