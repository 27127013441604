import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import { PencilAltIcon } from "react-line-awesome";

import MainTemplate from "../../templates/MainTemplate";
import DataTable from "../../components/dataTable/DataTable";
import ActionsPanel from "../../components/dataTable/ActionsPanel";
import BottomPanel from "../../components/dataTable/BottomPanel";
import SideModal from "../../components/modal/SideModal";
import EditUser from "../../components/users/EditUser";
import CreateUser from "../../components/users/CreateUser";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserFull, searchUsers } from "../../redux/actions/userActions";
import { usePermissionState } from "../../context/permission";
import { parsePhoneNumber } from "libphonenumber-js";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import usePermissionObject from "../../hooks/usePermissionObject";
import { useConfirmModalDispatch } from "../../context/ModalContext";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const CompanyName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.font300};
  margin-left: 24px;
  font-weight: ${({ theme }) => theme.weight.regular};
  transition: 0.14s ease-in-out;
`;
const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover ${CompanyName} {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;
const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

export default function Users() {
  const columns = useMemo(
    () => [
      {
        accessor: "user",
      },
      {
        accessor: "role",
      },
      {
        accessor: "email",
      },
      {
        accessor: "phone",
      },
      {
        accessor: "icons",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const modalDispatch = useConfirmModalDispatch();
  const permissionObject = usePermissionObject("users");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    userState: state.user,
    tableState: state.table,
  }));
  const { userState, tableState } = state;
  const { pagination, sorting } = tableState;
  const { nextToken, total } = userState;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const searchUsersVariables = useMemo(
    () => ({
      filter: {
        companyId: {
          eq: companyId,
        },
        roleId: {
          exists: true,
        },
        cognitoId: {
          ne: cognitoUser.username,
        },
      },
      sort: {
        direction:
          sorting.alphabetically.value === "DEFAULT"
            ? null
            : sorting.alphabetically.value,
        field: "fullName",
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
    }),
    [companyId, pagination, sorting, cognitoUser.username]
  );

  useEffect(() => {
    dispatch(searchUsers(searchUsersVariables));
  }, [dispatch, searchUsersVariables]);

  const users = useMemo(() => {
    return userState.users.map((item) => {
      const { id, fullName, phone, role } = item;
      const isDeleteLoading = userState.loadingDelete === id;

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć użytkownika?",
            buttonText: "Usuń",
            action: () => dispatch(deleteUserFull(id)),
          },
        });
      };

      return {
        ...item,
        user: (
          <CompanyContainer onClick={() => openEdit(id)}>
            <Avatar
              name={fullName}
              size="40"
              round={true}
              textSizeRatio={2}
              maxInitials={2}
              style={{
                fontFamily: "'Rubik', sans-serif",
                fontWeight: 500,
              }}
              // src={url}
            />
            <CompanyName className="rowHover">{fullName}</CompanyName>
          </CompanyContainer>
        ),
        role: role && role.name,
        phone: parsePhoneNumber(phone, "PL").nationalNumber.replace(
          /(\d{3})(\d{3})(\d{3})/,
          "$1-$2-$3"
        ),
        icons: (
          <>
            {permissionObject.edit && (
              <IconBox onClick={() => openEdit(id)}>
                <PencilAltIcon />
              </IconBox>
            )}
            {permissionObject.del && (
              <IconBox onClick={handleDelete}>
                <RowDeleteLoader loading={isDeleteLoading} />
              </IconBox>
            )}
          </>
        ),
      };
    });
  }, [
    userState.users,
    userState.loadingDelete,
    permissionObject,
    modalDispatch,
    dispatch,
  ]);

  return (
    <MainTemplate title="Użytkownicy">
      <TableBox>
        {permissionObject.create && (
          <ActionsPanel
            addText="Nowy użytkownik"
            setIsSidebarOpen={setIsCreateOpen}
          />
        )}
        <DataTable data={users} columns={columns} loading={userState.loading} />
        <BottomPanel nextToken={nextToken} total={total} />
      </TableBox>

      {permissionObject.create && (
        <SideModal
          title="Nowy użytkownik"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateUser setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}

      {permissionObject.edit && (
        <SideModal
          title="Edycja użytkownika"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        >
          <EditUser setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </MainTemplate>
  );
}
