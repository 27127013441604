/* --------------------------------- Queries -------------------------------- */
export const listBuildings = /* GraphQL */ `
  query ListBuildings($filter: ModelBuildingFilterInput) {
    listBuildings(filter: $filter) {
      items {
        id
        name
        address
      }
    }
  }
`;

export const searchBuildings = /* GraphQL */ `
  query SearchBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: SearchableBuildingSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address
        latitude
        longitude
      }
      nextToken
      total
    }
  }
`;

export const getBuilding = /* GraphQL */ `
  query GetBuilding($id: ID!) {
    getBuilding(id: $id) {
      id
      name
      address
      latitude
      longitude
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createBuilding = /* GraphQL */ `
  mutation CreateBuilding($input: CreateBuildingInput!) {
    createBuilding(input: $input) {
      id
      name
      address
    }
  }
`;

export const updateBuilding = /* GraphQL */ `
  mutation UpdateBuilding($input: UpdateBuildingInput!) {
    updateBuilding(input: $input) {
      id
      name
      address
    }
  }
`;

export const deleteBuilding = /* GraphQL */ `
  mutation DeleteBuilding($input: DeleteBuildingInput!) {
    deleteBuilding(input: $input) {
      id
      name
      address
    }
  }
`;
