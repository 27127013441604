import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../Button";
import Submit from "../../Submit";
import FormFields from "./FormFields";
import { usePermissionState } from "../../../context/permission";
import { createNotificationType } from "../../../redux/actions/settings/notificationTypeActions";
import { typeIconsList } from "../../icons/typeIconsList";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateNotificationType = ({ setIsOpen }) => {
  const form = useForm({ defaultValues: { types: [] } });
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const { loadingCreate, loadingGet } = useSelector(({ typeItem }) => typeItem);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  useEffect(() => {
    setValue("icon", typeIconsList[0]);
  }, [setValue]);

  const onSubmit = (data) => {
    const variables = {
      ...data,
      types: data.types.reduce((acc, item) => (acc = [...acc, item.name]), []),
      icon: data.icon.value,
      companyId,
    };

    dispatch(createNotificationType(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={loadingGet} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateNotificationType.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateNotificationType;
