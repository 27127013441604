import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconBox = styled.div`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  display: flex;
  height: 11px;
  top: 6px;
  left: 5px;
  pointer-events: none;
`;

const TypeIcon = ({ icon: Icon, color, ...rest }) => {
  return (
    <Container color={color}>
      <IconBox {...rest}>
        <Icon />
      </IconBox>
    </Container>
  );
};

export default TypeIcon;
