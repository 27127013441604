import React from "react";
import styled from "styled-components";
import NavLink from "./NavLink";

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.h5`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.14s ease-in-out;
  font-weight: ${({ theme }) => theme.weight.light};
  font-size: 14px;
  margin: 13px 0 12px 26px;
  color: ${({ theme }) => theme.color.font200}; ;
`;

const Text = styled.div`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.24s ease-in-out;
`;

const EmptySection = styled.div``;

const Navigation = styled.div`
  margin-top: 13px;
`;

const SidebarSection = ({
  navLinksArray,
  linksArray,
  isOpen,
  showInformationAboutNavSection,
}) => {
  return (
    <Navigation>
      {navLinksArray.map(({ section }, index) => {
        return linksArray[index].length > 0 ? (
          <SectionBox key={index}>
            {section ? (
              <SectionHeader isOpen={isOpen}>{section}</SectionHeader>
            ) : null}
            {linksArray[index].map(
              ({ name, slug, path, icon: Icon, isDisabled }) => {
                return (
                  <NavLink
                    key={`${name}${slug}`}
                    to={path}
                    Icon={Icon}
                    name={name}
                    isDisabled={isDisabled}
                    showInformationAboutNavSection={
                      showInformationAboutNavSection
                    }
                  >
                    <Text isOpen={isOpen}>{isOpen && name}</Text>
                  </NavLink>
                );
              }
            )}
          </SectionBox>
        ) : (
          <EmptySection key={index} />
        );
      })}
    </Navigation>
  );
};

export default SidebarSection;
