import React, { useEffect, useReducer } from "react";
import { PlusCircleIcon } from "react-line-awesome";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Input, { Label } from "../../Input";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const SplitInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > *:nth-of-type(1) {
    width: calc(60% - 16px);
  }
  & > *:nth-of-type(2) {
    width: 40%;
  }
`;
const AddButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.font100};
  font-size: 14px;
  width: fit-content;

  cursor: pointer;
`;
const IconBox = styled.div`
  display: flex;
  transform: translateY(-1px);
  font-size: 18px;
  margin-right: 4px;
`;

const ProtocolFieldsInput = ({ field: { onChange }, edit }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "init":
        return action.items;

      case "add":
        return [...state, ""];

      default:
        const [target, index] = action;
        state[index] = target.value;
        return [...state];
    }
  };
  const [nameValue, dispatchName] = useReducer(reducer, [""]);
  const [moreValue, dispatchMore] = useReducer(reducer, [""]);

  const { protocol } = useSelector((state) => state.protocol);

  useEffect(() => {
    if (protocol.items && edit) {
      const [name, more] = JSON.parse(protocol.items).reduce(
        (acc, item) => {
          acc[0].push(item.name);
          acc[1].push(item.more);
          return acc;
        },
        [[], []]
      );
      dispatchName({ type: "init", items: name });
      dispatchMore({ type: "init", items: more });
    } else return;
    // eslint-disable-next-line
  }, [protocol]);

  const addFieldGroup = () => {
    dispatchName({ type: "add" });
    dispatchMore({ type: "add" });
  };

  useEffect(() => {
    const response = nameValue.map((_, i) => ({
      name: nameValue[i],
      more: moreValue[i],
    }));

    onChange(response);
  }, [nameValue, , moreValue]);

  return (
    <Container>
      <Label>Pozycje</Label>
      {nameValue.map((input, i) => (
        <SplitInputs key={`${input.name}_${i}`}>
          <Input
            name="name"
            placeholder="Nazwa"
            value={nameValue[i]}
            onChange={({ target }) => dispatchName([target, i])}
          />
          <Input
            name="more"
            placeholder="Dodatkowe"
            value={moreValue[i]}
            onChange={({ target }) => dispatchMore([target, i])}
          />
        </SplitInputs>
      ))}
      <AddButton onClick={addFieldGroup}>
        <IconBox>
          <PlusCircleIcon />
        </IconBox>
        Dodaj pozycję
      </AddButton>
    </Container>
  );
};

export default ProtocolFieldsInput;
