import React, {
  useEffect,
  forwardRef,
  useState,
  useReducer,
  useCallback,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Controller } from "react-hook-form";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import {
  AddressBookIcon,
  AlignLeftIcon,
  CalendarIcon,
  CheckSquareIcon,
  CommentIcon,
  DollyIcon,
  EllipsisHIcon,
  FileAltIcon,
  ImageIcon,
  TrashIcon,
} from "react-line-awesome";
import { assign, debounce, isEmpty, pick } from "lodash";
import { Collapse } from "react-collapse";

import "react-datepicker/dist/react-datepicker.css";

import { usePermissionState } from "../../context/permission";
import { searchClients } from "../../graphql/clients";
import { routes } from "../../routes";
import FormSectionTitle from "../FormSectionTitle";
import Input, { Label } from "../Input";
import Select from "../Select";
import useGetDropdownData from "../../hooks/useGetDropdownData";
import { getTypeItem } from "../../graphql/settings/notificationTypes";
import Loader from "../Loader";
import SelectTeam from "../SelectTeam";
import { listTeams } from "../../graphql/teams";

import MultiFilesUpload from "../MultiFilesUpload";
import { listFieldItems } from "../../graphql/settings/fields";
import { listProducts } from "../../graphql/settings/products";
import ProductCountInput from "../ProductCountInput";
import { getTypeIconByValue } from "../icons/typeIconsList";
import SelectStatus from "./SelectStatus";
import { useChangeStatus } from "../../hooks/tickets/useChangeStatus";
import SearchableSelectInput from "../searchableSelectInput";
import { searchBuildings } from "../../graphql/client/buildings";
import ProtocolComponent from "./ProtocolComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTicketAll,
  updateStatusTicket,
} from "../../redux/actions/ticketActions";
import { listProtocols } from "../../graphql/protocols";
import SimpleDropdown from "../SimpleDropdown";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import usePermissionObject from "../../hooks/usePermissionObject";
import { format, set, subDays } from "date-fns";

registerLocale("pl", pl);

const typeState = {
  empty: "empty",
  isLoading: "isLoading",
  hasLoaded: "hasLoaded",
  hasError: "hasError",
};

const typeItemMachine = createMachine({
  id: "type-item-machine",
  initial: typeState.empty,
  states: {
    [typeState.empty]: {
      on: {
        FETCH_TYPE: typeState.isLoading,
      },
    },
    [typeState.isLoading]: {
      on: {
        FETCH_TYPE_SUCCESS: typeState.hasLoaded,
        FETCH_TYPE_ERROR: typeState.hasError,
      },
    },
    [typeState.hasLoaded]: {
      on: {
        FETCH_TYPE: typeState.isLoading,
      },
    },
    [typeState.hasError]: {
      on: {
        FETCH_TYPE: typeState.isLoading,
      },
    },
  },
});

const FormSection = styled.div`
  margin-bottom: ${({ isClosed }) => (isClosed ? "18px" : "26px")};
  transition: margin 300ms;
`;
const CenterBox = styled.div`
  display: flex;
  justify-content: center;
`;
const SplitInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  & > * {
    max-width: calc(50% - 6px);
  }
`;

const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker {
    font-family: 'Rubik', sans-serif;
    font-weight: ${({ theme }) => theme.weight.regular};
    color: ${({ theme }) => theme.color.font300};
    font-size: 14px;
    background-color: white;
  ;

    ul.react-datepicker__time-list {
      ::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: lightgrey;
        border-radius: 10px;
      }
    }
    
    .react-datepicker__header{
      background-color: white;
      border: 0;
    }
    
    .react-datepicker__day-names {
      color: ${({ theme }) => theme.color.font100} !important;
    }
    .react-datepicker__day-name {
      border: 0;
      color: ${({ theme }) => theme.color.font100} !important;
    }

    .react-datepicker__day-name:focus-visible {
      border: 0;
    }
    
    .react-datepicker__day--selected {
      border: 0;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.blue}
    }
    
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: ${({ theme }) => theme.color.blue};
    }

    .react-datepicker__day--keyboard-selected:hover {
      border: 0;
      background-color: ${({ theme }) => theme.color.blue} !important;
      border-radius: 50%;
    }

    .react-datepicker__day--keyboard-selected {
      border: 0;
      background-color: ${({ theme }) => theme.color.blue} !important;
      border-radius: 50%;
    }

    .react-datepicker__day:hover {
      border-radius: 50%
    }

    .react-datepicker-time__header {
      font-weight: ${({ theme }) => theme.weight.regular};
      font-size: 1.14rem;
    }
    
    .react-datepicker__current-month {
      ::first-letter {
        text-transform: uppercase;
      };
      font-weight: ${({ theme }) => theme.weight.regular};
      font-size: 1.14rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      font-weight: ${({ theme }) => theme.weight.regular};
      padding: 6px 10px
    }
  }
`;

const TypeItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;
const TypeItemIconBox = styled.div`
  margin-right: 14px;
`;
const TypeItemText = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.regular};
`;
const StyledLabel = styled(Label)`
  margin-bottom: 21px;
`;
const AdditionalBox = styled.div`
  padding-bottom: 23px;
`;
const ConfirmTicketBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 32px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 27px + 29px);
    height: 1px;
    background-color: ${({ theme }) => theme.color.mainBorder};
  }
`;
const ConfirmTiccetButton = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font100};
  padding: 2px 0 25px;
  background-color: transparent;
  border: none;
`;
const ConfirmTicketIconBox = styled.div`
  font-size: 18px;
  margin-right: 6px;
`;
const ConfirmTicketText = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

const FormFields = ({
  form,
  loading,
  typeItem,
  isCreate,
  ticketId,
  setIsOpen,
}) => {
  const history = useHistory();
  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
  } = form;

  const [typeMachine, sendType] = useMachine(typeItemMachine);

  const [fields, setFields] = useState(null);
  const [specification, setSpecification] = useState(null);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const mainFilter = {
    companyId: {
      eq: companyId,
    },
  };

  const reduxDispatch = useDispatch();
  const updateStatus = (status) => {
    reduxDispatch(updateStatusTicket(ticketId, status, () => setIsOpen(false)));
  };

  const { ticket, isDeleteLoading } = useSelector(({ ticket }) => ticket);
  const [protocols, setProtocols] = useState(null);

  const getProtocols = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(listProtocols, {
          filter: {
            ticketId: {
              eq: ticket?.id,
            },
          },
        })
      );
      const readyProtocols = [];
      data.listProtocols.items.map((protocolItem) => {
        let status = false;
        let ref = "";
        protocolItem.files.items.map((fileItem) => {
          if (fileItem.type === "PDF") {
            status = true;
            ref = fileItem.ref;
          }
        });
        if (status) {
          readyProtocols.push({
            id: protocolItem.id,
            type: protocolItem.type,
            ref,
            createdAt: protocolItem.createdAt,
          });
        }
      });
      if (isEmpty(readyProtocols)) setProtocols(null);
      else setProtocols(readyProtocols);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (ticket?.id) getProtocols();
  }, [ticket]);

  useEffect(() => {
    const building = watch("building");
    if (isEmpty(watch("client"))) return;
    else {
      if (!building?.location && !watch("client").force) {
        setValue("building", "");
      }
    }
    // eslint-disable-next-line
  }, [watch("client")]);

  useEffect(() => {
    const fetchTypeObject = async () => {
      try {
        sendType("FETCH_TYPE");
        const { data } = await API.graphql(
          graphqlOperation(getTypeItem, { id: typeItem.id })
        );

        const { data: fieldData } = await API.graphql(
          graphqlOperation(listFieldItems, {
            filter: {
              typeItemId: {
                eq: data.getTypeItem.id,
              },
            },
          })
        );

        const { data: productData } = await API.graphql(
          graphqlOperation(listProducts, {
            filter: {
              companyId: {
                eq: companyId,
              },
            },
          })
        );

        setFields(fieldData.listFieldItems.items);
        setSpecification(productData.listProducts.items);

        sendType("FETCH_TYPE_SUCCESS");
      } catch (err) {
        console.log(err);
        sendType("FETCH_TYPE_ERROR");
      }
    };

    debounce(fetchTypeObject, 500)();
  }, []);

  const permissionObject = usePermissionObject("tickets");
  const modalDispatch = useConfirmModalDispatch();
  const dropdownOptions = useCallback(() => {
    const handleDelete = () => {
      modalDispatch({
        type: "OPEN",
        payload: {
          title: "Potwierdzenie usunięcia",
          content: "Na pewno chcesz usunąć zgłoszenie?",
          buttonText: "Usuń",
          action: () => {
            reduxDispatch(deleteTicketAll(ticket?.id, setIsOpen(false)));
          },
        },
      });
    };

    return [
      {
        name: "usuń",
        icon: TrashIcon,
        handler: handleDelete,
        isEditDisplay: true,
        permission: permissionObject.edit,
      },
    ];
  }, [modalDispatch, reduxDispatch, permissionObject, ticket]);

  const [getDropdownTeams, { data: teamsList, loading: teamsListLoading }] =
    useGetDropdownData(listTeams, mainFilter);

  const collapseReducer = (state, action) => {
    return { ...state, [action]: !state[action] };
  };

  const [collapseState, dispatch] = useReducer(collapseReducer, {
    informations: true,
    specification: true,
    description: true,
    notes: true,
  });

  let formView;
  if (typeMachine.matches(typeState.isLoading)) {
    formView = (
      <CenterBox>
        <Loader loading={true} />
      </CenterBox>
    );
  } else if (typeMachine.matches(typeState.hasLoaded)) {
    const typesForTypeItem = typeItem.types.items.map(({ type }) => ({
      name: type.name,
      value: type.id,
    }));

    const allowFields = pick(typeItem, [
      "attachments",
      "customerOrder",
      "description",
      "measurementCard",
      "notes",
      "producerOrder",
      "specification",
      "photos",
      "protocol",
    ]);

    formView = (
      <>
        {watch("status") === "COMPLETED" && protocols && allowFields.protocol && (
          <FormSection>
            <ProtocolComponent protocols={protocols} />
          </FormSection>
        )}
        <Controller
          render={({ ref, ...rest }) => (
            <Select
              isLoading={loading}
              items={typesForTypeItem}
              placeholder="Wybierz rodzaj typu zgłoszenia"
              handleEmpty={{
                text: "Dodaj rodzaj typu zgłoszenia",
                action: () => history.push(routes.settings.notificationTypes),
              }}
              error={errors?.type?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Wybierz z listy" },
          }}
          control={control}
          name="type"
        />
        <FormSection>
          <FormSectionTitle
            title="Klient"
            icon={AddressBookIcon}
            style={{ marginBottom: 22 }}
          />
          <SplitInputs>
            <Controller
              control={control}
              name="client"
              rules={{
                required: { value: true, message: "Wybierz klienta z listy" },
              }}
              render={({ ref, ...rest }) => (
                <SearchableSelectInput
                  placeholder="Wybierz klienta"
                  schema={searchClients}
                  config={{
                    filter: mainFilter,
                    icons: ["phone", "map"],
                    searchPlaceholder: "Klient",
                  }}
                  handleEmpty={{
                    text: "Dodaj klienta",
                    action: () =>
                      history.push(routes.clients, { isCreateOpen: true }),
                  }}
                  {...rest}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Wybierz z listy" },
              }}
              control={control}
              name="building"
              render={({ ref, ...rest }) => (
                <SearchableSelectInput
                  placeholder="Wybierz budynek"
                  schema={searchBuildings}
                  config={{
                    filter: {
                      clientId: {
                        eq: watch("client")?.id || watch("client")?.value,
                      },
                    },
                    icons: ["map"],
                  }}
                  handleEmpty={{
                    text: "Dodaj budynek",
                    action: () =>
                      history.push(
                        `${routes.client.home}/${
                          watch("client")?.id || watch("client")?.value
                        }${routes.client.buildings}`,
                        {
                          isCreateOpen: true,
                        }
                      ),
                  }}
                  googleAutocomplete
                  disabled={!watch("client")}
                  error={errors?.building?.message}
                  {...rest}
                />
              )}
            />
          </SplitInputs>
        </FormSection>
        {isEmpty(fields) || (
          <FormSection isClosed={!isCreate && !collapseState.informations}>
            <FormSectionTitle
              title="Informacje"
              icon={AlignLeftIcon}
              style={{ marginBottom: 22 }}
              onClick={() => dispatch("informations")}
              isOpen={collapseState.informations}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.informations}>
              {fields.map((fieldItem) => {
                const dropdownList = fieldItem.fields.items.map(
                  ({ field }) => ({
                    value: field.id,
                    name: field.name,
                  })
                );

                return (
                  <Controller
                    key={fieldItem.id}
                    control={control}
                    name={`ticketFieldItem.${fieldItem.id}`}
                    rules={{
                      required: { value: true, message: "Wybierz z listy" },
                    }}
                    render={({ ref, ...rest }) => (
                      <Select
                        isLoading={loading}
                        items={dropdownList}
                        label={fieldItem.name}
                        placeholder="Wybierz opcję"
                        error={errors?.ticketFieldItem?.[fieldItem.id]?.message}
                        {...rest}
                      />
                    )}
                  />
                );
              })}
            </Collapse>
          </FormSection>
        )}
        {allowFields.description && (
          <FormSection isClosed={!isCreate && !collapseState.specification}>
            <FormSectionTitle
              title="Specyfikacja"
              icon={DollyIcon}
              style={{ marginBottom: 22 }}
              onClick={() => dispatch("specification")}
              isOpen={collapseState.specification}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.specification}>
              <ProductCountInput products={specification} form={form} />
            </Collapse>
          </FormSection>
        )}
        {allowFields.description && (
          <FormSection isClosed={!isCreate && !collapseState.description}>
            <FormSectionTitle
              title="Opis"
              icon={CommentIcon}
              style={{ marginBottom: 22 }}
              onClick={() => dispatch("description")}
              isOpen={collapseState.description}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.description}>
              <Input
                textarea
                minRows={3}
                defaultValue={watch("description", "")}
                placeholder="Wprowadź opis zgłoszenia"
                error={errors?.description?.message}
                isLoading={loading}
                maxLength={255}
                {...register("description")}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.notes && (
          <FormSection isClosed={!isCreate && !collapseState.notes}>
            <FormSectionTitle
              title="Uwagi"
              icon={CommentIcon}
              style={{ marginBottom: 22 }}
              onClick={() => dispatch("notes")}
              isOpen={collapseState.notes}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.notes}>
              <Input
                textarea
                minRows={3}
                defaultValue={watch("notes", "")}
                placeholder="Wprowadź uwagi do zgłoszenia"
                error={errors?.notes?.message}
                isLoading={loading}
                maxLength={255}
                {...register("notes")}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.measurementCard && (
          <FormSection isClosed={!isCreate && !collapseState.measurementCard}>
            <FormSectionTitle
              title="Karta pomiarowa"
              icon={FileAltIcon}
              style={{ marginBottom: 21 }}
              onClick={() => dispatch("measurementCard")}
              isOpen={collapseState.measurementCard}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.measurementCard}>
              <Controller
                control={control}
                name="measurementCard"
                render={({ ref, ...rest }) => (
                  <MultiFilesUpload
                    isLoading={loading}
                    placeholder="Dodaj zdjęcie lub skan"
                    error={errors?.measurementCard?.message}
                    {...rest}
                  />
                )}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.customerOrder && (
          <FormSection isClosed={!isCreate && !collapseState.customerOrder}>
            <FormSectionTitle
              title="Zamówienie od klienta"
              icon={FileAltIcon}
              style={{ marginBottom: 21 }}
              onClick={() => dispatch("customerOrder")}
              isOpen={collapseState.customerOrder}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.customerOrder}>
              <Controller
                control={control}
                name="customerOrder"
                render={({ ref, ...rest }) => (
                  <MultiFilesUpload
                    isLoading={loading}
                    placeholder="Dodaj zdjęcie lub skan"
                    error={errors?.customerOrder?.message}
                    {...rest}
                  />
                )}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.producerOrder && (
          <FormSection isClosed={!isCreate && !collapseState.producerOrder}>
            <FormSectionTitle
              title="Zamówienie od producenta"
              icon={FileAltIcon}
              style={{ marginBottom: 21 }}
              onClick={() => dispatch("producerOrder")}
              isOpen={collapseState.producerOrder}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.producerOrder}>
              <Controller
                control={control}
                name="producerOrder"
                render={({ ref, ...rest }) => (
                  <MultiFilesUpload
                    isLoading={loading}
                    placeholder="Dodaj zdjęcie lub skan"
                    error={errors?.producerOrder?.message}
                    {...rest}
                  />
                )}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.attachments && (
          <FormSection isClosed={!isCreate && !collapseState.attachments}>
            <FormSectionTitle
              title="Załączniki"
              icon={FileAltIcon}
              style={{ marginBottom: 21 }}
              onClick={() => dispatch("attachments")}
              isOpen={collapseState.attachments}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.attachments}>
              <Controller
                control={control}
                name="attachments"
                render={({ ref, ...rest }) => (
                  <MultiFilesUpload
                    isLoading={loading}
                    placeholder="Dodaj załącznik"
                    error={errors?.attachments?.message}
                    {...rest}
                  />
                )}
              />
            </Collapse>
          </FormSection>
        )}
        {allowFields.photos && (
          <FormSection isClosed={!isCreate && !collapseState.photos}>
            <FormSectionTitle
              title="Zdjęcia"
              icon={ImageIcon}
              style={{ marginBottom: 21 }}
              onClick={() => dispatch("photos")}
              isOpen={collapseState.photos}
              noAngle={isCreate}
            />
            <Collapse isOpened={isCreate || collapseState.photos}>
              <Controller
                control={control}
                name="photos"
                render={({ ref, ...rest }) => (
                  <MultiFilesUpload
                    isLoading={loading}
                    placeholder="Dodaj zdjęcia"
                    types="image/*"
                    error={errors?.photos?.message}
                    {...rest}
                  />
                )}
              />
            </Collapse>
          </FormSection>
        )}
      </>
    );
  } else if (typeMachine.matches(typeState.hasError)) {
    formView = "";
  }

  return (
    <>
      {watch("status") === "PENDING" && (
        <ConfirmTicketBox>
          <ConfirmTiccetButton onClick={() => updateStatus("SCHEDULED")}>
            <ConfirmTicketIconBox>
              <CheckSquareIcon />
            </ConfirmTicketIconBox>
            <ConfirmTicketText>potwierdź zgłoszenie</ConfirmTicketText>
          </ConfirmTiccetButton>
        </ConfirmTicketBox>
      )}

      <TypeItemContainer>
        <TicketTypeNameBox>
          <TypeItemIconBox>{getTypeIconByValue(typeItem.icon)}</TypeItemIconBox>
          <TypeItemText>{typeItem.name}</TypeItemText>
          <DocumentNumber>{ticket?.docNum}</DocumentNumber>
        </TicketTypeNameBox>
        <div>
          {isEmpty(dropdownOptions()) || (
            <DropdownBox>
              {isDeleteLoading ? (
                <Loader loading={isDeleteLoading} />
              ) : (
                <SimpleDropdown data={dropdownOptions()} icon={EllipsisHIcon} />
              )}
            </DropdownBox>
          )}
        </div>
      </TypeItemContainer>

      {watch("status") !== undefined && (
        <AdditionalBox>
          <SplitInputs>
            <SelectStatus
              label="Status"
              status={watch("status") || "PENDING"}
            />
            <DateTime>
              <StyledLabel>Data i godzina rozpoczęcia</StyledLabel>
              <DateTimeContent>
                <DateTimeIconBox>
                  <CalendarIcon />
                </DateTimeIconBox>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <>
                      <DatePickerWrapperStyles />
                      <DatePicker
                        locale="pl"
                        dateFormat="dd.MM.yyyy HH:mm"
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        timeCaption="Godzina"
                        showTimeSelect
                        withPortal
                        disabledKeyboardNavigation
                        wrapperClassName="react-datepicker"
                        customInput={
                          <CustomInput
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            timeCaption="Godzina"
                            showTimeSelect
                            withPortal
                            disabledKeyboardNavigation
                            customInput={
                              <CustomInput
                                value={field.value}
                                onChange={(e) => field.onChange(e)}
                              />
                            }
                          />
                        }
                      />
                    </>
                  )}
                />
              </DateTimeContent>
            </DateTime>
          </SplitInputs>
          <SplitInputs>
            <Controller
              render={({ ref, ...rest }) => (
                <SelectTeam
                  isLoading={loading}
                  items={teamsList}
                  getItems={getDropdownTeams}
                  isListLoading={teamsListLoading}
                  placeholder="Wybierz zespół"
                  label="Zespoół"
                  handleEmpty={{
                    text: "Utwórz zespół",
                    action: () =>
                      history.push(routes.teams, { isCreateOpen: true }),
                  }}
                  error={errors?.team?.message}
                  {...rest}
                />
              )}
              control={control}
              name="team"
            />
            <DateTime>
              <StyledLabel>Data i godzina zakończenia</StyledLabel>
              <DateTimeContent>
                <DateTimeIconBox>
                  <CalendarIcon />
                </DateTimeIconBox>
                <Controller
                  control={control}
                  name="deadline"
                  render={({ field }) => (
                    <DatePicker
                      locale="pl"
                      dateFormat="dd.MM.yyyy HH:mm"
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      showTimeSelect
                      disabledKeyboardNavigation
                      timeCaption="Godzina"
                      withPortal
                      customInput={
                        <CustomInput
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                        />
                      }
                    />
                  )}
                />
              </DateTimeContent>
            </DateTime>
          </SplitInputs>
        </AdditionalBox>
      )}

      {formView}
    </>
  );
};

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <StyledDataInput onClick={onClick} ref={ref}>
    {value || "WYBIERZ DATĘ I GODZINĘ"}
  </StyledDataInput>
));

export default FormFields;

const DateTime = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const DateTimeContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.medium};
`;
const DateTimeIconBox = styled.div`
  display: flex;
  margin-right: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.font200};
  transform: translateY(-1px);
`;
const StyledDataInput = styled.div`
  width: 100%;
  cursor: pointer;
`;
const DocumentNumber = styled.div`
  color: ${({ theme }) => theme.color.font100};
  font-size: 20px;
  margin-left: 8px;
`;
const DropdownBox = styled.div`
  color: ${({ theme }) => theme.color.font100};
  font-size: 22px;
`;
const TicketTypeNameBox = styled.div`
  display: flex;
  align-items: center;
`;
