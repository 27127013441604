import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";

import Select from "../Select";
import SingleFileUpload from "../SingleFileUpload";
import { languageList } from "../../util/variables";

const Container = styled.div`
  max-width: 380px;
`;

const PersonalizationFormFields = ({ form, loading }) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Container>
      <Controller
        render={({ ref, ...rest }) => (
          <SingleFileUpload
            isLoading={loading}
            label="Logo"
            error={errors?.logo?.message}
            {...rest}
          />
        )}
        control={control}
        name="logo"
      />
      <Controller
        render={({ ref, ...rest }) => (
          <Select
            isLoading={loading}
            items={languageList}
            label="Język"
            placeholder="Wybierz język"
            error={errors?.language?.message}
            {...rest}
          />
        )}
        rules={{
          required: { value: true, message: "Wybierz z listy" },
        }}
        control={control}
        name="language"
      />
    </Container>
  );
};

export default PersonalizationFormFields;
