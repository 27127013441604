export const mainTheme = {
  color: {
    white: "#FFFFFF",
    black: "#000000",
    blackOpacity: (opacity) => `rgba(0, 0, 0, 0.${opacity})`,
    primaryYellow: "#FFD609",
    primaryBlue: "#007AFF",
    blue: "#0B84FF",
    grey: "#2C2C2E",
    lightGrey: "#F2F2F7",
    font100: "#8E8E93",
    font200: "#778CA2",
    font300: "#252631",
    font400: "#1B1E24",
    backgroundDark: "#1C1C1E",
    backgroundDarkOpacity: (opacity) => `rgba(28, 28, 30, 0.${opacity})`,
    separator: "#2D2D2F",
    activeNavButton: "rgba(248, 250, 251, 0.08)",
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};

export const lightTheme = {
  color: {
    // Main
    white: "#FFFFFF",
    black: "#000000",
    blackOpacity: (opacity) => `rgba(0, 0, 0, 0.${opacity})`,
    mainBackground: "#F8FAFB",
    mainBackgroundOpacity10: "rgba(248, 250, 251, 0.1)",
    authorizationBox: "#FFFFFF",
    mainBorder: "#E8ECEF",
    secondaryBorder: "#F2F4F6",
    primaryColor: "#FE0009",
    error: "#FE4D97",
    // Input
    inputBorder: "#E8ECEF",
    inputFill: "#FFFFFF",
    inputErrorBorder: "#FE4D97",
    inputErrorIcon: "#FE4D97",
    inputErrorBackground: "rgba(254, 77, 151, 0.1)",
    // Select
    selectHoverText: "#FE0009",
    // Checkbox
    checkboxBackground: "#E8ECEF",
    checkboxCheckedBackground: "#FE0009",
    checkboxBorder: "transparent",
    // Primary button
    primaryButtonBackground: "#FE0009",
    primaryButtonText: "#FFFFFF",
    // Dark button
    darkButtonBackground: "#F2F4F6",
    darkButtonText: "#778CA2",
    // Progress bar
    progressBarBackground: "#E8ECEF",
    progressBarStep: ["#FE4D97", "#FFAB2B", "#dfeed6"],
    progressBarFinal: "#6DD230",
    // Reports status
    reportCompleted: (isLight) =>
      isLight ? "rgba(109, 210, 48, 0.08)" : "#34C759",
    reportPending: (isLight) => (isLight ? "white" : "#98A9BC"),
    reportScheduled: (isLight) =>
      isLight ? "rgba(37, 38, 49, 0.08)" : "#252631",
    reportInProgress: (isLight) =>
      isLight ? "rgba(0, 130, 255, 0.08)" : "#0082FF",
    completedBackground: "#34C759",
    // Navigation button
    hoverBackground: "#F8FAFB",
    // Dropdown
    dropdownBox: "#FFFFFF",
    dropdownOptionHover: "transparent",
    dropdownNotificationBall: {
      green: "#FE0009",
      pink: "#FE0009",
    },
    // Modal
    modalBackground: "#FFFFFF",
    confirmModalBackground: "rgba(37, 38, 49, 0.6)",
    // Pagination
    paginationButtonBackground: "#F2F4F6",
    // Table
    tableHeaderLine: "#F6F9FC",
    // Toastify
    toastifySuccess: "rgb(109, 210, 48)",
    toastifyWarning: "rgb(255, 171, 43)",
    toastifyError: "rgb(255, 59, 48)",

    textLink: "#FE0009",
    blue: "#FE0009",
    font100: "#778CA2",
    font200: "#98A9BC",
    font300: "#252631",
    font400: "#1B1E24",
    sideBarFont: "#252631",
    sideBarIconFont: "#778CA2",
    // backgroundDark: "#181617",
    activeNavLinkBackground: "#F8FAFB",
    backgroundDarkOpacity: (opacity) => `rgba(28, 28, 30, 0.${opacity})`,
    topBarFontPrimary: "#ffffff",
    topBarFontSecondary: "rgba(255, 255, 255, 0.6)",
    topBarBackgroundColor: "#FE0009 ",
    topBarAvatar: "#FE999C",
    notificationColor: "#FFD609",
    sideBarBorder: "#E8ECEF",
  },
  shadow: {
    rowHover: "0 2px 16px 0 rgba(153, 155, 168, 0.12)",
    sideModal: "0 2px 16px 0 rgba(0,0,0,.12)",
    confirmModal: "0 2px 16px 0 rgba(153, 155, 168, 0.12)",
    dropdown: "0 2px 16px 0 rgba(28, 28, 30, 0.12)",
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};

export const customTheme = {
  color: {
    // Main
    white: "#FFFFFF",
    black: "#000000",
    blackOpacity: (opacity) => `rgba(0, 0, 0, 0.${opacity})`,
    mainBackground: "#F8FAFB",
    mainBackgroundOpacity10: "rgba(248, 250, 251, 0.1)",
    authorizationBox: "#FFFFFF",
    mainBorder: "#E8ECEF",
    secondaryBorder: "#F2F4F6",
    primaryColor: "#0082FF",
    error: "#FE4D97",
    // Input
    inputBorder: "#E8ECEF",
    inputFill: "#FFFFFF",
    inputErrorBorder: "#FE4D97",
    inputErrorIcon: "#FE4D97",
    inputErrorBackground: "rgba(254, 77, 151, 0.1)",
    // Select
    selectHoverText: "#0082FF",
    // Checkbox
    checkboxBackground: "#E8ECEF",
    checkboxCheckedBackground: "#0082FF",
    checkboxBorder: "transparent",
    // Primary button
    primaryButtonBackground: "#0082FF",
    primaryButtonText: "#FFFFFF",
    // Dark button
    darkButtonBackground: "#F2F4F6",
    darkButtonText: "#778CA2",
    // Progress bar
    progressBarBackground: "#E8ECEF",
    progressBarStep: ["#FE4D97", "#FFAB2B", "#dfeed6"],
    progressBarFinal: "#6DD230",
    // Reports status
    reportCompleted: (isLight) =>
      isLight ? "rgba(109, 210, 48, 0.08)" : "#34C759",
    reportPending: (isLight) => (isLight ? "white" : "#98A9BC"),
    reportScheduled: (isLight) =>
      isLight ? "rgba(37, 38, 49, 0.08)" : "#252631",
    reportInProgress: (isLight) =>
      isLight ? "rgba(0, 130, 255, 0.08)" : "#0082FF",
    completedBackground: "#34C759",
    // Navigation button
    hoverBackground: "#F8FAFB",
    // Dropdown
    dropdownBox: "#FFFFFF",
    dropdownOptionHover: "transparent",
    dropdownNotificationBall: {
      green: "#0082FF",
      pink: "#0082FF",
    },
    // Modal
    modalBackground: "#FFFFFF",
    confirmModalBackground: "rgba(37, 38, 49, 0.6)",
    // Pagination
    paginationButtonBackground: "#F2F4F6",
    // Table
    tableHeaderLine: "#F6F9FC",
    // Toastify
    toastifySuccess: "rgb(109, 210, 48)",
    toastifyWarning: "rgb(255, 171, 43)",
    toastifyError: "rgb(255, 59, 48)",

    textLink: "#0082FF",
    blue: "#0082FF",
    font100: "#778CA2",
    font200: "#98A9BC",
    font300: "#252631",
    font400: "#1B1E24",
    sideBarFont: "#778CA2",
    sideBarIconFont: "#252631",
    backgroundDarkOpacity: (opacity) => `rgba(28, 28, 30, 0.${opacity})`,
    topBarFontPrimary: "#252631",
    topBarFontSecondary: "#778CA2",
    topBarBackgroundColor: "#ffffff",
    topBarAvatar: "#E8ECEF",
    sideBarBorder: "#E8ECEF",
  },
  shadow: {
    rowHover: "0 2px 16px 0 rgba(153, 155, 168, 0.12)",
    sideModal: "0 2px 16px 0 rgba(0,0,0,.12)",
    confirmModal: "0 2px 16px 0 rgba(153, 155, 168, 0.12)",
    dropdown: "0 2px 16px 0 rgba(28, 28, 30, 0.12)",
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
