import React from "react";
import styled from "styled-components";

import MainTemplate from "../templates/MainTemplate";
import low_permission from "../assets/images/low_permission.png";
import { Title } from "../components/Title";
import { Subtitle } from "../components/Subtitle";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Image = styled.img`
  height: 260px;
  width: auto;
  object-fit: contain;
  margin-bottom: 46px;
  margin-top: 216px;
`;
const TextBox = styled.div``;
const StyledTitle = styled(Title)`
  margin-bottom: 6px;
`;

export default function LowPermissionPage() {
  return (
    <MainTemplate title="Brak uprawnień">
      <Container>
        <Image src={low_permission} alt="Low permission image" />
        <TextBox>
          <StyledTitle>Brak uprawnień</StyledTitle>
          <Subtitle>
            Nie posiadasz dostępu do wybranego modułu, <br />
            skontakuj się z administratorem.
          </Subtitle>
        </TextBox>
      </Container>
    </MainTemplate>
  );
}
