import React from "react";
import styled from "styled-components";

import MainTemplate from "../templates/MainTemplate";
import not_found from "../assets/images/not_found.png";
import { Title } from "../components/Title";
import { Subtitle } from "../components/Subtitle";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Image = styled.img`
  height: 366px;
  width: auto;
  object-fit: contain;
  margin-bottom: 46px;
  margin-top: 152px;
`;
const TextBox = styled.div``;
const StyledTitle = styled(Title)`
  margin-bottom: 6px;
`;

export default function NotFoundPage() {
  return (
    <MainTemplate title="Strona nie istnieje">
      <Container>
        <Image src={not_found} alt="Not found image" />
        <TextBox>
          <StyledTitle>Podana strona nie istnieje</StyledTitle>
          <Subtitle>
            Niestety strona którą chcesz odwiedzić nie istnieje. <br />
            Prawdopodobnie została przeniesiona lub źle wpisałeś adres.
          </Subtitle>
        </TextBox>
      </Container>
    </MainTemplate>
  );
}
