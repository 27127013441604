import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "../../redux/actions/userActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditUser = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const userState = useSelector(({ user }) => user);

  useEffect(() => {
    dispatch(getUser(id));
  }, [id, dispatch]);

  useEffect(() => {
    const language = "PL";
    if (!_.isEmpty(userState.user)) {
      const { fullName, email, address, phone, role } = userState.user;

      setValue("name", fullName);
      setValue("email", email);
      setValue("address", address);
      setValue("phone", parsePhoneNumber(phone, language).nationalNumber);
      setValue("role", { name: role.name, value: role.id });
    }
  }, [userState.user, setValue]);

  const onSubmit = (data) => {
    const language = "PL";
    const { location } = data.address?.geometry || {};

    const input = {
      id,
      fullName: data.name,
      email: userState.user.email,
      phone: parsePhoneNumber(data.phone, language).number,
      roleId: data.role.value,
      address: data.address.formatted_address || data.address,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    if (!location) {
      delete input.latitude;
      delete input.longitude;
    }

    dispatch(updateUser({ input }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={userState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={userState.loadingGet}
            loader={userState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

EditUser.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EditUser;
