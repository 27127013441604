import React from "react";
import styled, { css } from "styled-components";
import { TimesIcon } from "react-line-awesome";
import ReactTags from "react-tag-autocomplete";

import { InputBox, Label } from "./Input";
import Loader from "./Loader";

const StyledInputBox = styled(InputBox)`
  position: relative;
`;
const MainBox = styled.div`
  border: 1px solid transparent;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `} {
  }
  ${({ error, theme }) =>
    error &&
    css`
      background-color: ${theme.color.inputErrorBackground};
      border-color: ${theme.color.inputErrorBorder};
      border-radius: 4px;
    `} {
  }
`;
const LoaderBox = styled.div`
  position: absolute;
  top: 44px;
  right: 14px;
`;
const TagBox = styled.div`
  position: relative;
  padding: 9px 43px 9px 20px;
  margin-right: 4px;
  margin-bottom: 3px;
  width: fit-content;
  color: ${({ theme }) => theme.color.font100};
  background-color: ${({ theme }) => theme.color.darkButtonBackground};
  border-radius: 4px;
`;
const DeleteBox = styled.div`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-44%);
  font-size: 16px;
  cursor: pointer;
`;

const TagComponent = ({ tag, removeButtonText, onDelete }) => {
  return (
    <TagBox title={removeButtonText}>
      {tag.name}
      <DeleteBox onClick={onDelete}>
        <TimesIcon />
      </DeleteBox>
    </TagBox>
  );
};

const TagSelect = ({
  field: { onChange, value: tags },
  fieldState,
  label,
  ...rest
}) => {
  const onDelete = (i) => {
    const newTags = tags.filter((_, id) => id !== i);
    onChange(newTags);
  };

  const onAddition = (tag) => {
    const newTags = [...tags, tag];
    onChange(newTags);
  };

  return (
    <StyledInputBox>
      {label && <Label>{label}</Label>}
      <LoaderBox>
        <Loader loading={rest.disabled} />
      </LoaderBox>
      <MainBox {...rest}>
        <ReactTags
          allowNew
          tags={tags}
          onDelete={onDelete}
          onAddition={onAddition}
          tagComponent={TagComponent}
          {...rest}
        />
      </MainBox>
    </StyledInputBox>
  );
};

export default TagSelect;
