import _ from "lodash";
import { API, graphqlOperation, Storage } from "aws-amplify";
import uuid from "react-uuid";

import config from "../aws-exports";

import { roleTranslation } from "../util/variables";
import {IconPathsBig, IconPathsSmall} from "../components/library/IconPaths";
import { toast } from "react-toastify";
import MainToast from "../components/toastify/MainToast";
import * as graphql from "../graphql/rootFiles";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
  aws_cognito_identity_pool_id: poolId,
} = config;

export const baseS3Url = `https://${bucket}.s3.${region}.amazonaws.com/public`;

const AWS = require("aws-sdk");
AWS.config.update({
  region: region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: poolId,
  }),
});
const s3 = new AWS.S3({
  params: { Bucket: bucket },
});

s3.config.update({
  region: region,
  accessKeyId: "AKIAWUMFZNH3OPD5BTW5",
  secretAccessKey: "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",
});

export const dec2bin = (dec) => {
  return (dec >>> 0).toString(2).padStart(4, "0");
};

export const boolArr2dec = (arr) => {
  const stringArray = arr.map((item) => (item ? "1" : "0"));
  return parseInt(stringArray.join(""), 2);
};

export const permissionObject2Array = (permissions) => {
  if (_.isEmpty(permissions)) {
    return [];
  } else {
    let permissionArray = [];

    for (const key in permissions) {
      if (Object.hasOwnProperty.call(permissions, key)) {
        const permission = permissions[key];
        const name = roleTranslation[key];

        permissionArray = [...permissionArray, { name, slug: key, permission }];
      }
    }

    return permissionArray;
  }
};

export const getPermissionObject = (permissionDec) => {
  const [read, edit, create, del] = dec2bin(permissionDec)
    .split("")
    .map((item) => (parseInt(item) ? true : false));
  let permissionObject = { read, edit, create, del };

  return permissionObject;
};

/**
 *
 * @param {String} path String witg "/"
 * @param {Int} wordFromEnd Get word from the end
 * @param {Int} wordFromStart Get word from start
 */
export const getSingleWordFromPathname = (
  path,
  wordFromEnd = null,
  wordFromStart = 1,
  separator = "/"
) => {
  const arr = path.split(separator);
  if (wordFromEnd) {
    return arr[arr.length - wordFromEnd];
  } else {
    return arr[wordFromStart];
  }
};

export const generateID = (file) => {
  const formattedType = getSingleWordFromPathname(file.type);
  return `${uuid()}.${formattedType}`;
};

export const recognizeFileExtension = (file, isFolder) => {
  try {
    const index = file.name.lastIndexOf(".");
    if (index === -1) {
      return IconPathsBig["file"];
    }
    const fileExtension = file.name.slice(index + 1, file.length);

    if (fileExtension === 'pdf') {
      return IconPathsBig["pdf"];
    }  else if (fileExtension === 'jpg') {
      return IconPathsBig['jpg']
    } else if (fileExtension === 'png') {
      return IconPathsBig['png']
    }
    else return IconPathsBig['file'];
  } catch (err) {
    console.log(err);
    return IconPathsBig["file"];
  }
};


export const recognizeFileExtensionSmall = (file, isFolder) => {
  try {
    if (isFolder) {
      console.log('abr')
      return IconPathsSmall["folder"];
    }
    const index = file.name.lastIndexOf(".");
    if (index === -1) {
      return IconPathsSmall["file"];
    }
    const fileExtension = file.name.slice(index + 1, file.length);
    if (fileExtension === 'pdf') {
      return IconPathsSmall["pdf"];
    } else if (fileExtension === 'jpg') {
      return IconPathsSmall['jpg']
    } else if (fileExtension === 'png') {
      return IconPathsSmall['png']
    }

    else return IconPathsSmall['file'];
  } catch (err) {
    console.log(err);
    return IconPathsSmall["file"];
  }
};

export const addPhoto = async (file, config) => {
  const { companyId, fieldType } = config;
  const type = file ? file.type : null;
  if (type === null) return;

  const formattedType = getSingleWordFromPathname(type);
  const location = `${companyId}/${fieldType}`;
  const fileName = `companies/${location}/${uuid()}.${formattedType}`;

  const url = `${baseS3Url}/${fileName}`;

  await Storage.put(fileName, file, {
    contentType: type,
  });
  return url;
};

export const formatDate = (date) => {
  try {
    const index = date.createdAt.indexOf("T");
    return date.createdAt.slice(0, index);
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const deletePhoto = async (link) => {
  if (link === null) return;
  await Storage.remove(link).then(() => {
    console.log(true);
    return true;
  });
};

export function downloadBlob(blob, filename) {
  try {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  } catch (err) {
    // toast.error(<MainToast message="Ups! Coś poszło nie tak" />)
  }
}

export async function download(fileKey, filename) {
  const result = await Storage.get(fileKey, { download: true }).catch((e) =>
    toast.error(
      <MainToast message="Ups! Coś poszło nie tak podczas pobierania" />
    )
  );
  downloadBlob(result.Body, filename);
}

export function emptyBucket(prefix) {
  var params = {
    Prefix: prefix,
  };
  try {
    s3.listObjects(params, function (err, data) {
      if (err) {
        console.log(err);
        return err;
      }

      if (data.Contents.length == 0) return;
      params = { Bucket: bucket };
      params.Delete = { Objects: [] };

      data.Contents.forEach(function (content) {
        params.Delete.Objects.push({ Key: content.Key });
      });

      s3.deleteObjects(params, function (err, data) {
        if (err) {
          console.log(err);
          return err;
        }
        if (data.IsTruncated) {
          emptyBucket(bucket);
        } else {
          return "finished";
        }
      });
    });
  } catch (err) {
    console.log(err);
  }
}
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export async function checkIfFileExists(filename, isSingle) {
  const { data } = await API.graphql(
    graphqlOperation(graphql.searchRootFiles, {
      filter: { name: { eq: filename } },
    })
  );

  if (data.searchRootFiles.items.length > 0) {
    if (!isSingle) {
      toast.info(
        <MainToast message="Plik lub folder o takiej nazwie już istnieje" />
      );
    }
    return true;
  }
}
