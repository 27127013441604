import {
  SEARCH_COMPANIES_STARTED,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_FAILURE,
  CREATE_COMPANY_STARTED,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  GET_COMPANY_STARTED,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  DELETE_COMPANY_FULL_STARTED,
  DELETE_COMPANY_FULL_SUCCESS,
  DELETE_COMPANY_FULL_FAILURE,
  COMPANY_UPDATE_STARTED,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  companies: [],
  company: {},
  error: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_COMPANIES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        companies: action.payload.companies,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_COMPANY_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        companies: [action.payload, ...state.companies],
      };
    case CREATE_COMPANY_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case DELETE_COMPANY_FULL_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_COMPANY_FULL_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        companies: state.companies.filter((item) => item.id !== action.payload),
      };
    case DELETE_COMPANY_FULL_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_COMPANY_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        company: action.payload,
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case COMPANY_UPDATE_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        companies: state.companies.map((company) =>
          company.id === action.payload.id
            ? { ...company, ...action.payload }
            : company
        ),
      };
    case COMPANY_UPDATE_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default roleReducer;
