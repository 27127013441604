// Queries
export const googleAutocomplete = /* GraphQL */ `
  query GoogleAutocomplete($input: String!, $params: AWSJSON) {
    googleAutocomplete(input: $input, params: $params)
  }
`;

export const googlePlaceDetails = /* GraphQL */ `
  query GooglePlaceDetails($placeid: String!, $params: AWSJSON) {
    googlePlaceDetails(placeid: $placeid, params: $params)
  }
`;
