import {
  CREATE_TEAM_STARTED,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  SEARCH_TEAMS_STARTED,
  SEARCH_TEAMS_SUCCESS,
  SEARCH_TEAMS_FAILURE,
  GET_TEAM_STARTED,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  UPDATE_TEAM_STARTED,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  DELETE_TEAM_STARTED,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  teams: [],
  team: {},
  error: null,
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TEAM_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        teams: [action.payload, ...state.teams],
      };
    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case SEARCH_TEAMS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        teams: action.payload.teams,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_TEAM_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        teams: state.teams.filter((item) => item.id !== action.payload),
      };
    case DELETE_TEAM_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_TEAM_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        team: action.payload,
      };
    case GET_TEAM_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_TEAM_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        teams: state.teams.map((team) =>
          team.id === action.payload.id ? { ...team, ...action.payload } : team
        ),
        team: action.payload,
      };
    case UPDATE_TEAM_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default teamReducer;
