import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";
import { CloudUploadAltIcon } from "react-line-awesome";

import { Label } from "./Input";
import { useEffect } from "react";
import Loader from "./Loader";

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-bottom: 26px;
`;
const BackgroundIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: ${({ theme }) => theme.color.font100};
  transition: color 0.14s ease-in-out;
`;
const DropzoneBox = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "124px")};
`;
const DropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  border: 1px dashed ${({ theme }) => theme.color.mainBorder};
  border-radius: 4px;

  :hover {
    cursor: pointer;

    ${BackgroundIcon} {
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-style: solid;
    `}
`;
const Image = styled.img`
  width: 85px;
`;
const Text = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.font100};
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
  margin-left: 10px;
`;

const SingleFileUpload = ({
  field: { onChange, value },
  label,
  isLoading,
  fullWidth,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  useEffect(() => {
    if (typeof value === "string") setImagePreview(value);
    else if (value === null) setImagePreview(null);
  }, [setImagePreview, value]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const [file] = acceptedFiles;
      if (file) {
        const url = URL.createObjectURL(file);
        setImagePreview(url);
        onChange(file);
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
    multiple: false,
  });

  return (
    <MainBox>
      {label && <Label>{label}</Label>}
      <DropzoneBox {...getRootProps({ fullWidth })}>
        <input {...getInputProps()} />
        <DropzoneContainer isSelected={imagePreview}>
          {imagePreview ? (
            <Image src={imagePreview} alt="Preview" />
          ) : isLoading ? (
            <Loader loading={isLoading} />
          ) : (
            <BackgroundIcon>
              <CloudUploadAltIcon />
              {fullWidth && <Text>Wybierz lub przeciągnij obraz</Text>}
            </BackgroundIcon>
          )}
        </DropzoneContainer>
      </DropzoneBox>
    </MainBox>
  );
};

export default SingleFileUpload;
