import React from "react";
import styled, { css, useTheme } from "styled-components";
import { CommentsIcon } from "react-line-awesome";

import DropdownMenu from "./DropdownMenu";

const DropdownBox = styled.div`
  margin-right: 24px;
`;

const CommentsIconStyle = styled(CommentsIcon)`
  color: ${({ theme }) => theme.color.topBarFontSecondary};
`;

const UserName = styled.div`
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 14px;
  transition: 0.14s ease-in-out;

  :not(:last-of-type) {
    margin-bottom: 2px;
  }
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color.topBarFontSecondary};
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 14px;
  letter-spacing: 0.2px;
`;
const ChatElement = styled.div`
  height: 60px;
  transition: background 0.14s ease-in-out;
  padding: 12px 19px;

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
    cursor: pointer;
  }

  ${({ theme, isRead }) =>
    isRead &&
    css`
      ${UserName} {
        color: ${theme.color.primaryColor};
      }
    `}
`;
const Messages = styled.div`
  max-height: calc(60px * 5);
  overflow-y: auto;
  margin-top: 9px;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: ${({ theme }) => theme.color.mainBackground};
  border-radius: 0 0 2px 2px;
  color: ${({ theme }) => theme.color.primaryColor};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.96;
    cursor: pointer;
  }
`;

export default function Chat() {
  const { color } = useTheme();

  return (
    <DropdownBox>
      <DropdownMenu
        icon={<CommentsIconStyle className="la-lg" />}
        notificationOptions={{
          right: -3,
          top: -2,
          color: color.notificationColor,
        }}
        isNotification={true}
      >
        <Messages>
          <ChatElement>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement isRead>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement isRead>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
          <ChatElement>
            <UserName>1/M/01/01/2021</UserName>
            <Text>Montaż odwołany</Text>
          </ChatElement>
        </Messages>
        <Button>Wszystkie wiadomości</Button>
      </DropdownMenu>
    </DropdownBox>
  );
}
