import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import { PencilAltIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteCompanyFull,
  searchCompanies,
} from "../../redux/actions/companyActions";

import MainTemplate from "../../templates/MainTemplate";
import DataTable from "../../components/dataTable/DataTable";
import ActionsPanel from "../../components/dataTable/ActionsPanel";
import BottomPanel from "../../components/dataTable/BottomPanel";
import SideModal from "../../components/modal/SideModal";
import CreateCompany from "../../components/companies/CreateCompany";
import EditCompany from "../../components/companies/EditCompany";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import { useConfirmModalDispatch } from "../../context/ModalContext";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const CompanyName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.font300};
  margin-left: 24px;
  font-weight: ${({ theme }) => theme.weight.regular};
  transition: 0.14s ease-in-out;
`;
const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover ${CompanyName} {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

const NipBox = styled.div`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.color.mainBackground};
  width: fit-content;
  border-radius: 4px;
`;
const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

export default function Companies() {
  const columns = useMemo(
    () => [
      {
        accessor: "company",
      },
      {
        accessor: "nip",
      },
      {
        accessor: "customerName",
      },
      {
        accessor: "email",
      },
      {
        accessor: "icons",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    companyState: state.company,
    tableState: state.table,
  }));
  const { companyState, tableState } = state;
  const { pagination, sorting } = tableState;
  const { nextToken, total } = companyState;

  const searchCompaniesVariables = useMemo(
    () => ({
      sort: {
        direction:
          sorting.alphabetically.value === "DEFAULT"
            ? null
            : sorting.alphabetically.value,
        field: "companyName",
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
    }),
    [pagination, sorting]
  );

  useEffect(() => {
    dispatch(searchCompanies(searchCompaniesVariables));
  }, [dispatch, searchCompaniesVariables]);

  const modalDispatch = useConfirmModalDispatch();

  const companies = useMemo(() => {
    return companyState.companies.map((item) => {
      const { id, companyName, vat } = item;
      const isDeleteLoading = companyState.loadingDelete === id;
      return {
        ...item,
        company: (
          <CompanyContainer onClick={() => openEdit(id)}>
            <Avatar
              name={companyName}
              size="40"
              round={true}
              textSizeRatio={2}
              maxInitials={2}
              src={undefined}
              style={{
                fontFamily: "'Rubik', sans-serif",
                fontWeight: 500,
              }}
            />
            <CompanyName className="rowHover">{companyName}</CompanyName>
          </CompanyContainer>
        ),
        nip: <NipBox>{vat}</NipBox>,
        icons: (
          <>
            <IconBox onClick={() => openEdit(id)}>
              <PencilAltIcon />
            </IconBox>
            <IconBox
              onClick={() => {
                if (!isDeleteLoading) {
                  modalDispatch({
                    type: "OPEN",
                    payload: {
                      content:
                        "Na pewno usunąć firmę razem z wszystkimi użytkownikami?",
                      title: "Potwierdzenie usunięcia",
                      buttonText: "Usuń",
                      action: () => dispatch(deleteCompanyFull(id)),
                    },
                  });
                }
              }}
            >
              <RowDeleteLoader loading={isDeleteLoading} />
            </IconBox>
          </>
        ),
      };
    });
  }, [
    companyState.companies,
    companyState.loadingDelete,
    modalDispatch,
    dispatch,
  ]);

  return (
    <MainTemplate title="Firmy">
      <TableBox>
        <ActionsPanel addText="nowa firma" setIsSidebarOpen={setIsCreateOpen} />
        <DataTable
          data={companies}
          columns={columns}
          loading={companyState.loading}
        />
        <BottomPanel nextToken={nextToken} total={total} />
      </TableBox>

      <SideModal
        title="Nowa firma"
        isOpen={isCreateOpen}
        setIsOpen={setIsCreateOpen}
      >
        <CreateCompany setIsOpen={setIsCreateOpen} />
      </SideModal>

      <SideModal
        title="Edycja firmy"
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      >
        <EditCompany setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
      </SideModal>
    </MainTemplate>
  );
}
