import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { AngleDownIcon, FilterIcon } from "react-line-awesome";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

const Container = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  height: 20px;
  z-index: 4;
`;
const Selected = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
  margin: 0 12px;
`;
const TextBox = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font100};
  margin-right: 16px;
  cursor: pointer;
`;
const IconBox = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 6px;
`;
const Text = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
`;
const Options = styled.div`
  position: absolute;
  left: 0;
  max-height: 220px;
  min-width: 100%;
  width: fit-content;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  min-height: 60px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.12);
  overflow-y: auto;
  outline: none;
`;

const Option = styled.div`
  width: 100%;
  padding: 14px 18px;
  white-space: nowrap;
  transition: background 0.14s ease-in-out;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.font300};
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
  }
`;
const OprionIconBox = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 6px;
  color: ${({ theme }) => theme.color.font100};
`;
const Arrow = styled.div`
  font-size: 16px;
  transition: transform 0.16s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const Dropdown = ({
  children,
  icon,
  data = [],
  selected,
  handleSelect,
  ...rest
}) => {
  const Icon = icon || FilterIcon;

  const [isOpen, setIsOpen] = useState(false);
  const [directionStyles, setDirectionStyles] = useState({ top: "26px" });

  // Close when click outside
  const picker = useRef(null);
  useDetectOutsideClick(picker, setIsOpen);

  // Animations
  function enterAnimation(node, done) {
    gsap
      .timeline()
      .from(node, {
        autoAlpha: 0,
        scaleY: 0,
        duration: 0.2,
        ease: "Power2.inOut",
      })
      .from(
        node.childNodes,
        {
          x: -16,
          autoAlpha: 0,
          stagger: 0.06,
          duration: 0.25,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.05"
      );
  }

  function exitAnimation(node, done) {
    gsap.timeline().to(node, {
      autoAlpha: 0,
      duration: 0.3,
      onComplete: done,
      ease: "Power2.inOut",
    });
  }

  function handleOptionChange(item) {
    handleSelect(item);
    setIsOpen(false);
  }

  const setDirection = () => {
    if (picker.current) {
      const screen = { h: window.innerHeight };
      const { y } = picker.current.getBoundingClientRect();

      const isEnoughBottomSpace = !(screen.h - y < 220);

      setDirectionStyles(
        isEnoughBottomSpace ? { top: "26px" } : { bottom: "26px" }
      );
    }
  };

  const handleOpen = () => {
    if (!isOpen) setDirection();
    setIsOpen(!isOpen);
  };

  return (
    <Container ref={picker} {...rest}>
      <TextBox onClick={handleOpen}>
        <IconBox>
          <Icon />
        </IconBox>
        <Text>{children}:</Text>
        <Selected>{selected?.name}</Selected>
        <Arrow isOpen={isOpen}>
          <AngleDownIcon />
        </Arrow>
      </TextBox>
      <Transition
        timeout={300}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <Options style={directionStyles}>
          {data.map(({ name, value, icon: OptionIcon }) => (
            <Option
              key={value}
              onClick={() => handleOptionChange({ name, value })}
            >
              {OptionIcon && (
                <OprionIconBox>
                  <OptionIcon />
                </OprionIconBox>
              )}
              {name}
            </Option>
          ))}
        </Options>
      </Transition>
    </Container>
  );
};

Dropdown.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      icon: PropTypes.object,
    })
  ),
  selected: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Dropdown;
