import React from "react";
import styled from "styled-components";

const ProgressContainer = styled.div`
  height: 10px;
  min-width: 20px;
  width: 100%;
  background-color: ${({theme}) => theme.color.secondaryBorder};
  border-radius: 1px;
`
const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex: 0.2
`

const FillerStyles = styled.div`
  height: 100%;
  width: 0;
  border-radius: inherit;
  background-color: ${({theme}) => theme.color.progressBarStep[0]};
  transition: width 1s ease-in-out;
`

const ProgressBar = React.forwardRef((props, ref) => (
    <ProgressBarWrapper>
        <ProgressContainer>
            <FillerStyles ref={ref}/>
        </ProgressContainer>
    </ProgressBarWrapper>
    )
)

export default ProgressBar;