import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/client/buildings";
import {
  CREATE_BUILDING_STARTED,
  CREATE_BUILDING_SUCCESS,
  CREATE_BUILDING_FAILURE,
  LIST_BUILDINGS_STARTED,
  LIST_BUILDINGS_SUCCESS,
  LIST_BUILDINGS_FAILURE,
  GET_BUILDING_STARTED,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_FAILURE,
  UPDATE_BUILDING_STARTED,
  UPDATE_BUILDING_SUCCESS,
  UPDATE_BUILDING_FAILURE,
  DELETE_BUILDING_STARTED,
  DELETE_BUILDING_SUCCESS,
  DELETE_BUILDING_FAILURE,
} from "../types";

const createBuildingStarted = () => ({
  type: CREATE_BUILDING_STARTED,
});
const createBuildingSuccess = (building) => ({
  type: CREATE_BUILDING_SUCCESS,
  payload: building,
});
const createBuildingFailure = (error) => ({
  type: CREATE_BUILDING_FAILURE,
  payload: {
    error,
  },
});

export const createBuilding = (input, callback) => async (dispatch) => {
  try {
    dispatch(createBuildingStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.createBuilding, { input })
    );

    dispatch(createBuildingSuccess(data.createBuilding));
    toast.success(<MainToast message="Budynek stworzony pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(createBuildingFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const listBuildingsSuccess = (buildings) => ({
  type: LIST_BUILDINGS_SUCCESS,
  payload: buildings,
});
const listBuildingsStarted = () => ({
  type: LIST_BUILDINGS_STARTED,
});
const listBuildingsFailure = (error) => ({
  type: LIST_BUILDINGS_FAILURE,
  payload: {
    error,
  },
});

export const listBuildings = (variables) => async (dispatch) => {
  try {
    dispatch(listBuildingsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.listBuildings, variables)
    );

    dispatch(listBuildingsSuccess(data.listBuildings.items));
  } catch (err) {
    console.log(err);
    dispatch(listBuildingsFailure(err));
  }
};

const deleteBuildingSuccess = (id) => ({
  type: DELETE_BUILDING_SUCCESS,
  payload: id,
});
const deleteBuildingStarted = (id) => ({
  type: DELETE_BUILDING_STARTED,
  payload: id,
});
const deleteBuildingFailure = (error) => ({
  type: DELETE_BUILDING_FAILURE,
  payload: {
    error,
  },
});

export const deleteBuilding = (id, callback) => async (dispatch) => {
  try {
    dispatch(deleteBuildingStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteBuilding, {
        input: { id },
      })
    );

    dispatch(deleteBuildingSuccess(id));
    toast.success(<MainToast message="Budynek usunięty pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    dispatch(deleteBuildingFailure(err.errors));
  }
};

const getBuildingSuccess = (building) => ({
  type: GET_BUILDING_SUCCESS,
  payload: building,
});
const getBuildingStarted = () => ({
  type: GET_BUILDING_STARTED,
});
const getBuildingFailure = (error) => ({
  type: GET_BUILDING_FAILURE,
  payload: {
    error,
  },
});

export const getBuilding = (id) => async (dispatch) => {
  try {
    dispatch(getBuildingStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getBuilding, {
        id,
      })
    );

    dispatch(getBuildingSuccess(data.getBuilding));
  } catch (err) {
    console.log(err);
    dispatch(getBuildingFailure(err.errors));
  }
};

const updateBuildingSuccess = (building) => ({
  type: UPDATE_BUILDING_SUCCESS,
  payload: building,
});
const updateBuildingStarted = () => ({
  type: UPDATE_BUILDING_STARTED,
});
const updateBuildingFailure = (error) => ({
  type: UPDATE_BUILDING_FAILURE,
  payload: {
    error,
  },
});

export const updateBuilding = (input) => async (dispatch) => {
  try {
    dispatch(updateBuildingStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.updateBuilding, { input })
    );

    dispatch(updateBuildingSuccess(data.updateBuilding));
    toast.success(<MainToast message="Zaktualizowano budynek" />);
  } catch (err) {
    console.log(err);
    dispatch(updateBuildingFailure(err.errors));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};
