import styled from "styled-components";
import { device } from '../../../pages/log/mediaQueries';
import { CalendarCheckIcon } from "react-line-awesome";

export const ReportPendingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme }) => theme.color.black};
    font-size: 14px;
    border-top: 1px RGB(242, 244, 246) solid;
    padding: 32px 0;
    margin-top: 32px;
`;

export const ReportPendingHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;

export const ReportPendingHeader = styled.span`
    font-size: 16px;
    margin-right: auto;
`;

export const ReportPendingArrowButtonContainer = styled.div`
    display: flex;
`;

export const ReportPendingTaskButtonAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px RGB(242, 244, 246) solid; 
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.color.font200};

    :hover {
        color: ${({ theme }) => theme.color.blue};
        border-color: ${({ theme }) => theme.color.blue};
    }
`;

export const ReportPendingTaskTitleContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.weight.medium};
`;

export const ReportPendingTaskTitle = styled.span`
    margin-left: 8px;
`;

export const ReportPendingIconTask = styled.div`
    color: ${({ theme }) => theme.color.font200};
    font-size: 18px;
    margin-right: 8px;
`;

export const ReportPendingIconWithTextContainer = styled(ReportPendingTaskTitleContainer)`
    font-weight: ${({ theme }) => theme.weight.regular};
    margin: 8px 0 16px 0;
`;

export const ReportPendingIconCalendarWithText = styled(ReportPendingTaskTitleContainer)`
    font-weight: ${({ theme }) => theme.weight.regular};
    font-size: 12px;
`;

export const ReportPendingCalendarCheckIcon = styled(CalendarCheckIcon)`
    font-size: 16px;
    margin-right: 8px;
`;

export const ReportPendingStartDate = styled(ReportPendingIconCalendarWithText)`
    color: ${({ theme }) => theme.color.primaryColor};
    margin-left: 8px;
`;

export const ReportPendingCenterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 96px;
`;