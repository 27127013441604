import React from "react";
import styled from "styled-components";

import Burger from "./Burger";
import Add from "./Add";
import Search from "./Search";
import Chat from "./Chat";
import Alerts from "./Alerts";
import Profile from "./Profile";
import Clock from "../time/Clock";
import DateComponent from "../time/DateComponent";

const Container = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.color.topBarBackgroundColor};
  padding: 0 26px 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
  z-index: 20;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled(LeftSection)``;
const Title = styled.div`
  color: ${({ theme }) => theme.color.topBarFontPrimary};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.weight.regular};
  transform: translateY(-2px);
`;
const DisabledBox = styled.span`
  & > * {
    pointer-events: none;
    user-select: none;
  }
`;

const TimeAndDateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 27px;
`;

export default function TopBar({ title }) {
  return (
    <Container>
      <LeftSection>
        <Burger />
        <DisabledBox title="W przygotowaniu">
          <Add />
        </DisabledBox>
        <Title>{title}</Title>
      </LeftSection>
      <RightSection>
        <DisabledBox title="W przygotowaniu">
          <Search />
        </DisabledBox>
        <DisabledBox title="W przygotowaniu">
          <Chat />
        </DisabledBox>
        <DisabledBox title="W przygotowaniu">
          <Alerts />
        </DisabledBox>
        <TimeAndDateBox>
          <Clock />
          <DateComponent />
        </TimeAndDateBox>
        <Profile />
      </RightSection>
    </Container>
  );
}
