import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { usePermissionState } from "../../context/permission";
import { createTeam } from "../../redux/actions/teamActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateTeam = ({ setIsOpen }) => {
  const form = useForm({ defaultValues: { members: [] } });
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector(({ team }) => team);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    let price = [];
    if (data.price) {
      for (let [key, value] of Object.entries(data.price)) {
        price = [...price, { id: key, value }];
      }
    }

    const variables = {
      ...data,
      companyId,
      type: data.type.value,
      price,
    };

    dispatch(createTeam(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={false} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateTeam.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateTeam;
