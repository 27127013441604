import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PencilAltIcon } from "react-line-awesome";

import FormSectionTitle from "../../FormSectionTitle";
import Input from "../../Input";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const ProductFormFields = ({ form, loading, ...rest }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <FormSection>
      <FormSectionTitle title="Produkty" icon={PencilAltIcon} />
      <Input
        label="Nazwa produktu"
        placeholder="Wprowadź nazwę produktu"
        error={errors?.name?.message}
        isLoading={loading}
        {...register("name", {
          required: { value: true, message: "Pole nie może być puste" },
        })}
      />
      <Input
        label="Jednostka"
        placeholder="Wprowadź jednostkę"
        error={errors?.unit?.message}
        isLoading={loading}
        {...register("unit", {
          required: { value: true, message: "Pole nie może być puste" },
        })}
      />
    </FormSection>
  );
};

ProductFormFields.propTypes = {
  form: PropTypes.object,
};

export default ProductFormFields;
