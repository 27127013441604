/* --------------------------------- Queries -------------------------------- */
export const listRates = /* GraphQL */ `
  query ListRates($filter: ModelRateFilterInput) {
    listRates(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/* -------------------------------- Mutation -------------------------------- */
export const createRate = /* GraphQL */ `
  mutation CreateRate($input: CreateRateInput!) {
    createRate(input: $input) {
      id
    }
  }
`;

export const deleteRate = /* GraphQL */ `
  mutation deleteRate($input: DeleteRateInput!) {
    deleteRate(input: $input) {
      id
    }
  }
`;
