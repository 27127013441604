/* --------------------------------- Queries -------------------------------- */
export const listSections = /* GraphQL */ `
  query ListSections($filter: ModelSectionFilterInput) {
    listSections(filter: $filter) {
      items {
        id
        name
        items
      }
    }
  }
`;

export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      name
      items
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createSection = /* GraphQL */ `
  mutation CreateSection($input: CreateSectionInput!) {
    createSection(input: $input) {
      id
      name
      items
    }
  }
`;

export const deleteSection = /* GraphQL */ `
  mutation DeleteSection($input: DeleteSectionInput!) {
    deleteSection(input: $input) {
      id
    }
  }
`;

export const updateSection = /* GraphQL */ `
  mutation UpdateSection($input: UpdateSectionInput!) {
    updateSection(input: $input) {
      id
      name
      items
    }
  }
`;
