import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Checkbox from "./Checkbox";

import { Label } from "./Input";

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 20px;
`;
const SingleCheckboxContainer = styled.div`
  margin-bottom: 16px;
`;

const MultiCheckbox = ({ form, label, data }) => {
  const { control } = form;

  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      {data.map((checkbox) => (
        <SingleCheckboxContainer key={checkbox.slug}>
          <Controller
            render={({ ref, ...rest }) => (
              <Checkbox htmlFor={checkbox.slug} {...rest}>
                {checkbox.name}
              </Checkbox>
            )}
            defaultValue={false}
            control={control}
            name={checkbox.slug}
          />
        </SingleCheckboxContainer>
      ))}
    </div>
  );
};

export default MultiCheckbox;
