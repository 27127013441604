import React from "react";
import { SearchIcon, TimesIcon } from "react-line-awesome";
import styled from "styled-components";

const SearchInputContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.font100};
  margin: 0 18px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2 * 18px);
    height: 1px;
    background-color: ${({ theme }) => theme.color.mainBorder};
  }
`;
const StyledSearchInput = styled.input`
  width: 100%;
  padding: 12px calc(8px + 14px) 12px calc(8px + 17px);
  color: ${({ theme }) => theme.color.font300};
  font-size: 14px;
  border: none;

  ::placeholder {
    color: ${({ theme }) => theme.color.font100};
  }
`;
const IconBoxSearch = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 17px;
`;
const IconBoxClear = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 14px;
  cursor: pointer;
`;

const SearchInput = ({ value, setValue, placeholder = "Wyszukaj" }) => {
  return (
    <SearchInputContainer>
      <IconBoxSearch>
        <SearchIcon />
      </IconBoxSearch>
      <StyledSearchInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {value.length > 0 && (
        <IconBoxClear onClick={() => setValue("")}>
          <TimesIcon />
        </IconBoxClear>
      )}
    </SearchInputContainer>
  );
};

export default SearchInput;
