import React from 'react';
import Status from '../reports/Status';
import * as Styles from "./TaskListItem.styles";
import { PhoneIcon } from "react-line-awesome";
import { LocationArrowIcon } from "react-line-awesome";
import { UserIcon } from "react-line-awesome";
import { UsersIcon } from "react-line-awesome";
import { format } from 'date-fns';
import { getTypeIconByValue } from "../icons/typeIconsList";
import { pl } from 'date-fns/locale'

const TaskListItem = ({
    report,
    openEdit,
}) => {

    const { 
        id,
        client,
        deadline,
        status,
        startDate,
        typeItem,
        type,
        notes,
        team,
        latitude,
        longitude,
        building
    } = report;

    const deadlineAsDate = deadline && new Date(deadline);
    const startDateAsDate = startDate && new Date(startDate);

    const handlePhoneCall = () => {
        window.open(`tel:${client?.phoneNumber}`, "_self");
    }

    const handleViewLocalisation = () => {
        if (building) {
          if (building.latitude && building.longitude) {
            const link = `https://www.google.com/maps/search/?api=1&query=${building.latitude},${building.longitude}`;
            window.open(link, "_blank");
            return;
          }
        }

        if (latitude && longitude) {
            const link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(link, "_blank");
            return;
        }
        return;
      };

    const showTeamNameSection = () => {
        return (
            <>
            <Styles.IconTask>
                <UsersIcon />
            </Styles.IconTask>
            <Styles.IconTaskText>
                {team.name}
            </Styles.IconTaskText>
            </>
        );
    }

    const showClientNameSection = () => {
        return (
            <>
            <Styles.IconTask>
                <UserIcon />
            </Styles.IconTask>
            <Styles.IconTaskText>
                {client.name}
            </Styles.IconTaskText>
            </>
        );
    }

    const showPhoneButton = () => {
        return (
            <>
                <Styles.TaskButtonAction onClick = {(event) => {
                    event.stopPropagation();
                    handlePhoneCall();
                }}>
                    <PhoneIcon />
                </Styles.TaskButtonAction>
            </>
        );
    }

    const showMapButton = () => {
        return (
            <>
                <Styles.TaskButtonAction onClick = {(event) => {
                    event.stopPropagation();
                    handleViewLocalisation();
                }}>
                    <LocationArrowIcon />
                </Styles.TaskButtonAction>
            </>
        );
    }

    const showDeadlineSection = () => {
        return (
            <>
                <Styles.TaskEndHourText>
                    {format(deadlineAsDate, 'dd LLLL', { locale: pl })}
                </Styles.TaskEndHourText>
                <Styles.TaskEndHourText>
                    {format(deadlineAsDate, "HH:mm")}
                </Styles.TaskEndHourText>
            </>
        );
    }

    return (
        <Styles.TaskListItemWrapper onClick={() => openEdit({ id, typeItem })}>
            <Styles.TaskHoursContainer>
                <Styles.TaskStartHour>
                    {startDate ? format(startDateAsDate, "HH:mm") : "18:00"}
                </Styles.TaskStartHour>
                <Styles.TaskEndHour>
                    {deadline ? showDeadlineSection() : "---"}
                </Styles.TaskEndHour>
            </Styles.TaskHoursContainer>
            <Styles.TaskInformationAndStatus>
                <Styles.TaskInformationsContainer>
                    <Styles.TaskTitleContainer>
                        {getTypeIconByValue(typeItem ? typeItem.icon : "COMPLAINT")}
                        <Styles.TaskTitle>
                            {type ? type.name : "Brak tytułu"}
                        </Styles.TaskTitle>
                    </Styles.TaskTitleContainer>
                    <Styles.TaskButtonsClientNameAndTeamNameContainer>
                        {client ? showClientNameSection() : null}
                        {team ? showTeamNameSection() : null}
                    </Styles.TaskButtonsClientNameAndTeamNameContainer>
                    <Styles.TaskDescription>
                        {notes ? notes : null}
                    </Styles.TaskDescription>
                    <Styles.TaskButtonsContainer>
                        {
                            (((building && building.latitude) && (building && building.longitude)) || (latitude && longitude))
                            ? showMapButton() : null
                        }
                        {client ? showPhoneButton() : null}
                    </Styles.TaskButtonsContainer>
                </Styles.TaskInformationsContainer>
                {status ? <Status status={status}></Status> : null}
            </Styles.TaskInformationAndStatus>
        </Styles.TaskListItemWrapper>
    );
};

export default TaskListItem;