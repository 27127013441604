import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TimeBox = styled.div`
  color: ${({ theme }) => theme.color.topBarFontPrimary};
  font-size: 14px;
  line-height: 21px;
  width: fit-content;
`;

const getTimeString = () => {
  const date = new Date(Date.now()).toLocaleTimeString();
  return date;
};

const Clock = () => {
  const [time, setTime] = useState(getTimeString());

  useEffect(() => {
    const timer = setInterval(() => {
      const date = getTimeString();
      setTime(date);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <TimeBox>{time}</TimeBox>;
};

export default Clock;
