import { useState, useEffect } from "react";

const originalStyle = window.getComputedStyle(document.body).overflow;

export default function useLockBodyScroll() {
  const [isBodyLock, setIsBodyLock] = useState(false);

  useEffect(() => {
    if (isBodyLock) document.body.style.overflow = "hidden";
    else document.body.style.overflow = originalStyle;

    return () => (document.body.style.overflow = originalStyle);
  }, [isBodyLock]);

  return { setIsBodyLock, isBodyLock };
}
