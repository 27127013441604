import React, {useEffect} from "react";
import MainTemplate from "../../templates/MainTemplate";
import styled from "styled-components";
import {Button} from "../../components/Button";
import FileDetailHeader from "../../components/library/FileDetailHeader";
import {Title} from "../../components/Title";
import FilesButton from "../../components/FilesButton";
import {Subtitle} from "../../components/Subtitle";
import {useDispatch, useSelector} from "react-redux";
import {getRootFile} from "../../redux/actions/rootfileActions";
import {download, formatDate, recognizeFileExtension} from "../../helpers/functions";
import Pdf_big from "../../assets/svg/Pdf_big";
// import Pd
// import {file_big_key} from "../../assets/svg/file_big";
// import {img_big_key} from "../../assets/svg/image_big";
// import {pdf_big_key} from "../../assets/svg/pdf_big";
import {usePermissionState} from "../../context/permission";

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

const DataPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
`;

const Image = styled.div`
  margin-bottom: 25px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 30px;
`;

const TextBox = styled.div``;

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const DateHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DateDisplay = styled.div`
  border-radius: 4px;
  padding: 4px 16px;
  margin: 20px;
  color: ${({theme}) => theme.color.primaryColor};
  background-color: RGBA(0,130,255,0.08);
`


const FileDetails = ({match}) => {
    const fileId = match.params.id;
    const dispatch = useDispatch();
    const parentId = useSelector((state) => (state.rootFile.parentId));
    const rootFile = useSelector((state) => (
        state.rootFile.rootFile));

    const path = `${parentId}/${fileId}`

    useEffect(() => {
        dispatch(getRootFile(fileId));
    }, [fileId, dispatch]);

    return (
        <MainTemplate title={"Biblioteka"}>
            <TableBox>
                <DataPanel>
                   <FileDetailHeader id={fileId} path={path}/>
                    <Container>
                            <DateHeader>
                                <DateDisplay>
                                    {formatDate(rootFile)}
                                </DateDisplay>
                            </DateHeader>
                        <Image>
                            {recognizeFileExtension(rootFile, rootFile.isFolder)}
                        </Image>
                        <TextBox>
                            <StyledTitle>
                                {rootFile?.name}
                            </StyledTitle>
                            <StyledSubtitle>
                                {rootFile?.size}
                            </StyledSubtitle>
                            <FilesButton text={'Pobierz'} width={160} height={50} onClick={() => download(path, rootFile?.name)}/>
                        </TextBox>
                    </Container>
                </DataPanel>
            </TableBox>
        </MainTemplate>
    );
}

export default FileDetails;