import React from "react";
import { PlusCircleIcon } from "react-line-awesome";
import styled from "styled-components";

const NewButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.textLink};
`;
const Icon = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 6px;
`;
const Text = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.medium};
  text-transform: uppercase;
`;

const AddButton = ({ text, ...rest }) => {
  return (
    <NewButton {...rest}>
      <Icon>
        <PlusCircleIcon />
      </Icon>
      <Text>{text}</Text>
    </NewButton>
  );
};

export default AddButton;
