/* --------------------------------- Queries -------------------------------- */
export const listProtocols = /* GraphQL */ `
  query ListProtocols(
    $filter: ModelProtocolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProtocols(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        files {
          items {
            ref
            type
          }
        }
        type
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
