import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../Button";
import Submit from "../../Submit";
import FormFields from "./FormFields";
import { createBuilding } from "../../../redux/actions/buildingActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateBuilding = ({ clientId, setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate, loadingGet } = useSelector(
    ({ fieldItem }) => fieldItem
  );

  const onSubmit = (data) => {
    const { location } = data.address.geometry;
    const variables = {
      ...data,
      address: data.address.formatted_address,
      latitude: location.lat,
      longitude: location.lng,
      clientId,
    };

    dispatch(createBuilding(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={loadingGet} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default CreateBuilding;
