import {
  CREATE_TYPE_ITEM_STARTED,
  CREATE_TYPE_ITEM_SUCCESS,
  CREATE_TYPE_ITEM_FAILURE,
  LIST_TYPE_ITEMS_STARTED,
  LIST_TYPE_ITEMS_SUCCESS,
  LIST_TYPE_ITEMS_FAILURE,
  GET_TYPE_ITEM_STARTED,
  GET_TYPE_ITEM_SUCCESS,
  GET_TYPE_ITEM_FAILURE,
  UPDATE_TYPE_ITEM_STARTED,
  UPDATE_TYPE_ITEM_SUCCESS,
  UPDATE_TYPE_ITEM_FAILURE,
  DELETE_TYPE_ITEM_STARTED,
  DELETE_TYPE_ITEM_SUCCESS,
  DELETE_TYPE_ITEM_FAILURE,
} from "../../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  typeItems: [],
  typeItem: {},
  error: null,
};

const typeItemReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ----------------------------- Tworzenie typu ----------------------------- */
    case CREATE_TYPE_ITEM_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_TYPE_ITEM_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        typeItems: [action.payload, ...state.typeItems],
      };
    case CREATE_TYPE_ITEM_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    /* ----------------------- Listowanie wszystkich typów ---------------------- */
    case LIST_TYPE_ITEMS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_TYPE_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        typeItems: action.payload,
      };
    case LIST_TYPE_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    /* ----------------------- Pobieranie pojedyńczego typu ---------------------- */
    case GET_TYPE_ITEM_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_TYPE_ITEM_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        typeItem: action.payload,
      };
    case GET_TYPE_ITEM_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    /* ----------------------- Edycja typu ---------------------- */
    case UPDATE_TYPE_ITEM_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_TYPE_ITEM_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        typeItem: action.payload,
        typeItems: state.typeItems.map((typeItem) =>
          typeItem.id === action.payload.id ? action.payload : typeItem
        ),
      };
    case UPDATE_TYPE_ITEM_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    /* ----------------------- Usuwnaie typu ---------------------- */
    case DELETE_TYPE_ITEM_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_TYPE_ITEM_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        typeItems: state.typeItems.filter(
          (typeItem) => typeItem.id !== action.payload
        ),
      };
    case DELETE_TYPE_ITEM_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default typeItemReducer;
