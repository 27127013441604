import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import Input from "../../../components/Input";
import Submit from "../../../components/Submit";
import { Title } from "../../../components/Title";
import { emailReg } from "../../../util/reg";
import { Button } from "../../../components/Button";
import { routes } from "../../../routes";

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`;
const ButtonBox = styled.div`
  width: 116px;
`;
const Form = styled.form`
  min-width: 360px;
`;

export default function Step1({ setStep, recoveryForm }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = recoveryForm;

  const onSubmit = async (data) => {
    const { email } = data;

    try {
      setLoading(true);
      await Auth.forgotPassword(email);
      setStep(2);
    } catch (err) {
      console.log(err);
      if (err.code === "LimitExceededException") {
        setError("email", {
          type: "manual",
          message: "Zbyt wiele prób resetu hasła, spróbuj puźniej",
        });
      } else if (err.code === "NotAuthorizedException") {
        setError("email", {
          type: "manual",
          message: "Konto niezweryfikowane, skontaktuj się z administratorem",
        });
      } else if (err.code === "InvalidParameterException") {
        setError("email", {
          type: "manual",
          message: "Konto niezweryfikowane, sprawdź skrzynkę e-mail",
        });
      } else {
        setError("email", {
          type: "manual",
          message: "Użytkownik o takim adresie e-mail nie istnieje",
        });
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Title>Odzyskiwanie hasła</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Twój e-mail"
          placeholder="Wpisz swój adres e-mail"
          error={errors?.email?.message}
          {...register("email", {
            required: { value: true, message: "Pole nie może być puste" },
            pattern: {
              value: emailReg,
              message: "Podaj poprawny adres email",
            },
          })}
        />
        <ButtonsRow>
          <ButtonBox>
            <Button as={Link} to={routes.login} dark="1" big="1">
              Wróć
            </Button>
          </ButtonBox>
          <ButtonBox>
            <Submit value="Dalej" isHeight loader={loading} />
          </ButtonBox>
        </ButtonsRow>
      </Form>
    </>
  );
}
