import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainTemplate from "../../templates/MainTemplate";
import * as Styles from "./Log.styles";
import AddButton from "../../components/AddButton";
import SideModal from "../../components/modal/SideModal";
import CreateReport from "../../components/reports/CreateReport";
import TaskListItem from "../../components/log/TaskListItem";
import ReportPending from "../../components/log/ReportPending/ReportPending";
import Calendar from "../../components/log/Calendar/Calendar";
import { TaskButtonActionArrow } from "../../components/log/TaskListItem.styles";
import { AngleLeftIcon } from "react-line-awesome";
import { AngleRightIcon } from "react-line-awesome";
import { SunIcon } from "react-line-awesome";
import { routes } from "../../routes";
import { addDays, subDays, format, set } from "date-fns";
import { pl } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import { usePermissionState } from "../../context/permission";
import { getTypeIconByValue } from "../../components/icons/typeIconsList";
import EditReport from "../../components/reports/EditReport";
import {
  searchTicketsLog,
  searchListStatus,
} from "../../redux/actions/logActions";
import Loader from "../../components/Loader";
import SimpleDropdown from "../../components/SimpleDropdown";
import { API, graphqlOperation } from "aws-amplify";
import { listTypeItems } from "../../graphql/settings/notificationTypes";
import { utcToZonedTime } from "date-fns-tz";
import usePermissionObject from "../../hooks/usePermissionObject";

const Log = () => {
  const [filterDate, setFilterDate] = useState(new Date());
  const [isOpenModalCreateReport, setIsOpenModalCreateReport] = useState(false);
  const [countStatusReport, setCountStatusReport] = useState({
    pending: 0,
    inProgress: 0,
    scheduled: 0,
  });
  const [createDropdown, setCreateDropdown] = useState([]);
  const [creatingTypeItem, setCreatingTypeItem] = useState(null);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [currentOpenData, setCurrentOpenData] = useState(false);

  const openEdit = (data) => {
    setIsOpenModalEdit(true);
    setCurrentOpenData(data);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    reportState: state.log,
  }));

  const { reportState } = state;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const permissionObject = usePermissionObject("reports");

  const getDatesRange = (filterDate) => {
    const gte = set(utcToZonedTime(filterDate, "UTC"), {
      hours: 22,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const lte = set(addDays(utcToZonedTime(filterDate, "UTC"), 1), {
      hours: 21,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });

    return {
      gte: format(gte, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      lte: format(lte, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
    };
  };

  const searchTicketsLogVariables = useMemo(
    () => ({
      limit: 100,
      filter: {
        and: [
          { companyId: { eq: companyId } },
          {
            startDate: getDatesRange(filterDate),
          },
        ],
      },
      sort: {
        direction: "asc",
        field: "startDate",
      },
    }),
    [filterDate]
  );

  const searchStatusListVariables = useMemo(
    () => ({
      limit: 100,
      filter: {
        companyId: { eq: companyId },
      },
    }),
    []
  );

  useEffect(() => {
    dispatch(searchListStatus(searchStatusListVariables));
  }, [searchStatusListVariables]);

  useEffect(() => {
    dispatch(searchTicketsLog(searchTicketsLogVariables));
  }, [filterDate, searchTicketsLogVariables]);

  const isLoadingStatus = reportState.loadingStatus;

  const getCountStatusReport = () => {
    const statusList = reportState.statusList;

    var countStatusPending = statusList.filter((report) => {
      return report.status === "PENDING";
    }).length;

    var countStatusInProgress = statusList.filter((report) => {
      return report.status === "IN_PROGRESS";
    }).length;

    var countStatusScheduled = statusList.filter((report) => {
      return report.status === "SCHEDULED";
    }).length;

    setCountStatusReport({
      ...countStatusReport,
      pending: countStatusPending,
      inProgress: countStatusInProgress,
      scheduled: countStatusScheduled,
    });
  };

  useEffect(() => {
    getCountStatusReport();
  }, [reportState.statusList]);

  useEffect(() => {
    const getItems = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(listTypeItems, {
            filter: { companyId: { eq: companyId } },
          })
        );
        const response = data.listTypeItems.items.map((item) => ({
          value: item.id,
          name: item.name,
          icon: () => getTypeIconByValue(item.icon),
          handler: () => {
            setIsOpenModalCreateReport(true);
            setCreatingTypeItem(item);
          },
        }));

        setCreateDropdown(response);
      } catch (err) {
        console.log(err);
      }
    };

    getItems();
  }, [setCreateDropdown]);

  let history = useHistory();

  const redirectStateToCheckboxInReportPage = (statusReport) => {
    history.push({
      pathname: routes.reports,
      state: {
        state: { status: statusReport },
      },
    });
  };

  const formattedFilterDate = format(filterDate, "EEEE, dd LLLL", {
    locale: pl,
  });

  let reportsList = reportState.reports;

  const compareDateWithoutHours = (dateFirst, dateSecond) => {
    return (
      new Date(dateFirst).setHours(0, 0, 0, 0) ===
      dateSecond.setHours(0, 0, 0, 0)
    );
  };

  const incrementDate = useCallback(() => {
    const resultIncrementDate = addDays(filterDate, 1);
    setFilterDate(resultIncrementDate);
  });

  const decrementDate = useCallback(() => {
    const resultDecrementDate = subDays(filterDate, 1);
    setFilterDate(resultDecrementDate);
  });

  const RenderTasksListWithoutReportWithStatusPending = () => {
    const isLoading = reportState.loading;

    if (isLoading) {
      return (
        <Styles.NoDataInformation>
          <Loader />
        </Styles.NoDataInformation>
      );
    }

    if (!isLoading && reportsList.length === 0) {
      return (
        <Styles.NoDataInformation>
          Brak zgłoszeń do wyświetlenia
        </Styles.NoDataInformation>
      );
    }

    return reportsList
      .filter((report) => report.status !== "PENDING")
      .map((report) => {
        return (
          <TaskListItem key={report.id} report={report} openEdit={openEdit} />
        );
      });
  };

  return (
    <MainTemplate title="Dziennik">
      {permissionObject.create && (
        <SideModal
          title="Nowe zgłoszenie"
          isOpen={isOpenModalCreateReport}
          setIsOpen={setIsOpenModalCreateReport}
        >
          <CreateReport
            setIsOpen={setIsOpenModalCreateReport}
            typeItem={creatingTypeItem}
          />
        </SideModal>
      )}
      {permissionObject.create && (
        <SideModal isOpen={isOpenModalEdit} setIsOpen={setIsOpenModalEdit}>
          <EditReport setIsOpen={setIsOpenModalEdit} data={currentOpenData} />
        </SideModal>
      )}
      <Styles.LogWrapper>
        <Styles.SummaryContainer>
          <Styles.SectionHeader>Podsumowanie</Styles.SectionHeader>
          <Styles.SummaryInformationSection>
            <Styles.NumbersOfReportsOfAGivenTypeBox
              onClick={() => {
                redirectStateToCheckboxInReportPage("PENDING");
              }}
            >
              <Styles.NumbersOfReports>
                {isLoadingStatus ? <Loader /> : countStatusReport.pending}
              </Styles.NumbersOfReports>
              <Styles.ReportStatus>Robocze</Styles.ReportStatus>
            </Styles.NumbersOfReportsOfAGivenTypeBox>
            <Styles.NumbersOfReportsOfAGivenTypeBox
              onClick={() => {
                redirectStateToCheckboxInReportPage("SCHEDULED");
              }}
            >
              <Styles.NumbersOfReports>
                {isLoadingStatus ? <Loader /> : countStatusReport.scheduled}
              </Styles.NumbersOfReports>
              <Styles.ReportStatus>Zaplanowane</Styles.ReportStatus>
            </Styles.NumbersOfReportsOfAGivenTypeBox>
            <Styles.NumbersOfReportsOfAGivenTypeBox
              onClick={() => {
                redirectStateToCheckboxInReportPage("IN_PROGRESS");
              }}
            >
              <Styles.NumbersOfReports>
                {isLoadingStatus ? <Loader /> : countStatusReport.inProgress}
              </Styles.NumbersOfReports>
              <Styles.ReportStatus>W toku</Styles.ReportStatus>
            </Styles.NumbersOfReportsOfAGivenTypeBox>
            <Styles.WeatherWidgetBox>
              <Styles.WeatherWidgetLStatisticsContainer>
                <Styles.WeatherWidgetCityName>
                  Bydgoszcz
                </Styles.WeatherWidgetCityName>
                <Styles.WeatherWidgetTemperatureAltitude>
                  18&deg;
                </Styles.WeatherWidgetTemperatureAltitude>
              </Styles.WeatherWidgetLStatisticsContainer>
              <Styles.WeatherWidgetLStatisticsContainer>
                <Styles.WeatherWidgetStatusTemperature>
                  Słonecznie
                </Styles.WeatherWidgetStatusTemperature>
                <Styles.WeatherWidgetInformation>
                  Od 11&deg; do 23&deg;
                </Styles.WeatherWidgetInformation>
              </Styles.WeatherWidgetLStatisticsContainer>
              <Styles.WeatherWidgetIcon>
                <SunIcon />
              </Styles.WeatherWidgetIcon>
            </Styles.WeatherWidgetBox>
          </Styles.SummaryInformationSection>
        </Styles.SummaryContainer>
        <Styles.TasksListAndCalendarContainer>
          <Styles.TasksContainer>
            <Styles.SectionHeader>
              Zadania
              <Styles.AllReports>Wszystkie Zgłoszenia</Styles.AllReports>
            </Styles.SectionHeader>
            <Styles.TasksListContainer>
              <Styles.TasksListHeader>
                <Styles.TasksListHeaderDate>
                  {formattedFilterDate}
                </Styles.TasksListHeaderDate>
                <Styles.TaskListButtonsContainer>
                  {permissionObject.create && (
                    <SimpleDropdown
                      data={createDropdown}
                      icon={() => <AddButton text={"Nowe zgłoszenie"} />}
                    />
                  )}
                  <Styles.ArrowButtonContainer>
                    <TaskButtonActionArrow onClick={decrementDate}>
                      <AngleLeftIcon />
                    </TaskButtonActionArrow>
                    <TaskButtonActionArrow>
                      <AngleRightIcon onClick={incrementDate} />
                    </TaskButtonActionArrow>
                  </Styles.ArrowButtonContainer>
                </Styles.TaskListButtonsContainer>
              </Styles.TasksListHeader>
              <Styles.TasksList>
                {RenderTasksListWithoutReportWithStatusPending()}
              </Styles.TasksList>
            </Styles.TasksListContainer>
          </Styles.TasksContainer>
          <Styles.CalendarContainer>
            <Styles.SectionHeader>Kalendarz</Styles.SectionHeader>
            <Styles.CalendarAndDraftReportsContainer>
              <Calendar
                filterDate={filterDate}
                setFilterDate={(date) => setFilterDate(date)}
              />
              <ReportPending />
            </Styles.CalendarAndDraftReportsContainer>
          </Styles.CalendarContainer>
        </Styles.TasksListAndCalendarContainer>
      </Styles.LogWrapper>
    </MainTemplate>
  );
};

export default Log;
