import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateTicket } from "../../graphql/tickets";

export const useChangeStatus = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleStatusChange = async (status, ticketId) => {
    try {
      setLoading(true);

      if (ticketId) {
        await API.graphql(
          graphqlOperation(updateTicket, { input: { id: ticketId, status } })
        );
      }

      setStatus(status);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return [handleStatusChange, { state: status, loading }];
};
