import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { useDispatch, useSelector } from "react-redux";
import { getClient, updateClient } from "../../redux/actions/clientActions";
import { isEmpty } from "lodash";
import { parsePhoneNumber } from "libphonenumber-js";
import { usePermissionState } from "../../context/permission";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditClient = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const dispatch = useDispatch();
  const clientState = useSelector(({ client }) => client);

  useEffect(() => {
    dispatch(getClient(id));
  }, [id, dispatch]);

  useEffect(() => {
    const language = "PL";
    if (!isEmpty(clientState.client)) {
      const { name, fullName, phoneNumber, logo, address, email, notes } =
        clientState.client;

      setValue("logo", logo);
      setValue("name", name);
      setValue("fullName", fullName);
      setValue(
        "phoneNumber",
        parsePhoneNumber(phoneNumber, language).nationalNumber
      );
      setValue("address", address);
      setValue("email", email);
      setValue("notes", notes);
    }
  }, [clientState.client, setValue]);

  const onSubmit = (data) => {
    const language = "PL";
    const { location } = data.address?.geometry || {};

    const input = {
      ...data,
      phoneNumber: parsePhoneNumber(data.phoneNumber, language).number,
      address: data.address.formatted_address || data.address,
      latitude: location?.lat,
      longitude: location?.lng,
      id,
    };
    if (!location) {
      delete input.latitude;
      delete input.longitude;
    }

    dispatch(updateClient(input, companyId));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={clientState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={clientState.loadingGet}
            loader={clientState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

EditClient.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EditClient;
