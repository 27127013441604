import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";

import MainToast from "../../../components/toastify/MainToast";
import * as graphql from "../../../graphql/settings/fields";
import {
  CREATE_FIELD_ITEM_STARTED,
  CREATE_FIELD_ITEM_SUCCESS,
  CREATE_FIELD_ITEM_FAILURE,
  LIST_FIELD_ITEMS_STARTED,
  LIST_FIELD_ITEMS_SUCCESS,
  LIST_FIELD_ITEMS_FAILURE,
  GET_FIELD_ITEM_STARTED,
  GET_FIELD_ITEM_SUCCESS,
  GET_FIELD_ITEM_FAILURE,
  UPDATE_FIELD_ITEM_STARTED,
  UPDATE_FIELD_ITEM_SUCCESS,
  UPDATE_FIELD_ITEM_FAILURE,
  DELETE_FIELD_ITEM_STARTED,
  DELETE_FIELD_ITEM_SUCCESS,
  DELETE_FIELD_ITEM_FAILURE,
} from "../../types";

const createFieldWithRelation = (fieldItemId) => async (type) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(graphql.createField, { input: { name: type } })
    );
    const fieldId = data.createField.id;

    await API.graphql(
      graphqlOperation(graphql.createFieldConnector, {
        input: { fieldId, fieldItemId },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const deleteExistingRelations = () => async (type) => {
  try {
    await API.graphql(
      graphqlOperation(graphql.deleteFieldConnector, {
        input: { id: type.id },
      })
    );

    await API.graphql(
      graphqlOperation(graphql.deleteField, {
        input: { id: type.field.id },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const createFieldItemSuccess = (field) => ({
  type: CREATE_FIELD_ITEM_SUCCESS,
  payload: field,
});
const createFieldItemStarted = () => ({
  type: CREATE_FIELD_ITEM_STARTED,
});
const createFieldItemFailure = (error) => ({
  type: CREATE_FIELD_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const createFieldItem = (variables, callback) => async (dispatch) => {
  const { companyId, name, types, typeItemId } = variables;

  try {
    dispatch(createFieldItemStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.createFieldItem, {
        input: { companyId, name, typeItemId },
      })
    );
    const fieldItemId = data.createFieldItem.id;

    await Promise.all(types.map(createFieldWithRelation(fieldItemId)));

    // Get item with relation
    const { data: response } = await API.graphql(
      graphqlOperation(graphql.getFieldItem, { id: fieldItemId })
    );
    const fieldItem = response.getFieldItem;

    dispatch(createFieldItemSuccess(fieldItem));
    toast.success(<MainToast message="Nowy typ dodany pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(createFieldItemFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const listFieldItemsSuccess = (fieldItems) => ({
  type: LIST_FIELD_ITEMS_SUCCESS,
  payload: fieldItems,
});
const listFieldItemsStarted = () => ({
  type: LIST_FIELD_ITEMS_STARTED,
});
const listFieldItemsFailure = (error) => ({
  type: LIST_FIELD_ITEMS_FAILURE,
  payload: {
    error,
  },
});

export const listFieldItems = (variables) => async (dispatch) => {
  try {
    dispatch(listFieldItemsStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.listFieldItems, variables)
    );
    const fieldItems = data.listFieldItems.items;

    dispatch(listFieldItemsSuccess(fieldItems));
  } catch (err) {
    console.log(err);
    dispatch(listFieldItemsFailure(err));
  }
};

const getFieldItemSuccess = (fieldItem) => ({
  type: GET_FIELD_ITEM_SUCCESS,
  payload: fieldItem,
});
const getFieldItemStarted = () => ({
  type: GET_FIELD_ITEM_STARTED,
});
const getFieldItemFailure = (error) => ({
  type: GET_FIELD_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const getFieldItem = (id) => async (dispatch) => {
  try {
    dispatch(getFieldItemStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.getFieldItem, { id })
    );
    const fieldItem = data.getFieldItem;

    dispatch(getFieldItemSuccess(fieldItem));
  } catch (err) {
    console.log(err);
    dispatch(getFieldItemFailure(err));
  }
};

const updateFieldItemSuccess = (fieldItem) => ({
  type: UPDATE_FIELD_ITEM_SUCCESS,
  payload: fieldItem,
});
const updateFieldItemStarted = () => ({
  type: UPDATE_FIELD_ITEM_STARTED,
});
const updateFieldItemFailure = (error) => ({
  type: UPDATE_FIELD_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const updateFieldItem = (variables, callback) => async (dispatch) => {
  try {
    dispatch(updateFieldItemStarted());

    const { data: typeItem } = await API.graphql(
      graphqlOperation(graphql.updateFieldItem, {
        input: { id: variables.id, name: variables.name },
      })
    );

    const fields = typeItem.updateFieldItem.fields.items;
    await Promise.all(fields.map(deleteExistingRelations()));

    await Promise.all(
      variables.types.map(createFieldWithRelation(variables.id))
    );

    const { data: newFieldItem } = await API.graphql(
      graphqlOperation(graphql.getFieldItem, {
        id: variables.id,
      })
    );

    dispatch(updateFieldItemSuccess(newFieldItem.getFieldItem));
    toast.success(<MainToast message="Zapisano zmiany" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(updateFieldItemFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const deleteFieldItemSuccess = (id) => ({
  type: DELETE_FIELD_ITEM_SUCCESS,
  payload: id,
});
const deleteFieldItemStarted = (id) => ({
  type: DELETE_FIELD_ITEM_STARTED,
  payload: id,
});
const deleteFieldItemFailure = (error) => ({
  type: DELETE_FIELD_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const deleteFieldItem = (id) => async (dispatch) => {
  try {
    dispatch(deleteFieldItemStarted(id));

    const { data } = await API.graphql(
      graphqlOperation(graphql.deleteFieldItem, {
        input: { id },
      })
    );

    const types = data.deleteFieldItem.fields.items;
    await Promise.all(types.map(deleteExistingRelations()));

    dispatch(deleteFieldItemSuccess(id));
    toast.success(<MainToast message="Usunięto pomyślnie" />);
  } catch (err) {
    console.log(err);
    dispatch(deleteFieldItemFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};
