import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { PencilAltIcon } from "react-line-awesome";

import { Title } from "../../components/Title";
import AddButton from "../../components/AddButton";
import DataTableSecondary from "../../components/dataTable/DataTableSecondary";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import SideModal from "../../components/modal/SideModal";
import { usePermissionState } from "../../context/permission";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProtocol,
  listProtocols,
} from "../../redux/actions/settings/protocolActions";
import { useConfirmModalDispatch } from "../../context/ModalContext";

import CreateProtocol from "../../components/settings/protocols/CreateProtocol";
import EditProtocol from "../../components/settings/protocols/EditProtocol";

const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const FlexEndCell = styled.div`
  display: flex;

  & > * {
    display: flex;
  }
`;
const Medium = styled.div`
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;
const TruncateBox = styled.div``;

const Container = styled.div`
  padding: 31px 31px 36px 29px;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 0;
  font-size: 20px;
`;

const Protocols = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <Medium className="rowHover">{value}</Medium>
        ),
      },
      {
        Header: "Działania",
        accessor: "actions",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const modalDispatch = useConfirmModalDispatch();

  const dispatch = useDispatch();
  const protocolState = useSelector((state) => state.protocol);

  const searchCompaniesVariables = useMemo(
    () => ({
      filter: { companyId: { eq: companyId } },
    }),
    []
  );

  useEffect(() => {
    dispatch(listProtocols(searchCompaniesVariables));
  }, [dispatch, searchCompaniesVariables]);

  const protocols = useMemo(() => {
    return protocolState.protocols.map((item) => {
      const { id, name } = item;
      const isDeleteLoading = protocolState.loadingDelete === id;

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć sekcję?",
            buttonText: "Usuń",
            action: () => dispatch(deleteProtocol(id)),
          },
        });
      };

      return {
        ...item,
        name: (
          <Medium className="rowHover" onClick={() => openEdit(id)}>
            {name}
          </Medium>
        ),
        actions: (
          <>
            <IconBox>
              <PencilAltIcon onClick={() => openEdit(id)} />
            </IconBox>
            <IconBox onClick={handleDelete}>
              <RowDeleteLoader loading={isDeleteLoading} />
            </IconBox>
          </>
        ),
      };
    });
  }, [protocolState.protocols, protocolState.loadingDelete]);

  return (
    <Container>
      <HeaderBox>
        <StyledTitle>Protokół odbioru</StyledTitle>
        <AddButton text="Dodaj sekcje" onClick={() => setIsCreateOpen(true)} />
      </HeaderBox>
      <DataTableSecondary
        data={protocols}
        columns={columns}
        loading={protocolState.loading}
      />

      <SideModal
        title="Nowa sekcja"
        isOpen={isCreateOpen}
        setIsOpen={setIsCreateOpen}
      >
        <CreateProtocol setIsOpen={setIsCreateOpen} />
      </SideModal>

      <SideModal
        title="Edycja sekcji"
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      >
        <EditProtocol setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
      </SideModal>
    </Container>
  );
};

export default Protocols;
