import React, { useEffect } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import { InputElement, Label, InputBox } from "../../../components/Input";
import Submit from "../../../components/Submit";
import { Title } from "../../../components/Title";
import { Button } from "../../../components/Button";

const Form = styled.form`
  min-width: 360px;
`;
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`;
const ButtonBox = styled.div`
  width: 116px;
`;
const StyledInputElement = styled(InputElement)`
  border-radius: 0;
  width: 60px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  border: none;

  :not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.color.inputBorder};
  }

  :first-of-type {
    border-radius: 4px 0 0 4px;
  }
  :last-of-type {
    border-radius: 0 4px 4px 0;
  }
`;
const MultiInputBox = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 4px;
`;
const CodeError = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
  color: red;
  font-size: 12px;
`;
const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ResendCode = styled.div`
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.primaryColor};
  font-size: 14px;
  cursor: pointer;
`;

export default function Step2({ setStep, recoveryForm, codeError }) {
  const {
    register,
    unregister,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    formState: { errors },
  } = recoveryForm;

  useEffect(() => {
    setFocus("pin.p1");
  }, [setFocus]);

  const handleKeyPress = (e) => {
    const { maxLength, value, name } = e.target;

    if (name.includes("pin.p")) {
      const index = parseInt(name.substr(name.length - 1));

      if (value.length >= maxLength && e.keyCode !== 13) {
        setValue(name, "");
      }

      if (e.keyCode === 8 && index > 1) {
        if (value.length === 0) {
          const prevInput = `pin.p${index - 1}`;
          setValue(name, "");
          setFocus(prevInput, "");
        }
      }
    }
  };

  const handleResendClick = async () => {
    const email = getValues("email");
    try {
      await Auth.forgotPassword(email);
    } catch (err) {
      console.log("Error: Ups! Coś poszło nie tak!", err);
    }
  };

  const handleFormChange = (e) => {
    const { maxLength, value, name } = e.target;
    if (name.includes("pin.p")) {
      const index = parseInt(name.substr(name.length - 1));
      if (value.length >= maxLength && index < 6) {
        setFocus(`pin.p${index + 1}`);
      }
    }
  };

  const handlePrevStep = () => {
    unregister(["pin.p1", "pin.p2", "pin.p3", "pin.p4", "pin.p5", "pin.p6"]);
    setStep(1);
  };

  const onSubmit = () => {
    setStep(3);
  };

  return (
    <>
      <Title>Potwierdź adres e-mail</Title>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleFormChange}
        onKeyDown={handleKeyPress}
        autoComplete="off"
      >
        <InputBox>
          <LabelBox>
            <Label>Wprowadź 6-cyfrowy pin</Label>
            <ResendCode onClick={handleResendClick}>Wyślij ponownie</ResendCode>
          </LabelBox>
          <MultiInputBox>
            <StyledInputElement
              {...register("pin.p1", {
                required: true,
              })}
              maxLength={1}
            />
            <StyledInputElement
              {...register("pin.p2", {
                required: true,
              })}
              maxLength={1}
            />
            <StyledInputElement
              {...register("pin.p3", {
                required: true,
              })}
              maxLength={1}
            />
            <StyledInputElement
              {...register("pin.p4", {
                required: true,
              })}
              maxLength={1}
            />
            <StyledInputElement
              {...register("pin.p5", {
                required: true,
              })}
              maxLength={1}
            />
            <StyledInputElement
              {...register("pin.p6", {
                required: true,
              })}
              maxLength={1}
            />
            {codeError && <CodeError>{codeError}</CodeError>}
            {Object.keys(errors).length > 0 && (
              <CodeError>Błędny format pinu</CodeError>
            )}
          </MultiInputBox>
        </InputBox>
        <ButtonsRow>
          <ButtonBox>
            <Button onClick={handlePrevStep} dark="1" big="1">
              Wróć
            </Button>
          </ButtonBox>
          <ButtonBox>
            <Submit value="Dalej" isHeight />
          </ButtonBox>
        </ButtonsRow>
      </Form>
    </>
  );
}
