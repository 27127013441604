import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import { toast } from "react-toastify";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { createCompanyUser } from "../../redux/actions/companyActions";
import MainToast from "../toastify/MainToast";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateCompany = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.company);

  const onSubmit = (data) => {
    const language = "PL";
    const { customerName, phone } = data;
    const { location } = data.address.geometry;

    const variables = {
      ...data,
      fullName: customerName,
      phone: parsePhoneNumber(phone, language).number,
      address: data.address.formatted_address,
      latitude: location.lat,
      longitude: location.lng,
    };

    dispatch(
      createCompanyUser(variables, () => {
        setIsOpen(false);
        toast.success(<MainToast message="Stworzono nową firmę" />);
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateCompany.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateCompany;
