import { API, graphqlOperation } from "aws-amplify";
import * as graphql from "../../graphql/companies";
import { toast } from "react-toastify";
import {
  SEARCH_COMPANIES_STARTED,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_FAILURE,
  CREATE_COMPANY_STARTED,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  DELETE_COMPANY_FULL_STARTED,
  DELETE_COMPANY_FULL_SUCCESS,
  DELETE_COMPANY_FULL_FAILURE,
  GET_COMPANY_STARTED,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  COMPANY_UPDATE_STARTED,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAILURE,
} from "../types";
import MainToast from "../../components/toastify/MainToast";
import { addPhoto } from "../../helpers/functions";

const searchCompaniesSuccess = (companies, nextToken, total) => ({
  type: SEARCH_COMPANIES_SUCCESS,
  payload: {
    companies,
    nextToken,
    total,
  },
});
const searchCompaniesStarted = () => ({
  type: SEARCH_COMPANIES_STARTED,
});
const searchCompaniesFailure = (error) => ({
  type: SEARCH_COMPANIES_FAILURE,
  payload: {
    error,
  },
});

export const searchCompanies = (variables) => async (dispatch) => {
  try {
    dispatch(searchCompaniesStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchCompanies, variables)
    );
    const nextToken = data.searchCompanys.nextToken;
    const total = data.searchCompanys.total;
    const companies = data.searchCompanys.items;

    dispatch(searchCompaniesSuccess(companies, nextToken, total));
  } catch (err) {
    console.log(err);
    dispatch(searchCompaniesFailure(err));
  }
};

const createCompanySuccess = (company) => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: company,
});
const createCompanyStarted = () => ({
  type: CREATE_COMPANY_STARTED,
});
const createCompanyFailure = (error) => ({
  type: CREATE_COMPANY_FAILURE,
  payload: {
    error,
  },
});

export const createCompanyUser =
  (input, callback = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStarted());
      const { data } = await API.graphql(
        graphqlOperation(graphql.createCompanyUser, {
          ...input,
        })
      );
      const companyUser = data.createCompanyUser.company;

      dispatch(createCompanySuccess(companyUser));
      callback();
    } catch (err) {
      console.log(err);
      if (err.errors[0].errorType === "Lambda:Unhandled") {
        toast.error(<MainToast message="Email jest zajty" />);
      } else {
        toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
      }
      dispatch(createCompanyFailure(err.errors));
    }
  };

const deleteCompanyFullSuccess = (id) => ({
  type: DELETE_COMPANY_FULL_SUCCESS,
  payload: id,
});
const deleteCompanyFullStarted = (id) => ({
  type: DELETE_COMPANY_FULL_STARTED,
  payload: id,
});
const deleteCompanyFullFailure = (error) => ({
  type: DELETE_COMPANY_FULL_FAILURE,
  payload: {
    error,
  },
});

export const deleteCompanyFull = (id) => async (dispatch) => {
  try {
    dispatch(deleteCompanyFullStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteCompanyFull, {
        id,
      })
    );

    dispatch(deleteCompanyFullSuccess(id));
    toast.success(<MainToast message="Firma usunięta pomyślnie" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(deleteCompanyFullFailure(err.errors));
  }
};

const getCompanySuccess = (company) => ({
  type: GET_COMPANY_SUCCESS,
  payload: company,
});
const getCompanyStarted = () => ({
  type: GET_COMPANY_STARTED,
});
const getCompanyFailure = (error) => ({
  type: GET_COMPANY_FAILURE,
  payload: {
    error,
  },
});

export const getCompany = (id) => async (dispatch) => {
  try {
    dispatch(getCompanyStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getCompany, {
        id,
      })
    );
    const company = data.getCompany;

    dispatch(getCompanySuccess(company));
  } catch (err) {
    console.log(err);
    dispatch(getCompanyFailure(err.errors));
  }
};

const companyUpdateSuccess = (user) => ({
  type: COMPANY_UPDATE_SUCCESS,
  payload: user,
});
const companyUpdateStarted = () => ({
  type: COMPANY_UPDATE_STARTED,
});
const companyUpdateFailure = (error) => ({
  type: COMPANY_UPDATE_FAILURE,
  payload: {
    error,
  },
});

export const companyUpdate = (input) => async (dispatch) => {
  try {
    dispatch(companyUpdateStarted());

    let variables = { ...input };

    // Add logo
    if (input.logo && typeof input.logo !== "string") {
      const config = { companyId: input.id, fieldType: "logos" };
      variables.logo = await addPhoto(input.logo, config);
    } else delete variables.logo;

    const { data } = await API.graphql(
      graphqlOperation(graphql.companyUpdate, variables)
    );

    dispatch(companyUpdateSuccess(data.companyUpdate));
    toast.success(<MainToast message="Zapisano pomyślnie" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(companyUpdateFailure(err.errors));
  }
};
