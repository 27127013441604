import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BriefcaseIcon, UserTieIcon } from "react-line-awesome";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Controller } from "react-hook-form";

import FormSectionTitle from "../FormSectionTitle";
import Input from "../Input";
import AddressInput from "../AddressInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading, ...rest }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Informacje o firmie" icon={BriefcaseIcon} />
        <Input
          label="Nazwa firmy"
          placeholder="Wprowadź nazwę firmy"
          error={errors?.companyName?.message}
          isLoading={loading}
          {...register("companyName", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Numer NIP"
          placeholder="Wprowadź numer NIP"
          error={errors?.vat?.message}
          isLoading={loading}
          {...register("vat", {
            required: { value: true, message: "Pole nie może być puste" },
            maxLength: { value: 10, message: "Nip musi posiadać 10 cyfr" },
            minLength: { value: 10, message: "Nip musi posiadać 10 cyfr" },
            pattern: {
              value: /^[0-9]*$/,
              message: "Nip musi posiadać 10 cyfr",
            },
          })}
        />
        <Controller
          render={({ ...rest }) => (
            <AddressInput
              isLoading={loading}
              label="Adres zamieszkania"
              placeholder="Wprowadź adres zamieszkania"
              error={errors?.address?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Podaj adres siedziby" },
          }}
          control={control}
          name="address"
        />
      </FormSection>
      <FormSection>
        <FormSectionTitle title="Osoba kontaktowa" icon={UserTieIcon} />
        <Input
          label="Imię i nazwisko"
          placeholder="Wprowadź  imię i nazwisko"
          error={errors?.customerName?.message}
          isLoading={loading}
          {...register("customerName", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Adres e-mail"
          placeholder="Wprowadź adres e-mail"
          error={errors?.email?.message}
          disabled={rest.edit || false}
          isLoading={loading}
          {...register("email", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Numer telefonu"
          placeholder="Wprowadź numer telefonu"
          error={errors?.phone?.message}
          isLoading={loading}
          {...register("phone", {
            required: { value: true, message: "Pole nie może być puste" },
            validate: (value) =>
              isValidPhoneNumber(value, "PL") ? true : "Podaj poprawny numer",
          })}
        />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
};

export default FormFields;
