import React, { createContext, useContext, useReducer } from "react";
import ConfirmModal from "../components/modal/ConfirmModal";
import InputModal from "../components/modal/InputModal";

const initialState = {
  isOpen: false,
  title: null,
  content: null,
  action: null,
  buttonText: "dalej",
  isInput: false,
};

const ConfirmModalStateContext = createContext();
const ConfirmModalDispatchContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN":
      return {
        ...state,
        isOpen: true,
        isButtonSectionDisabled: payload.isButtonSectionDisabled,
        title: payload.title,
        content: payload.content,
        buttonText: payload.buttonText,
        action: payload.action,
        isInput: payload.isInput,
      };
    case "CLOSE":
      return {
        ...state,
        isOpen: false,
      };
    default:
      console.log("Błędny typ akcji");
      return state;
  }
};

export const ConfirmModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfirmModalDispatchContext.Provider value={dispatch}>
      <ConfirmModalStateContext.Provider value={state}>
        {state.isInput ? (
          <InputModal {...state} />
        ) : (
          <ConfirmModal {...state} />
        )}
        {children}
      </ConfirmModalStateContext.Provider>
    </ConfirmModalDispatchContext.Provider>
  );
};

export const useConfirmModalState = () => useContext(ConfirmModalStateContext);
export const useConfirmModalDispatch = () =>
  useContext(ConfirmModalDispatchContext);
