import React from "react";
import styled from "styled-components";

import { Checked } from "../icons";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => center && "center"};
`;
const BoxContainer = styled.div`
  position: relative;
  display: flex;
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.color.checkboxBorder};
  background-color: ${({ theme }) => theme.color.checkboxBackground};
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  appearance: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.16s ease-in-out;

  :checked {
    border-color: ${({ theme }) => theme.color.checkboxCheckedBackground};
    background-color: ${({ theme }) => theme.color.checkboxCheckedBackground};
  }
`;

const Label = styled.label`
  display: flex;
  margin-left: 14px;
  color: ${({ theme, error }) =>
    error ? theme.color.primary : theme.color.font300};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.regular};
  user-select: none;
  cursor: pointer;
  transition: color 0.16s ease-in-out;

  color: ${({ theme, checked }) => checked || theme.color.font100};
`;

const IconBox = styled.div`
  position: absolute;
  display: flex;
  height: 11px;
  top: 6px;
  left: 5px;
  transform: scale(${({ checked }) => (checked ? 1 : 0)});
  pointer-events: none;
  transition: transform 0.16s ease-in-out;
`;

const Checkbox = ({
  field: { onChange, value },
  htmlFor,
  children,
  center,
  iconBoxStyles,
  ...rest
}) => (
  <CheckboxContainer center={center}>
    <BoxContainer>
      <Input
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        id={htmlFor}
        checked={value}
        {...rest}
      />
      <IconBox checked={value} style={iconBoxStyles}>
        <Checked width={11} />
      </IconBox>
    </BoxContainer>
    {children && (
      <Label htmlFor={htmlFor} checked={value}>
        {children}
      </Label>
    )}
  </CheckboxContainer>
);

export default Checkbox;
