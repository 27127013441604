import { combineReducers } from "redux";

import role from "./roleReducer";
import company from "./companyReducer";
import table from "./tableReducer";
import user from "./userReducer";
import team from "./teamReducer";
import product from "./settings/productReducer";
import protocol from "./settings/protocolReducer";
import typeItem from "./settings/typeItemReducer";
import fieldItem from "./settings/fieldItemReducer";
import client from "./clientReducer";
import building from "./buildingReducer";
import ticket from "./ticketReducer";
import log from "./logReducer";
import rootFile from "./rootfileReducer";

const appReducer = combineReducers({
  table,
  role,
  company,
  user,
  team,
  product,
  protocol,
  typeItem,
  fieldItem,
  client,
  building,
  ticket,
  log,
  rootFile
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
