import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { defaultStyles, FileIcon } from "react-file-icon";
import _ from "lodash";
import {
  CloudUploadIcon,
  PlusCircleIcon,
  TimesCircleIcon,
} from "react-line-awesome";
import { isEmpty } from "@aws-amplify/core";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.color.font100};
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;
const IconBox = styled.div`
  display: flex;
  font-size: 18px;
  margin-right: 4px;
`;
const DropzoneBox = styled.div`
  position: relative;
  width: 100%;
`;
const Dropzone = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 8px;
  border-radius: 4px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const RemoveIcon = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.font100};
  border-radius: 50%;
  display: flex;
  opacity: 0;
  transform: scale(0.4);
  cursor: pointer;
  z-index: 40;
  transition: all.16s ease-in-out;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.color.white};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    z-index: -1;
  }
`;
const FilePreviewBox = styled.div`
  position: relative;
  cursor: pointer;

  :hover > ${RemoveIcon} {
    opacity: 1;
    transform: scale(1);
  }
`;
const DropdownPlaceholder = styled.div`
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.color.mainBorder};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: ${({ theme }) => theme.color.font100};
  cursor: pointer;
`;
const FileIconContainer = styled.div`
  cursor: pointer;
`;

const imageExtensions = ["png", "jpg", "jpeg"];

const MultiFilesUpload = ({
  field: { onChange, value },
  placeholder = "Wybierz lub przeciągnij plik",
  types,
}) => {
  const [filePreviewArray, setFilePreviewArray] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const fileToBlob = (file) => URL.createObjectURL(file);

  const [images, otherFiles] = useMemo(
    () =>
      filePreviewArray.reduce(
        (acc, file) => {
          if (typeof file === "string") {
            const extension = file.split(".").pop();

            if (imageExtensions.includes(extension)) acc[0].push({ url: file });
            else acc[1].push({ url: file, extension });
          } else {
            const [type, extension] = file.type.split("/");

            if (type === "image") acc[0].push({ url: fileToBlob(file), file });
            else acc[1].push({ url: fileToBlob(file), extension, file });
          }

          return acc;
        },
        [[], []]
      ),
    [filePreviewArray]
  );

  useEffect(() => {
    if (!value) return;
    setFilePreviewArray([]);
    const onlyUrlValue = value.filter((i) => typeof i === "string");
    setFilePreviewArray(onlyUrlValue);
    // eslint-disable-next-line
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...filePreviewArray, ...acceptedFiles];

      setFilePreviewArray(files);
      onChange(files);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: types,
  });

  const handleFileDelete =
    ({ url, file }) =>
    (e) => {
      e.stopPropagation();
      const newFilesArray = filePreviewArray.filter((item) => {
        if (file) return !_.isEqual(item, file);
        else return item !== url;
      });
      onChange(newFilesArray);
      setFilePreviewArray(newFilesArray);
    };

  return (
    <>
      <DropzoneBox {...getRootProps()}>
        {isEmpty(filePreviewArray) && (
          <Text>
            <IconBox>
              <PlusCircleIcon />
            </IconBox>
            {placeholder}
          </Text>
        )}
        <input {...getInputProps()} />
        {isEmpty(filePreviewArray) || (
          <Dropzone>
            {filePreviewArray.length > 0 && (
              <DropdownPlaceholder>
                <CloudUploadIcon />
              </DropdownPlaceholder>
            )}

            {images.map(({ url, file }, index) => (
              <FilePreviewComponent
                key={url}
                handleDelete={handleFileDelete({ url, file })}
              >
                <Image
                  src={url}
                  alt="Preview"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPhotoIndex(index);
                    setIsLightboxOpen(true);
                  }}
                />
              </FilePreviewComponent>
            ))}
            {otherFiles.map(({ url, extension, file }) => (
              <FilePreviewComponent
                key={url}
                handleDelete={handleFileDelete({ url, file })}
              >
                {console.log({ url, file })}
                <FilePreviewBox
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(url, "_blank");
                  }}
                >
                  <FileIcon
                    extension={extension}
                    {...defaultStyles[extension]}
                  />
                </FilePreviewBox>
              </FilePreviewComponent>
            ))}
          </Dropzone>
        )}
      </DropzoneBox>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex].url}
          nextSrc={images[(photoIndex + 1) % images.length].url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

const FilePreviewComponent = ({ handleDelete, children, ...rest }) => (
  <FilePreviewBox {...rest}>
    <RemoveIcon onClick={(e) => handleDelete(e)}>
      <TimesCircleIcon />
    </RemoveIcon>
    {children}
  </FilePreviewBox>
);

export default MultiFilesUpload;
