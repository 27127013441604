import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/clients";
import { addPhoto } from "../../helpers/functions";
import {
  SEARCH_CLIENTS_STARTED,
  SEARCH_CLIENTS_SUCCESS,
  SEARCH_CLIENTS_FAILURE,
  LIST_CLIENTS_STARTED,
  LIST_CLIENTS_SUCCESS,
  LIST_CLIENTS_FAILURE,
  CREATE_CLIENT_STARTED,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  GET_CLIENT_STARTED,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  UPDATE_CLIENT_STARTED,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from "../types";

const createClientStarted = () => ({
  type: CREATE_CLIENT_STARTED,
});
const createClientSuccess = (client) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: client,
});
const createClientFailure = (error) => ({
  type: CREATE_CLIENT_FAILURE,
  payload: {
    error,
  },
});

export const createClient = (input, callback) => async (dispatch) => {
  try {
    dispatch(createClientStarted());

    // Dodanie zdjęcia
    if (input.logo && typeof input.logo !== "string") {
      const config = { companyId: input.companyId, fieldType: "clients" };
      input.logo = await addPhoto(input.logo, config);
    } else delete input.logo;

    // Dodanie klienta
    const { data } = await API.graphql(
      graphqlOperation(graphql.createClient, { input })
    );

    dispatch(createClientSuccess(data.createClient));
    toast.success(<MainToast message="Klient stworzony pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(createClientFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const searchClientsSuccess = (clients, nextToken, total) => ({
  type: SEARCH_CLIENTS_SUCCESS,
  payload: {
    clients,
    nextToken,
    total,
  },
});
const searchClientsStarted = () => ({
  type: SEARCH_CLIENTS_STARTED,
});
const searchClientsFailure = (error) => ({
  type: SEARCH_CLIENTS_FAILURE,
  payload: {
    error,
  },
});

export const searchClients = (variables) => async (dispatch) => {
  try {
    dispatch(searchClientsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchClients, variables)
    );
    const nextToken = data.searchClients.nextToken;
    const total = data.searchClients.total;
    const clients = data.searchClients.items;

    dispatch(searchClientsSuccess(clients, nextToken, total));
  } catch (err) {
    console.log(err);
    dispatch(searchClientsFailure(err));
  }
};

const listClientsSuccess = (clients) => ({
  type: LIST_CLIENTS_SUCCESS,
  payload: clients,
});
const listClientsStarted = () => ({
  type: LIST_CLIENTS_STARTED,
});
const listClientsFailure = (error) => ({
  type: LIST_CLIENTS_FAILURE,
  payload: {
    error,
  },
});

export const listClients = (variables) => async (dispatch) => {
  try {
    dispatch(listClientsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.listClients, variables)
    );

    const clients = data.listClients.items;

    dispatch(listClientsSuccess(clients));
  } catch (err) {
    console.log(err);
    dispatch(listClientsFailure(err));
  }
};

const deleteClientSuccess = (id) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: id,
});
const deleteClientStarted = (id) => ({
  type: DELETE_CLIENT_STARTED,
  payload: id,
});
const deleteClientFailure = (error) => ({
  type: DELETE_CLIENT_FAILURE,
  payload: {
    error,
  },
});

export const deleteClient = (id, callback) => async (dispatch) => {
  try {
    dispatch(deleteClientStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteClient, {
        input: { id },
      })
    );

    dispatch(deleteClientSuccess(id));
    toast.success(<MainToast message="Klient usunięty pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    dispatch(deleteClientFailure(err.errors));
  }
};

const getClientSuccess = (client) => ({
  type: GET_CLIENT_SUCCESS,
  payload: client,
});
const getClientStarted = () => ({
  type: GET_CLIENT_STARTED,
});
const getClientFailure = (error) => ({
  type: GET_CLIENT_FAILURE,
  payload: {
    error,
  },
});

export const getClient = (id) => async (dispatch) => {
  try {
    dispatch(getClientStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getClient, {
        id,
      })
    );

    dispatch(getClientSuccess(data.getClient));
  } catch (err) {
    console.log(err);
    dispatch(getClientFailure(err.errors));
  }
};

const updateClientSuccess = (client) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
});
const updateClientStarted = () => ({
  type: UPDATE_CLIENT_STARTED,
});
const updateClientFailure = (error) => ({
  type: UPDATE_CLIENT_FAILURE,
  payload: {
    error,
  },
});

export const updateClient = (input, companyId) => async (dispatch) => {
  try {
    dispatch(updateClientStarted());

    // Dodanie zdjęcia
    if (input.logo && typeof input.logo !== "string") {
      const config = { companyId: companyId, fieldType: "clients" };
      input.logo = await addPhoto(input.logo, config);
    } else delete input.logo;

    // Dodanie klienta
    const { data } = await API.graphql(
      graphqlOperation(graphql.updateClient, { input })
    );

    dispatch(updateClientSuccess(data.updateClient));
    toast.success(<MainToast message="Zaktualizowano zespół" />);
  } catch (err) {
    console.log(err);
    dispatch(updateClientFailure(err.errors));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};
