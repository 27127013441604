import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/teams";
import * as graphqlMembers from "../../graphql/members";
import * as graphqlRates from "../../graphql/rates";
import {
  CREATE_TEAM_STARTED,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  SEARCH_TEAMS_STARTED,
  SEARCH_TEAMS_SUCCESS,
  SEARCH_TEAMS_FAILURE,
  GET_TEAM_STARTED,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  UPDATE_TEAM_STARTED,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  DELETE_TEAM_STARTED,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
} from "../types";

const createMembers = (teamId) => async (member) => {
  try {
    const createMemberInput = { teamId, userId: member.id };
    await API.graphql(
      graphqlOperation(graphqlMembers.createMember, {
        input: createMemberInput,
      })
    );
  } catch (err) {
    console.log(`Member: ${err}`);
  }
};

const deleteMembers = async ({ id }) => {
  try {
    await API.graphql(
      graphqlOperation(graphqlMembers.deleteMember, {
        input: { id },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const createRates = (teamId) => async (rate) => {
  try {
    const createRateInput = {
      amount: rate.value,
      productId: rate.id,
      teamId,
    };
    await API.graphql(
      graphqlOperation(graphqlRates.createRate, {
        input: createRateInput,
      })
    );
  } catch (err) {
    console.log(`Rate: ${err}`);
  }
};

export const deleteRates = async ({ id }) => {
  try {
    await API.graphql(
      graphqlOperation(graphqlRates.deleteRate, {
        input: { id },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const createTeamStarted = () => ({
  type: CREATE_TEAM_STARTED,
});
const createTeamSuccess = (team) => ({
  type: CREATE_TEAM_SUCCESS,
  payload: team,
});
const createTeamFailure = (error) => ({
  type: CREATE_TEAM_FAILURE,
  payload: {
    error,
  },
});

export const createTeam = (variables, callback) => async (dispatch) => {
  const { companyId, name, type } = variables;
  try {
    dispatch(createTeamStarted());

    // Dodanie teamu
    const createTeamInput = { companyId, name, type };
    const { data } = await API.graphql(
      graphqlOperation(graphql.createTeam, { input: createTeamInput })
    );
    const team = data.createTeam;

    // Dodanie relacji z userem (member)
    await Promise.all(variables.members.map(createMembers(team.id)));

    // Dodanie relacji z produktami (rate)
    await Promise.all(variables.price.map(createRates(team.id)));

    const { data: newTeamData } = await API.graphql(
      graphqlOperation(graphql.getTeam, { id: team.id })
    );

    dispatch(createTeamSuccess(newTeamData.getTeam));
    toast.success(<MainToast message="Zespół stworzony pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(createTeamFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const searchTeamsSuccess = (teams, nextToken, total) => ({
  type: SEARCH_TEAMS_SUCCESS,
  payload: {
    teams,
    nextToken,
    total,
  },
});
const searchTeamsStarted = () => ({
  type: SEARCH_TEAMS_STARTED,
});
const searchTeamsFailure = (error) => ({
  type: SEARCH_TEAMS_FAILURE,
  payload: {
    error,
  },
});

export const searchTeams = (variables) => async (dispatch) => {
  try {
    dispatch(searchTeamsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchTeams, variables)
    );
    const nextToken = data.searchTeams.nextToken;
    const total = data.searchTeams.total;
    const teams = data.searchTeams.items;

    dispatch(searchTeamsSuccess(teams, nextToken, total));
  } catch (err) {
    console.log(err);
    dispatch(searchTeamsFailure(err));
  }
};

const deleteTeamSuccess = (id) => ({
  type: DELETE_TEAM_SUCCESS,
  payload: id,
});
const deleteTeamStarted = (id) => ({
  type: DELETE_TEAM_STARTED,
  payload: id,
});
const deleteTeamFailure = (error) => ({
  type: DELETE_TEAM_FAILURE,
  payload: {
    error,
  },
});

export const deleteTeam = (id) => async (dispatch) => {
  try {
    dispatch(deleteTeamStarted(id));
    const { data } = await API.graphql(
      graphqlOperation(graphql.deleteTeam, {
        input: { id },
      })
    );

    // TODO: Delete all team members
    const members = data.deleteTeam.members.items;
    await Promise.all(members.map(deleteMembers));

    // TODO: Delete all team rates
    const rates = data.deleteTeam.rates.items;
    await Promise.all(rates.map(deleteRates));

    dispatch(deleteTeamSuccess(id));
    toast.success(<MainToast message="Zespół usunięty pomyślnie" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    dispatch(deleteTeamFailure(err.errors));
  }
};

const getTeamSuccess = (team) => ({
  type: GET_TEAM_SUCCESS,
  payload: team,
});
const getTeamStarted = () => ({
  type: GET_TEAM_STARTED,
});
const getTeamFailure = (error) => ({
  type: GET_TEAM_FAILURE,
  payload: {
    error,
  },
});

export const getTeam = (id) => async (dispatch) => {
  try {
    dispatch(getTeamStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getTeam, {
        id,
      })
    );
    const team = data.getTeam;

    dispatch(getTeamSuccess(team));
  } catch (err) {
    console.log(err);
    dispatch(getTeamFailure(err.errors));
  }
};

const updateTeamSuccess = (team) => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: team,
});
const updateTeamStarted = () => ({
  type: UPDATE_TEAM_STARTED,
});
const updateTeamFailure = (error) => ({
  type: UPDATE_TEAM_FAILURE,
  payload: {
    error,
  },
});

export const updateTeam = (variables) => async (dispatch) => {
  const { id, name, type } = variables;
  try {
    dispatch(updateTeamStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.updateTeam, { input: { id, name, type } })
    );

    // Edycja members'ów
    const oldMembers = data.updateTeam.members.items;
    await Promise.all(oldMembers.map(deleteMembers));

    const newMembers = variables.members;
    await Promise.all(newMembers.map(createMembers(id)));

    // Edycja ceny produktów
    const oldRates = data.updateTeam.rates.items;
    await Promise.all(oldRates.map(deleteRates));

    const newRates = variables.price;
    await Promise.all(newRates.map(createRates(id)));

    const { data: newTeamData } = await API.graphql(
      graphqlOperation(graphql.getTeam, { id })
    );

    dispatch(updateTeamSuccess(newTeamData.getTeam));
    toast.success(<MainToast message="Zaktualizowano zespół" />);
  } catch (err) {
    console.log(err);
    dispatch(updateTeamFailure(err.errors));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};
