import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../Button";
import Submit from "../../Submit";
import { usePermissionState } from "../../../context/permission";
import { createProtocol } from "../../../redux/actions/settings/protocolActions";
import ProtocolFormFields from "./ProtocolFormFields";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateProtocol = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.protocol);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    const input = {
      companyId,
      name: data.name,
      items: JSON.stringify(data.items),
    };

    dispatch(createProtocol(input, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ProtocolFormFields form={form} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default CreateProtocol;
