import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const TopTabTitle = styled.div`
  color: ${({ theme }) => theme.color.font200};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.medium};
  margin-top: 7px;
  transition: color 0.14s ease-in-out;
  padding-bottom: 4.5px;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
`;

const TopTabContainer = styled(NavLink)`
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  margin-right: 24px;
  user-select: none;
  transition: background 0.14s ease-in-out;
  
  :hover {
    ${TopTabTitle} {
      border-bottom: 2px solid;
      border-bottom-color: ${({ theme }) => theme.color.blue};
      color: ${({ theme }) => theme.color.blue};
    }
  }

  &.active {

    ${TopTabTitle} {
      border-bottom: 2px solid;
      border-bottom-color: ${({ theme }) => theme.color.blue};
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

const TopTab = ({title, ...rest}) => {
    return (
        <TopTabContainer {...rest}>
            <TopTabTitle>
                {title}
            </TopTabTitle>
        </TopTabContainer>
    );
}

export default TopTab;