import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import { BanIcon } from "react-line-awesome";

import Loader from "./Loader";
import { Label, InputBox, InputElement } from "./Input";
import { debounce } from "lodash";
import { useGetAddressViaGoogle } from "../hooks/useGetAddressViaGoogle";
import { useGetPlaceInfo } from "../hooks/useGetPlaceInfo";
import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-bottom: 18px;

  * {
    outline: none;
  }
`;
const StyledInputBox = styled(InputBox)`
  position: relative;
  margin: 0;
`;
const DropdownList = styled.ul`
  position: absolute;
  list-style: none;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.05);
  max-height: 220px;
  overflow-y: auto;
  z-index: 3;
`;
const Element = styled.li`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.selectHoverText : theme.color.grey};
  padding: 11px 18px 10px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.medium};
  transition: color 0.14s ease-in-out;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.selectHoverText};
  }
`;
const LoaderBox = styled.div`
  position: absolute;
  display: flex;
  top: 17px;
  right: 18px;
`;
const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;
const ErrorIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 18px;
  color: ${({ theme }) => theme.color.inputErrorIcon};
  font-size: 18px;
  cursor: help;
`;

const AddressInput = (props) => {
  const { label, error, isLoading, disabled, ...rest } = props;
  const { value, onChange } = props.field;

  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef(null);
  useDetectOutsideClick(containerRef, setIsOpen);

  useEffect(() => {
    if (typeof value === "string") setInputValue(value);
  }, [setInputValue, value]);

  const {
    getAddressViaGoogle,
    addressList,
    loading: getAddressLoading,
  } = useGetAddressViaGoogle();

  const { getPlaceInfo } = useGetPlaceInfo();

  const handleInputType = (value) => {
    setInputValue(value);
    onChange(null);
  };

  // eslint-disable-next-line
  const handleInputChange = useCallback(
    debounce(async (input) => {
      try {
        await getAddressViaGoogle(input);
      } catch (err) {
        console.error(err);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const handleListElementClick = async (placeId) => {
    try {
      const placeInfoResponse = await getPlaceInfo(placeId);
      onChange(placeInfoResponse);
      setInputValue(placeInfoResponse.formatted_address);
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container ref={containerRef}>
      <Label>{label}</Label>
      <StyledInputBox>
        <InputElement
          autoComplete="off"
          onChange={(e) => handleInputType(e.target.value)}
          onFocus={() => setIsOpen(true)}
          value={inputValue}
          error={error}
          {...rest}
        />
        {isLoading && (
          <LoaderBox>
            <Loader loading={isLoading} size={18} />
          </LoaderBox>
        )}
        {error && (
          <ErrorIcon title={error}>
            <BanIcon />
          </ErrorIcon>
        )}
      </StyledInputBox>
      <DropdownList>
        {isOpen &&
          (!getAddressLoading ? (
            addressList.map((place, index) => (
              <Element
                key={`${place.place_id}${index}`}
                onClick={() => handleListElementClick(place.place_id)}
              >
                {place.description}
              </Element>
            ))
          ) : (
            <LoadingBox>
              <Loader />
            </LoadingBox>
          ))}
      </DropdownList>
    </Container>
  );
};

export default AddressInput;
