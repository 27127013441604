import React, { useEffect, useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBuilding,
  listBuildings,
} from "../../redux/actions/buildingActions";
import { TrashIcon } from "react-line-awesome";

import { useConfirmModalDispatch } from "../../context/ModalContext";

import { Title } from "../../components/Title";
import DataTableSecondary from "../../components/dataTable/DataTableSecondary";
import SideModal from "../../components/modal/SideModal";
import usePermissionObject from "../../hooks/usePermissionObject";
import CircleAddButton from "../../components/CircleAddButton";
import CreateBuilding from "../../components/clients/buildings/CreateBuilding";
import EditBuilding from "../../components/clients/buildings/EditBuilding";
import { useHistory } from "react-router";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const Medium = styled.div`
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 16px;
  cursor: pointer;
`;

const Container = styled.div`
  height: calc(100vh - 131px);
  padding: 31px 31px 36px 29px;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 23px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 0;
  font-size: 20px;
`;
const AddressBox = styled.div`
  color: ${({ theme }) => theme.color.font200};
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 14px;
  width: fit-content;
  white-space: nowrap;
`;

const Buildings = ({ match }) => {
  const history = useHistory();

  useEffect(() => {
    const historyState = history.location.state;
    if (historyState && historyState.isCreateOpen) {
      setIsCreateOpen(historyState.isCreateOpen);
    }
  }, [history]);

  const clientId = match.params.id;
  const columns = useMemo(
    () => [
      {
        accessor: "name",
      },
      {
        accessor: "address",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const permissionObject = usePermissionObject("clients");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const modalDispatch = useConfirmModalDispatch();

  const dispatch = useDispatch();
  const buildingState = useSelector(({ building }) => building);
  const buildings = buildingState.buildings;

  const listBuildingsVariables = useMemo(
    () => ({
      filter: { clientId: { eq: clientId } },
    }),
    [clientId]
  );

  useEffect(() => {
    dispatch(listBuildings(listBuildingsVariables));
  }, [dispatch, listBuildingsVariables]);

  const dropdownOptions = useCallback(
    (id) => {
      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć klienta?",
            buttonText: "Usuń",
            action: () => {
              dispatch(deleteBuilding(id, setIsEditOpen(false)));
            },
          },
        });
      };

      return [
        {
          name: "usuń",
          icon: TrashIcon,
          handler: handleDelete,
          isEditDisplay: true,
        },
      ];
    },
    [modalDispatch, dispatch]
  );

  const buildingsList = useMemo(() => {
    return buildings.map((item) => {
      const { id, name, address } = item;
      const addressFormated = address.split(",").slice(0, -1).join(", ");

      return {
        ...item,
        name: (
          <Medium className="rowHover" onClick={() => openEdit(id)}>
            {name}
          </Medium>
        ),
        address: <AddressBox>{addressFormated}</AddressBox>,
      };
    });
  }, [buildings]);

  const noDataContent = {
    title: "Brak dostępnych budynków",
    subtitle: "Dodaj nowe budynki, aby wyświetlić je w sekcji",
  };

  return (
    <Container>
      <HeaderBox>
        <StyledTitle>Dostępne budynki</StyledTitle>
        {permissionObject.create && (
          <CircleAddButton onClick={() => setIsCreateOpen(true)} />
        )}
      </HeaderBox>
      <DataTableSecondary
        data={buildingsList}
        columns={columns}
        loading={buildingState.loading}
        noDataContent={noDataContent}
        headless
      />

      {permissionObject.create && (
        <SideModal
          title="Nowy budynek"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateBuilding setIsOpen={setIsCreateOpen} clientId={clientId} />
        </SideModal>
      )}

      {permissionObject.edit && (
        <SideModal
          title="Edycja budynku"
          isOpen={isEditOpen}
          dropdownOptions={dropdownOptions}
          setIsOpen={setIsEditOpen}
          id={String(currentOpenId)}
        >
          <EditBuilding
            setIsOpen={setIsEditOpen}
            id={String(currentOpenId)}
            clientId={clientId}
          />
        </SideModal>
      )}
    </Container>
  );
};

export default Buildings;
