/* --------------------------------- Queries -------------------------------- */
export const listTickets = /* GraphQL */ `
  query ListTickets($filter: ModelTicketFilterInput) {
    listTickets(filter: $filter) {
      items {
        id
        status
        deadline
        type {
          id
          name
        }
        typeItem {
          id
          name
          icon
        }
      }
    }
  }
`;

export const searchTickets = /* GraphQL */ `
  query SearchTickets(
    $filter: SearchableTicketFilterInput
    $sort: SearchableTicketSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchTickets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        startDate
        deadline
        client {
          id
          name
          fullName
        }
        team {
          id
          name
        }
        type {
          id
          name
        }
        typeItem {
          id
          name
          icon
          attachments
          customerOrder
          description
          measurementCard
          notes
          photos
          producerOrder
          protocol
          specification
          types {
            items {
              type {
                id
                name
              }
            }
          }
        }
        type {
          id
          name
        }
      }
      nextToken
      total
    }
  }
`;

export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      docNum
      status
      startDate
      deadline
      notes
      latitude
      longitude
      address
      description
      status
      files {
        items {
          id
          ref
          type
        }
      }
      specifications {
        items {
          id
          product {
            id
            name
            unit
          }
        }
      }
      fieldItems {
        items {
          id
          fieldItem {
            id
          }
          selectedField {
            id
            name
          }
        }
      }
      building {
        id
        name
        latitude
        longitude
      }
      client {
        id
        name
        fullName
        phoneNumber
        latitude
        longitude
      }
      team {
        id
        name
      }
      type {
        id
        name
      }
      typeItem {
        id
        name
        icon
      }
      type {
        id
        name
      }
    }
  }
`;

export const listSpecifications = /* GraphQL */ `
  query listSpecificationes($filter: ModelSpecificationeFilterInput) {
    listSpecificationes(filter: $filter) {
      items {
        id
        value
        product {
          id
          name
          unit
        }
      }
    }
  }
`;

export const listTicketFieldItems = /* GraphQL */ `
  query listTicketFieldItemos($filter: ModelTicketFieldItemoFilterInput) {
    listTicketFieldItemos(filter: $filter) {
      items {
        id
        fieldItem {
          id
          name
        }
      }
    }
  }
`;

/* -------------------------------- Mutation -------------------------------- */
export const createTicket = /* GraphQL */ `
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      id
      status
      deadline
      type {
        id
        name
      }
      typeItem {
        id
        name
        icon
      }
      type {
        id
        name
      }
    }
  }
`;

export const createTicketAll = /* GraphQL */ `
  mutation CreateTicketAll($input: createTicketAllInput!) {
    createTicketAll(input: $input) {
      id
      status
      startDate
      deadline
      client {
        id
        name
        fullName
      }
      team {
        id
        name
      }
      type {
        id
        name
      }
      typeItem {
        id
        name
        icon
        attachments
        customerOrder
        description
        measurementCard
        notes
        photos
        producerOrder
        protocol
        specification
        types {
          items {
            type {
              id
              name
            }
          }
        }
      }
      type {
        id
        name
      }
    }
  }
`;

export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      id
      status
      startDate
      deadline
      notes
      description
      status
      files {
        items {
          id
          ref
          type
        }
      }
      specifications {
        items {
          id
          product {
            id
            name
            unit
          }
        }
      }
      typeItem {
        id
        name
        icon
        attachments
        customerOrder
        description
        measurementCard
        notes
        photos
        producerOrder
        protocol
        specification
        types {
          items {
            type {
              id
              name
            }
          }
        }
      }
      building {
        id
        name
      }
      client {
        id
        name
        fullName
      }
      team {
        id
        name
      }
      type {
        id
        name
      }
      typeItem {
        id
        name
        icon
      }
      type {
        id
        name
      }
    }
  }
`;

export const updateTicketAll = /* GraphQL */ `
  mutation UpdateTicketAll($input: updateTicketAllInput) {
    updateTicketAll(input: $input) {
      id
      status
      startDate
      deadline
      notes
      description
      status
      files {
        items {
          id
          ref
          type
        }
      }
      specifications {
        items {
          id
          product {
            id
            name
            unit
          }
        }
      }
      typeItem {
        id
        name
        icon
        attachments
        customerOrder
        description
        measurementCard
        notes
        photos
        producerOrder
        protocol
        specification
        types {
          items {
            type {
              id
              name
            }
          }
        }
      }
      building {
        id
        name
      }
      client {
        id
        name
        fullName
      }
      team {
        id
        name
      }
      type {
        id
        name
      }
      typeItem {
        id
        name
        icon
      }
      type {
        id
        name
      }
    }
  }
`;

export const deleteTicketAll = /* GraphQL */ `
  mutation deleteTicketAll($id: ID!) {
    deleteTicketAll(id: $id)
  }
`;

export const createSpecification = /* GraphQL */ `
  mutation CreateSpecification($input: CreateSpecificationInput!) {
    createSpecification(input: $input) {
      id
      product {
        id
        name
        unit
      }
    }
  }
`;

export const updateSpecification = /* GraphQL */ `
  mutation CreateSpecification($input: CreateSpecificationInput!) {
    createSpecification(input: $input) {
      id
      product {
        id
        name
        unit
      }
    }
  }
`;

export const deleteSpecification = /* GraphQL */ `
  mutation deleteSpecification($input: DeleteSpecificationInput!) {
    deleteSpecification(input: $input) {
      id
    }
  }
`;

export const createTicketFieldItem = /* GraphQL */ `
  mutation CreateTicketFieldItem($input: CreateTicketFieldItemInput!) {
    createTicketFieldItem(input: $input) {
      id
      fieldItem {
        id
        name
      }
    }
  }
`;

export const updateTicketFieldItem = /* GraphQL */ `
  mutation UpdateTicketFieldItem($input: UpdateTicketFieldItemInput!) {
    updateTicketFieldItem(input: $input) {
      id
      product {
        id
        name
        unit
      }
    }
  }
`;

export const deleteTicketFieldItem = /* GraphQL */ `
  mutation deleteTicketFieldItem($input: DeleteTicketFieldItemInput!) {
    deleteTicketFieldItem(input: $input) {
      id
    }
  }
`;
