import Image_big from "../../assets/svg/Image_big";
import File_big from "../../assets/svg/File_big";
import Pdf_big from "../../assets/svg/Pdf_big";
import Pdf_small from "../../assets/svg/Pdf_small";
import Image_small from "../../assets/svg/Image_small";
import File_small from "../../assets/svg/File_small";
import Folder_small from "../../assets/svg/Folder_small";

export const IconPathsBig = {
    pdf : <Pdf_big />,
    img: <Image_big />,
    jpg: <Image_big />,
    png: <Image_big />,
    file : <File_big />,
}

export const IconPathsSmall = {
    pdf : <Pdf_small />,
    img: <Image_small />,
    file : <File_small />,
    jpg: <Image_small />,
    png: <Image_small />,
    folder: <Folder_small />
}