// Queries
export const searchRoles = /* GraphQL */ `
  query SearchRoles(
    $filter: SearchableRoleFilterInput
    $sort: SearchableRoleSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchRoles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles($filter: ModelRoleFilterInput) {
    listRoles(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions {
        # log
        reports
        clients
        roles
        users
        teams
        settings
        # library
        # analyze
        # biling
        # userProfile
      }
    }
  }
`;

// Mutations
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      name
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      id
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      name
      permissions {
        # log
        reports
        clients
        roles
        users
        teams
        settings
        # library
        # analyze
        # biling
        # userProfile
      }
    }
  }
`;
