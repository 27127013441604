import React, {createRef, useCallback, useEffect, useRef, useState} from "react";
import MainTemplate from "../../templates/MainTemplate";
import styled from "styled-components";
import usePermissionObject from "../../hooks/usePermissionObject";
import _, {isEmpty, isString} from "lodash";
import Dropzone, {useDropzone} from "react-dropzone";
import upload_files from "../../assets/images/upload_files.png";
import {Title} from "../../components/Title";
import {Subtitle} from "../../components/Subtitle";
import FilesButton from "../../components/FilesButton";
import {
    checkIfFileExists,
    generateID,
    recognizeFileExtension,
    recognizeFileExtensionSmall
} from "../../helpers/functions";
import {createRootFiles, createRootFile} from "../../redux/actions/rootfileActions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import {Storage} from "aws-amplify";
import config from "../../aws-exports";
import {
    CheckCircleIcon,
    TimesCircleIcon
} from "react-line-awesome";
import { withTheme } from 'styled-components';
import ProgressBar from "../../components/ProgressBar";
import {usePermissionState} from "../../context/permission";
import Upload_files from "../../assets/svg/upload_files";

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

const DataPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 28px;
  background-color: ${({ theme }) => theme.color.white};
`;

const UploadPanel = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled(Upload_files)`
  object-fit: contain;
  margin-bottom: 30px;
  margin-top: 8vh;
`;

const Container = styled.div`
  display: flex;
  overflow-y: auto;
  flex: 1;
  padding: 30px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.color.mainBorder};
`;

const TextBox = styled.div``;
const StyledTitle = styled(Title)`
  margin-bottom: 10px;
`;

const UploadStatusBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;
`
const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 10px;
`;

const FileIcon = styled.div`
  width: 34px;
  height: 40px;
  margin-right: 16px;
`;

const FileNameContainer = styled.div`
  padding-bottom: 17px;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondaryBorder};
  display: flex;
  flex-direction: row;
`
const Medium = styled.div`
  display: flex;
  align-items: center;
  flex: 0.8;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  height: 100%;
  cursor: pointer;
`;

  const UploadedFilesContainer = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: row;
  align-items: center;
  `
const NotFoundImgWrapper = styled.div`
  display: flex;
  height: 75%;
`

const UploadFiles = (props) => {
    const permissionObject = usePermissionObject("library");
    const [filePreviewArray, setFilePreviewArray] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [filesData, setFilesData] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const uploadRefs = useRef([]);
    const parentId = useSelector((state) => (state.rootFile.parentId));

    const addPhotowithName = async (file, fileName, index) => {
        if (isString(file)) return;

        try {
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/images/${fileName}`;
            setUploadSuccess(true)

            let path = `${fileName}`
            if (parentId != null) {
                path = `${parentId}/${fileName}`
            }

            await Storage.put(path, file, {
                progressCallback(progress) {
                    const percentage = Math.round((progress.loaded/progress.total)*100)
                    uploadRefs.current[index].current.style.width = `${percentage}%`

                    if (percentage > 33 && percentage < 66) {
                        uploadRefs.current[index].current.style.backgroundColor = props.theme.color.progressBarStep[1];
                    } else if (percentage > 66) {
                        uploadRefs.current[index].current.style.backgroundColor = props.theme.color.progressBarFinal;
                    }

                },
                contentType: file.type,
            }).then(() => { });

            return url;
        } catch (err) {
            console.log(`File upload: ${err}`);
        }

    }

    const sizeToMb = (size) => {
        const sizeMb = size/1000000;
        return `${sizeMb.toFixed(1)} Mb`;
    }


    const createFiles = async (filesArray) => {
        let singleFileToast = false;
        for (let key in filesArray) {
            if (await checkIfFileExists(filesArray[key]?.name, singleFileToast) ) {
                singleFileToast = true
                continue
            }
            const fileName = generateID(filesArray[key]);
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${parentId}/${fileName}`;
            const fileData = {fileName: fileName, ref: url, name: filesArray[key]?.name, size: sizeToMb(filesArray[key]?.size), parentId: parentId};
            setFilesData([...filesData, fileData]);
            await addPhotowithName(filesArray[key], fileName, key).then((b) => {});
        }
    }

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const files = [...filePreviewArray, ...acceptedFiles];
            setFilePreviewArray(files);
            uploadRefs.current = files.map((ref, index) => uploadRefs.current[index] = React.createRef())
            createFiles(files).then(() => {setTimeout(() => {  history.push(routes.library.home) }, 2000);})
            },
        []
    );

    useEffect(() => {
        dispatch(createRootFiles(filesData, (b) => console.log(b)))
    }, [filesData])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
    });


    return (
        <MainTemplate title="Przesyłanie Plików">
            <TableBox>
                <DataPanel>
                        <UploadPanel>
                            <Container {...getRootProps()} >
                                {uploadSuccess ?
                                    <UploadStatusBox>
                                        {filePreviewArray.map((file, index) => {
                                            return (
                                                <FileNameContainer key={file.lastModified}>
                                                    <UploadedFilesContainer>
                                                        <FileIcon>
                                                            {recognizeFileExtensionSmall(file)}
                                                        </FileIcon>
                                                        <Medium className="rowHover">
                                                            {file.name}
                                                        </Medium>
                                                    </UploadedFilesContainer>
                                                        <ProgressBar ref={uploadRefs?.current[index]}/>
                                                </FileNameContainer>
                                            );
                                        })}
                                    </UploadStatusBox>
                                :
                                    <NotFoundImgWrapper>
                                        <Image/>
                                        <input {...getInputProps()} />
                                    </NotFoundImgWrapper>

                                }
                                <TextBox>
                                    <StyledTitle>Przeciągnij swoje pliki tutaj, aby je załadować</StyledTitle>
                                    <StyledSubtitle>
                                        lub
                                    </StyledSubtitle>
                                    <FilesButton text={'wybierz pliki'} width={160} height={40}/>
                                </TextBox>
                            </Container>
                        </UploadPanel>
                </DataPanel>
            </TableBox>
        </MainTemplate>
    );
}

export default withTheme(UploadFiles)