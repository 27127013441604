import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer, Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ThemeProvider from "./theme";
import { ConfirmModalProvider } from "./context/ModalContext";
import { PermissionProvider, functionTypes } from "./context/permission";
import { MultiThemeProvider } from "./context/theme";
import { SideBarProvider } from "./context/sideBar";

import { routes } from "./routes";
import store from "./redux/store";
import PrivateRoute from "./auth/PrivateRout";

import Login from "./pages/authorization/Login";
import Recovery from "./pages/authorization/recovery";
import Companies from "./pages/companies/Companies";
import Roles from "./pages/roles/Roles";
import Users from "./pages/users/Users";
import Logs from "./pages/log/Log";
import NotFoundPage from "./pages/NotFoundPage";
import Profile from "./pages/user/Profile";
import Reports from "./pages/reports/Reports";
import Clients from "./pages/clients/Clients";
import Teams from "./pages/teams/Teams";
import Biling from "./pages/biling/Biling";
import Analyze from "./pages/analyze/Analyze";
import Settings from "./pages/settings/Settings";
import Client from "./pages/clients/Client";
import Library from "./pages/library/Library";
import UploadFiles from "./pages/library/UploadFiles";
import FileDetails from "./pages/library/FileDetails";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    padding: 7px 12px 7px 14px;
    margin-bottom: 12px;
  }
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.color.toastifyError};
  }
  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.color.toastifyWarning};
    color: ${({ theme }) => theme.color.white};
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.color.toastifySuccess};
    color: ${({ theme }) => theme.color.white};
  }
  .Toastify__toast-body {
    padding: 6px 0;
  }
`;

export default function App() {
  return (
    <Provider store={store}>
      <PermissionProvider>
        <MultiThemeProvider>
          <SideBarProvider>
            <ThemeProvider>
              <ConfirmModalProvider>
                <Router basename="/">
                  <Switch>
                    <PrivateRoute
                      path={routes.login}
                      component={Login}
                      permission={{ type: "GUEST" }}
                    />
                    <PrivateRoute
                      path={routes.recovery}
                      component={Recovery}
                      permission={{ type: "GUEST" }}
                    />
                    <PrivateRoute
                      path={routes.companies}
                      component={Companies}
                      permission={{
                        name: "companies",
                        type: functionTypes.READ,
                      }}
                    />
                    <PrivateRoute
                      path={routes.roles}
                      component={Roles}
                      permission={{ name: "roles", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.users}
                      component={Users}
                      permission={{ name: "users", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.log}
                      component={Logs}
                      permission={{ name: "reports", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.reports}
                      component={Reports}
                      permission={{ name: "reports", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.clients}
                      component={Clients}
                      permission={{ name: "clients", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={`${routes.client.home}/:id`}
                      component={Client}
                      permission={{ name: "clients", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.teams}
                      component={Teams}
                      permission={{ name: "teams", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={`${routes.library.home}`}
                      component={Library}
                      permission={{ name: "library", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={`${routes.library.files}/:id`}
                      component={FileDetails}
                      permission={{ name: "library", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.library.upload}
                      component={UploadFiles}
                      permission={{ name: "library", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.biling}
                      component={Biling}
                      permission={{ name: "biling", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.analyze}
                      component={Analyze}
                      permission={{ name: "analyze", type: functionTypes.READ }}
                    />
                    <PrivateRoute
                      path={routes.settings.home}
                      component={Settings}
                      permission={{
                        name: "settings",
                        type: functionTypes.READ,
                      }}
                    />
                    <PrivateRoute
                      path={routes.userProfile}
                      component={Profile}
                      permission={{
                        name: "userProfile",
                        type: functionTypes.READ,
                      }}
                    />
                    <PrivateRoute
                      path="/404"
                      component={NotFoundPage}
                      permission={{ type: "404" }}
                    />
                    <Redirect to="/404" />
                  </Switch>
                </Router>
                <StyledToastContainer
                  transition={Bounce}
                  position={toast.POSITION.BOTTOM_RIGHT}
                  closeButton={false}
                  hideProgressBar={true}
                  autoClose={60 * 60}
                />
              </ConfirmModalProvider>
            </ThemeProvider>
          </SideBarProvider>
        </MultiThemeProvider>
      </PermissionProvider>
    </Provider>
  );
}
