/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:ab5e21bb-5613-4f36-80ed-44c4bbbe6183",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_UhIs0rNtr",
  aws_user_pools_web_client_id: "1n4h787hktt5349n513g9tdak2",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://ldciy2so2zgnrg4wrysuqqqnbm.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: "montergo-images124713-prod",
  aws_user_files_s3_bucket_region: "eu-central-1",
};

export default awsmobile;
