import React from "react";
import { TrashAltIcon } from "react-line-awesome";
import styled from "styled-components";

import Loader from "./Loader";

const LoaderBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
`;

const RowDeleteLoader = ({ loading }) => {
  return (
    <>
      <LoaderBox>
        <Loader loading={loading} />
      </LoaderBox>
      <TrashAltIcon style={loading ? { opacity: 0 } : {}} />
    </>
  );
};

export default RowDeleteLoader;
