/* --------------------------------- Queries -------------------------------- */
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput) {
    listUsers(filter: $filter) {
      items {
        id
        fullName
        email
        role {
          name
        }
        phone
      }
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        email
        role {
          name
        }
        phone
      }
      nextToken
      total
    }
  }
`;

export const getUsersByCognitoId = /* GraphQL */ `
  query GetUsersByCognitoId($cognitoId: ID!) {
    getUsersByCognitoId(cognitoId: $cognitoId) {
      items {
        id
        fullName
        email
        companyId
        role {
          permissions {
            log
            reports
            clients
            roles
            users
            teams
            settings
            # library
            # analyze
            # biling
            # userProfile
          }
        }
      }
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fullName
      email
      address
      phone
      companyId
      role {
        id
        name
      }
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createFullUser = /* GraphQL */ `
  mutation CreateFullUser(
    $fullName: String
    $email: String
    $phone: AWSPhone
    $address: String
    $roleId: ID
    $companyId: ID
  ) {
    createFullUser(
      fullName: $fullName
      email: $email
      phone: $phone
      address: $address
      roleId: $roleId
      companyId: $companyId
    ) {
      id
      fullName
      email
      role {
        name
      }
      phone
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      fullName
      email
      address
      role {
        id
        name
      }
      phone
    }
  }
`;

export const deleteUserFull = /* GraphQL */ `
  mutation deleteUserFull($id: ID!) {
    deleteUserFull(id: $id)
  }
`;
