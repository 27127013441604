/* --------------------------------- Queries -------------------------------- */
export const listProducts = /* GraphQL */ `
  query ListProducts($filter: ModelProductFilterInput) {
    listProducts(filter: $filter) {
      items {
        id
        name
        unit
      }
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      unit
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      name
      unit
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      id
    }
  }
`;

export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      unit
    }
  }
`;
