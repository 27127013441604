import { API, graphqlOperation } from "aws-amplify";
import { useState } from "react";

const useGetDropdownData = (schema, filter, customFormat) => {
  const [dropdownArray, setDropdownArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultFormat = {
    name: "name",
    value: "id",
    icon: "icon",
    members: "members",
  };
  const format = { ...defaultFormat, ...customFormat };

  const getDropdownData = async () => {
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(schema, {
          filter,
        })
      );
      const list = data[Object.keys(data)[0]].items.map((item) => ({
        name: item[format.name],
        value: item[format.value],
        icon: item[format.icon],
        members: item[format.members],
      }));

      setDropdownArray(list);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return [getDropdownData, { data: dropdownArray, loading }];
};

export default useGetDropdownData;
