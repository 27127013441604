import React, { useRef } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";
import { useSideBarState } from "../../context/sideBar";
import { useToggleSideBar } from "../../context/sideBar";
import SideBar from "./SideBar";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 30;
`;

const SideBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({isOpen}) => isOpen ? '80px' : '243px'};
  max-width: 90%;
  background-color: ${({ theme }) => theme.color.modalBackground};
  box-shadow: ${({ theme }) => theme.shadow.sideModal};
  overflow-y: auto;
`;

const SideBarMobile = ({children}) => {
    const sideBox = useRef(null);

    // Animations
    function enterAnimation(node, done) {
        gsap.from(node, {
            x: -200,
            duration: 0.2,
            ease: "Power1.out",
            onComplete: done,
        });
    }

    function exitAnimation(node, done) {
        gsap.to(node, {
            x: -200,
            duration: 0.2,
            ease: "Power2.inOut",
            onComplete: done,
        });
    }

    const isOpen = useSideBarState();
    const toggleSideBar = useToggleSideBar();

    return (
        <Transition
            timeout={300}
            mountOnEnter
            unmountOnExit
            in={isOpen}
            onEnter={(node, done) => {
                enterAnimation(node, done);
            }}
            onExit={(node, done) => {
                exitAnimation(node, done);
            }}
        >
            <Container onClick={() => toggleSideBar()}>
                <SideBox ref={sideBox}>
                    <SideBar />
                </SideBox>
            </Container>
        </Transition>
    );
};


export default SideBarMobile;