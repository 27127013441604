import React, { useState, useEffect, useMemo } from 'react';
import * as Styles from "./Calendar.styles"
import { AngleLeftIcon } from "react-line-awesome";
import { AngleRightIcon } from "react-line-awesome";
import { addMonths, set, subDays } from 'date-fns';
import { format, utcToZonedTime } from "date-fns-tz";
import { pl } from 'date-fns/locale'
import { useSelector, useDispatch } from "react-redux";
import { usePermissionState } from "../../../context/permission";
import { searchReportsDatesForCalendar } from "../../../redux/actions/logActions";
import Loader from "../../../components/Loader";
import { Button } from "../../Button";
import { SelectTodayButton } from "../../log/SelectTodayButton/SelectTodayButton";

const Calendar = ({
    setFilterDate,
    filterDate,
}) => {

    const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DAYS_OF_THE_WEEK = ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Niedz'];
    const MONTHS = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
    const [day, setDay] = useState(filterDate.getDate());
    const [month, setMonth] = useState(filterDate.getMonth());
    const [year, setYear] = useState(filterDate.getFullYear());
    const [isChangeMonth, setIsChangeMonth] = useState(filterDate);

    const dispatch = useDispatch();
    const state = useSelector((state) => ({
        reportState: state.log
      }));
    
    const { reportState } = state;

    const { cognitoUser } = usePermissionState();
    const companyId = cognitoUser.databaseUser.companyId;

    const formattedDateFilterMonthVariable = format(filterDate, 'yyyy-MM');
    const formattedDateFilterNextMonthVariable = format(addMonths(filterDate, 1), 'yyyy-MM');

    const searchReportsDatesForCalendarVariables = useMemo(
        () => ({
          limit: 100,
          filter: {
            and: [
              { companyId: { eq: companyId }},
              {
                startDate: {
                    gte: formattedDateFilterMonthVariable,
                    lte: formattedDateFilterNextMonthVariable
                }
              },
              {
                status: {
                    ne: "PENDING",
                }
              }
            ]},
            sort: {
              direction: "asc",
              field: "startDate",
            },
    }), [filterDate]);

    const getStartDayOfMonth = (date) => {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        return startDate === 0 ? 7 : startDate;
    };

    const [startDay, setStartDay] = useState(getStartDayOfMonth(filterDate));

    useEffect(() => {
        setDay(filterDate.getDate());
        setMonth(filterDate.getMonth());
        setYear(filterDate.getFullYear());
        setStartDay(getStartDayOfMonth(filterDate));
    }, [filterDate]);

    useEffect(() => {
        dispatch(searchReportsDatesForCalendar(searchReportsDatesForCalendarVariables));
    }, [month])

    const isReportsDatesList = reportState.reportsDate;
    const isReportsDatesListLoading = reportState.loadingReportsDate;

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
    
    const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

    const formattedDateMonth = format(filterDate, "LLLL yyyy", {
        locale: pl
    });

    return (
        <Styles.CalendarWrapper>
            <Styles.CalendarHeaderContainer>
                <Styles.CalendarHeader>
                    {formattedDateMonth}
                </Styles.CalendarHeader>
                <Styles.CalendarArrowButtonContainer>
                    <SelectTodayButton onClick={() => setFilterDate(new Date())}>
                    Dzisiaj
                    </SelectTodayButton>
                    <Styles.CalendarTaskButtonAction onClick={() => setFilterDate(new Date(year, month - 1, 1))}>
                        <AngleLeftIcon />
                    </Styles.CalendarTaskButtonAction>
                    <Styles.CalendarTaskButtonAction onClick={() => setFilterDate(new Date(year, month + 1, 1))}>
                        <AngleRightIcon />
                    </Styles.CalendarTaskButtonAction>
                </Styles.CalendarArrowButtonContainer>
            </Styles.CalendarHeaderContainer>
            {!isReportsDatesListLoading ? <>
            <Styles.CalendarContainer>
                {DAYS_OF_THE_WEEK.map((day) => (
                    <Styles.Day key={day}>
                        <Styles.DaysOfTheWeek>
                            {day}
                        </Styles.DaysOfTheWeek>
                    </Styles.Day>
                ))}
                {Array(days[month] + (startDay - 1))
                .fill(null)
                .map((_, index) => {
                    const d = index - (startDay - 2);

                    const isReport = isReportsDatesList.find(date => {

                        let reportDateFormat = new Date(date.startDate);

                        return (d === reportDateFormat.getDate());
                    });

                    return (
                    <Styles.Day
                        key={index}
                        isSelected={d === filterDate.getDate()}
                        onClick={() => setFilterDate(new Date(year, month, d))}
                        isReport={isReport}
                    >
                        {d > 0 ? d : ''}
                    </Styles.Day>
                );
          })}
            </Styles.CalendarContainer>
            <Styles.ButtonsContainer>
                <Styles.ButtonSelectToday>
                  
                </Styles.ButtonSelectToday>
              </Styles.ButtonsContainer>
            </>
            : 
            <Styles.LoaderContainer>
                <Loader />
            </Styles.LoaderContainer>
            }
        </Styles.CalendarWrapper>
    );
};

export default Calendar;