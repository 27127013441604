import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LockIcon, TagIcon } from "react-line-awesome";

import FormSectionTitle from "../FormSectionTitle";
import Input from "../Input";
import PermissionInput from "../PermissionInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, permissionData, loading }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Informacje o roli" icon={TagIcon} />
        <Input
          label="Nazwa roli"
          placeholder="Wprowadź nazwę roli"
          isLoading={loading}
          error={errors?.name?.message}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
      </FormSection>
      <FormSection>
        <FormSectionTitle title="Schemat uprawnień" icon={LockIcon} />
        <PermissionInput form={form} items={permissionData} />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
  permissionData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      permission: PropTypes.number,
    })
  ).isRequired,
};

export default FormFields;
