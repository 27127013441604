import React from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import {
  EllipsisHIcon,
  EnvelopeIcon,
  MicrophoneIcon,
} from "react-line-awesome";

import { Title } from "./Title";
import SimpleDropdown from "./SimpleDropdown";
import Loader from "./Loader";
import { isEmpty } from "lodash";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.white};
`;
const DropdownAbsoluteBox = styled.div`
  position: absolute;
  top: 17px;
  right: 21px;
  height: 24px;
  font-size: 22px;
`;
const Buttons = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.color.mainBorder};
`;
const ProfileImage = styled.div`
  margin-bottom: 23px;
`;
const ImageBox = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ClientName = styled.div`
  margin-bottom: 28px;
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 1px;
  transition: 0.2s ease-in-out;
`;
const FullName = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.font100};
  line-height: 21px;
  transition: 0.2s ease-in-out;
`;
const Address = styled.div``;
const Line = styled.div`
  color: ${({ theme }) => theme.color.font200};
  line-height: 21px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.regular};

  :not(:last-of-type) {
    margin-bottom: 4px;
  }
`;
const ButtonText = styled.div`
  display: flex;
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 12px;
  transform: translateY(1px);
  font-weight: ${({ theme }) => theme.weight.medium};
  transition: color 0.2s ease-in-out;
`;
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 48px;
  color: ${({ theme }) => theme.color.font200};
  font-size: 18px;
  user-select: none;

  :not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.color.mainBorder};
  }

  :hover {
    ${ButtonText} {
      color: ${({ theme }) => theme.color.font300};
    }
  }
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 28px 25px 20px;

  :hover {
    cursor: pointer;

    ${StyledTitle} {
      color: ${({ theme }) => theme.color.primaryColor};
    }
    ${FullName} {
      color: ${({ theme }) => theme.color.font300};
    }
  }
`;

const Tile = ({ data, dropdownOptions, loadingDelete, ...rest }) => {
  const { id, name, fullName, address, phoneNumber, email, logo } = data;
  const addressArray = address.split(", ").slice(0, -1);

  const isDeleteLoading = loadingDelete === id;

  return (
    <Container>
      {isEmpty(dropdownOptions) || (
        <DropdownAbsoluteBox>
          {isDeleteLoading ? (
            <Loader loading={isDeleteLoading} />
          ) : (
            <SimpleDropdown data={dropdownOptions} icon={EllipsisHIcon} />
          )}
        </DropdownAbsoluteBox>
      )}

      <Content {...rest}>
        <ProfileImage>
          {logo ? (
            <ImageBox>
              <Image src={logo} alt="Avatar" />
            </ImageBox>
          ) : (
            <Avatar
              name={name}
              size={86}
              round={true}
              textSizeRatio={3.4}
              maxInitials={2}
              style={{
                fontFamily: "'Rubik', sans-serif",
                fontWeight: 500,
              }}
            />
          )}
        </ProfileImage>
        <ClientName>
          <StyledTitle>{name}</StyledTitle>
          <FullName>{fullName}</FullName>
        </ClientName>
        <Address>
          {addressArray.map((address) => (
            <Line key={address}>{address}</Line>
          ))}
        </Address>
      </Content>
      <Buttons>
        <Button href={`mailto:${email}`}>
          <ButtonContent>
            <EnvelopeIcon />
            <ButtonText>email</ButtonText>
          </ButtonContent>
        </Button>
        <Button href={`tel:${phoneNumber}`}>
          <ButtonContent>
            <MicrophoneIcon />
            <ButtonText>zadzwoń</ButtonText>
          </ButtonContent>
        </Button>
      </Buttons>
    </Container>
  );
};

export default Tile;
