/* --------------------------------- Queries -------------------------------- */
export const listCompanies = /* GraphQL */ `
  query ListCompanies {
    listCompanys {
      items {
        id
        companyName
        customerName
        email
        phone
        vat
      }
    }
  }
`;

export const searchCompanies = /* GraphQL */ `
  query SearchCompanies(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        customerName
        email
        phone
        vat
      }
      nextToken
      total
    }
  }
`;

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      address
      companyName
      customerName
      email
      phone
      vat
      logo
      language
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createCompanyUser = /* GraphQL */ `
  mutation CreateCompanyUser(
    $address: String
    $companyName: String
    $email: String
    $fullName: String
    $language: Language
    $phone: AWSPhone
    $vat: String
  ) {
    createCompanyUser(
      address: $address
      companyName: $companyName
      email: $email
      fullName: $fullName
      language: $language
      phone: $phone
      vat: $vat
    ) {
      company {
        id
        companyName
        customerName
        email
        phone
        vat
      }
    }
  }
`;

export const deleteCompanyFull = /* GraphQL */ `
  mutation DeleteCompanyFull($id: ID!) {
    deleteCompanyFull(id: $id)
  }
`;

export const companyUpdate = /* GraphQL */ `
  mutation CompanyUpdate(
    $id: ID!
    $address: String
    $email: AWSEmail
    $companyName: String
    $customerName: String
    $phone: AWSPhone
    $vat: String
    $logo: AWSURL
    $language: Language
  ) {
    companyUpdate(
      id: $id
      address: $address
      email: $email
      customerName: $customerName
      companyName: $companyName
      phone: $phone
      vat: $vat
      logo: $logo
      language: $language
    ) {
      id
      companyName
      customerName
      email
      phone
      vat
      logo
      language
    }
  }
`;
