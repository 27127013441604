import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  width: ${({ rest }) => `${rest.width}px`};
  display: flex;
  font-family: 'Rubik', sans-serif;
  justify-content: center;
  align-items: center;
  height: ${({ rest }) => `${rest.height}px`};
  background-color: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 30px;
  border-radius: 4px;
  border: none;
  transition: transform 0.18s ease-in-out;

  :hover {
    cursor: pointer;
    transform: scale(0.992);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  
  @media only screen and (max-width: 600px) {
    width: auto;
    white-space: break-spaces;
    font-size: 12px;
  }
`


const FilesButton = ({text, onClick, ...rest}) => {

    return (
        <ButtonContainer>
            <Button onClick = {onClick} rest={rest}>
                {text}
            </Button>
        </ButtonContainer>
    );
}

export default FilesButton

