import Checked from "../../icons/Checked";
import Wrench from "../../icons/Wrench";
import ExclamationMark from "../../icons/ExclamationMark";

import TypeIcon from "./TypeIcon";

export const typeIconsList = [
  {
    name: <TypeIcon icon={Checked} color="#34C759" />,
    value: "ASSIGNMENT",
  },
  {
    name: <TypeIcon icon={Wrench} color="#007AFF" style={{ top: 5 }} />,
    value: "SERVICE",
  },
  {
    name: (
      <TypeIcon
        icon={ExclamationMark}
        color="#FF9500"
        style={{ top: 5, left: 9 }}
      />
    ),
    value: "COMPLAINT",
  },
];

export const getTypeIconByValue = (value) => {
  const icon = typeIconsList.filter((item) => item.value === value)[0]?.name;
  return icon;
};
