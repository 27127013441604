export const searchRootFiles = /* GraphQL */ `
  query SearchRootFiles(
    $filter: SearchableRootFileFilterInput
    $sort: SearchableRootFileSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchRootFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        ref
        updatedAt
        name
        id
        isFolder
        parentId
      }
      nextToken
      total
    }
  }
`;

export const getRootFile = /* GraphQL */ `
  query GetRootFile($id: ID!) {
    getRootFile(id: $id) {
      ref
      name
      id
      createdAt
      updatedAt
      size
    }
  }
`;

export const deleteRootFile = /* GraphQL */ `
  mutation deleteRootFile($input: DeleteRootFileInput!) {
    deleteRootFile(input: $input) {
      id
    }
  }
`;

export const deleteMultipleRootFiles = /* GraphQL */ `
    mutation deleteMultipleRootFiles($parentId: ID!) {
    deleteMultipleRootFiles(parentId: $parentId)
}
`;

export const createRootFile = /* GraphQL */ `
  mutation CreateRootFile($input: CreateRootFileInput!) {
    createRootFile(input: $input) {
      createdAt
      id
      name
      ref
      size
      isFolder
      parentId
    }
  }
`;

export const createRootFiles = /* GraphQL */ `
  mutation CreateMultipleRootFiles($input: [rootFileInput]!) {
    createMultipleRootFiles(input: $input) {
      size
      createdAt
      id
      name
      ref
      parentId
    }
  }
`;
