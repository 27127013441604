import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AddressBookIcon,
  ClockIcon,
  EllipsisHIcon,
  TasksIcon,
  UsersIcon,
} from "react-line-awesome";

import SearchableDropdown from "../searchableDropdown";
import AddButton from "../AddButton";
import { searchClients } from "../../graphql/clients";
import { searchTeams } from "../../graphql/teams";
import {
  listTypeItems,
  searchTypeItems,
} from "../../graphql/settings/notificationTypes";
import SimpleDropdown from "../SimpleDropdown";
import { API, graphqlOperation } from "aws-amplify";
import { usePermissionState } from "../../context/permission";
import { getTypeIconByValue } from "../icons/typeIconsList";
import usePermissionObject from "../../hooks/usePermissionObject";

export const statusDropdownList = [
  {
    id: "PENDING",
    name: "Robocze",
  },
  {
    id: "SCHEDULED",
    name: "Zaplanowane",
  },
  {
    id: "IN_PROGRESS",
    name: "W toku",
  },
  {
    id: "COMPLETED",
    name: "Gotowe",
  },
];

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
`;
const Dropdowns = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 800px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const TableActions = ({
  addText,
  setIsSidebarOpen,
  setCreatingTypeItem,
  filtersState,
}) => {
  const { filters, setFilters } = filtersState;

  const permissionObject = usePermissionObject("reports");

  const dropdownAction = (fieldName) => (selectedItems) => {
    setFilters({ ...filters, [fieldName]: selectedItems });
  };

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const mainFilter = {
    companyId: {
      eq: companyId,
    },
  };

  const [createDropdown, setCreateDropdown] = useState([]);
  useEffect(() => {
    const getItems = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(listTypeItems, {
            filter: { companyId: { eq: companyId } },
          })
        );
        const response = data.listTypeItems.items.map((item) => ({
          value: item.id,
          name: item.name,
          icon: () => getTypeIconByValue(item.icon),
          handler: () => {
            setIsSidebarOpen(true);
            setCreatingTypeItem(item);
          },
        }));

        setCreateDropdown(response);
      } catch (err) {
        console.log(err);
      }
    };

    getItems();
  }, [setCreateDropdown]);

  return (
    <Container>
      <Dropdowns>
        <SearchableDropdown
          name="Typ zgłoszenia"
          icon={TasksIcon}
          initial={filters.typeItemId}
          config={{
            schema: searchTypeItems,
            action: dropdownAction("typeItemId"),
            searchable: false,
            filter: mainFilter,
          }}
          renderItem={() => {}}
        />
        <SearchableDropdown
          name="Klient"
          icon={AddressBookIcon}
          initial={filters.clientId}
          config={{
            schema: searchClients,
            action: dropdownAction("clientId"),
            filter: mainFilter,
          }}
        />
        <SearchableDropdown
          name="Status"
          icon={ClockIcon}
          initial={filters.status}
          config={{
            items: statusDropdownList,
            action: dropdownAction("status"),
            searchable: false,
            filter: mainFilter,
          }}
        />
        <SearchableDropdown
          name="Zespół"
          icon={UsersIcon}
          initial={filters.teamId}
          config={{
            schema: searchTeams,
            action: dropdownAction("teamId"),
            filter: mainFilter,
          }}
        />
      </Dropdowns>
      {permissionObject.create && (
        <SimpleDropdown
          data={createDropdown}
          icon={() => <AddButton text={addText} />}
        />
      )}
    </Container>
  );
};

export default TableActions;
