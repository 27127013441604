import {
    SEARCH_TICKETS_LOG_STARTED,
    SEARCH_TICKETS_LOG_SUCCESS,
    SEARCH_TICKETS_LOG_FAILURE,
    SEARCH_LIST_STATUS_STARTED,
    SEARCH_LIST_STATUS_SUCCESS,
    SEARCH_LIST_STATUS_FAILURE,
    SEARCH_REPORTS_PENDING_STARTED,
    SEARCH_REPORTS_PENDING_SUCCESS,
    SEARCH_REPORTS_PENDING_FAILURE,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE
} from "../types";

const initialState = {
    loading: false,
    loadingStatus: false,
    loadingReportPending: false,
    loadingReportsDate: false,
    nextToken: null,
    reports: [],
    statusList: [],
    reportsPending: [],
    reportsDate: [],
    error: null,
};

const logReducer = (state = initialState, action) => {
    switch(action.type) {
    case SEARCH_TICKETS_LOG_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_TICKETS_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: action.payload.reports,
        nextToken: action.payload.nextToken,
      };
    case SEARCH_TICKETS_LOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SEARCH_LIST_STATUS_STARTED:
      return {
        ...state,
        loadingStatus: true,
      };
    case SEARCH_LIST_STATUS_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        error: null,
        statusList: action.payload.statusList,
        nextToken: action.payload.nextToken,
      };
    case SEARCH_LIST_STATUS_FAILURE:
      return {
        ...state,
        loadingStatus: false,
        error: action.payload.error,
      };
    case SEARCH_REPORTS_PENDING_STARTED:
      return {
        ...state,
        loadingReportPending: true,
      };
    case SEARCH_REPORTS_PENDING_SUCCESS:
      return {
        ...state,
        loadingReportPending: false,
        error: null,
        reportsPending: action.payload.reportsPending,
        nextToken: action.payload.nextToken,
      };
    case SEARCH_REPORTS_PENDING_FAILURE:
      return {
        ...state,
        loadingReportPending: false,
        error: action.payload.error,
      };
      case SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED:
        return {
          ...state,
          loadingReportsDate: true,
        };
      case SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS:
        return {
          ...state,
          loadingReportsDate: false,
          error: null,
          reportsDate: action.payload.reportsDates,
          nextToken: action.payload.nextToken,
        };
      case SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE:
        return {
          ...state,
          loadingReportsDate: false,
          error: action.payload.error,
        };
    default:
        return state;
    }
};

export default logReducer;