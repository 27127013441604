import React, { createContext, useReducer, useContext } from "react";
import { mainTheme, lightTheme } from "../theme/theme";

const themes = { mainTheme, lightTheme };

const ThemeStateContext = createContext();
const ThemeDispatchContext = createContext();

let theme = null;
const themeName = localStorage.getItem("theme");
if (themeName) {
  theme = themes[themeName];
} else {
  localStorage.setItem("theme", "lightTheme");
  theme = themes.lightTheme;
}

const authReducer = (state, action) => {
  localStorage.setItem("theme", action.type);
  return {
    ...state,
    theme: themes[action.type],
  };
};

export const MultiThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { theme });

  return (
    <ThemeDispatchContext.Provider value={dispatch}>
      <ThemeStateContext.Provider value={state}>
        {children}
      </ThemeStateContext.Provider>
    </ThemeDispatchContext.Provider>
  );
};

export const useThemeState = () => useContext(ThemeStateContext);
export const useThemeDispatch = () => useContext(ThemeDispatchContext);
