import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { EditIcon, TrashIcon } from "react-line-awesome";

import MainTemplate from "../../templates/MainTemplate";
import ActionsPanel from "../../components/dataTable/ActionsPanel";
import BottomPanel from "../../components/dataTable/BottomPanel";
import SideModal from "../../components/modal/SideModal";
import { usePermissionState } from "../../context/permission";
import usePermissionObject from "../../hooks/usePermissionObject";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import TilesGridTemplate from "../../components/TilesGridTemplate";
import { deleteClient, searchClients } from "../../redux/actions/clientActions";
import CreateClient from "../../components/clients/CreateClient";
import EditClient from "../../components/clients/EditClient";
import { useCallback } from "react";
import { routes } from "../../routes";

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

export default function Clients() {
  const history = useHistory();
  const historyState = history.location.state;

  const permissionObject = usePermissionObject("clients");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  useEffect(() => {
    const historyState = history.location.state;
    if (historyState && historyState.isCreateOpen) {
      setIsCreateOpen(historyState.isCreateOpen);
    }
  }, [history]);

  const modalDispatch = useConfirmModalDispatch();

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    clientState: state.client,
    tableState: state.table,
  }));
  const { clientState, tableState } = state;
  const { pagination, sorting } = tableState;
  const { loadingDelete, nextToken, total } = clientState;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const searchClientsVariables = useMemo(
    () => ({
      filter: {
        companyId: {
          eq: companyId,
        },
      },
      sort: {
        direction:
          sorting.alphabetically.value === "DEFAULT"
            ? null
            : sorting.alphabetically.value,
        field: "name",
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
    }),
    [companyId, pagination, sorting]
  );

  useEffect(() => {
    dispatch(searchClients(searchClientsVariables));
  }, [dispatch, searchClientsVariables]);

  const dropdownOptions = useCallback(
    (id) => {
      const handleEdit = () => openEdit(id);

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć klienta?",
            buttonText: "Usuń",
            action: () => {
              dispatch(deleteClient(id, setIsEditOpen(false)));
            },
          },
        });
      };

      return [
        {
          name: "edytuj",
          icon: EditIcon,
          handler: handleEdit,
          isEditDisplay: false,
          permission: permissionObject.edit,
        },
        {
          name: "usuń",
          icon: TrashIcon,
          handler: handleDelete,
          isEditDisplay: true,
          permission: permissionObject.edit,
        },
      ];
    },
    [modalDispatch, dispatch, permissionObject]
  );

  const handleTileClick = (id) => {
    history.push(`${routes.client.home}/${id}`);
  };

  return (
    <MainTemplate title="Klienci">
      <TableBox>
        {permissionObject.create && (
          <ActionsPanel
            addText="Nowy klient"
            setIsSidebarOpen={setIsCreateOpen}
          />
        )}
        <TilesGridTemplate
          data={clientState.clients.filter(
            ({ id }) => id !== historyState?.deletedId
          )}
          loading={clientState.loading}
          handleClick={handleTileClick}
          dropdownOptions={dropdownOptions}
          loadingDelete={loadingDelete}
        />
        <BottomPanel nextToken={nextToken} total={total} />
      </TableBox>

      {permissionObject.create && (
        <SideModal
          title="Nowy klient"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateClient setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}
      {permissionObject.edit && (
        <SideModal
          title="Edycja klienta"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          dropdownOptions={dropdownOptions}
          loadingDelete={loadingDelete}
          id={String(currentOpenId)}
        >
          <EditClient setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </MainTemplate>
  );
}
