import React from "react";
import { AngleDownIcon } from "react-line-awesome";
import styled, { css } from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 29px;
  cursor: ${({ onClick }) => onClick && "pointer"};
`;
const IconBox = styled.div`
  margin-right: 12px;
  font-size: 22px;
  color: ${({ theme }) => theme.color.font100};
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 16px;
`;
const ArrowIconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 18px;
  color: ${({ theme }) => theme.color.font100};
  transition: transform 300ms;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `};
`;

const FormSectionTitle = ({ title, icon: Icon, isOpen, ...rest }) => {
  return (
    <Container {...rest}>
      {Icon && (
        <IconBox>
          <Icon />
        </IconBox>
      )}
      <Text>{title}</Text>
      {rest.onClick && !rest.noAngle && (
        <ArrowIconBox isOpen={isOpen}>
          <AngleDownIcon />
        </ArrowIconBox>
      )}
    </Container>
  );
};

export default FormSectionTitle;
