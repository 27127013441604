import React, { useMemo, useRef, useState } from "react";
import { AngleDownIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import { useChangeStatus } from "../../hooks/tickets/useChangeStatus";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { updateStatusTicket } from "../../redux/actions/ticketActions";
import { Label } from "../Input";

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;
const StatusBox = styled.div`
  position: relative;
  display: flex;
`;
const StyledLabel = styled(Label)`
  margin-bottom: 21px;
`;
const IconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
const PendingBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 34px;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.reportPending()};
  background-color: ${({ theme }) => theme.color.reportPending(true)};
  border: 1px dashed ${({ theme }) => theme.color.mainBorder};
  /* user-select: none; */
  white-space: nowrap;
`;
const ScheduledBox = styled(PendingBox)`
  padding-right: ${({ noArrow }) => (noArrow ? "16px" : "34px")};
  color: ${({ theme }) => theme.color.font300};
  background-color: ${({ theme }) => theme.color.darkButtonBackground};
  width: ${({ noArrow }) => noArrow && "100%"};
  border: transparent;
  cursor: pointer;

  ${({ noArrow }) =>
    noArrow &&
    css`
      width: 100%;
      height: 34px;
    `};

  ${IconBox} {
    color: ${({ theme }) => theme.color.font100};
  }
`;
const InProgressBox = styled(ScheduledBox)`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.reportInProgress()};

  ${IconBox} {
    opacity: 0.5;
    color: ${({ theme }) => theme.color.white};
  }
`;
const CompletedBox = styled(ScheduledBox)`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.completedBackground};

  ${IconBox} {
    opacity: 0.5;
    color: ${({ theme }) => theme.color.white};
  }
`;
const DropdownBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 34px;
  width: 100%;
  min-width: fit-content;
  border-radius: 4px;
  background-color: white;
  padding: 6px 0;
  overflow: hidden;
  z-index: 1;
`;
const DropdownElement = styled.div`
  width: 100%;
  min-height: 36px;
`;

const SelectStatus = ({ label, status }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const { ticket } = useSelector(({ ticket }) => ticket);

  const modalDispatch = useConfirmModalDispatch();

  const [handleStatusChange] = useChangeStatus();
  const dropdownOptions = useMemo(() => {
    const dropdownOptions = [
      {
        name: "Do zrobienia",
        status: "SCHEDULED",
        Component: ScheduledBox,
      },
      {
        name: "W trakcie",
        status: "IN_PROGRESS",
        Component: InProgressBox,
      },
      { name: "Gotowe", status: "COMPLETED", Component: CompletedBox },
    ];

    return dropdownOptions.filter((option) => option.status !== status);
  }, [status]);

  const statusBox = useRef(null);
  useDetectOutsideClick(statusBox, setIsDropdownOpen);

  let statusRender;
  if (status === "PENDING") {
    statusRender = <PendingBox>Robocze</PendingBox>;
  } else if (status === "SCHEDULED") {
    statusRender = (
      <ScheduledBox onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        Do zrobienia
        <IconBox>
          <AngleDownIcon />
        </IconBox>
      </ScheduledBox>
    );
  } else if (status === "IN_PROGRESS") {
    statusRender = (
      <InProgressBox onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        W trakcie
        <IconBox>
          <AngleDownIcon />
        </IconBox>
      </InProgressBox>
    );
  } else if (status === "COMPLETED") {
    statusRender = (
      <CompletedBox onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        Gotowe
        <IconBox>
          <AngleDownIcon />
        </IconBox>
      </CompletedBox>
    );
  } else console.error(`Unhandled status ${status}`);

  return (
    <Box>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StatusBox ref={statusBox}>
        {statusRender}
        {isDropdownOpen && (
          <DropdownBox isOpen={isDropdownOpen}>
            {dropdownOptions.map((option) => (
              <DropdownElement
                key={option.name}
                onClick={() => {
                  const handleChangeToCompleted = () => {
                    modalDispatch({
                      type: "OPEN",
                      payload: {
                        title: "Potwierdzenie zmiany statusu",
                        content:
                          "Czy chcesz zakończyć zlecenie bez protokołu odbioru? Protokół odbioru można wygenerować za pomocą aplikacji mobilnej ROTO dostępnej w sklepie Google Play oraz App Store.",
                        buttonText: "Zaktualizuj",
                        action: () => {
                          dispatch(
                            updateStatusTicket(ticket.id, option.status)
                          );
                          setIsDropdownOpen(!isDropdownOpen);
                        },
                      },
                    });
                  };

                  if (ticket) {
                    if (option.status === "COMPLETED")
                      handleChangeToCompleted();
                    else {
                      dispatch(updateStatusTicket(ticket.id, option.status));
                      setIsDropdownOpen(!isDropdownOpen);
                    }
                  }
                }}
              >
                <option.Component noArrow>{option.name}</option.Component>
              </DropdownElement>
            ))}
          </DropdownBox>
        )}
      </StatusBox>
    </Box>
  );
};

export default SelectStatus;
