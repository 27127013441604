import {
  CREATE_BUILDING_STARTED,
  CREATE_BUILDING_SUCCESS,
  CREATE_BUILDING_FAILURE,
  LIST_BUILDINGS_STARTED,
  LIST_BUILDINGS_SUCCESS,
  LIST_BUILDINGS_FAILURE,
  GET_BUILDING_STARTED,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_FAILURE,
  UPDATE_BUILDING_STARTED,
  UPDATE_BUILDING_SUCCESS,
  UPDATE_BUILDING_FAILURE,
  DELETE_BUILDING_STARTED,
  DELETE_BUILDING_SUCCESS,
  DELETE_BUILDING_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  buildings: [],
  building: {},
  error: null,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BUILDING_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_BUILDING_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        buildings: [action.payload, ...state.buildings],
      };
    case CREATE_BUILDING_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case LIST_BUILDINGS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_BUILDINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        buildings: action.payload,
      };
    case LIST_BUILDINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_BUILDING_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_BUILDING_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        buildings: state.buildings.filter((item) => item.id !== action.payload),
      };
    case DELETE_BUILDING_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_BUILDING_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_BUILDING_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        building: action.payload,
      };
    case GET_BUILDING_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_BUILDING_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_BUILDING_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        buildings: state.buildings.map((building) =>
          building.id === action.payload.id
            ? { ...building, ...action.payload }
            : building
        ),
        building: action.payload,
      };
    case UPDATE_BUILDING_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default clientReducer;
