import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Loader from "./Loader";

const ButtonContainer = styled.div`
  position: relative;
  user-select: none;
`;
const Button = styled.input`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isHeight }) => (isHeight ? "46px" : "38px")};
  background-color: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 30px;
  border-radius: 4px;
  border: none;
  transition: transform 0.18s ease-in-out;

  :hover {
    cursor: pointer;
    transform: scale(0.992);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  ${({ loader }) =>
    loader &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;
const LoaderElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ buttonDim }) => `${buttonDim.width}px`};
  height: ${({ buttonDim }) => `${buttonDim.height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Submit({ value = "Potwierdź", ...rest }) {
  const [buttonDim, setButtonDim] = useState({ width: 0, height: 0 });
  const button = useRef(null);

  useEffect(() => {
    const { width, height } = button.current.getBoundingClientRect();
    setButtonDim({ width, height });
  }, [setButtonDim]);

  return (
    <ButtonContainer>
      <Button type="submit" value={value} ref={button} {...rest} />
      {rest.loader && (
        <LoaderElement buttonDim={buttonDim}>
          <Loader {...rest.loader} size={30} />
        </LoaderElement>
      )}
    </ButtonContainer>
  );
}
