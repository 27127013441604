/* --------------------------------- Queries -------------------------------- */
export const listFieldItems = /* GraphQL */ `
  query ListFieldItems($filter: ModelFieldItemFilterInput) {
    listFieldItems(filter: $filter) {
      items {
        id
        name
        fields {
          items {
            id
            field {
              id
              name
            }
          }
        }
        typeItem {
          id
          name
        }
      }
    }
  }
`;

export const getFieldItem = /* GraphQL */ `
  query GetFieldItem($id: ID!) {
    getFieldItem(id: $id) {
      id
      name
      fields {
        items {
          id
          field {
            id
            name
          }
        }
      }
      typeItem {
        id
        name
      }
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createFieldItem = /* GraphQL */ `
  mutation CreateFieldItem($input: CreateFieldItemInput!) {
    createFieldItem(input: $input) {
      id
      name
      fields {
        items {
          id
          field {
            id
            name
          }
        }
      }
      typeItem {
        id
        name
      }
    }
  }
`;

export const createField = /* GraphQL */ `
  mutation CreateField($input: CreateFieldInput!) {
    createField(input: $input) {
      id
      name
    }
  }
`;

export const createFieldConnector = /* GraphQL */ `
  mutation CreateFieldConnector($input: CreateFieldConnectorInput!) {
    createFieldConnector(input: $input) {
      id
    }
  }
`;

export const updateFieldItem = /* GraphQL */ `
  mutation UpdateFieldItem($input: UpdateFieldItemInput!) {
    updateFieldItem(input: $input) {
      id
      name
      fields {
        items {
          id
          field {
            id
            name
          }
        }
      }
      typeItem {
        id
        name
      }
    }
  }
`;

export const deleteFieldItem = /* GraphQL */ `
  mutation DeleteFieldItem($input: DeleteFieldItemInput!) {
    deleteFieldItem(input: $input) {
      id
      name
      fields {
        items {
          id
          field {
            id
            name
          }
        }
      }
      typeItem {
        id
        name
      }
    }
  }
`;

export const deleteField = /* GraphQL */ `
  mutation DeleteField($input: DeleteFieldInput!) {
    deleteField(input: $input) {
      id
    }
  }
`;

export const deleteFieldConnector = /* GraphQL */ `
  mutation DeleteFieldConnector($input: DeleteFieldConnectorInput!) {
    deleteFieldConnector(input: $input) {
      id
    }
  }
`;
