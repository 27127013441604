import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  BanIcon,
  MapIcon,
  PhoneIcon,
  TimesCircleIcon,
} from "react-line-awesome";
import gsap from "gsap";
import SearchInput from "../searchableDropdown/SearchInput";
import { Transition } from "react-transition-group";
import Loader from "../Loader";
import Checkbox from "../Checkbox";
import { isEmpty, some } from "lodash";
import { useGetSearchDropdownItems } from "../../hooks/useGetSearchDropdownItems";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import AdditionalDropdownOption from "./AdditionalDropdownOption";
import { useGetPlaceInfo } from "../../hooks/useGetPlaceInfo";
import { GET_FIELD_ITEM_STARTED } from "../../redux/types";

const Container = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  width: 100%;
  margin-bottom: 18px;
  cursor: pointer;
  z-index: 4;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: unset;
      opacity: 0.6;
    `}
`;
const SelectedItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  border: 1px solid ${({ theme }) => theme.color.mainBorder};
  border-radius: 4px;
  padding: 0 86px 0 16px;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;

  ${({ theme, error }) =>
    error &&
    css`
      background-color: ${theme.color.inputErrorBackground};
      border-color: ${theme.color.inputErrorBorder};
    `}
`;
const IconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 17px;
  width: 22px;
  color: ${({ theme }) => theme.color.font100};
  font-size: 22px;
  transform: translateY(-50%);
  cursor: pointer;
`;
const DropdownBox = styled.div`
  position: absolute;
  top: 52px;
  width: fit-content;
  min-width: 100%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.12);
  overflow: hidden;
  outline: none;
`;
const OptionsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(20px - 12px) 18px;
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ErrorBox = styled(LoadingBox)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.color.toastifyError};
`;
const ErrorIconBox = styled.div`
  font-size: 16px;
  margin-right: 6px;
`;
const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.color.font300};
`;
const Label = styled.label`
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  ${({ isCut }) =>
    isCut &&
    css`
      width: 90%;

      overflow-x: hidden;

      ::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
        z-index: 1;
      }
    `}
`;
const Text = styled.div`
  position: relative;
  white-space: nowrap;

  ${({ isCut }) =>
    isCut &&
    css`
      overflow-x: hidden;

      ::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
        z-index: 1;
      }
    `}
`;
const Placeholder = styled.div`
  color: ${({ theme }) => theme.color.font100};
`;
const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6px 0;
`;
const EmptyButton = styled.div`
  color: ${({ theme }) => theme.color.primaryColor};
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 12px;
  cursor: pointer;
`;
const ErrorIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 16px;
  color: ${({ theme }) => theme.color.inputErrorIcon};
  font-size: 18px;
  cursor: help;
`;

function enterAnimation(node, done) {
  gsap.from(node, {
    autoAlpha: 0,
    duration: 0.1,
    onComplete: done,
    ease: "Power2.inOut",
  });
}

function exitAnimation(node, done) {
  gsap.to(node, {
    autoAlpha: 0,
    duration: 0.1,
    onComplete: done,
    ease: "Power2.inOut",
  });
}

const SearchableSelectInput = ({
  field,
  fieldState,
  placeholder,
  schema,
  config,
  googleAutocomplete,
  handleEmpty = { text: "Utwórz", action: () => {} },
  ...rest
}) => {
  const { value, onChange } = field;
  const { filter, icons = [], searchPlaceholder = "Nazwa" } = config || {};

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [googleAutocompleteMethod, setGoogleAutocompleteMethod] = useState(
    value?.googleMethod
  );

  const container = useRef(null);
  useDetectOutsideClick(container, setIsOpen);

  const [
    dropdownItems,
    currentMachine,
    { getItemsFromAPI, state, getGooglePlace },
  ] = useGetSearchDropdownItems({
    searchValue,
    schema,
    filter,
    googleAutocompleteMethod,
  });

  const { getPlaceInfo } = useGetPlaceInfo();

  const handleOpen = () => {
    if (isEmpty(dropdownItems) && isOpen) getItemsFromAPI();
    setIsOpen(!isOpen);
  };

  const handleSelectChange = async (item) => {
    try {
      if (googleAutocompleteMethod) {
        const placeInfoResponse = await getPlaceInfo(item.placeId);
        onChange({
          name: placeInfoResponse.formatted_address,
          location: placeInfoResponse.geometry.location,
        });
      } else onChange(item);

      setIsOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleMethodChange = () => {
    setSearchValue("");
    setGoogleAutocompleteMethod(!googleAutocompleteMethod);
  };

  const handlePhone = (e) => {
    e.stopPropagation();
    window.open(`tel:${value.phoneNumber}`, "_self");
  };

  const handleMap = (e) => {
    e.stopPropagation();
    let location;
    if (googleAutocompleteMethod) {
      const { lat, lng } = value.location;
      location = { lat, lng };
    } else {
      console.log("Building", value);
      const { latitude, longitude } = value;
      location = { lat: latitude, lng: longitude };
    }
    const link = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
    window.open(link, "_blank");
  };

  useEffect(() => {
    if (googleAutocompleteMethod) getGooglePlace();
    else getItemsFromAPI();
  }, [googleAutocompleteMethod]);

  return (
    <Container ref={container} {...rest}>
      <SelectedItem onClick={handleOpen} error={fieldState.error}>
        {value ? (
          <Text isCut={value?.name?.length > 20}>{value?.name}</Text>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        {value && icons.includes("phone") && (
          <IconBox
            style={{ right: 47 }}
            onClick={handlePhone}
            title={value.phoneNumber}
          >
            <PhoneIcon />
          </IconBox>
        )}
        {value && icons.includes("map") && (
          <IconBox onClick={handleMap}>
            <MapIcon />
          </IconBox>
        )}
        {fieldState.error && (
          <ErrorIcon title={fieldState.error.message}>
            <BanIcon />
          </ErrorIcon>
        )}
      </SelectedItem>
      <Transition
        timeout={150}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <DropdownBox>
          <SearchInput
            value={searchValue}
            setValue={setSearchValue}
            placeholder={
              googleAutocompleteMethod ? "Adres usługi" : searchPlaceholder
            }
          />
          <OptionsBox>
            {currentMachine.matches(state.isLoading) && (
              <LoadingBox>
                <Loader loading={true} />
              </LoadingBox>
            )}
            {currentMachine.matches(state.hasError) && (
              <ErrorBox>
                <ErrorIconBox>
                  <TimesCircleIcon />
                </ErrorIconBox>
                Error
              </ErrorBox>
            )}
            {currentMachine.matches(state.hasLoaded) && (
              <>
                {!isEmpty(dropdownItems) ? (
                  dropdownItems.map((item) => (
                    <CheckboxItem
                      key={item.id}
                      onClick={() => handleSelectChange(item)}
                    >
                      <Label isCut={item.name.length > 22}>{item.name}</Label>
                    </CheckboxItem>
                  ))
                ) : googleAutocompleteMethod ? null : (
                  <EmptyBox>
                    <EmptyButton onClick={handleEmpty.action}>
                      {handleEmpty.text}
                    </EmptyButton>
                  </EmptyBox>
                )}
              </>
            )}
          </OptionsBox>
          {googleAutocomplete && (
            <AdditionalDropdownOption
              text={googleAutocompleteMethod ? "Dodaj budynek" : "Dodaj adres"}
              onClick={handleMethodChange}
            />
          )}
        </DropdownBox>
      </Transition>
    </Container>
  );
};

export default SearchableSelectInput;
