import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { usePermissionState } from "../../context/permission";
import { createTicket } from "../../redux/actions/ticketActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateReport = ({ setIsOpen, typeItem }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector(({ ticket }) => ticket);

  const { cognitoUser } = usePermissionState();
  const { companyId, id: userId } = cognitoUser.databaseUser;

  const onSubmit = (data) => {
    const {
      attachments,
      building,
      client,
      customerOrder,
      description,
      measurementCard,
      notes,
      producerOrder,
      type,
      photos,
    } = data;

    let specification = [];
    if (data.product) {
      for (let [key, value] of Object.entries(data.product)) {
        if (typeof value === "number" && !isNaN(value)) {
          specification = [...specification, { id: key, value }];
        }
      }
    }

    let ticketFieldItems = [];
    if (data.ticketFieldItem) {
      for (let [key, value] of Object.entries(data.ticketFieldItem)) {
        ticketFieldItems = [
          ...ticketFieldItems,
          { fieldItemId: key, fieldId: value.value, name: value.name },
        ];
      }
    }

    const variables = {
      input: {
        companyId,
        userId,
        buildingId: building?.id || null,
        latitude: building.location ? building.location.lat : null,
        longitude: building.location ? building.location.lng : null,
        address: building.name ? building.name : null,
        clientId: client.id,
        typeItemId: typeItem.id,
        typeId: type.value,
        notes,
        description,
        status: "PENDING",
      },
      rest: {
        specifications: specification.map((item) => ({
          productId: item.id,
          value: item.value,
        })),
        fieldItems: ticketFieldItems.map(({ fieldItemId, fieldId }) => ({
          fieldItemId,
          selectedFieldId: fieldId,
        })),
        measurementCard,
        attachments,
        customerOrder,
        producerOrder,
        photos,
      },
    };

    dispatch(
      createTicket(variables, () => {
        setIsOpen(false);
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} typeItem={typeItem} isCreate />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default CreateReport;
