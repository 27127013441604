import React, { createContext, useState, useContext } from "react";

const SideBarStateContext = createContext();
const SideBarDispatchContext = createContext();

let isOpen = true;
const themeName = localStorage.getItem("isSidebarOpen");
if (themeName) {
  isOpen = themeName === "true";
} else {
  localStorage.setItem("isSidebarOpen", isOpen);
}

export const SideBarProvider = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(isOpen);

  const toggleSideBar = () => {
    localStorage.setItem("isSidebarOpen", !isSideBarOpen);
    setIsSideBarOpen(!isSideBarOpen);
  };

  return (
    <SideBarDispatchContext.Provider value={toggleSideBar}>
      <SideBarStateContext.Provider value={isSideBarOpen}>
        {children}
      </SideBarStateContext.Provider>
    </SideBarDispatchContext.Provider>
  );
};

export const useSideBarState = () => useContext(SideBarStateContext);
export const useToggleSideBar = () => useContext(SideBarDispatchContext);
