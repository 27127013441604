import { SET_LIMIT, NEXT_PAGE, PREV_PAGE, SET_SORTING } from "../types";

export const setLimit = (limit) => ({
  type: SET_LIMIT,
  payload: limit,
});

export const nextPage = (nextToken, total) => ({
  type: NEXT_PAGE,
  payload: { nextToken, total },
});
export const prevPage = () => ({ type: PREV_PAGE });

export const setSorting = (sorting) => ({
  type: SET_SORTING,
  payload: sorting,
});
