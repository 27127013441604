import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { boolArr2dec } from "../../helpers/functions";
import { createRole } from "../../redux/actions/roleActions";
import { usePermissionState } from "../../context/permission";
import MainToast from "../toastify/MainToast";

let permissionData = [
  // { name: "Dziennik", slug: "log", permission: 0 },
  { name: "Zgłoszenia", slug: "reports", permission: 0 },
  { name: "Klienci", slug: "clients", permission: 0 },
  { name: "Role", slug: "roles", permission: 0 },
  { name: "Użytkownicy", slug: "users", permission: 0 },
  { name: "Zespoły", slug: "teams", permission: 0 },
  { name: "Ustawienia", slug: "settings", permission: 0 },
  // { name: "Analiza", slug: "analyze", permission: 0 },
  // { name: "Rozliczenia", slug: "biling", permission: 0 },
  // { name: "Profil", slug: "userProfile", permission: 0 },
];

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateRole = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.role);
  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    let { name, permission } = data;

    for (const key in permission) {
      if (Object.hasOwnProperty.call(permission, key)) {
        const element = permission[key];

        permission[key] = boolArr2dec(Object.values(element));
      }
    }

    if (companyId) {
      dispatch(
        createRole({ name, permissions: { ...permission }, companyId }, () => {
          setIsOpen(false);
          toast.success(<MainToast message="Stworzono nową rolę" />);
        })
      );
    } else {
      toast.error(<MainToast message="Ups! Coś poszło nie tak." />);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} permissionData={permissionData} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateRole.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateRole;
