import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  BriefcaseIcon,
  BookIcon,
  TasksIcon,
  UsersIcon,
  ChartAreaIcon,
  AddressBookIcon,
  CogIcon,
  UserTagIcon,
  TagsIcon,
  FolderOpenIcon,
  ShoppingBasketIcon,
  QuestionCircleIcon,
  WalletIcon,
} from "react-line-awesome";

import { useSideBarState } from "../../context/sideBar";
import Logo from "../../icons/Logo";
import SmallLogo from "../../icons/SmallLogo";
import { routes } from "../../routes";
import { usePermissionState } from "../../context/permission";
import { dec2bin } from "../../helpers/functions";
import SidebarSection from "./SidebarSection";
import { useConfirmModalDispatch } from "../../context/ModalContext";

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  border-right: 1px solid ${({ theme }) => theme.color.sideBarBorder};
  overflow-x: hidden;
  overflow-y: auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 13px 24px 11px;
  background-color: ${({ theme }) => theme.color.backgroundDark};
`;

export default function SideBar() {
  const isOpen = useSideBarState();
  const permissionState = usePermissionState();
  const modalDispatch = useConfirmModalDispatch();

  const showInformationAboutNavSection = (navElementName) => {
    modalDispatch({
      type: "OPEN",
      payload: {
        title: navElementName,
        content: "Trwa budowa aplikacji. Funkcjonalność dostępna wkrótce.",
        isButtonSectionDisabled: true,
      },
    });
  };

  const navLinksArray = [
    {
      items: [
        {
          name: "Dziennik",
          slug: "reports",
          path: routes.log,
          icon: ChartAreaIcon,
        },
        {
          name: "Zgłoszenia",
          slug: "reports",
          path: routes.reports,
          icon: TasksIcon,
        },
        {
          name: "Klienci",
          slug: "clients",
          path: routes.clients,
          icon: AddressBookIcon,
        },
        {
          name: "Rozliczenia",
          slug: "settlements",
          path: routes.clients,
          icon: WalletIcon,
          isDisabled: true,
        },
        {
          name: "Analizy",
          slug: "analyzes",
          path: routes.clients,
          icon: ChartAreaIcon,
          isDisabled: true,
        },
      ],
    },
    {
      section: "Zasoby",
      items: [
        {
          name: "Dokumenty",
          slug: "documents",
          path: routes.library.home,
          icon: FolderOpenIcon,
          isDisabled: true,
        },
        {
          name: "Biblioteka",
          slug: "library",
          path: routes.library.home,
          icon: BookIcon,
        },
        // {
        //   name: "Sklep",
        //   slug: "shop",
        //   path: routes.library.home,
        //   icon: ShoppingBasketIcon,
        //   isDisabled: true,
        // },
      ],
    },
    {
      section: "Zarządzanie",
      items: [
        {
          name: "Zespoły",
          slug: "teams",
          path: routes.teams,
          icon: UsersIcon,
        },
        {
          name: "Pracownicy",
          slug: "users",
          path: routes.users,
          icon: UserTagIcon,
        },
        {
          name: "Role",
          slug: "roles",
          path: routes.roles,
          icon: TagsIcon,
        },
        {
          name: "Firmy",
          slug: "companies",
          path: routes.companies,
          icon: BriefcaseIcon,
        },
      ],
    },
    {
      section: "Pozostałe",
      items: [
        {
          name: "Ustawienia",
          slug: "settings",
          path: routes.settings.home,
          icon: CogIcon,
        },
        {
          name: "Pomoc Techniczna",
          slug: "technicalsupport",
          path: routes.settings.home,
          icon: QuestionCircleIcon,
          isDisabled: true,
        },
      ],
    },
  ];

  const linksArray = useMemo(() => {
    const filteredArray = [];
    navLinksArray.map(({ items }) => {
      filteredArray.push(
        items.filter((item) => {
          const readPermission = dec2bin(permissionState[item.slug]).split(
            ""
          )[0];
          return !!+readPermission && item;
        })
      );
    });
    return filteredArray;
  }, [permissionState]);

  return (
    <Container>
      <LogoBox>{isOpen ? <Logo /> : <SmallLogo width={40} />}</LogoBox>
      <SidebarSection
        isOpen={isOpen}
        linksArray={linksArray}
        navLinksArray={navLinksArray}
        showInformationAboutNavSection={showInformationAboutNavSection}
      />
    </Container>
  );
}
