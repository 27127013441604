import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    html {
      /* font-size: 62.5%; */
      scroll-behavior: smooth;
    }

    *, *::before, *::after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    body {
      font-size: 16px;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: ${({ theme }) => theme.weight.regular};
      background-color: ${({ theme }) => theme.color.white};
      overflow-x: hidden;
      ::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #464445;
        border-radius: 10px;
      }
    }

    //table {
      div::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      div::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        border-radius: 10px;
      }
      div::-webkit-scrollbar-thumb {
        background: #464445;
        border-radius: 10px;
      }
    //}
    
    input, textarea, a, p {
      font-family: 'Rubik', sans-serif;
      font-weight: ${({ theme }) => theme.weight.regular};
      text-decoration: none;
      outline: none;
    }

    .react-tags {
      position: relative;
      padding: 7px 6px;
      border: 1px solid ${({ theme }) => theme.color.mainBorder};
      cursor: text;
      border-radius: 4px;
      min-height: 136px;
    }

    .react-tags__selected {
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 30em) {

      .react-tags__search {
        position: relative;
      }

    }

    .react-tags__search-wrapper {
      padding: 9px 4px;
    }

    .react-tags__search-input {
      width: 100%;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.color.font300};
      font-size: 14px;
      font-weight: ${({ theme }) => theme.weight.medium};

      :disabled {
        opacity: 0.6;
        background-color: ${({ theme }) => theme.color.mainBackground};
      }

      ::placeholder {
        color: ${({ theme }) => theme.color.font100};
      }
    }

    .react-tags__search-input::-ms-clear {
      display: none;
    }

    .react-tags__suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }

    .react-tags__suggestions ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    .react-tags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px;
    }

    .react-tags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }

    .react-tags__suggestions li:hover {
      cursor: pointer;
      background: #eee;
    }

    .react-tags__suggestions li.is-active {
      background: #b7cfe0;
    }

    .react-tags__suggestions li.is-disabled {
      opacity: 0.5;
      cursor: auto;
    }

    .ReactCollapse--collapse {
      transition: height 300ms;
    }


    .react-tags__search-input {
      background-color: transparent;
    }
  `;
