import React from "react";
import styled from "styled-components";
import { ImageIcon, InfoCircleIcon } from "react-line-awesome";

import FormSectionTitle from "../FormSectionTitle";
import Input from "../Input";
import { emailReg, phoneReg } from "../../helpers/regex";
import { Controller } from "react-hook-form";
import SingleFileUpload from "../SingleFileUpload";
import AddressInput from "../AddressInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading }) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = form;

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Logo" icon={ImageIcon} />
        <Controller
          render={({ ref, ...rest }) => (
            <SingleFileUpload
              fullWidth
              isLoading={loading}
              label="Logo"
              error={errors?.logo?.message}
              {...rest}
            />
          )}
          control={control}
          name="logo"
        />
      </FormSection>
      <FormSection>
        <FormSectionTitle title="Informacje o kliencie" icon={InfoCircleIcon} />
        <Input
          label="Nazwa"
          placeholder="Wprowadź nazwę klienta"
          isLoading={loading}
          error={errors?.name?.message}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Imię i nazwisko"
          placeholder="Wprowadź imię i nazwisko"
          isLoading={loading}
          error={errors?.fullName?.message}
          {...register("fullName", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Controller
          render={({ ...rest }) => (
            <AddressInput
              isLoading={loading}
              label="Adres"
              placeholder="Wprowadź adres"
              error={errors?.address?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Podaj adres" },
          }}
          control={control}
          name="address"
        />
        <Input
          label="Telefon"
          placeholder="Wprowadź numer telefonu"
          error={errors?.phoneNumber?.message}
          isLoading={loading}
          {...register("phoneNumber", {
            required: { value: true, message: "Pole nie może być puste" },
            pattern: {
              value: phoneReg,
              message: "Podaj poprawny numer telefonu",
            },
          })}
        />
        <Input
          label="Adres e-mail"
          placeholder="Wprowadź adres e-mail"
          error={errors?.email?.message}
          isLoading={loading}
          {...register("email", {
            required: { value: true, message: "Pole nie może być puste" },
            pattern: {
              value: emailReg,
              message: "Podaj poprawny adres e-mail",
            },
          })}
        />
        <Input
          textarea
          defaultValue={watch("notes", "")}
          label="Uwagi"
          placeholder="Wprowadź uwagi"
          error={errors?.notes?.message}
          isLoading={loading}
          maxLength={255}
          {...register("notes")}
        />
      </FormSection>
    </>
  );
};

export default FormFields;
