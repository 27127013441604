import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TasksIcon } from "react-line-awesome";
import { Controller } from "react-hook-form";

import FormSectionTitle from "../../FormSectionTitle";
import Input from "../../Input";
import TagSelect from "../../TagSelect";
import MultiCheckbox from "../../MultiCheckbox";
import Select from "../../Select";
import { typeIconsList } from "../../icons/typeIconsList";

const FormSection = styled.div`
  margin-bottom: 26px;
`;
const OneLineInputs = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > *:not(:last-of-type) {
    margin-right: 15px;
  }
`;
const BiggerInputBox = styled.div`
  flex: 2;
`;
const SmallerInputBox = styled.div`
  flex: 1;
`;

const FormFields = ({ form, loading }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const checkboxOptionsArray = useMemo(
    () => [
      { name: "Opis", slug: "description" },
      { name: "Specyfikacja", slug: "specification" },
      { name: "Uwagi", slug: "notes" },
      { name: "Zamówienie od klienta", slug: "customerOrder" },
      { name: "Zamówienie od producenta", slug: "producerOrder" },
      { name: "Karta pomiarowa", slug: "measurementCard" },
      { name: "Załączniki", slug: "attachments" },
      { name: "Zdjęcia", slug: "photos" },
      { name: "Protokół odbioru", slug: "protocol" },
    ],
    []
  );

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Typy zgłoszeń" icon={TasksIcon} />
        <OneLineInputs>
          <BiggerInputBox>
            <Input
              label="Nazwa typu zgłoszenia"
              placeholder="Wprowadź nazwę typu zgłoszenia"
              error={errors?.name?.message}
              isLoading={loading}
              {...register("name", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
            />
          </BiggerInputBox>
          <SmallerInputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  isLoading={loading}
                  items={typeIconsList}
                  label="Ikona"
                  error={errors?.icon?.message}
                  {...rest}
                />
              )}
              control={control}
              name="icon"
            />
          </SmallerInputBox>
        </OneLineInputs>
        <Controller
          render={({ ref, ...rest }) => (
            <TagSelect
              disabled={loading}
              placeholderText="Wprowadź rodzaj typu zgłoszenia"
              label="Rodzaje typu zgłoszenia"
              error={errors?.types?.message}
              {...rest}
            />
          )}
          control={control}
          name="types"
          rules={{
            required: { value: true, message: "Pole nie może być puste" },
          }}
        />
        <MultiCheckbox
          form={form}
          data={checkboxOptionsArray}
          label="Dostępność"
        />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
};

export default FormFields;
