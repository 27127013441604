import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { companyUpdate, getCompany } from "../../redux/actions/companyActions";

const language = "PL";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditCompany = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const companyState = useSelector(({ company }) => company);

  useEffect(() => {
    dispatch(getCompany(id));
  }, [id, dispatch]);

  useEffect(() => {
    Object.entries(companyState.company).forEach(([name, value]) => {
      if (name === "phone")
        setValue(name, parsePhoneNumber(value, language).nationalNumber);
      else setValue(name, value);
    });
  }, [companyState.company, setValue]);

  const onSubmit = (data) => {
    const { customerName, phone } = data;
    const { location } = data.address?.geometry || {};
    delete data.email;

    const variables = {
      ...data,
      fullName: customerName,
      phone: parsePhoneNumber(phone, language).number,
      address: data.address.formatted_address || data.address,
      latitude: location?.lat,
      longitude: location?.lng,
      id,
    };
    if (!location) {
      delete variables.latitude;
      delete variables.longitude;
    }

    dispatch(companyUpdate(variables));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={companyState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={companyState.loadingGet}
            loader={companyState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

EditCompany.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EditCompany;
