import React from "react";
import { BellIcon, TimesCircleIcon } from "react-line-awesome";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Close = styled.div`
  display: flex;
  font-size: 18px;
  margin-left: 12px;
`;
const IconBox = styled.div`
  display: flex;
  font-size: 22px;
  margin-right: 14px;
`;
const Text = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 16px;
`;

const MainToast = ({ closeToast, message, icon: Icon = BellIcon }) => {
  return (
    <Container>
      <IconBox>
        <Icon />
      </IconBox>
      <Text>{message}</Text>
      <Close onClick={closeToast}>
        <TimesCircleIcon />
      </Close>
    </Container>
  );
};

export default MainToast;
