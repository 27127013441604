import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Button } from "../../Button";
import Submit from "../../Submit";
import {
  getProduct,
  updateProduct,
} from "../../../redux/actions/settings/productActions";
import ProductFormFields from "./ProductFormFields";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditProduct = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const productState = useSelector(({ product }) => product);

  useEffect(() => {
    dispatch(getProduct(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(productState.product)) {
      const { name, unit } = productState.product;

      setValue("name", name);
      setValue("unit", unit);
    }
  }, [productState.product, setValue]);

  const onSubmit = (data) => {
    const input = {
      id,
      name: data.name,
      unit: data.unit,
    };

    dispatch(updateProduct(input));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ProductFormFields form={form} loading={productState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={productState.loadingGet}
            loader={productState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditProduct;
