import React from "react";
import styled from "styled-components";
import { SignOutIcon } from "react-line-awesome";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  usePermissionDispatch,
  usePermissionState,
} from "../../context/permission";
import DropdownMenu from "./DropdownMenu";
import { routes } from "../../routes";

const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.topBarAvatar};
  /* background-image: url("https://randomuser.me/api/portraits/men/33.jpg"); */
`;

const ProfileInfo = styled.div`
  padding: 22px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.mainBackground};
`;
const InfoBox = styled.div`
  margin-left: 14px;
`;
const Name = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  margin-bottom: 3px;
  white-space: nowrap;
`;
const Email = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.font100};
`;
const ProfileButtons = styled.div`
  padding: 12px 0;
`;
const ProfileButton = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 20px;
  font-size: 18px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.font100};
  transition: background 0.16s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
    cursor: pointer;
  }
`;
const Text = styled.div`
  white-space: nowrap;
  font-size: 12px;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  margin-left: 6px;
`;

export default function Profile() {
  const history = useHistory();
  const permissionDispatch = usePermissionDispatch();
  const { cognitoUser } = usePermissionState();
  const { fullName, email } = cognitoUser.databaseUser || {};

  const dispatch = useDispatch();

  const signOut = async () => {
    try {
      await Auth.signOut();
      permissionDispatch({ type: "RESET" });
      dispatch({ type: "USER_LOGOUT" });
      history.push(routes.login);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DropdownMenu
      icon={<Avatar />}
      notificationOptions={{ allowNotification: false }}
      dropdownOptions={{
        offset: -15,
        arrowOffset: 22,
        offsetY: -19,
        width: "fit-content",
      }}
    >
      {cognitoUser.userGroup !== "Root" && (
        <ProfileInfo>
          <Avatar />
          <InfoBox>
            <Name>{fullName}</Name>
            <Email>{email}</Email>
          </InfoBox>
        </ProfileInfo>
      )}
      <ProfileButtons>
        {/* {cognitoUser.userGroup !== "Root" && (
          <>
            <ProfileButton to={routes.userProfile}>
              <UserIcon />
              <Text>Twój profil</Text>
            </ProfileButton>
            <ProfileButton to={routes.settings}>
              <CogIcon />
              <Text>Ustawienia</Text>
            </ProfileButton>
          </>
        )} */}
        <ProfileButton as="div" onClick={signOut}>
          <SignOutIcon />
          <Text>Wyloguj się</Text>
        </ProfileButton>
      </ProfileButtons>
    </DropdownMenu>
  );
}
