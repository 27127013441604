import React from "react";
import styled from "styled-components";
import { FilterIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "./Dropdown";
import { alphabeticallyArray } from "../../redux/reducers/tableReducer";
import { setSorting } from "../../redux/actions/tableActions";
import AddButton from "../AddButton";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
`;

const ActionsPanel = ({ addText, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const { sorting } = useSelector(({ table }) => table);

  return (
    <Container>
      <Dropdown
        icon={FilterIcon}
        data={alphabeticallyArray}
        selected={sorting.alphabetically}
        handleSelect={(payload) =>
          dispatch(setSorting({ alphabetically: payload }))
        }
      >
        Sortowanie
      </Dropdown>
      <AddButton text={addText} onClick={() => setIsSidebarOpen(true)} />
    </Container>
  );
};

export default ActionsPanel;
