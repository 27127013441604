import { useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import { Title } from "../Title";
import { Button } from "../Button";
import { TimesCircleIcon } from "react-line-awesome";

const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.confirmModalBackground};
  z-index: 60;
  overflow: hidden;
`;
const MainContentBox = styled.div`
  width: 555px;
  max-width: 94%;
  max-height: 300px;
  background-color: white;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadow.confirmModal};
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 21px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
`;
const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.font100};
  font-weight: ${({ theme }) => theme.weight.regular};
  line-height: 21px;
`;
const Close = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.color.font100};
  cursor: pointer;
`;
const Content = styled.div`
  padding: 25px 24px;
`;
const StyledTitle = styled(Title)`
  margin: 0;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 24px;
`;
const StyledButton = styled(Button)`
  width: 150px;
  height: 46px;
`;

const ConfirmModal = ({ isOpen, content, title, buttonText, action, isButtonSectionDisabled }) => {
  const modalContentBox = useRef(null);
  const modalBackground = useRef(null);

  const modalDispatch = useConfirmModalDispatch();

  // Animations
  const enterAnimation = (node, done) => {
    gsap
      .timeline()
      .from(node, {
        autoAlpha: 0,
        duration: 0.14,
        ease: "Power2.inOut",
      })
      .from(
        node.childNodes,
        {
          scale: 0.6,
          autoAlpha: 0,
          duration: 0.2,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.1"
      );
  };

  const exitAnimation = (node, done) => {
    gsap
      .timeline()
      .to(node.childNodes, {
        scale: 0.6,
        autoAlpha: 0,
        duration: 0.2,
        ease: "Power2.inOut",
      })
      .to(
        node,
        {
          autoAlpha: 0,
          duration: 0.14,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.1"
      );
  };

  const showButtonSectionModal = () => {
    if (!isButtonSectionDisabled) {
      return (
        <>
          <Buttons>
              <StyledButton
                onClick={() => {
                  action();
                  modalDispatch({ type: "CLOSE" });
                }}
              >
                {buttonText}
              </StyledButton>
              <StyledButton onClick={() => modalDispatch({ type: "CLOSE" })} dark>
                anuluj
              </StyledButton>
            </Buttons>
        </>
      );
    }

    return null;
  };

  return createPortal(
    <Transition
      timeout={340}
      mountOnEnter
      unmountOnExit
      in={isOpen}
      onEnter={(node, done) => {
        enterAnimation(node, done);
      }}
      onExit={(node, done) => {
        exitAnimation(node, done);
      }}
    >
      <ModalBackground ref={modalBackground}>
        <MainContentBox ref={modalContentBox}>
          <Header>
            <StyledTitle>{title}</StyledTitle>
            <Close onClick={() => modalDispatch({ type: "CLOSE" })}>
              <TimesCircleIcon />
            </Close>
          </Header>
          <Content>
            <Text>{content}</Text>
          </Content>
          {showButtonSectionModal()}
        </MainContentBox>
      </ModalBackground>
    </Transition>,
    document.querySelector("#confirm-modal")
  );
};

export default ConfirmModal;
