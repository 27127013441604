import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { BanIcon } from "react-line-awesome";
import TextareaAutosize from "react-textarea-autosize";

import Loader from "./Loader";

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;
const InputRelative = styled.div`
  position: relative;
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.color.font100};
  margin-bottom: 13px;
  font-size: 14px;
`;
export const InputElement = styled.input`
  width: 100%;
  background-color: ${({ theme }) => theme.color.inputFill};
  border-radius: 4px;
  padding: 18px 16px 17px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  border: 1px solid ${({ theme }) => theme.color.inputBorder};

  :disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.color.mainBackground};
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.font100};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.color.inputFill} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.color.font400};
  }

  ${({ theme, error }) =>
    error &&
    css`
      background-color: ${theme.color.inputErrorBackground};
      border-color: ${theme.color.inputErrorBorder};
    `}
`;
export const TextareaElement = styled(TextareaAutosize)`
  width: 100%;
  min-height: 54px;
  background-color: ${({ theme }) => theme.color.inputFill};
  border-radius: 4px;
  padding: 18px 16px 17px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.font400};
  font-weight: ${({ theme }) => theme.weight.medium};
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  resize: none;

  :disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.color.mainBackground};
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.font100};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.color.inputFill} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.color.font400};
  }

  ${({ theme, error }) =>
    error &&
    css`
      background-color: ${theme.color.inputErrorBackground};
      border-color: ${theme.color.inputErrorBorder};
    `}
`;
const ErrorIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 16px;
  color: ${({ theme }) => theme.color.inputErrorIcon};
  font-size: 18px;
  cursor: help;
`;
const LoaderBox = styled.div`
  position: absolute;
  display: flex;
  top: 17px;
  right: 16px;
`;
const CounterBox = styled.div`
  text-align: right;
  font-size: 12px;
  margin: 2px 2px 0 0;
  color: ${({ theme }) => theme.color.font100};
  letter-spacing: 0.5px;
`;

const Input = forwardRef(
  ({ label, error, isLoading, textarea, defaultValue, ...rest }, ref) => {
    return (
      <InputBox>
        {label && <Label>{label}</Label>}
        <InputRelative>
          {textarea ? (
            <>
              <TextareaElement
                ref={ref}
                disabled={isLoading}
                error={error}
                minRows={1}
                maxRows={10}
                defaultValue={defaultValue}
                {...rest}
              />
              <CounterBox>
                {`${defaultValue?.length || 0}/${rest.maxLength}`}
              </CounterBox>
            </>
          ) : (
            <InputElement
              ref={ref}
              disabled={isLoading}
              error={error}
              {...rest}
            />
          )}

          {isLoading && (
            <LoaderBox>
              <Loader loading={isLoading} size={18} />
            </LoaderBox>
          )}
          {error && (
            <ErrorIcon title={error}>
              <BanIcon />
            </ErrorIcon>
          )}
        </InputRelative>
      </InputBox>
    );
  }
);

export default Input;
