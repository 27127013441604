import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/roles";
import { listUsers } from "../../graphql/users";
import {
  SEARCH_ROLES_FAILURE,
  SEARCH_ROLES_STARTED,
  SEARCH_ROLES_SUCCESS,
  CREATE_ROLE_FAILURE,
  CREATE_ROLE_STARTED,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_STARTED,
  DELETE_ROLE_FAILURE,
  GET_ROLE_SUCCESS,
  GET_ROLE_STARTED,
  GET_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_STARTED,
  UPDATE_ROLE_FAILURE,
} from "../types";

const searchRolesSuccess = (roles, nextToken, total) => ({
  type: SEARCH_ROLES_SUCCESS,
  payload: {
    roles,
    nextToken,
    total,
  },
});
const searchRolesStarted = () => ({
  type: SEARCH_ROLES_STARTED,
});
const searchRolesFailure = (error) => ({
  type: SEARCH_ROLES_FAILURE,
  payload: {
    error,
  },
});

export const searchRoles = (variables) => async (dispatch) => {
  try {
    dispatch(searchRolesStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchRoles, variables)
    );
    const nextToken = data.searchRoles.nextToken;
    const total = data.searchRoles.total;
    const roles = data.searchRoles.items;

    dispatch(searchRolesSuccess(roles, nextToken, total));
  } catch (err) {
    dispatch(searchRolesFailure(err));
  }
};

const createRoleSuccess = (role) => ({
  type: CREATE_ROLE_SUCCESS,
  payload: role,
});
const createRoleStarted = () => ({
  type: CREATE_ROLE_STARTED,
});
const createRoleFailure = (error) => ({
  type: CREATE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const createRole = (input, callback) => async (dispatch) => {
  try {
    dispatch(createRoleStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.createRole, {
        input,
      })
    );
    const role = data.createRole;

    dispatch(createRoleSuccess(role));
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(createRoleFailure(err.errors));
  }
};

const deleteRoleSuccess = (role) => ({
  type: DELETE_ROLE_SUCCESS,
  payload: role,
});
const deleteRoleStarted = (id) => ({
  type: DELETE_ROLE_STARTED,
  payload: id,
});
const deleteRoleFailure = (error) => ({
  type: DELETE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch(deleteRoleStarted(id));

    const listVariables = { filter: { roleId: { eq: id } } };
    const listUsersQuery = await API.graphql(
      graphqlOperation(listUsers, listVariables)
    );
    const isListUsersEmpty = _.isEmpty(listUsersQuery.data.listUsers.items);

    if (isListUsersEmpty) {
      const { data } = await API.graphql(
        graphqlOperation(graphql.deleteRole, {
          input: { id },
        })
      );
      const role = data.deleteRole;

      dispatch(deleteRoleSuccess(role));
      toast.success(<MainToast message="Rola usunięta pomyślnie" />);
    } else {
      dispatch(deleteRoleFailure("Nie można usunąć"));
      toast.error(
        <MainToast message="Nie można usunąć, rola przypisana do użytkownika" />
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(deleteRoleFailure(err.errors));
  }
};

const getRoleSuccess = (role) => ({
  type: GET_ROLE_SUCCESS,
  payload: role,
});
const getRoleStarted = () => ({
  type: GET_ROLE_STARTED,
});
const getRoleFailure = (error) => ({
  type: GET_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const getRole = (id) => async (dispatch) => {
  try {
    dispatch(getRoleStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getRole, {
        id,
      })
    );
    const role = data.getRole;

    dispatch(getRoleSuccess(role));
  } catch (err) {
    console.log(err);
    dispatch(getRoleFailure(err.errors));
  }
};

const updateRoleSuccess = (role) => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
});
const updateRoleStarted = () => ({
  type: UPDATE_ROLE_STARTED,
});
const updateRoleFailure = (error) => ({
  type: UPDATE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const updateRole = (input) => async (dispatch) => {
  try {
    dispatch(updateRoleStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.updateRole, {
        input,
      })
    );
    const role = data.updateRole;

    dispatch(updateRoleSuccess(role));
    toast.success(<MainToast message="Zaktualizowano rolę" />);
  } catch (err) {
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(updateRoleFailure(err.errors));
  }
};
