import React from "react";
const File_big = (props) => (
    <svg width={106} height={124} {...props}>
            <g fill="none" fillRule="evenodd">
                <rect fill="#FFF2DE" width={105.4} height={124} rx={4} />
                <path
                    d="M35 39v47h36V50.92l-.506-.565-10.8-10.847L59.13 39H35zm3.6 3.615h18v10.847h10.8v28.923H38.6v-39.77zm21.6 2.599l4.612 4.632H60.2v-4.632z"
                    fill="#FFAB2C"
                    fillRule="nonzero"
                />
            </g>
        </svg>
);

export default File_big