import {
  SEARCH_ROLES_STARTED,
  SEARCH_ROLES_SUCCESS,
  SEARCH_ROLES_FAILURE,
  CREATE_ROLE_STARTED,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  DELETE_ROLE_STARTED,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_ROLE_STARTED,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  UPDATE_ROLE_STARTED,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingDelete: false,
  nextToken: null,
  roles: [],
  role: {},
  error: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ROLES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        roles: action.payload.roles,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ROLE_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        roles: [action.payload, ...state.roles],
      };
    case CREATE_ROLE_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case DELETE_ROLE_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        roles: state.roles.filter((item) => item.id !== action.payload.id),
      };
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_ROLE_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        role: action.payload,
      };
    case GET_ROLE_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_ROLE_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        roles: state.roles.map((role) =>
          role.id === action.payload.id
            ? { ...role, name: action.payload.name }
            : role
        ),
        role: action.payload,
      };
    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default roleReducer;
