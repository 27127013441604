import React, { useMemo } from "react";
import {
  CheckCircleIcon,
  DollyIcon,
  FileAltIcon,
  SlidersHIcon,
  TasksIcon,
} from "react-line-awesome";
import styled from "styled-components";

import SingleTab from "../../components/SingleTab";

import MainTemplate from "../../templates/MainTemplate";
import { Redirect, Switch } from "react-router-dom";
import { routes } from "../../routes";
import PrivateRout from "../../auth/PrivateRout";
import { functionTypes } from "../../context/permission";

import NotificationTypes from "./NotificationTypes";
import Personalization from "./Personalization";
import Fields from "./Fields";
import Products from "./Products";
import TabsPanel from "../../components/settings/TabsPanel";
import Protocols from "./Protocols";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 131px);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    flex: 1;
    height: 90%;
  }
`;
const SideTabs = styled.div`
  width: 292px;
  flex-shrink: 1;
  border-right: 1px solid ${({ theme }) => theme.color.secondaryBorder};
`;
const MainContent = styled.div`
  width: calc(100% - 292px);

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    flex: 1;
    width: auto;
  }
  @media only screen and (max-height: 400px) {
    overflow-y: auto;
    flex-direction: column;
    flex: 1;
    width: auto;
  }
`;

const Header = styled.div`
  transform: translate(0, -2.5vh);
`;

export default function Settings() {
  const isMobile = window.screen.width < 600;
  const tabs = useMemo(
    () => [
      {
        id: 1,
        tab: {
          title: "Personalizacja",
          subtitle: "Zmiana loga, języka",
          icon: SlidersHIcon,
        },
        route: routes.settings.personalization,
        content: Personalization,
      },
      {
        id: 2,
        tab: {
          title: "Typy zgłoszeń",
          subtitle: "Zarządzanie rodzajem zgłoszeń",
          icon: TasksIcon,
        },
        route: routes.settings.notificationTypes,
        content: NotificationTypes,
      },
      {
        id: 3,
        tab: {
          title: "Pola",
          subtitle: "Zarządzanie dodatkowymi polami",
          icon: CheckCircleIcon,
        },
        route: routes.settings.fields,
        content: Fields,
      },
      {
        id: 4,
        tab: {
          title: "Produkty",
          subtitle: "Zarządzaj produktami",
          icon: DollyIcon,
        },
        route: routes.settings.products,
        content: Products,
      },
      {
        id: 5,
        tab: {
          title: "Protokół odbioru",
          subtitle: "Zarządzaj polami",
          icon: FileAltIcon,
        },
        route: routes.settings.protocols,
        content: Protocols,
      },
    ],
    []
  );

  return (
    <MainTemplate title="Ustawienia">
      {isMobile && (
        <Header>
          <TabsPanel tabs={tabs} />
        </Header>
      )}
      <Container>
        {!isMobile && (
          <SideTabs>
            {tabs.map(({ id, tab, route }) => (
              <SingleTab
                key={id}
                to={route}
                activeClassName="active"
                title={tab.title}
                subtitle={tab.subtitle}
                icon={tab.icon}
              />
            ))}
          </SideTabs>
        )}
        <MainContent>
          <Switch>
            {tabs.map((tab) => (
              <PrivateRout
                key={tab.id}
                path={tab.route}
                component={tab.content}
                permission={{
                  name: "settings",
                  type: functionTypes.READ,
                }}
              />
            ))}
            <Redirect to={routes.settings.personalization} />
          </Switch>
        </MainContent>
      </Container>
    </MainTemplate>
  );
}
