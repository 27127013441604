import React from "react";
import styled from "styled-components";
import {ChevronCircleLeftIcon} from "react-line-awesome";

const ButtonGray = styled.div`
  transform: translate(0,-10px);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.font200};
  font-size: 12px;
  cursor: pointer;
  //background-color: ${({ theme }) => theme.color.white};
`;

const IconWrapper = styled.div`
    margin-left: 5px;
    color: ${({theme}) => theme.color.font200};
    font-size: 18px;
    margin-right: 2px;
`
const PathWrapper = styled.div``

const FolderPath = ({parentId, onClick}) => {

    return (
        <ButtonGray onClick={onClick}>
            {parentId}
            <IconWrapper>
                <ChevronCircleLeftIcon />
            </IconWrapper>
        </ButtonGray>
    )
}

export default FolderPath;