import React, { createContext, useReducer, useContext, useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUsersByCognitoId } from "../graphql/users";
export const functionTypes = {
  READ: "READ",
  EDIT: "EDIT",
  CREATE: "CREATE",
  DELETE: "DELETE",
};
const defaultState = {
  companies: 0,
  roles: 0,
  users: 0,
  log: 0,
  reports: 0,
  clients: 0,
  biling: 0,
  analyze: 0,
  teams: 0,
  settings: 0,
  userProfile: 0,
  library: 0,
  documents: 0,
  technicalsupport: 0,
  analyzes: 0,
  settlements: 0,
  shop: 0,
  cognitoUser: null,
};
const rootState = {
  ...defaultState,
  companies: 15,
  library: 15,
};
const adminState = {
  ...defaultState,
  roles: 15,
  users: 15,
  teams: 15,
  log: 15,
  reports: 15,
  clients: 15,
  biling: 15,
  analyze: 15,
  settings: 15,
  userProfile: 15,
  shop: 15,
  documents: 15,
  technicalsupport: 15,
  analyzes: 15,
  settlements: 15,
  library: 8,
  documents: 15,
  shop: 15,
};
const userState = {
  ...defaultState,
  library: 8,
};
const currentLoggedInUser = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const userGroupes =
      cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];
    const [userGroup] = userGroupes || [];
    if (userGroup) {
      const cognitoUserObject = { ...cognitoUser, userGroup };
      if (userGroup === "Root") {
        return { ...rootState, cognitoUser: cognitoUserObject };
      } else {
        const { data } = await API.graphql(
          graphqlOperation(getUsersByCognitoId, {
            cognitoId: cognitoUser.username,
          })
        );
        const [databaseUser] = data.getUsersByCognitoId.items;
        if (userGroup === "Admin") {
          return {
            ...adminState,
            cognitoUser: { ...cognitoUserObject, databaseUser },
          };
        } else {
          return {
            ...userState,
            ...databaseUser.role.permissions,
            cognitoUser: { ...cognitoUserObject, databaseUser },
          };
        }
      }
    }
  } catch (err) {
    console.log(err);
    return defaultState;
  }
};
const PermissionStateContext = createContext();
const PermissionDispatchContext = createContext();
const permissionReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return {
        ...action.payload,
      };
    case "SET_ROOT":
      return { ...rootState, cognitoUser: { ...action.payload } };
    case "SET_ADMIN":
      return { ...adminState, cognitoUser: { ...action.payload } };
    case "SET_USER":
      return {
        ...userState,
        ...action.payload.roles,
        cognitoUser: { ...action.payload.cognitoUser },
      };
    case "RESET":
      return defaultState;
    default:
      console.log("Błędny typ akcji");
      return state;
  }
};
export const PermissionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(permissionReducer, {});
  useEffect(() => {
    currentLoggedInUser().then((response) => {
      dispatch({ type: "SET", payload: response });
    });
  }, []);
  return (
    <PermissionDispatchContext.Provider value={dispatch}>
      <PermissionStateContext.Provider value={state}>
        {children}
      </PermissionStateContext.Provider>
    </PermissionDispatchContext.Provider>
  );
};
export const usePermissionState = () => useContext(PermissionStateContext);
export const usePermissionDispatch = () =>
  useContext(PermissionDispatchContext);
