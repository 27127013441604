import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const IconBox = styled.div`
  display: flex;
  margin-right: 18px;
  color: ${({ theme }) => theme.color.sideBarIconFont};
`;
const Text = styled.div`
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.sideBarFont};
  font-size: 14px;
  letter-spacing: 0.3px;
`;
const SideLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.primaryColor};
  opacity: 0;
`;

const DisabledNavLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
  height: 56px;
  color: ${({ theme }) => theme.color.sideBarFont};
  transition: background 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.activeNavLinkBackground};
  }
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
  height: 56px;
  color: ${({ theme }) => theme.color.sideBarFont};
  transition: background 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.color.activeNavLinkBackground};
  }

  &.active {
    background-color: ${({ theme }) => theme.color.activeNavLinkBackground};

    ${Text} {
      font-weight: ${({ theme }) => theme.weight.medium};
    }
    ${SideLine} {
      opacity: 1;
    }
    ${IconBox} {
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;

export default function NavLinkComponent({
  Icon,
  name,
  children,
  isDisabled,
  showInformationAboutNavSection,
  ...rest
}) {
  return !isDisabled ? (
    <StyledNavLink activeClassName="active" {...rest}>
      <IconBox>
        <Icon className="la-lg" />
      </IconBox>
      <Text>{children}</Text>
      <SideLine />
    </StyledNavLink>
  ) : (
    <DisabledNavLink
      onClick={() => {
        showInformationAboutNavSection(name);
      }}
    >
      <IconBox>
        <Icon className="la-lg" />
      </IconBox>
      <Text>{children}</Text>
      <SideLine />
    </DisabledNavLink>
  );
}
