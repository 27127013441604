/* ---------------------------------- Table --------------------------------- */
export const SET_LIMIT = "SET_LIMIT";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREV_PAGE = "PREV_PAGE";
export const SET_SORTING = "SET_SORTING";
export const SET_FILTER = "SET_FILTER";

/* ---------------------------------- Roles --------------------------------- */
export const SEARCH_ROLES_STARTED = "SEARCH_ROLES_STARTED";
export const SEARCH_ROLES_SUCCESS = "SEARCH_ROLES_SUCCESS";
export const SEARCH_ROLES_FAILURE = "SEARCH_ROLES_FAILURE";

export const CREATE_ROLE_STARTED = "CREATE_ROLE_STARTED";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const DELETE_ROLE_STARTED = "DELETE_ROLE_STARTED";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const GET_ROLE_STARTED = "GET_ROLE_STARTED";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

export const UPDATE_ROLE_STARTED = "UPDATE_ROLE_STARTED";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

/* -------------------------------- Companies ------------------------------- */
export const SEARCH_COMPANIES_STARTED = "SEARCH_COMPANIES_STARTED";
export const SEARCH_COMPANIES_SUCCESS = "SEARCH_COMPANIES_SUCCESS";
export const SEARCH_COMPANIES_FAILURE = "SEARCH_COMPANIES_FAILURE";

export const CREATE_COMPANY_STARTED = "CREATE_COMPANY_STARTED";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const DELETE_COMPANY_FULL_STARTED = "DELETE_COMPANY_FULL_STARTED";
export const DELETE_COMPANY_FULL_SUCCESS = "DELETE_COMPANY_FULL_SUCCESS";
export const DELETE_COMPANY_FULL_FAILURE = "DELETE_COMPANY_FULL_FAILURE";

export const GET_COMPANY_STARTED = "GET_COMPANY_STARTED";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

export const COMPANY_UPDATE_STARTED = "COMPANY_UPDATE_STARTED";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAILURE = "COMPANY_UPDATE_FAILURE";

/* ---------------------------------- Users --------------------------------- */
export const CREATE_USER_STARTED = "CREATE_USER_STARTED";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const LIST_USERS_STARTED = "LIST_USERS_STARTED";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_FAILURE = "LIST_USERS_FAILURE";

export const SEARCH_USERS_STARTED = "SEARCH_USERS_STARTED";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export const DELETE_USER_FULL_STARTED = "DELETE_USER_FULL_STARTED";
export const DELETE_USER_FULL_SUCCESS = "DELETE_USER_FULL_SUCCESS";
export const DELETE_USER_FULL_FAILURE = "DELETE_USER_FULL_FAILURE";

export const GET_USER_STARTED = "GET_USER_STARTED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

/* -------------------------- Settings -> Products -------------------------- */
export const LIST_PRODUCTS_STARTED = "LIST_PRODUCTS_STARTED";
export const LIST_PRODUCTS_SUCCESS = "LIST_PRODUCTS_SUCCESS";
export const LIST_PRODUCTS_FAILURE = "LIST_PRODUCTS_FAILURE";

export const CREATE_PRODUCT_STARTED = "CREATE_PRODUCT_STARTED";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_STARTED = "DELETE_PRODUCT_STARTED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_STARTED = "UPDATE_PRODUCT_STARTED";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const GET_PRODUCT_STARTED = "GET_PRODUCT_STARTED";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

/* -------------------------- Settings -> Notification types -------------------------- */
export const CREATE_TYPE_ITEM_STARTED = "CREATE_TYPE_ITEM_STARTED";
export const CREATE_TYPE_ITEM_SUCCESS = "CREATE_TYPE_ITEM_SUCCESS";
export const CREATE_TYPE_ITEM_FAILURE = "CREATE_TYPE_ITEM_FAILURE";

export const LIST_TYPE_ITEMS_STARTED = "LIST_TYPE_ITEMS_STARTED";
export const LIST_TYPE_ITEMS_SUCCESS = "LIST_TYPE_ITEMS_SUCCESS";
export const LIST_TYPE_ITEMS_FAILURE = "LIST_TYPE_ITEMS_FAILURE";

export const GET_TYPE_ITEM_STARTED = "GET_TYPE_ITEM_STARTED";
export const GET_TYPE_ITEM_SUCCESS = "GET_TYPE_ITEM_SUCCESS";
export const GET_TYPE_ITEM_FAILURE = "GET_TYPE_ITEM_FAILURE";

export const UPDATE_TYPE_ITEM_STARTED = "UPDATE_TYPE_ITEM_STARTED";
export const UPDATE_TYPE_ITEM_SUCCESS = "UPDATE_TYPE_ITEM_SUCCESS";
export const UPDATE_TYPE_ITEM_FAILURE = "UPDATE_TYPE_ITEM_FAILURE";

export const DELETE_TYPE_ITEM_STARTED = "DELETE_TYPE_ITEM_STARTED";
export const DELETE_TYPE_ITEM_SUCCESS = "DELETE_TYPE_ITEM_SUCCESS";
export const DELETE_TYPE_ITEM_FAILURE = "DELETE_TYPE_ITEM_FAILURE";

/* -------------------------- Settings -> Field types -------------------------- */
export const CREATE_FIELD_ITEM_STARTED = "CREATE_FIELD_ITEM_STARTED";
export const CREATE_FIELD_ITEM_SUCCESS = "CREATE_FIELD_ITEM_SUCCESS";
export const CREATE_FIELD_ITEM_FAILURE = "CREATE_FIELD_ITEM_FAILURE";

export const LIST_FIELD_ITEMS_STARTED = "LIST_FIELD_ITEMS_STARTED";
export const LIST_FIELD_ITEMS_SUCCESS = "LIST_FIELD_ITEMS_SUCCESS";
export const LIST_FIELD_ITEMS_FAILURE = "LIST_FIELD_ITEMS_FAILURE";

export const GET_FIELD_ITEM_STARTED = "GET_FIELD_ITEM_STARTED";
export const GET_FIELD_ITEM_SUCCESS = "GET_FIELD_ITEM_SUCCESS";
export const GET_FIELD_ITEM_FAILURE = "GET_FIELD_ITEM_FAILURE";

export const UPDATE_FIELD_ITEM_STARTED = "UPDATE_FIELD_ITEM_STARTED";
export const UPDATE_FIELD_ITEM_SUCCESS = "UPDATE_FIELD_ITEM_SUCCESS";
export const UPDATE_FIELD_ITEM_FAILURE = "UPDATE_FIELD_ITEM_FAILURE";

export const DELETE_FIELD_ITEM_STARTED = "DELETE_FIELD_ITEM_STARTED";
export const DELETE_FIELD_ITEM_SUCCESS = "DELETE_FIELD_ITEM_SUCCESS";
export const DELETE_FIELD_ITEM_FAILURE = "DELETE_FIELD_ITEM_FAILURE";

/* -------------------------- Settings -> Protocols -------------------------- */
export const CREATE_PROTOCOL_STARTED = "CREATE_PROTOCOL_STARTED";
export const CREATE_PROTOCOL_SUCCESS = "CREATE_PROTOCOL_SUCCESS";
export const CREATE_PROTOCOL_FAILURE = "CREATE_PROTOCOL_FAILURE";

export const LIST_PROTOCOLS_STARTED = "LIST_PROTOCOLS_STARTED";
export const LIST_PROTOCOLS_SUCCESS = "LIST_PROTOCOLS_SUCCESS";
export const LIST_PROTOCOLS_FAILURE = "LIST_PROTOCOLS_FAILURE";

export const GET_PROTOCOL_STARTED = "GET_PROTOCOL_STARTED";
export const GET_PROTOCOL_SUCCESS = "GET_PROTOCOL_SUCCESS";
export const GET_PROTOCOL_FAILURE = "GET_PROTOCOL_FAILURE";

export const UPDATE_PROTOCOL_STARTED = "UPDATE_PROTOCOL_STARTED";
export const UPDATE_PROTOCOL_SUCCESS = "UPDATE_PROTOCOL_SUCCESS";
export const UPDATE_PROTOCOL_FAILURE = "UPDATE_PROTOCOL_FAILURE";

export const DELETE_PROTOCOL_STARTED = "DELETE_PROTOCOL_STARTED";
export const DELETE_PROTOCOL_SUCCESS = "DELETE_PROTOCOL_SUCCESS";
export const DELETE_PROTOCOL_FAILURE = "DELETE_PROTOCOL_FAILURE";

/* -------------------------- Teams -------------------------- */
export const SEARCH_TEAMS_STARTED = "SEARCH_TEAMS_STARTED";
export const SEARCH_TEAMS_SUCCESS = "SEARCH_TEAMS_SUCCESS";
export const SEARCH_TEAMS_FAILURE = "SEARCH_TEAMS_FAILURE";

export const CREATE_TEAM_STARTED = "CREATE_TEAM_STARTED";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";

export const DELETE_TEAM_STARTED = "DELETE_TEAM_STARTED";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const GET_TEAM_STARTED = "GET_TEAM_STARTED";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

export const UPDATE_TEAM_STARTED = "UPDATE_TEAM_STARTED";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAILURE = "UPDATE_TEAM_FAILURE";

/* -------------------------- Clients -------------------------- */
export const SEARCH_CLIENTS_STARTED = "SEARCH_CLIENTS_STARTED";
export const SEARCH_CLIENTS_SUCCESS = "SEARCH_CLIENTS_SUCCESS";
export const SEARCH_CLIENTS_FAILURE = "SEARCH_CLIENTS_FAILURE";

export const LIST_CLIENTS_STARTED = "LIST_CLIENTS_STARTED";
export const LIST_CLIENTS_SUCCESS = "LIST_CLIENTS_SUCCESS";
export const LIST_CLIENTS_FAILURE = "LIST_CLIENTS_FAILURE";

export const CREATE_CLIENT_STARTED = "CREATE_CLIENT_STARTED";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

export const DELETE_CLIENT_STARTED = "DELETE_CLIENT_STARTED";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const GET_CLIENT_STARTED = "GET_CLIENT_STARTED";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const UPDATE_CLIENT_STARTED = "UPDATE_CLIENT_STARTED";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

/* -------------------------- Buildings -------------------------- */
export const LIST_BUILDINGS_STARTED = "LIST_BUILDINGS_STARTED";
export const LIST_BUILDINGS_SUCCESS = "LIST_BUILDINGS_SUCCESS";
export const LIST_BUILDINGS_FAILURE = "LIST_BUILDINGS_FAILURE";

export const CREATE_BUILDING_STARTED = "CREATE_BUILDING_STARTED";
export const CREATE_BUILDING_SUCCESS = "CREATE_BUILDING_SUCCESS";
export const CREATE_BUILDING_FAILURE = "CREATE_BUILDING_FAILURE";

export const DELETE_BUILDING_STARTED = "DELETE_BUILDING_STARTED";
export const DELETE_BUILDING_SUCCESS = "DELETE_BUILDING_SUCCESS";
export const DELETE_BUILDING_FAILURE = "DELETE_BUILDING_FAILURE";

export const GET_BUILDING_STARTED = "GET_BUILDING_STARTED";
export const GET_BUILDING_SUCCESS = "GET_BUILDING_SUCCESS";
export const GET_BUILDING_FAILURE = "GET_BUILDING_FAILURE";

export const UPDATE_BUILDING_STARTED = "UPDATE_BUILDING_STARTED";
export const UPDATE_BUILDING_SUCCESS = "UPDATE_BUILDING_SUCCESS";
export const UPDATE_BUILDING_FAILURE = "UPDATE_BUILDING_FAILURE";

/* -------------------------- Tickets -------------------------- */
export const SEARCH_TICKETS_STARTED = "SEARCH_TICKETS_STARTED";
export const SEARCH_TICKETS_SUCCESS = "SEARCH_TICKETS_SUCCESS";
export const SEARCH_TICKETS_FAILURE = "SEARCH_TICKETS_FAILURE";

export const LIST_TICKETS_STARTED = "LIST_TICKETS_STARTED";
export const LIST_TICKETS_SUCCESS = "LIST_TICKETS_SUCCESS";
export const LIST_TICKETS_FAILURE = "LIST_TICKETS_FAILURE";

export const CREATE_TICKET_STARTED = "CREATE_TICKET_STARTED";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

export const DELETE_TICKET_STARTED = "DELETE_TICKET_STARTED";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILURE = "DELETE_TICKET_FAILURE";

export const GET_TICKET_STARTED = "GET_TICKET_STARTED";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAILURE = "GET_TICKET_FAILURE";

export const UPDATE_TICKET_STARTED = "UPDATE_TICKET_STARTED";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILURE = "UPDATE_TICKET_FAILURE";

/* -------------------------- Tickets For Log -------------------------- */

export const SEARCH_TICKETS_LOG_STARTED = "SEARCH_TICKETS_LOG_STARTED";
export const SEARCH_TICKETS_LOG_SUCCESS = "SEARCH_TICKETS_LOG_SUCCESS";
export const SEARCH_TICKETS_LOG_FAILURE = "SEARCH_TICKETS_LOG_FAILURE";

export const SEARCH_LIST_STATUS_STARTED = "SEARCH_LIST_STATUS_STARTED";
export const SEARCH_LIST_STATUS_SUCCESS = "SEARCH_LIST_STATUS_SUCCESS";
export const SEARCH_LIST_STATUS_FAILURE = "SEARCH_LIST_STATUS_FAILURE";

export const SEARCH_REPORTS_PENDING_STARTED = "SEARCH_REPORTS_PENDING_STARTED";
export const SEARCH_REPORTS_PENDING_SUCCESS = "SEARCH_REPORTS_PENDING_SUCCESS";
export const SEARCH_REPORTS_PENDING_FAILURE = "SEARCH_REPORTS_PENDING_FAILURE";

export const SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED = "SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED";
export const SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS = "SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS";
export const SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE = "SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE";

export const UPDATE_STATUS_TICKET_STARTED = "UPDATE_STATUS_TICKET_STARTED";
export const UPDATE_STATUS_TICKET_SUCCESS = "UPDATE_STATUS_TICKET_SUCCESS";
export const UPDATE_STATUS_TICKET_FAILURE = "UPDATE_STATUS_TICKET_FAILURE";

/* -------------------------- Files -------------------------- */
export const SEARCH_ROOTFILES_STARTED = "SEARCH_ROOTFILES_STARTED";
export const SEARCH_ROOTFILES_SUCCESS = "SEARCH_ROOTFILES_SUCCESS";
export const SEARCH_ROOTFILES_FAILURE = "SEARCH_ROOTFILES_FAILURE";

export const LIST_ROOTFILES_STARTED = "LIST_ROOTFILES_STARTED";
export const LIST_ROOTFILES_SUCCESS = "LIST_ROOTFILES_SUCCESS";
export const LIST_ROOTFILES_FAILURE = "LIST_ROOTFILES_FAILURE";

export const CREATE_ROOTFILE_STARTED = "CREATE_ROOTFILE_STARTED";
export const CREATE_ROOTFILE_SUCCESS = "CREATE_ROOTFILE_SUCCESS";
export const CREATE_ROOTFILE_FAILURE = "CREATE_ROOTFILE_FAILURE";

export const DELETE_ROOTFILE_STARTED = "DELETE_ROOTFILE_STARTED";
export const DELETE_ROOTFILE_SUCCESS = "DELETE_ROOTFILE_SUCCESS";
export const DELETE_ROOTFILE_FAILURE = "DELETE_ROOTFILE_FAILURE";

export const GET_ROOTFILE_STARTED = "GET_ROOTFILE_STARTED";
export const GET_ROOTFILE_SUCCESS = "GET_ROOTFILE_SUCCESS";
export const GET_ROOTFILE_FAILURE = "GET_ROOTFILE_FAILURE";

export const SET_PARENT = "SET_PARENT";
