import styled from "styled-components";
import { device } from './mediaQueries';

export const LogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.font200};
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

`;

export const SectionHeader = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 18px;
    margin-bottom: 24px;

`;

export const SummaryInformationSection = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.largeDevices} {
        flex-direction: row;
    }
`;

export const NumbersOfReportsOfAGivenTypeBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    width: 100%;
    min-width: 230px;
    max-width: 230px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    margin: 0 32px 32px 0;
    cursor: pointer;

    @media ${device.largeDevices} {
        margin-bottom: 0;
        max-width: none;
    }
`;

export const NumbersOfReports = styled.span`
    font-size: 26px;
    color: ${({ theme }) => theme.color.black};
`;

export const ReportStatus = styled.span`
    font-size: 14px;
    margin-top: 8px;
`;

export const WeatherWidgetBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 360px;
    min-width: 360px;
    height: 97px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
`;

export const TasksContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 32px;
`;

export const TasksListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    height: 687px;
`;

export const TasksList = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 32px;
    border-top: 1px RGB(242, 244, 246) solid;
`;

export const AllReports = styled.span`
    font-size: 12px;
    font-weight: ${({ theme }) => theme.weight.medium};
    text-transform: uppercase;
    cursor: pointer;
    margin-left: auto;
`;

export const TasksListAndCalendarContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @media ${device.extraDevices} {
        flex-direction: row;
    }
`;

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 360px;
    min-width: 360px;
`;

export const CalendarAndDraftReportsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 24px 24px 0 24px;

    @media ${device.extraDevices} {
        height: 100%;
        margin-bottom: 0;
    }
`;

export const TasksListHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px 16px;
    
    @media ${device.largeDevices} {
        flex-direction: row;
        padding-bottom: 32px;
    }
`;

export const TaskListButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media ${device.largeDevices} {
        margin-top: 0;
    }
`;

export const TasksListHeaderDate = styled.span`
    font-size: 20px;
    font-weight: ${({ theme }) => theme.weight.medium};
    color: ${({ theme }) => theme.color.black};

    ::first-letter {
        text-transform: capitalize;
    }
`;

export const NoDataInformation = styled.span`
    display: flex;
    width: 100%;
    margin-top: 48px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.black};
`;

export const WeatherWidgetLStatisticsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WeatherWidgetCityName = styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.color.black};
`;

export const WeatherWidgetTemperatureAltitude = styled.span`
    font-size: 26px;
    color: ${({ theme }) => theme.color.black};
    margin-top: 8px;
`;

export const WeatherWidgetInformation = styled.span`
    font-size: 14px;
`;

export const WeatherWidgetStatusTemperature = styled(WeatherWidgetInformation)`
    text-align: right;
    margin-bottom: 8px;
`;

export const WeatherWidgetIcon = styled.div`
    font-size: 54px;
    color: ${({ theme }) => theme.color.primaryYellow};
`;

export const ArrowButtonContainer = styled.div`
    display: flex;
    margin-left: 16px;
`;



