import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";

import { Button } from "../../Button";
import Submit from "../../Submit";
import FormFields from "./FormFields";
import {
  getBuilding,
  updateBuilding,
} from "../../../redux/actions/buildingActions";

const language = "PL";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditBuilding = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const buildingState = useSelector(({ building }) => building);

  useEffect(() => {
    dispatch(getBuilding(id));
  }, [id, dispatch]);

  useEffect(() => {
    Object.entries(buildingState.building).forEach(([name, value]) => {
      if (name === "phone")
        setValue(name, parsePhoneNumber(value, language).nationalNumber);
      else setValue(name, value);
    });
  }, [buildingState.building, setValue]);

  const onSubmit = (data) => {
    const { location } = data.address?.geometry || {};
    const variables = {
      ...data,
      address: data.address.formatted_address || data.address,
      latitude: location?.lat,
      longitude: location?.lng,
      id,
    };
    if (!location) {
      delete variables.latitude;
      delete variables.longitude;
    }

    dispatch(updateBuilding(variables));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={buildingState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={buildingState.loadingGet}
            loader={buildingState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditBuilding;
