import React from "react";

export default function ExclamationMark({ width = 11, fill = "#FFFFFF" }) {
  return (
    <svg
      width={`${width}px`}
      //   viewBox="0 0 11 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.63 7.043a.272.272 0 0 0 .217-.107.379.379 0 0 0 .09-.25V.357c0-.105-.03-.19-.09-.257a.28.28 0 0 0-.216-.1H.382a.293.293 0 0 0-.23.1.372.372 0 0 0-.088.257v6.329c0 .095.03.178.089.25.06.071.136.107.23.107H1.63zM1.683 10c.093 0 .17-.033.229-.1A.372.372 0 0 0 2 9.643V8.1a.379.379 0 0 0-.09-.25.285.285 0 0 0-.228-.107H.306a.272.272 0 0 0-.217.107A.379.379 0 0 0 0 8.1v1.543c0 .105.03.19.09.257.059.067.13.1.216.1h1.376z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
