import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";

import AuthorizationTemplate from "../../templates/AuthorizationTemplate";

import { usePermissionDispatch } from "../../context/permission";
import { Title } from "../../components/Title";
import Input from "../../components/Input";
import Submit from "../../components/Submit";
import Checkbox from "../../components/Checkbox";
import { getFirstAvailableRoute, routes } from "../../routes";
import { emailReg } from "../../util/reg";
import Step3 from "./recovery/Step3";
import { getUsersByCognitoId } from "../../graphql/users";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.textLink};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.medium};
`;
const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 26px 0 24px;
`;
const Form = styled.form`
  min-width: 360px;
`;

export default function Login({ location }) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [isForcePassword, setIsForcePassword] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    setError,
  } = useForm({ defaultValues: { checkbox: false } });
  const forcePasswordForm = useForm();

  useEffect(() => {
    if (location?.state) {
      setValue("email", location.state.email);
    }
  }, [setValue, location]);

  const permissionDispatch = usePermissionDispatch();
  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      setLoading(true);
      const cognitoUser = await Auth.signIn(email, password);

      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setLoggedUser(cognitoUser);
        setIsForcePassword(true);
        setLoading(false);
      } else {
        const userGroups =
          cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];

        if (userGroups) {
          const { data } = await API.graphql(
            graphqlOperation(getUsersByCognitoId, {
              cognitoId: cognitoUser.username,
            })
          );
          const [databaseUser] = data.getUsersByCognitoId.items;
          const [userGroup] = userGroups;
          const cognitoUserObject = { ...cognitoUser, userGroup };

          if (userGroup === "Root") {
            permissionDispatch({
              type: "SET_ROOT",
              payload: cognitoUserObject,
            });
            history.push(routes.companies);
          } else if (userGroup === "Admin") {
            permissionDispatch({
              type: "SET_ADMIN",
              payload: { ...cognitoUserObject, databaseUser },
            });
            history.push(routes.log);
          } else if (userGroup === "User") {
            permissionDispatch({
              type: "SET_USER",
              payload: {
                roles: databaseUser.role.permissions,
                cognitoUser: { ...cognitoUserObject, databaseUser },
              },
            });
            history.push(getFirstAvailableRoute(databaseUser.role.permissions));
          }
        } else {
          await Auth.signOut();
          setError("password", {
            type: "manual",
            message: "Użytkownik nie jest przypisany do grupy",
          });
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("password", {
        type: "manual",
        message: "Nieprawidłowy email lub hasło",
      });
    }
  };

  const onForceSubmit = async (data) => {
    try {
      const { password } = data;
      await Auth.completeNewPassword(loggedUser, password);
      setIsForcePassword(false);
      setValue("password", "");
    } catch (err) {
      if (err.code === "InvalidPasswordException") {
        toast.error(<MainToast message="Za krótkie hasło" />);
      } else if (err.code === "NotAuthorizedException") {
        toast.error(<MainToast message="Sesja wygasła" />);
        setIsForcePassword(false);
      }
      console.log(err);
    }
  };

  if (isForcePassword) {
    return (
      <AuthorizationTemplate title="Zmiana hasła - ROTO">
        <Step3
          recoveryForm={forcePasswordForm}
          controlledSubmit={onForceSubmit}
        />
      </AuthorizationTemplate>
    );
  } else {
    return (
      <AuthorizationTemplate title="Logowanie - ROTO">
        <Title>Logowanie</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Twój e-mail"
            placeholder="Wprowadź swój adres e-mail"
            error={errors?.email?.message}
            {...register("email", {
              required: { value: true, message: "Pole nie może być puste" },
              pattern: {
                value: emailReg,
                message: "Podaj poprawny adres email",
              },
            })}
          />
          <Input
            label="Hasło"
            placeholder="Wprowadź swoje hasło"
            type="password"
            error={errors?.password?.message}
            {...register("password", {
              required: { value: true, message: "Pole nie może być puste" },
            })}
          />
          <RowBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Checkbox htmlFor="checkbox" {...rest}>
                  Zapamiętaj mnie
                </Checkbox>
              )}
              control={control}
              name="checkbox"
            />
            <StyledLink to={routes.recovery}>Odzyskaj hasło</StyledLink>
          </RowBox>
          <Submit value="zaloguj się" isHeight loader={loading} />
        </Form>
      </AuthorizationTemplate>
    );
  }
}
