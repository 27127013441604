import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";

import { Title } from "../../components/Title";
import PersonalizationFormFields from "../../components/settings/PersonalizationFormFields";
import Submit from "../../components/Submit";
import { useDispatch, useSelector } from "react-redux";
import { companyUpdate, getCompany } from "../../redux/actions/companyActions";
import { usePermissionState } from "../../context/permission";
import { languageList } from "../../util/variables";
import usePermissionObject from "../../hooks/usePermissionObject";

const Container = styled.div`
  padding: 31px 29px 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 32px;
  font-size: 20px;
`;
const ButtonBox = styled.div`
  margin-top: 26px;
  width: 150px;
`;

const Personalization = () => {
  const permissionObject = usePermissionObject("settings");

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const {
    cognitoUser: {
      databaseUser: { companyId },
    },
  } = usePermissionState();
  const companyState = useSelector((state) => state.company);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompany(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    const { logo, language } = companyState.company;
    setValue("logo", logo);

    const [languageObject] = languageList.filter(
      (item) => item.value === language
    );
    setValue("language", languageObject);
  }, [companyState.company, setValue]);

  const onSubmit = (data) => {
    dispatch(
      companyUpdate({
        ...data,
        id: companyId,
        language: data.language.value,
      })
    );
  };

  return (
    <Container disabled={!permissionObject.edit}>
      <StyledTitle>Personalizacja</StyledTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalizationFormFields
          form={form}
          loading={companyState.loadingGet}
        />
        {permissionObject.edit && (
          <ButtonBox>
            <Submit
              value="Zaktualizuj"
              loader={companyState.loadingUpdate}
              disabled={companyState.loadingGet}
            />
          </ButtonBox>
        )}
      </form>
    </Container>
  );
};

export default Personalization;
