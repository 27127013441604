import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import {
  fileTypesTranslation,
  getTicket,
  updateTicket,
} from "../../redux/actions/ticketActions";
import { isEmpty } from "lodash";
import { usePermissionState } from "../../context/permission";
import { baseS3Url, getKeyByValue } from "../../helpers/functions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

function responseToDropdownFormat(data) {
  const { id, name } = data || {};
  return { name, value: id };
}

const EditReport = ({ setIsOpen, data }) => {
  const { id, typeItem } = data;
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const ticketState = useSelector(({ ticket }) => ticket);
  const { loadingUpdate, loadingGet } = ticketState;

  const { cognitoUser } = usePermissionState();
  const { companyId } = cognitoUser.databaseUser;

  useEffect(() => {
    dispatch(getTicket(String(id)));
  }, [id, dispatch]);

  useEffect(() => {
    if (isEmpty(ticketState.ticket)) return;
    const {
      status,
      type,
      team,
      startDate,
      deadline,
      latitude,
      longitude,
      address,
      client,
      building,
      notes,
      description,
      files,
      specification,
      fieldItems,
    } = ticketState.ticket;

    setValue("status", status);
    setValue("type", { ...responseToDropdownFormat(type) });
    setValue("team", responseToDropdownFormat(team));
    setValue("startDate", startDate && new Date(startDate));
    setValue("deadline", deadline && new Date(deadline));

    const clientData = {
      ...responseToDropdownFormat(client),
      name: client?.name,
      phoneNumber: client?.phoneNumber,
      force: true,
      latitude: client?.latitude,
      longitude: client?.longitude,
    };
    setValue("client", clientData);
    setValue("notes", notes);
    setValue("description", description);

    if (building) {
      setValue("building", {
        name: building?.name,
        value: building?.id,
        latitude: building?.latitude,
        longitude: building?.longitude,
      });
    } else if (address) {
      setValue("building", {
        name: address,
        location: { lat: latitude, lng: longitude },
        googleMethod: true,
      });
    }

    const filesObject = files?.items.reduce((acc, { ref, type }) => {
      const url = `${baseS3Url}${ref}`;
      if (acc[getKeyByValue(fileTypesTranslation, type)]) {
        acc[getKeyByValue(fileTypesTranslation, type)].push(url);
      } else {
        acc = { ...acc, [getKeyByValue(fileTypesTranslation, type)]: [] };
        acc[getKeyByValue(fileTypesTranslation, type)].push(url);
      }
      return acc;
    }, {});

    if (filesObject) {
      for (let [key, value] of Object.entries(filesObject)) {
        setValue(key, value);
      }
    }

    specification?.forEach((item) => {
      if (item.product) {
        setValue(`product.${item.product.id}`, item.value);
      }
    });

    fieldItems?.items?.forEach((item) => {
      setValue(`ticketFieldItem.${item.fieldItem.id}`, {
        name: item.selectedField?.name,
        value: item.selectedField?.id,
      });
    });
  }, [ticketState.ticket, setValue, loadingGet]);

  const onSubmit = (data) => {
    const {
      building,
      client,
      team,
      startDate,
      deadline,
      type,
      description,
      notes,
      measurementCard,
      attachments,
      customerOrder,
      producerOrder,
      photos,
    } = data;

    let specification = [];
    if (data.product) {
      for (let [key, value] of Object.entries(data.product)) {
        if (typeof value === "number" && !isNaN(value)) {
          specification = [...specification, { id: key, value }];
        }
      }
    }

    let ticketFieldItems = [];
    if (data.ticketFieldItem) {
      for (let [key, value] of Object.entries(data.ticketFieldItem)) {
        ticketFieldItems = [
          ...ticketFieldItems,
          { fieldItemId: key, fieldId: value.value, name: value.name },
        ];
      }
    }

    const variables = {
      input: {
        id: ticketState.ticket.id,
        companyId,
        buildingId: building.location
          ? null
          : building?.id || building?.value || null,
        latitude: building.location ? building.location.lat : null,
        longitude: building.location ? building.location.lng : null,
        address: building.name ? building.name : null,
        clientId: client?.id || client?.value,
        teamId: team?.value || null,
        typeItemId: typeItem.id,
        typeId: type.value,
        startDate,
        deadline,
        notes,
        description,
      },
      rest: {
        specifications: specification.map((item) => ({
          productId: item.id,
          value: item.value,
        })),
        fieldItems: ticketFieldItems.map(({ fieldItemId, fieldId }) => ({
          fieldItemId,
          selectedFieldId: fieldId,
        })),
        measurementCard,
        attachments,
        customerOrder,
        producerOrder,
        photos,
      },
    };

    dispatch(updateTicket(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields
        form={form}
        loading={loadingGet}
        typeItem={typeItem}
        ticketId={id}
        setIsOpen={setIsOpen}
      />
      <Buttons>
        <ButtonBox>
          <Submit value="zapisz" loader={loadingUpdate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditReport;
