import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../../components/toastify/MainToast";

import * as graphql from "../../../graphql/settings/protocols";
import {
  LIST_PROTOCOLS_STARTED,
  LIST_PROTOCOLS_SUCCESS,
  LIST_PROTOCOLS_FAILURE,
  CREATE_PROTOCOL_STARTED,
  CREATE_PROTOCOL_SUCCESS,
  CREATE_PROTOCOL_FAILURE,
  DELETE_PROTOCOL_STARTED,
  DELETE_PROTOCOL_SUCCESS,
  DELETE_PROTOCOL_FAILURE,
  UPDATE_PROTOCOL_STARTED,
  UPDATE_PROTOCOL_SUCCESS,
  UPDATE_PROTOCOL_FAILURE,
  GET_PROTOCOL_STARTED,
  GET_PROTOCOL_SUCCESS,
  GET_PROTOCOL_FAILURE,
} from "../../types";

/* --------------------------- Pobiranie protokołu --------------------------- */
const getProtocolSuccess = (protocol) => ({
  type: GET_PROTOCOL_SUCCESS,
  payload: protocol,
});
const getProtocolStarted = () => ({
  type: GET_PROTOCOL_STARTED,
});
const getProtocolFailure = (error) => ({
  type: GET_PROTOCOL_FAILURE,
  payload: {
    error,
  },
});

export const getProtocol = (id) => async (dispatch) => {
  try {
    dispatch(getProtocolStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getSection, { id })
    );

    const protocol = data.getSection;

    dispatch(getProtocolSuccess(protocol));
  } catch (err) {
    dispatch(getProtocolFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    console.log(err);
  }
};

/* -------------------------- Listowanie protokołów -------------------------- */
const listProtocolsSuccess = (protocols) => ({
  type: LIST_PROTOCOLS_SUCCESS,
  payload: {
    protocols,
  },
});
const listProtocolsStarted = () => ({
  type: LIST_PROTOCOLS_STARTED,
});
const listProtocolsFailure = (error) => ({
  type: LIST_PROTOCOLS_FAILURE,
  payload: {
    error,
  },
});

export const listProtocols = (variables) => async (dispatch) => {
  try {
    dispatch(listProtocolsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.listSections, variables)
    );

    const protocols = data.listSections.items;

    dispatch(listProtocolsSuccess(protocols));
  } catch (err) {
    dispatch(listProtocolsFailure(err));
  }
};

/* --------------------------- Dodawanie protokołu --------------------------- */
const createProtocolSuccess = (protocol) => ({
  type: CREATE_PROTOCOL_SUCCESS,
  payload: {
    protocol,
  },
});
const createProtocolStarted = () => ({
  type: CREATE_PROTOCOL_STARTED,
});
const createProtocolFailure = (error) => ({
  type: CREATE_PROTOCOL_FAILURE,
  payload: {
    error,
  },
});

export const createProtocol = (input, callback) => async (dispatch) => {
  try {
    dispatch(createProtocolStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.createSection, { input })
    );

    const protocol = data.createSection;

    dispatch(createProtocolSuccess(protocol));
    toast.success(<MainToast message="Protokół dodany pomyślnie" />);
    callback && callback();
  } catch (err) {
    dispatch(createProtocolFailure(err));
    console.error(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};

/* ---------------------------- Usuwnaie protokołu --------------------------- */
const deleteProtocolSuccess = (id) => ({
  type: DELETE_PROTOCOL_SUCCESS,
  payload: id,
});
const deleteProtocolStarted = (id) => ({
  type: DELETE_PROTOCOL_STARTED,
  payload: id,
});
const deleteProtocolFailure = (error) => ({
  type: DELETE_PROTOCOL_FAILURE,
  payload: {
    error,
  },
});

export const deleteProtocol = (id) => async (dispatch) => {
  try {
    dispatch(deleteProtocolStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteSection, { input: { id } })
    );

    dispatch(deleteProtocolSuccess(id));
    toast.success(<MainToast message="Protokół usunięty pomyślnie" />);
  } catch (err) {
    dispatch(deleteProtocolFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};

/* ---------------------------- Edycja protokołu --------------------------- */
const updateProtocolSuccess = (protocol) => ({
  type: UPDATE_PROTOCOL_SUCCESS,
  payload: protocol,
});
const updateProtocolStarted = () => ({
  type: UPDATE_PROTOCOL_STARTED,
});
const updateProtocolFailure = (error) => ({
  type: UPDATE_PROTOCOL_FAILURE,
  payload: {
    error,
  },
});

export const updateProtocol = (input) => async (dispatch) => {
  try {
    dispatch(updateProtocolStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.updateSection, { input })
    );

    const protocol = data.updateSection;
    dispatch(updateProtocolSuccess(protocol));
    toast.success(<MainToast message="Zapisano pomyślnie" />);
  } catch (err) {
    dispatch(updateProtocolFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};
