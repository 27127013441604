import {
  SEARCH_TICKETS_STARTED,
  SEARCH_TICKETS_SUCCESS,
  SEARCH_TICKETS_FAILURE,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  GET_TICKET_STARTED,
  GET_TICKET_SUCCESS,
  GET_TICKET_FAILURE,
  UPDATE_TICKET_STARTED,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILURE,
  UPDATE_STATUS_TICKET_STARTED,
  UPDATE_STATUS_TICKET_SUCCESS,
  UPDATE_STATUS_TICKET_FAILURE,
  DELETE_TICKET_STARTED,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  tickets: [],
  ticket: {},
  error: null,
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TICKET_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        tickets: [action.payload, ...state.tickets],
      };
    case CREATE_TICKET_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case SEARCH_TICKETS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        tickets: action.payload.tickets,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_STATUS_TICKET_STARTED:
      return {
        ...state,
        // loading: true,
      };
    case UPDATE_STATUS_TICKET_SUCCESS:
      return {
        ...state,
        // loading: false,
        error: null,
        tickets: state.tickets.map((ticket) => {
          return ticket.id === action.payload.id ? action.payload : ticket;
        }),
        ticket: action.payload,
      };
    case UPDATE_STATUS_TICKET_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.payload.error,
      };

    // case LIST_TICKETS_STARTED:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case LIST_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     tickets: action.payload,
    //   };
    // case LIST_TICKETS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //   };

    case DELETE_TICKET_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        tickets: state.tickets.filter((item) => item.id !== action.payload),
      };
    case DELETE_TICKET_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_TICKET_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_TICKET_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        ticket: action.payload,
      };
    case GET_TICKET_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_TICKET_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        tickets: state.tickets.map((ticket) => {
          return ticket.id === action.payload.id ? action.payload : ticket;
        }),
        ticket: action.payload,
      };
    case UPDATE_TICKET_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default ticketReducer;
