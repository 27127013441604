import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Button } from "../../Button";
import Submit from "../../Submit";
import {
  getTypeItem,
  updateTypeItem,
} from "../../../redux/actions/settings/notificationTypeActions";
import FormFields from "./FormFields";
import { typeIconsList } from "../../icons/typeIconsList";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditTypeItem = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const typeItemState = useSelector(({ typeItem }) => typeItem);

  useEffect(() => {
    dispatch(getTypeItem(id));
  }, [id, dispatch]);

  useEffect(() => {
    const currentItem = typeItemState.typeItem;

    if (!_.isEmpty(currentItem)) {
      const { name } = currentItem;
      const types = currentItem.types.items.reduce((acc, item) => {
        const element = { id: item.type.id, name: item.type.name };

        return [...acc, element];
      }, []);

      setValue("name", name);
      setValue("types", types);
      setValue(
        "icon",
        typeIconsList.find(({ value }) => value === currentItem.icon)
      );
      setValue("specification", currentItem.specification);
      setValue("attachments", currentItem.attachments);
      setValue("producerOrder", currentItem.producerOrder);
      setValue("customerOrder", currentItem.customerOrder);
      setValue("description", currentItem.description);
      setValue("measurementCard", currentItem.measurementCard);
      setValue("notes", currentItem.notes);
      setValue("photos", currentItem.photos);
      setValue("protocol", currentItem.protocol);
    }
  }, [typeItemState.typeItem, setValue]);

  const onSubmit = (data) => {
    const variables = {
      ...data,
      id,
    };

    dispatch(updateTypeItem(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={typeItemState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={typeItemState.loadingGet}
            loader={typeItemState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditTypeItem;
