import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { pl, enUS } from "date-fns/esm/locale";

const DateBox = styled.div`
  color: ${({ theme }) => theme.color.topBarFontSecondary};
  font-size: 14px;
  line-height: 21px;
  width: fit-content;
`;

const getDateString = () => {
  const date = format(new Date(), "d LLLL yyyy", { locale: pl });
  return date;
};

const DateComponent = () => {
  const [date, setDate] = useState(getDateString());

  useEffect(() => {
    const timer = setInterval(() => {
      const date = getDateString();
      setDate(date);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <DateBox>{date}</DateBox>;
};

export default DateComponent;
