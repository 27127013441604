import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Avatar from "react-avatar";
import { PencilAltIcon } from "react-line-awesome";

import MainTemplate from "../../templates/MainTemplate";
import DataTable from "../../components/dataTable/DataTable";
import ActionsPanel from "../../components/dataTable/ActionsPanel";
import BottomPanel from "../../components/dataTable/BottomPanel";
import SideModal from "../../components/modal/SideModal";
import { useDispatch, useSelector } from "react-redux";
import { usePermissionState } from "../../context/permission";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import usePermissionObject from "../../hooks/usePermissionObject";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import { deleteTeam, searchTeams } from "../../redux/actions/teamActions";
import CreateTeam from "../../components/teams/CreateTeam";
import { teamTypes } from "../../util/variables";
import EditTeam from "../../components/teams/EditTeam";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const NameTitle = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.regular};
  transition: 0.14s ease-in-out;
  cursor: pointer;
`;
const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 131px);
`;

const MembersContainer = styled.div`
  position: relative;
  display: flex;
`;
const CircleMemberBox = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 50%;
  overflow: hidden;
  user-select: none;

  :not(:first-of-type) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(${({ index }) => index} * 25px);
  }

  :hover {
    z-index: 2;
  }

  ${({ theme, more }) =>
    more &&
    css`
      border-width: 1px;
      color: ${theme.color.font200};
      font-size: 14px;
      font-weight: ${theme.weight.regular};
      background-color: ${theme.color.darkButtonBackground};
    `}
`;

const MembersProfileCircleComponent = ({ members }) => {
  return (
    <MembersContainer>
      {members.slice(0, 5).map(({ id, user }, index) => (
        <CircleMemberBox key={id} index={index} title={user?.fullName}>
          <Avatar
            name={user?.fullName}
            size="32"
            round={true}
            textSizeRatio={1.7}
            maxInitials={2}
            style={{
              fontFamily: "'Rubik', sans-serif",
              fontWeight: 500,
            }}
            // src={url}
          />
        </CircleMemberBox>
      ))}
      {members.length > 5 && (
        <CircleMemberBox index={5} more>{`+${
          members.length - 5
        }`}</CircleMemberBox>
      )}
    </MembersContainer>
  );
};

export default function Teams() {
  const columns = useMemo(
    () => [
      {
        accessor: "name",
      },
      {
        accessor: "type",
      },
      {
        accessor: "members",
      },
      {
        accessor: "icons",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const modalDispatch = useConfirmModalDispatch();
  const permissionObject = usePermissionObject("teams");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    teamState: state.team,
    tableState: state.table,
  }));
  const { teamState, tableState } = state;
  const { pagination, sorting } = tableState;
  const { nextToken, total } = teamState;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const searchTeamsVariables = useMemo(
    () => ({
      filter: {
        companyId: {
          eq: companyId,
        },
      },
      sort: {
        direction:
          sorting.alphabetically.value === "DEFAULT"
            ? null
            : sorting.alphabetically.value,
        field: "name",
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
    }),
    [companyId, pagination, sorting]
  );

  useEffect(() => {
    dispatch(searchTeams(searchTeamsVariables));
  }, [dispatch, searchTeamsVariables]);

  const teams = useMemo(() => {
    return teamState.teams.map((item) => {
      const { id } = item;
      const isDeleteLoading = teamState.loadingDelete === id;

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć zespół?",
            buttonText: "Usuń",
            action: () => dispatch(deleteTeam(id)),
          },
        });
      };

      return {
        ...item,
        name: (
          <NameTitle className="rowHover" onClick={() => openEdit(id)}>
            {item.name}
          </NameTitle>
        ),
        type: teamTypes.filter((type) => type.value === item.type)[0].name,
        members: (
          <MembersProfileCircleComponent
            members={item.members.items.filter((item) => item.user)}
          />
        ),
        icons: (
          <>
            {permissionObject.edit && (
              <IconBox onClick={() => openEdit(id)}>
                <PencilAltIcon />
              </IconBox>
            )}
            {permissionObject.del && (
              <IconBox onClick={handleDelete}>
                <RowDeleteLoader loading={isDeleteLoading} />
              </IconBox>
            )}
          </>
        ),
      };
    });
  }, [
    teamState.teams,
    teamState.loadingDelete,
    permissionObject,
    modalDispatch,
    dispatch,
  ]);

  return (
    <MainTemplate title="Zespoły">
      <TableBox>
        {permissionObject.create && (
          <ActionsPanel
            addText="Nowy zespół"
            setIsSidebarOpen={setIsCreateOpen}
          />
        )}
        <DataTable data={teams} columns={columns} loading={teamState.loading} />
        <BottomPanel nextToken={nextToken} total={total} />
      </TableBox>

      {permissionObject.create && (
        <SideModal
          title="Nowy zespół"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateTeam setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}

      {permissionObject.edit && (
        <SideModal
          title="Edycja zespołu"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        >
          <EditTeam setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </MainTemplate>
  );
}
