import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainTemplate from "../../templates/MainTemplate";
import TabsPanel from "../../components/dataTable/TabsPanel";
import styled from "styled-components";
import usePermissionObject from "../../hooks/usePermissionObject";
import BottomPanel from "../../components/dataTable/BottomPanel";
import { EllipsisHIcon, TrashIcon } from "react-line-awesome";
import { Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import { Title } from "../../components/Title";
import no_files_found from "../../assets/images/no_files_found.png";
import { Subtitle } from "../../components/Subtitle";
import { functionTypes, usePermissionState } from "../../context/permission";
import {
  deleteMultipleRootFiles,
  searchRootFiles,
  setParent,
} from "../../redux/actions/rootfileActions";
import { deleteRootFile } from "../../redux/actions/rootfileActions";
import { routes } from "../../routes";
import FileDetails from "./FileDetails";
import PrivateRoute from "../../auth/PrivateRout";
import {
  formatDate,
  recognizeFileExtensionSmall,
} from "../../helpers/functions";
import LibraryTable from "../../components/library/LibraryTable";
import SimpleDropdown from "../../components/SimpleDropdown";
import Loader from "../../components/Loader";
import FolderPath from "../../components/library/FolderPath";
import No_files_found from "../../assets/svg/no_files_found";

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexEndCell = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;

  & > * {
    display: flex;
  }
`;

const DataPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px 30px 25px;
  width: 100%;
  min-height: calc(100vh - 300px);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
`;

const Medium = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const Image = styled(No_files_found)`
  height: 42%;
  width: auto;
  object-fit: contain;
  margin-bottom: 46px;
  margin-top: 12vh;
`;

const TextBox = styled.div``;

const StyledTitle = styled(Title)`
  margin-bottom: 6px;
`;

const FileIcon = styled.div`
  width: 34px;
  height: 40px;
  margin-right: 16px;
`;

const FileNameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CenterBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.mainBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export default function Library() {
  const permissionObject = usePermissionObject("library");
  const dispatch = useDispatch();
  const modalDispatch = useConfirmModalDispatch();
  const { cognitoUser } = usePermissionState();
  const state = useSelector((state) => ({
    fileRootState: state.rootFile,
    tableState: state.table,
  }));
  const { fileRootState, tableState } = state;
  const { pagination } = tableState;
  const { nextToken, total, parentId } = fileRootState;
  const [sortingState, setSortingState] = useState(null);
  const [filterState, setFilterState] = useState({
    parentId: { eq: parentId },
  });
  const history = useHistory();
  const [parentID, setParentID] = useState(null);

  const searchFilesVariables = useMemo(
    () => ({
      sort: {
        direction: sortingState && sortingState.direction,
        field: sortingState && sortingState.id,
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
      filter: filterState,
    }),
    [pagination, sortingState, filterState, setFilterState]
  );

  function handleSorting(column) {
    const { id } = column;

    if (sortingState === null) setSortingState({ id, direction: "asc" });
    else {
      if (sortingState.id === id) {
        if (sortingState.direction === "desc")
          setSortingState({ id, direction: "asc" });
        else setSortingState({ id, direction: "desc" });
      } else {
        setSortingState({ id, direction: "asc" });
      }
    }
  }

  useEffect(() => {
    dispatch(searchRootFiles(searchFilesVariables));
  }, [dispatch, searchFilesVariables]);

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        width: "60%",
        align: false,
        Cell: ({ cell: { value } }) => (
          <Medium className="rowHover">{value}</Medium>
        ),
      },
      {
        Header: "Dodano",
        accessor: "createdAt",
        width: "20%",
        align: true,
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
      {
        Header: "",
        width: "10%",
        accessor: "actions",
        align: false,
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const handleTileClick = (id, isFolder, name) => {
    if (isFolder) {
      setParentID(parentId + "/" + name);
      setFilterState({ parentId: { eq: parentId + "/" + name } });
      dispatch(setParent(parentId + "/" + name));
    } else {
      history.push(`${routes.library.files}/${id}`);
    }
  };

  const handleFolderBackClick = () => {
    const slashIndex = parentId.lastIndexOf("/");
    const prevLocation = parentId.slice(0, slashIndex);
    setFilterState({ parentId: { eq: prevLocation } });
    dispatch(setParent(prevLocation));
  };

  const dropdownOptions = useCallback(
    (id, isFolder, name) => {
      const handleDelete = () => {
        if (isFolder) {
          const folderPath = `${parentId}/${name}`;
          const url = `public/${folderPath}`;
          modalDispatch({
            type: "OPEN",
            payload: {
              title: "Potwierdzenie usunięcia",
              content:
                "UWAGA! Folder zostanie usunięty. Na pewno chcesz usunąć?",
              buttonText: "Usuń",
              action: () => {
                dispatch(deleteMultipleRootFiles(folderPath, id, url));
                dispatch(searchRootFiles(searchFilesVariables));
              },
            },
          });
        } else {
          const url = `${parentId}/${id}`;
          modalDispatch({
            type: "OPEN",
            payload: {
              title: "Potwierdzenie usunięcia",
              content: "UWAGA! Plik zostanie usunięty. Na pewno chcesz usunąć?",
              buttonText: "Usuń",
              action: () => {
                dispatch(deleteRootFile(id, url));
                dispatch(searchRootFiles(searchFilesVariables));
              },
            },
          });
        }
      };

      return [
        {
          name: "usuń",
          icon: TrashIcon,
          handler: handleDelete,
          isEditDisplay: true,
          permission: permissionObject.delete,
        },
      ];
    },
    [modalDispatch, dispatch, permissionObject, parentID, setParentID, parentId]
  );

  const files = useMemo(() => {
    return fileRootState.rootFiles.map((item) => {
      const { id, name, parentId, isFolder } = item;

      return {
        ...item,
        name: (
          <FileNameContainer
            key={id}
            onClick={() => handleTileClick(id, isFolder, name, parentId)}
          >
            <FileIcon>{recognizeFileExtensionSmall(item, isFolder)}</FileIcon>
            <Medium className="rowHover">{name}</Medium>
          </FileNameContainer>
        ),
        createdAt: <Medium>{formatDate(item)}</Medium>,
        actions: (
          <>
            {permissionObject.del && (
              <SimpleDropdown
                data={dropdownOptions(id, isFolder, name)}
                icon={EllipsisHIcon}
              />
            )}
          </>
        ),
      };
    });
  }, [
    dispatch,
    modalDispatch,
    permissionObject,
    fileRootState.rootFiles,
    fileRootState.loadingDelete,
  ]);

  const mainContent = () => {
    if (!files || fileRootState.loading) {
      return (
        <CenterBox>
          <Loader />
        </CenterBox>
      );
    } else if (files.length > 0) {
      return (
        <TableBox>
          <DataPanel>
            <LibraryTable
              columns={columns}
              data={files}
              loading={fileRootState.loading}
              sorting={{ onClick: handleSorting, ...sortingState }}
            />
          </DataPanel>
          <BottomPanel nextToken={nextToken} total={total} />
        </TableBox>
      );
    } else {
      return (
        <Container>
          <Image />
          <TextBox>
            <StyledTitle>Nie znaleziono plików?</StyledTitle>
            <Subtitle>Spróbuj przesłać więcej plików</Subtitle>
          </TextBox>
        </Container>
      );
    }
  };

  return (
    <MainTemplate title="Biblioteka">
      <Switch>
        {fileRootState.rootFiles.map((file) => {
          return (
            <PrivateRoute
              key={file.id}
              path={routes.library.files}
              component={FileDetails}
              permission={{
                name: "library",
                type: functionTypes.READ,
              }}
            />
          );
        })}
      </Switch>
      <TableBox>
        <TabsPanel addText="Prześlij Pliki" parentId={parentId} />
        {parentId !== "ROTO" ? (
          <FolderPath
            parentId={parentId}
            onClick={() => handleFolderBackClick()}
          />
        ) : null}
        {mainContent()}
      </TableBox>
    </MainTemplate>
  );
}
