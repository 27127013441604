import React from "react";
import { AngleLeftIcon, AngleRightIcon, ListOlIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { nextPage, prevPage } from "../../redux/actions/tableActions";
import { setLimit } from "../../redux/actions/tableActions";

import { limitArray } from "../../redux/reducers/tableReducer";
import Dropdown from "./Dropdown";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 43px;
  @media only screen and (max-height: 400px) {
    margin-top: 10px;
  }

`;
const Buttons = styled.div`
  display: flex;
`;
const PaginationButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.paginationButtonBackground};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.font100};
  font-size: 18px;
  cursor: pointer;

  :not(:last-of-type) {
    margin-right: 4px;
  }
`;

export const BottomPanel = ({ nextToken, total }) => {
  const dispatch = useDispatch();
  const { pagination } = useSelector((state) => state.table);

  return (
    <Container>
      <Dropdown
        icon={ListOlIcon}
        data={limitArray}
        selected={pagination.limit}
        handleSelect={(payload) => dispatch(setLimit(payload))}
      >
        na stronie
      </Dropdown>
      <Buttons>
        <PaginationButton onClick={() => dispatch(prevPage())}>
          <AngleLeftIcon />
        </PaginationButton>
        <PaginationButton onClick={() => dispatch(nextPage(nextToken, total))}>
          <AngleRightIcon />
        </PaginationButton>
      </Buttons>
    </Container>
  );
};

export default BottomPanel;
