import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PencilAltIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import MainTemplate from "../../templates/MainTemplate";
import DataTable from "../../components/dataTable/DataTable";
import ActionsPanel from "../../components/dataTable/ActionsPanel";
import BottomPanel from "../../components/dataTable/BottomPanel";
import SideModal from "../../components/modal/SideModal";
import CreateRole from "../../components/roles/CreateRole";
import EditRole from "../../components/roles/EditRole";
import { deleteRole, searchRoles } from "../../redux/actions/roleActions";
import { usePermissionState } from "../../context/permission";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import usePermissionObject from "../../hooks/usePermissionObject";
import { useConfirmModalDispatch } from "../../context/ModalContext";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const Name = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.regular};
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(100vh - 131px);
`;

export default function Roles() {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: "name",
      },
      {
        accessor: "icons",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const permissionObject = usePermissionObject("roles");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  useEffect(() => {
    const historyState = history.location.state;
    if (historyState && historyState.isCreateOpen) {
      setIsCreateOpen(historyState.isCreateOpen);
    }
  }, [history]);

  const modalDispatch = useConfirmModalDispatch();

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    roleState: state.role,
    tableState: state.table,
  }));
  const { roleState, tableState } = state;
  const { pagination, sorting } = tableState;
  const { loadingDelete, nextToken, total } = roleState;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const searchRolesVariables = useMemo(
    () => ({
      filter: {
        companyId: {
          eq: companyId,
        },
      },
      sort: {
        direction:
          sorting.alphabetically.value === "DEFAULT"
            ? null
            : sorting.alphabetically.value,
        field: "name",
      },
      limit: pagination.limit.value,
      nextToken: pagination.nextTokenArray[pagination.currentPage],
    }),
    [companyId, pagination, sorting]
  );

  useEffect(() => {
    dispatch(searchRoles(searchRolesVariables));
  }, [dispatch, searchRolesVariables]);

  const roles = useMemo(() => {
    return roleState.roles.map((item) => {
      const { id, name } = item;
      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć rolę?",
            buttonText: "usuń",
            action: () => dispatch(deleteRole(id)),
          },
        });
      };

      return {
        ...item,
        name: (
          <Name
            className="rowHover"
            onClick={() => permissionObject.edit && openEdit(id)}
          >
            {name}
          </Name>
        ),
        icons: (
          <>
            {permissionObject.edit && (
              <IconBox onClick={() => openEdit(id)}>
                <PencilAltIcon />
              </IconBox>
            )}
            {permissionObject.del && (
              <IconBox onClick={handleDelete}>
                <RowDeleteLoader loading={loadingDelete === id} />
              </IconBox>
            )}
          </>
        ),
      };
    });
  }, [
    roleState.roles,
    permissionObject,
    loadingDelete,
    modalDispatch,
    dispatch,
  ]);

  return (
    <MainTemplate title="Role">
      <TableBox>
        {permissionObject.create && (
          <ActionsPanel
            addText="Nowa rola"
            setIsSidebarOpen={setIsCreateOpen}
          />
        )}
        <DataTable data={roles} columns={columns} loading={roleState.loading} />
        <BottomPanel nextToken={nextToken} total={total} />
      </TableBox>

      {permissionObject.create && (
        <SideModal
          title="Nowa rola"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateRole setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}
      {permissionObject.edit && (
        <SideModal
          title="Edycja roli"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        >
          <EditRole setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </MainTemplate>
  );
}
