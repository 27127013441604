import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CalendarAltIcon, UsersIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import { getTypeIconByValue } from "../../components/icons/typeIconsList";
import SideModal from "../../components/modal/SideModal";
import CreateReport from "../../components/reports/CreateReport";
import EditReport from "../../components/reports/EditReport";
import Status from "../../components/reports/Status";

import Table from "../../components/reports/Table";
import TableActions, {
  statusDropdownList,
} from "../../components/reports/TableActions";
import { usePermissionState } from "../../context/permission";
import usePermissionObject from "../../hooks/usePermissionObject";
import { searchTickets } from "../../redux/actions/ticketActions";
import MainTemplate from "../../templates/MainTemplate";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 131px);
`;
const NameBox = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.font300};
  font-size: 16px;
  cursor: pointer;
`;
const IconBox = styled.div`
  margin-right: 12px;
`;
const DateFormatted = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font200};
`;
const CalendarIconBox = styled.div`
  font-size: 19px;
  margin-right: 6px;
  color: ${({ theme }) => theme.color.font100};
`;
const StatusContainer = styled.div`
  margin-right: 28px;
`;

export default function Reports() {
  const { state: routState } = useLocation();
  const [status] = statusDropdownList.filter(
    ({ id }) => id === routState?.state?.status
  );

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [creatingTypeItem, setCreatingTypeItem] = useState(null);
  const [currentOpenData, setCurrentOpenData] = useState(false);

  const initFiltersState = status ? { status: [status] } : {};
  const [filters, setFilters] = useState(initFiltersState);

  const openEdit = (data) => {
    setIsEditOpen(true);
    setCurrentOpenData(data);
  };

  const permissionObject = usePermissionObject("reports");

  const columns = useMemo(
    () => [
      {
        Header: "Rodzaj zgłoszenia",
        accessor: "typeId",
        width: "26%",
      },
      {
        Header: "Klient",
        accessor: "clientId",
        width: "42%",
      },
      {
        Header: "Status",
        accessor: "status",
        width: "8%",
      },
      {
        Header: "Zespół",
        accessor: "teamId",
        width: "8%",
      },
      {
        Header: "Data realizacji",
        accessor: "deadline",
        width: "8%",
      },
    ],
    []
  );

  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    ticketState: state.ticket,
    tableState: state.table,
  }));
  const { ticketState, tableState } = state;
  const { pagination } = tableState;
  // const { loadingDelete, nextToken, total } = ticketState;
  const [sortingState, setSortingState] = useState(null);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const preparedFilters = useMemo(() => {
    let filterObjectAnd = [];
    for (const [key, value] of Object.entries(filters)) {
      if (!isEmpty(value)) {
        filterObjectAnd = [
          ...filterObjectAnd,
          { or: value.map((item) => ({ [key]: { eq: item.id } })) },
        ];
      }
    }

    return {
      and: [
        {
          companyId: {
            eq: companyId,
          },
        },
        ...filterObjectAnd,
      ],
    };
  }, [filters]);

  const searchTicketsVariables = useMemo(
    () => ({
      filter: preparedFilters,
      sort: {
        direction: sortingState && sortingState.direction,
        field: sortingState && sortingState.id,
      },
      limit: 100,
    }),
    [companyId, pagination, sortingState, preparedFilters]
  );

  useEffect(() => {
    dispatch(searchTickets(searchTicketsVariables));
  }, [dispatch, searchTicketsVariables]);

  const reportsList = useMemo(() => {
    return ticketState.tickets.map((item) => {
      const { id, typeItem, type, status, deadline, client, team } = item;
      const deadlineAsDate = deadline && new Date(deadline);

      return {
        typeId: (
          <NameBox
            onClick={() => permissionObject.edit && openEdit({ id, typeItem })}
          >
            <IconBox>{getTypeIconByValue(typeItem.icon)}</IconBox>
            {type?.name}
          </NameBox>
        ),
        clientId: client?.name,
        teamId: (
          <DateFormatted>
            <CalendarIconBox>
              <UsersIcon />
            </CalendarIconBox>
            {team ? team.name : "-"}
          </DateFormatted>
        ),
        status: (
          <StatusContainer>
            <Status status={status} />
          </StatusContainer>
        ),
        deadline: (
          <DateFormatted>
            <CalendarIconBox>
              <CalendarAltIcon />
            </CalendarIconBox>
            {deadline ? format(deadlineAsDate, "dd.MM.yyyy") : "-"}
          </DateFormatted>
        ),
      };
    });
  }, [ticketState.tickets]);

  function handleSorting(column) {
    const { id } = column;

    if (sortingState === null) setSortingState({ id, direction: "asc" });
    else {
      if (sortingState.id === id) {
        if (sortingState.direction === "desc")
          setSortingState({ id, direction: "asc" });
        else setSortingState({ id, direction: "desc" });
      } else {
        setSortingState({ id, direction: "asc" });
      }
    }
  }

  return (
    <MainTemplate title="Zgłoszenia">
      <FlexContainer>
        <TableActions
          addText="Nowe zgłoszenie"
          setIsSidebarOpen={setIsCreateOpen}
          setCreatingTypeItem={setCreatingTypeItem}
          filtersState={{ filters, setFilters }}
        />
        <Table
          data={reportsList}
          columns={columns}
          loading={ticketState.loading}
          sorting={{ onClick: handleSorting, ...sortingState }}
        />
      </FlexContainer>

      <SideModal isOpen={isCreateOpen} setIsOpen={setIsCreateOpen}>
        <CreateReport setIsOpen={setIsCreateOpen} typeItem={creatingTypeItem} />
      </SideModal>

      {permissionObject.edit && (
        <SideModal isOpen={isEditOpen} setIsOpen={setIsEditOpen}>
          <EditReport setIsOpen={setIsEditOpen} data={currentOpenData} />
        </SideModal>
      )}
    </MainTemplate>
  );
}
