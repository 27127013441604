export const roleTranslation = {
  roles: "Role",
  users: "Użytkownicy",
  log: "Dziennik",
  analyze: "Analiza",
  biling: "Rozliczenia",
  reports: "Zgłoszenia",
  clients: "Klienci",
  teams: "Zespoły",
  settings: "Ustawienia",
  userProfile: "Profil",
};

export const languageList = [
  { name: "Polski", value: "PL" },
  { name: "Angielski", value: "EN" },
  { name: "Niemiecki", value: "DE" },
];

export const teamTypes = [
  { name: "Wewnętrzny", value: "OWN" },
  { name: "Firma zewnętrzna", value: "OUTSOURCE" },
];
