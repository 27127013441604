import { API, graphqlOperation } from "aws-amplify";
import { pick } from "lodash";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/tickets";
import { addPhoto } from "../../helpers/functions";
import {
  SEARCH_TICKETS_STARTED,
  SEARCH_TICKETS_SUCCESS,
  SEARCH_TICKETS_FAILURE,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  GET_TICKET_STARTED,
  GET_TICKET_SUCCESS,
  GET_TICKET_FAILURE,
  UPDATE_TICKET_STARTED,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILURE,
  UPDATE_STATUS_TICKET_STARTED,
  UPDATE_STATUS_TICKET_SUCCESS,
  UPDATE_STATUS_TICKET_FAILURE,
  DELETE_TICKET_STARTED,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
} from "../types";

export const fileTypesTranslation = {
  attachments: "ATTACHMENT",
  customerOrder: "CUSTOMER_ORDER",
  producerOrder: "PRODUCER_ORDER",
  measurementCard: "MEASUREMENT_CARD",
  photos: "PHOTO",
};

const removeUndefined = (arr) => arr.filter((x) => x !== undefined);
const isString = (item) => typeof item === "string";

const createFile = (config) => async (file) => {
  if (isString(file)) return;
  try {
    const fileUrl = await addPhoto(file, config);
    return fileUrl;
  } catch (err) {
    console.log(`File upload: ${err}`);
  }
};

const createTicketStarted = () => ({
  type: CREATE_TICKET_STARTED,
});
const createTicketSuccess = (ticket) => ({
  type: CREATE_TICKET_SUCCESS,
  payload: ticket,
});
const createTicketFailure = (error) => ({
  type: CREATE_TICKET_FAILURE,
  payload: {
    error,
  },
});

export const createTicket =
  ({ input, rest }, callback) =>
  async (dispatch) => {
    try {
      dispatch(createTicketStarted());

      const filesObject = pick(rest, [
        "attachments",
        "customerOrder",
        "producerOrder",
        "measurementCard",
        "photos",
      ]);

      let s3FilesArrayInput = [];
      for (const [key, value] of Object.entries(filesObject)) {
        if (value) {
          let s3Files = await Promise.all(
            value.map(
              createFile({ companyId: input.companyId, fieldType: key })
            )
          );
          s3Files = removeUndefined(s3Files);
          const prepareInputFiles = s3Files.map((file) => ({
            ref: file.split("public")[1],
            type: fileTypesTranslation[key],
          }));
          s3FilesArrayInput = [...s3FilesArrayInput, ...prepareInputFiles];
        }
      }

      const newInput = {
        ...input,
        files: s3FilesArrayInput,
        specifications: rest.specifications,
        fieldItems: rest.fieldItems,
      };

      const { data } = await API.graphql(
        graphqlOperation(graphql.createTicketAll, { input: newInput })
      );
      dispatch(createTicketSuccess(data.createTicketAll));
      toast.success(<MainToast message="Zgłoszenie stworzone pomyślnie" />);
      callback && callback();
    } catch (err) {
      console.log(err);
      dispatch(createTicketFailure(err));
      toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    }
  };

const searchTicketsSuccess = (tickets, nextToken, total) => ({
  type: SEARCH_TICKETS_SUCCESS,
  payload: {
    tickets,
    nextToken,
    total,
  },
});
const searchTicketsStarted = () => ({
  type: SEARCH_TICKETS_STARTED,
});
const searchTicketsFailure = (error) => ({
  type: SEARCH_TICKETS_FAILURE,
  payload: {
    error,
  },
});

export const searchTickets = (variables) => async (dispatch) => {
  try {
    dispatch(searchTicketsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchTickets, variables)
    );
    const nextToken = data.searchTickets.nextToken;
    const total = data.searchTickets.total;
    const tickets = data.searchTickets.items;

    dispatch(searchTicketsSuccess(tickets, nextToken, total));
  } catch (err) {
    console.log(err);
    dispatch(searchTicketsFailure(err));
  }
};

// const listClientsSuccess = (clients) => ({
//   type: LIST_CLIENTS_SUCCESS,
//   payload: clients,
// });
// const listClientsStarted = () => ({
//   type: LIST_CLIENTS_STARTED,
// });
// const listClientsFailure = (error) => ({
//   type: LIST_CLIENTS_FAILURE,
//   payload: {
//     error,
//   },
// });

// export const listClients = (variables) => async (dispatch) => {
//   try {
//     dispatch(listClientsStarted());
//     const { data } = await API.graphql(
//       graphqlOperation(graphql.listClients, variables)
//     );

//     const clients = data.listClients.items;

//     dispatch(listClientsSuccess(clients));
//   } catch (err) {
//     console.log(err);
//     dispatch(listClientsFailure(err));
//   }
// };

const deleteTicketSuccess = (id) => ({
  type: DELETE_TICKET_SUCCESS,
  payload: id,
});
const deleteTicketStarted = (id) => ({
  type: DELETE_TICKET_STARTED,
  payload: id,
});
const deleteTicketFailure = (error) => ({
  type: DELETE_TICKET_FAILURE,
  payload: {
    error,
  },
});

export const deleteTicketAll = (id, callback) => async (dispatch) => {
  try {
    dispatch(deleteTicketStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteTicketAll, {
        id,
      })
    );

    dispatch(deleteTicketSuccess(id));
    toast.success(<MainToast message="Zlecenie usunięte pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    dispatch(deleteTicketFailure(err.errors));
  }
};

const getTicketSuccess = (ticket) => ({
  type: GET_TICKET_SUCCESS,
  payload: ticket,
});
const getTicketStarted = () => ({
  type: GET_TICKET_STARTED,
});
const getTicketFailure = (error) => ({
  type: GET_TICKET_FAILURE,
  payload: {
    error,
  },
});

export const getTicket = (id) => async (dispatch) => {
  try {
    dispatch(getTicketStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getTicket, {
        id,
      })
    );

    const filter = {
      ticketId: {
        eq: data.getTicket.id,
      },
    };
    const { data: specificationData } = await API.graphql(
      graphqlOperation(graphql.listSpecifications, {
        filter,
      })
    );
    const { data: ticketFieldItemsData } = await API.graphql(
      graphqlOperation(graphql.listTicketFieldItems, {
        filter,
      })
    );

    const output = {
      ...data.getTicket,
      specification: [...specificationData.listSpecificationes.items],
      ticketFieldItems: [...ticketFieldItemsData.listTicketFieldItemos.items],
    };

    dispatch(getTicketSuccess(output));
  } catch (err) {
    console.log(err);
    dispatch(getTicketFailure(err.errors));
  }
};

const updateTicketSuccess = (client) => ({
  type: UPDATE_TICKET_SUCCESS,
  payload: client,
});
const updateTicketStarted = () => ({
  type: UPDATE_TICKET_STARTED,
});
const updateTicketFailure = (error) => ({
  type: UPDATE_TICKET_FAILURE,
  payload: {
    error,
  },
});

export const updateTicket =
  ({ input, rest }, callback) =>
  async (dispatch) => {
    try {
      dispatch(updateTicketStarted());

      // Edycja zdjęć
      const filesObject = pick(rest, [
        "attachments",
        "customerOrder",
        "producerOrder",
        "measurementCard",
        "photos",
      ]);

      let s3FilesArrayInput = [];
      for (const [key, value] of Object.entries(filesObject)) {
        if (value) {
          const newFilesArray = value.filter(
            (file) => typeof file !== "string"
          );
          let s3Files = await Promise.all(
            newFilesArray.map(
              createFile({ companyId: input.companyId, fieldType: key })
            )
          );
          const links = value.filter((file) => typeof file === "string");
          s3Files = removeUndefined([...s3Files, ...links]);
          const prepareInputFiles = s3Files.map((file) => ({
            ref: file.split("public")[1],
            type: fileTypesTranslation[key],
          }));
          s3FilesArrayInput = [...s3FilesArrayInput, ...prepareInputFiles];
        }
      }
      const newInput = {
        ...input,
        files: s3FilesArrayInput,
        specifications: rest.specifications,
        fieldItems: rest.fieldItems,
      };

      const { data } = await API.graphql(
        graphqlOperation(graphql.updateTicketAll, {
          input: newInput,
        })
      );
      const ticket = data.updateTicketAll;

      dispatch(updateTicketSuccess(ticket));
      toast.success(<MainToast message="Zaktualizowano zgłoszenie" />);
      callback && callback();
    } catch (err) {
      console.error(err);
      dispatch(updateTicketFailure(err.errors));
      toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    }
  };

const updateStatusTicketSuccess = (ticket) => ({
  type: UPDATE_STATUS_TICKET_SUCCESS,
  payload: ticket,
});
const updateStatusTicketStarted = () => ({
  type: UPDATE_STATUS_TICKET_STARTED,
});
const updateStatusTicketFailure = (error) => ({
  type: UPDATE_STATUS_TICKET_FAILURE,
  payload: {
    error,
  },
});

export const updateStatusTicket =
  (ticketId, status, callback) => async (dispatch) => {
    try {
      dispatch(updateStatusTicketStarted());

      const { data } = await API.graphql(
        graphqlOperation(graphql.updateTicket, {
          input: { id: ticketId, status },
        })
      );
      const ticket = data.updateTicket;

      dispatch(updateStatusTicketSuccess(ticket));
      toast.success(<MainToast message="Zaktualizowano zgłoszenie" />);
      callback && callback();
    } catch (err) {
      console.error(err);
      dispatch(updateStatusTicketFailure(err.errors));
      toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    }
  };
