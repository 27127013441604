import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { UserTagIcon } from "react-line-awesome";
import { useHistory } from "react-router-dom";

import FormSectionTitle from "../FormSectionTitle";
import Input from "../Input";
import { emailReg, phoneReg } from "../../helpers/regex";
import Select from "../Select";
import { Controller } from "react-hook-form";
import API, { graphqlOperation } from "@aws-amplify/api";
import { listRoles } from "../../graphql/roles";
import { usePermissionState } from "../../context/permission";
import { routes } from "../../routes";
import AddressInput from "../AddressInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading, ...rest }) => {
  const history = useHistory();
  const {
    register,
    control,
    formState: { errors },
  } = form;
  const [rolesList, setRolesList] = useState([]);
  const [rolesListLoading, setRolesListLoading] = useState(false);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const getDropdownRoles = async () => {
    setRolesListLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listRoles, {
          filter: {
            companyId: {
              eq: companyId,
            },
          },
        })
      );
      const list = data.listRoles.items.map(({ id, name }) => ({
        name,
        value: id,
      }));
      setRolesList(list);
      setRolesListLoading(false);
    } catch (err) {
      console.log(err);
      setRolesListLoading(false);
    }
  };

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Dane użytkownika" icon={UserTagIcon} />
        <Input
          label="Imię i nazwisko"
          placeholder="Wprowadź imię i nazwisko"
          error={errors?.name?.message}
          isLoading={loading}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Input
          label="Adres e-mail"
          placeholder="Wprowadź adres e-mail"
          error={errors?.email?.message}
          disabled={rest.edit || false}
          isLoading={loading}
          {...register("email", {
            required: { value: true, message: "Pole nie może być puste" },
            pattern: {
              value: emailReg,
              message: "Podaj poprawny adres e-mail",
            },
          })}
        />
        <Input
          label="Numer telefonu"
          placeholder="Wprowadź numer telefonu"
          error={errors?.phone?.message}
          isLoading={loading}
          {...register("phone", {
            required: { value: true, message: "Pole nie może być puste" },
            pattern: {
              value: phoneReg,
              message: "Podaj poprawny numer telefonu",
            },
          })}
        />
        <Controller
          render={({ ...rest }) => (
            <AddressInput
              isLoading={loading}
              label="Adres zamieszkania"
              placeholder="Wprowadź adres zamieszkania"
              error={errors?.address?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Podaj adres" },
          }}
          control={control}
          name="address"
        />
        <Controller
          render={({ ref, ...rest }) => (
            <Select
              isLoading={loading}
              items={rolesList}
              getItems={getDropdownRoles}
              isListLoading={rolesListLoading}
              label="Rola"
              placeholder="Wybierz rolę"
              handleEmpty={{
                text: "Utwórz rolę",
                action: () =>
                  history.push(routes.roles, { isCreateOpen: true }),
              }}
              error={errors?.role?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Wybierz z listy" },
          }}
          control={control}
          name="role"
        />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
};

export default FormFields;
