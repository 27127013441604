/* --------------------------------- Queries -------------------------------- */
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: SearchableClientSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        logo
        fullName
        name
        phoneNumber
        email
        address
        notes
        latitude
        longitude
      }
      nextToken
      total
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClients($filter: ModelClientFilterInput) {
    listClients(filter: $filter) {
      items {
        id
        logo
        fullName
        name
        phoneNumber
        email
        address
        notes
      }
    }
  }
`;

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      logo
      fullName
      name
      phoneNumber
      email
      address
      notes
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createClient = /* GraphQL */ `
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      logo
      fullName
      name
      phoneNumber
      email
      address
      notes
    }
  }
`;

export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      logo
      fullName
      name
      phoneNumber
      email
      address
      notes
    }
  }
`;

export const deleteClient = /* GraphQL */ `
  mutation deleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      id
    }
  }
`;
