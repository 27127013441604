/* --------------------------------- Queries -------------------------------- */
export const listTeams = /* GraphQL */ `
  query ListTeams($filter: ModelTeamFilterInput) {
    listTeams(filter: $filter) {
      items {
        id
        name
        members {
          items {
            id
            user {
              id
              photo
            }
          }
        }
      }
    }
  }
`;

export const searchTeams = /* GraphQL */ `
  query SearchTeams(
    $filter: SearchableTeamFilterInput
    $sort: SearchableTeamSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchTeams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        members {
          items {
            id
            user {
              id
              fullName
              photo
            }
          }
        }
        rates {
          items {
            id
            product {
              id
              name
            }
            amount
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      type
      members {
        items {
          id
          user {
            id
            fullName
            photo
          }
        }
      }
      rates {
        items {
          id
          product {
            id
            name
          }
          amount
        }
      }
    }
  }
`;

/* -------------------------------- Mutations ------------------------------- */
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
      name
      type
    }
  }
`;

export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
      name
      type
      members {
        items {
          id
          user {
            id
            fullName
            photo
          }
        }
      }
      rates {
        items {
          id
          product {
            id
            name
          }
          amount
        }
      }
    }
  }
`;

export const deleteTeam = /* GraphQL */ `
  mutation deleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      id
      members {
        items {
          id
        }
      }
      rates {
        items {
          id
        }
      }
    }
  }
`;
