import {
  LIST_PRODUCTS_STARTED,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAILURE,
  CREATE_PRODUCT_STARTED,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_STARTED,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  GET_PRODUCT_STARTED,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
} from "../../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingDelete: false,
  nextToken: null,
  products: [],
  product: {},
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    /* -------------------------- Pobieranie produktu -------------------------- */
    case GET_PRODUCT_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        product: action.payload,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    /* -------------------------- Listowanie produktów -------------------------- */
    case LIST_PRODUCTS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.products,
      };
    case LIST_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    /* --------------------------- Dodawanie produktu --------------------------- */
    case CREATE_PRODUCT_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        products: [action.payload.product, ...state.products],
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    /* --------------------------- Usuwanie produktu --------------------------- */
    case DELETE_PRODUCT_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        products: state.products.filter(
          (product) => product.id !== action.payload
        ),
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    /* --------------------------- Edycja produktu --------------------------- */
    case UPDATE_PRODUCT_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        products: state.products.map((product) =>
          product.id === action.payload.id
            ? { ...product, ...action.payload }
            : product
        ),
        product: action.payload,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default productReducer;
