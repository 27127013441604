import styled, { css } from "styled-components";

export const SelectTodayButton = styled.div`
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  width: 74px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.weight.medium};
  border: 1px RGB(242, 244, 246) solid;
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 8px;
  user-select: none;
  transition: transform 0.18s ease-in-out;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.blue};
        border-color: ${({ theme }) => theme.color.blue};
  }

  ${({ theme, dark }) =>
    dark &&
    css`
      background-color: ${theme.color.darkButtonBackground};
      color: ${theme.color.darkButtonText};
    `}
`;

export default SelectTodayButton;