import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { googlePlaceDetails } from "../graphql/global";
import { isEmpty } from "lodash";

export const useGetPlaceInfo = () => {
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);

  const getPlaceInfo = async (placeId) => {
    if (isEmpty(placeId)) return;
    try {
      setLoading(true);

      const { data: placeData } = await API.graphql(
        graphqlOperation(googlePlaceDetails, {
          placeid: placeId,
        })
      );
      const placeFormatted = JSON.parse(
        JSON.parse(placeData.googlePlaceDetails)
      ).result;

      setPlace(placeFormatted);
      setLoading(false);

      return placeFormatted;
    } catch (err) {
      console.log(err);
      setPlace(null);
      setLoading(false);
    }
  };

  return { getPlaceInfo, place, loading };
};
