import React from "react";
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "./GlobalStyle";

import { useThemeState } from "../context/theme";

export default function Provider({ children }) {
  const { theme } = useThemeState();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
