import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../Button";
import Submit from "../../Submit";
import ProductFormFields from "./ProductFormFields";
import { usePermissionState } from "../../../context/permission";
import { createProduct } from "../../../redux/actions/settings/productActions";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateProduct = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.product);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    const input = {
      name: data.name,
      unit: data.unit,
      companyId,
    };

    dispatch(createProduct(input, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ProductFormFields form={form} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateProduct.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateProduct;
