import React from "react";
const Image_small = props => (
    <svg width={34} height={40} {...props}>
            <g fill="none" fillRule="evenodd">
                <rect fill="#F1F5FF" width={34} height={40} rx={1.28} />
                <path
                    d="M12 12v15h12V15.804l-.169-.18-3.6-3.462-.187-.162H12zm1.2 1.154h6v3.461h3.6v9.231h-9.6V13.154zm7.2.83l1.538 1.478H20.4v-1.479zm.656 4.362c-.33 0-.6.26-.6.577 0 .318.27.577.6.577.33 0 .6-.26.6-.577a.59.59 0 0 0-.6-.577zm-4.256.92l-.431.396-2.4 2.308.862.83 1.969-1.894 1.369 1.316.431.397.431-.397.769-.739 1.369 1.316.862-.829-1.8-1.73-.431-.397-.431.396-.769.74-1.369-1.317-.431-.396z"
                    fill="#4D7CFE"
                    fillRule="nonzero"
                />
            </g>
        </svg>
);

export default Image_small;