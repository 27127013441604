import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";

import Loader from "../Loader";
import { SortAmountDownIcon, SortAmountUpIcon } from "react-line-awesome";

const Box = styled.div`
  width: 100%;
  padding: 0 25px;
  flex: 1;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;
`;
const TableBox = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin: none;

  thead {
    th {
      position: sticky;
      top: 0;
      height: 55px;
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.font200};
      font-weight: ${({ theme }) => theme.weight.regular};
      font-size: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
      white-space: nowrap;
      z-index: 1;
      cursor: pointer;
    }
  }

  tbody {
    width: 100%;
    td {
      font-size: 14px;
      color: ${({ theme }) => theme.color.font100};
      font-weight: ${({ theme }) => theme.weight.regular};
      height: 56px;
      white-space: nowrap;
      border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
    }
  }
`;
const CenterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;
const InsideHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const IconBox = styled.div`
  margin-left: 8px;
  font-size: 17px;
`;

const Table = ({ data, columns, loading, sorting }) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable({
      columns,
      data,
    });

  let tableInfo;
  if (!rows || loading) {
    tableInfo = (
      <CenterBox>
        <Loader />
      </CenterBox>
    );
  } else if (rows.length === 0) {
    tableInfo = (
      <CenterBox>
        <p>Brak danych</p>
      </CenterBox>
    );
  } else if (rows.length > 0) {
    tableInfo = null;
  }

  let generateDirectionIcon = (column) => {
    if (sorting?.id === column.id) {
      if (sorting?.direction === "asc") {
        return <SortAmountDownIcon />;
      } else {
        return <SortAmountUpIcon />;
      }
    } else {
      return null;
    }
  };

  return (
    <Box>
      <TableBox {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    onClick: () => sorting.onClick(column),
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  <InsideHeader>
                    {column.render("Header")}
                    <IconBox>{generateDirectionIcon(column)}</IconBox>
                  </InsideHeader>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!tableInfo &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </TableBox>
      {tableInfo}
    </Box>
  );
};

export default Table;
