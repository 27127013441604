import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ListIcon } from "react-line-awesome";
import { Controller } from "react-hook-form";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useHistory } from "react-router-dom";

import Select from "../../Select";
import { usePermissionState } from "../../../context/permission";
import FormSectionTitle from "../../FormSectionTitle";
import Input from "../../Input";
import TagSelect from "../../TagSelect";
import { routes } from "../../../routes";
import { listTypeItems } from "../../../graphql/settings/notificationTypes";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading }) => {
  const history = useHistory();
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const [typeList, setTypeList] = useState([]);
  const [typeListLoading, setTypeListLoading] = useState(false);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const getDropdownTypes = async () => {
    setTypeListLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listTypeItems, {
          filter: {
            companyId: {
              eq: companyId,
            },
          },
        })
      );
      const list = data.listTypeItems.items.map(({ id, name }) => ({
        name,
        value: id,
      }));

      setTypeList(list);
      setTypeListLoading(false);
    } catch (err) {
      console.log(err);
      setTypeListLoading(false);
    }
  };

  return (
    <>
      <FormSection>
        <FormSectionTitle title="Pola" icon={ListIcon} />
        <Input
          label="Nazwa pola"
          placeholder="Wprowadź nazwę pola"
          error={errors?.name?.message}
          isLoading={loading}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Controller
          render={({ ref, ...rest }) => (
            <Select
              isLoading={loading}
              items={typeList}
              getItems={getDropdownTypes}
              isListLoading={typeListLoading}
              label="Typ zgłoszenia"
              placeholder="Wybierz typ zgłoszenia"
              handleEmpty={{
                text: "Utwórz typ zgłoszenia",
                action: () =>
                  history.push(routes.settings.notificationTypes, {
                    isCreateOpen: true,
                  }),
              }}
              error={errors?.type?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Wybierz z listy" },
          }}
          control={control}
          name="type"
        />
        <Controller
          render={({ ref, ...rest }) => (
            <TagSelect
              disabled={loading}
              placeholderText="Wprowadź pozycję"
              label="Pozycje do wyboru"
              error={errors?.types?.message}
              {...rest}
            />
          )}
          control={control}
          name="types"
          rules={{
            required: { value: true, message: "Pole nie może być puste" },
          }}
        />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
};

export default FormFields;
