import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { PencilAltIcon } from "react-line-awesome";

import { Title } from "../../components/Title";
import AddButton from "../../components/AddButton";
import DataTableSecondary from "../../components/dataTable/DataTableSecondary";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import SideModal from "../../components/modal/SideModal";
import CreateProduct from "../../components/settings/products/CreateProduct";
import { usePermissionState } from "../../context/permission";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  listProducts,
} from "../../redux/actions/settings/productActions";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import EditProduct from "../../components/settings/products/EditProduct";
import { useHistory } from "react-router-dom";
import usePermissionObject from "../../hooks/usePermissionObject";

const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const FlexEndCell = styled.div`
  display: flex;

  & > * {
    display: flex;
  }
`;
const Medium = styled.div`
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 31px 31px 36px 29px;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 0;
  font-size: 20px;
`;

const Products = () => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <Medium className="rowHover">{value}</Medium>
        ),
      },
      {
        Header: "Jednostka",
        accessor: "unit",
      },
      {
        Header: "Działania",
        accessor: "actions",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const permissionObject = usePermissionObject("settings");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  useEffect(() => {
    const historyState = history.location.state;
    if (historyState && historyState.isCreateOpen) {
      setIsCreateOpen(historyState.isCreateOpen);
    }
  }, [history]);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const modalDispatch = useConfirmModalDispatch();

  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product);

  const listProductsVariables = useMemo(
    () => ({
      filter: { companyId: { eq: companyId } },
    }),
    [companyId]
  );

  useEffect(() => {
    dispatch(listProducts(listProductsVariables));
  }, [dispatch, listProductsVariables]);

  const products = useMemo(() => {
    return productState.products.map((item) => {
      const { id, name } = item;
      const isDeleteLoading = productState.loadingDelete === id;

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content:
              "UWAGA! Produkt oraz wszystkie powiązania zostaną usunięte. Na pewno chcesz usunąć?",
            buttonText: "Usuń",
            action: () => dispatch(deleteProduct(id)),
          },
        });
      };

      return {
        ...item,
        name: (
          <Medium className="rowHover" onClick={() => openEdit(id)}>
            {name}
          </Medium>
        ),
        actions: (
          <>
            {permissionObject.edit && (
              <IconBox onClick={() => openEdit(id)}>
                <PencilAltIcon />
              </IconBox>
            )}
            {permissionObject.del && (
              <IconBox onClick={handleDelete}>
                <RowDeleteLoader loading={isDeleteLoading} />
              </IconBox>
            )}
          </>
        ),
      };
    });
  }, [
    dispatch,
    modalDispatch,
    permissionObject,
    productState.products,
    productState.loadingDelete,
  ]);

  // console.log(products)
  // console.log(columns)

  return (
    <Container>
      <HeaderBox>
        <StyledTitle>Produkty</StyledTitle>
        {permissionObject.create && (
          <AddButton
            text="Dodaj produkt"
            onClick={() => setIsCreateOpen(true)}
          />
        )}
      </HeaderBox>
      <DataTableSecondary
        data={products}
        columns={columns}
        loading={productState.loading}
        noDataContent={{
          title: "Brak dostępnych produktów",
          subtitle: "Dodaj nowe produkty, aby wyświetlić je w tej sekcji",
        }}
      />

      {permissionObject.create && (
        <SideModal
          title="Nowy produkt"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateProduct setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}

      {permissionObject.edit && (
        <SideModal
          title="Edycja produktu"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        >
          <EditProduct setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </Container>
  );
};

export default Products;
