import React from "react";
import styled from "styled-components";
import {
  PlusCircleIcon,
  TasksIcon,
  UsersIcon,
  UserTieIcon,
} from "react-line-awesome";

import DropdownMenu from "./DropdownMenu";

const DropdownBox = styled.div`
  margin-right: 24px;
`;
const AddButtons = styled.div`
  padding: 12px 0;
`;
const AddButton = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 18px 0 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.topBarFontSecondary};
  font-size: 18px;
  transition: background 0.16s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.color.mainBackground};
    cursor: pointer;
  }
`;
const Text = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.font300};
  font-weight: ${({ theme }) => theme.weight.medium};
  margin-left: 6px;
  font-size: 12px;
`;

export default function Alerts() {
  return (
    <DropdownBox>
      <DropdownMenu
        icon={<PlusCircleIcon className="la-lg" />}
        notificationOptions={{ allowNotification: false }}
        dropdownOptions={{
          width: "fit-content",
          direction: "left",
          offset: -10,
          arrowOffset: 11,
        }}
      >
        <AddButtons>
          <AddButton>
            <TasksIcon className="la-1x" />
            <Text>Nowe zgłoszenie</Text>
          </AddButton>
          <AddButton>
            <UserTieIcon className="la-1x" />
            <Text>Nowy klient</Text>
          </AddButton>
          <AddButton>
            <UsersIcon className="la-1x" />
            <Text>Nowy zespół</Text>
          </AddButton>
        </AddButtons>
      </DropdownMenu>
    </DropdownBox>
  );
}
