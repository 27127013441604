import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

let defaultNotificationOptions = {
  top: -1,
  right: -3,
  color: "blue",
  allowNotification: true,
};

let defaultDropdownOptions = {
  direction: "right",
  offset: -28,
  arrowOffset: 30,
  offsetY: -27,
  width: 344,
};

const Container = styled.div`
  position: relative;
`;
const IconBox = styled.div`
  position: relative;
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.color.primaryColor : theme.color.topBarFontSecondary};
  cursor: pointer;
  transition: color 0.16s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;
const NotificationBall = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.topBarBackgroundColor};
  background-color: ${({ color }) => color};
`;
const DropdownContainer = styled.div`
  position: absolute;
  ${({ direction, offset }) => `${direction}: ${offset}px`};
  bottom: ${({ offsetY }) => offsetY}px;
  transform: translateY(100%);
  z-index: 10;
  width: ${({ width }) => (Number.isInteger(width) ? `${width}px` : width)};
  background-color: ${({ theme }) => theme.color.dropdownBox};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadow.dropdown};
`;
const DropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
const Arrow = styled.div`
  position: absolute;
  top: -3px;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.color.dropdownBox};
  border-radius: 2px;
  width: 20px;
  height: 20px;
  z-index: -1;
  ${({ direction, arrowOffset }) => `${direction}: ${arrowOffset}px`};
`;

export default function DropdownMenu({
  children,
  icon,
  notificationOptions,
  isNotification = false,
  dropdownOptions,
  closeFns,
}) {
  const [isOpen, setIsOpen] = useState(false);

  notificationOptions = {
    ...defaultNotificationOptions,
    ...notificationOptions,
  };
  const { allowNotification } = notificationOptions;

  dropdownOptions = {
    ...defaultDropdownOptions,
    ...dropdownOptions,
  };

  const { setIsBodyLock } = useLockBodyScroll();
  useEffect(() => {
    setIsBodyLock(isOpen);
  }, [isOpen, setIsBodyLock]);

  const container = useRef(null);
  useDetectOutsideClick(container, setIsOpen);

  // Animations
  function enterAnimation(node, done) {
    gsap.from(node, {
      autoAlpha: 0,
      duration: 0.1,
      onComplete: done,
      ease: "Power2.inOut",
    });
  }

  function exitAnimation(node, done) {
    gsap.to(node, {
      autoAlpha: 0,
      duration: 0.1,
      onComplete: done,
      ease: "Power2.inOut",
    });
  }

  const handleIconBoxClick = () => {
    if (closeFns && isOpen) {
      closeFns(setIsOpen);
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Container ref={container}>
      <IconBox onClick={handleIconBoxClick} isOpen={isOpen}>
        {allowNotification && isNotification && (
          <NotificationBall {...notificationOptions} />
        )}
        {icon}
      </IconBox>
      <Transition
        timeout={240}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <DropdownContainer {...dropdownOptions}>
          <Arrow {...dropdownOptions} />
          <DropdownBox>{children}</DropdownBox>
        </DropdownContainer>
      </Transition>
    </Container>
  );
}
