import React from "react";
import styled from "styled-components";
import { FileAltIcon } from "react-line-awesome";
import { Controller } from "react-hook-form";

import FormSectionTitle from "../../FormSectionTitle";
import Input from "../../Input";
import ProtocolFieldsInput from "./ProtocolFieldsInput";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const ProtocolFormFields = ({ form, edit, loading }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <FormSection>
      <FormSectionTitle title="Protokół odbioru" icon={FileAltIcon} />
      <Input
        label="Nazwa sekcji"
        placeholder="Wprowadź nazwę sekcji"
        error={errors?.name?.message}
        isLoading={loading}
        {...register("name", {
          required: { value: true, message: "Pole nie może być puste" },
        })}
      />
      <Controller
        control={control}
        name="items"
        render={({ ref, ...rest }) => (
          <ProtocolFieldsInput loading={loading} edit={edit} {...rest} />
        )}
      />
    </FormSection>
  );
};

export default ProtocolFormFields;
