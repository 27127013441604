import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const Box = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;
const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  text-align: left;
  margin: none;

  thead {
    th {
      color: ${({ theme }) => theme.color.font300};
      font-weight: ${({ theme }) => theme.weight.medium};
      font-size: 14px;
      padding-bottom: 22px;
      border-bottom: 2px solid ${({ theme }) => theme.color.mainBorder};
      text-align: center;
      white-space: nowrap;

      :first-of-type {
        color: ${({ theme }) => theme.color.font100};
        font-weight: ${({ theme }) => theme.weight.regular};
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      :not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
      }
    }

    td {
      font-size: 14px;
      color: ${({ theme }) => theme.color.font300};
      font-weight: ${({ theme }) => theme.weight.regular};
      height: 60px;
      white-space: nowrap;

      :first-of-type {
        text-align: left;
        padding-right: 40px;
      }
    }
  }
`;

const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
`;

export default function DataTable({ data, columns }) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const { loadingGet } = useSelector((state) => state.role);

  return (
    <Box>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {!loadingGet && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {loadingGet && (
        <LoaderBox>
          <Loader />
        </LoaderBox>
      )}
    </Box>
  );
}
