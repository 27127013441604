import React from "react";
const Pdf_small = props => (
    <svg width={34} height={40} {...props}>
        <g fill="none" fillRule="evenodd">
            <rect fill="#FFEEF1" width={34} height={40} rx={1.28} />
            <path
                d="M11 12v15h12V12H11zm1.2 1.154h9.6v12.692h-9.6V13.154zm4.444 3.083a.888.888 0 0 0-.544.18.792.792 0 0 0-.263.433c-.082.31-.058.633.02.991.09.42.35.924.562 1.407-.108.439-.136.829-.3 1.28-.14.387-.321.61-.488.955-.377.138-.827.219-1.125.397-.32.191-.602.403-.768.703a.985.985 0 0 0 .075 1.028c.11.16.255.286.45.36a.876.876 0 0 0 .58.018c.355-.117.605-.378.845-.685.222-.284.38-.766.58-1.154.303-.097.521-.218.845-.288.337-.072.564-.039.88-.072.137.148.252.387.395.504.285.24.6.429.974.451.376.023.75-.203.957-.54h.018v-.019a.933.933 0 0 0 .15-.505.79.79 0 0 0-.224-.504c-.247-.253-.56-.318-.9-.36-.263-.035-.629.055-.938.071-.272-.345-.541-.604-.788-1.045-.133-.24-.168-.442-.28-.686.086-.392.257-.829.28-1.171.029-.415.012-.773-.112-1.1a1.054 1.054 0 0 0-.319-.45.918.918 0 0 0-.543-.2h-.02zm.394 4.273c.107.182.24.297.356.468-.169.03-.298 0-.469.036-.028.007-.047.03-.075.036.035-.09.08-.144.112-.234.038-.106.04-.2.076-.306zm2.212 1.171c.202.025.274.061.3.073-.005.009.007.006 0 .018-.075.12-.082.11-.131.108-.04-.002-.192-.081-.338-.18.042.002.131-.023.169-.019zm-4.2.902c-.033.047-.06.158-.094.198-.183.235-.351.343-.393.36-.008-.008.011 0 0-.017h-.02c-.06-.084-.044-.05 0-.126.045-.077.186-.233.432-.379.019-.011.056-.025.075-.036z"
                fill="#FF2D55"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default Pdf_small;