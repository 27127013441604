import React from "react";
import styled from "styled-components";

import Logo from "../icons/Logo";
import DarkLogo from "../icons/DarkLogo";
import ProgressBar from "../components/authorization/ProgressBar";
// import { useThemeDispatch, useThemeState } from "../context/theme";

const Template = styled.div`
  background-color: ${({ theme }) => theme.color.mainBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
const LogoBox = styled.div`
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);
`;
const CenterBox = styled.div`
  background-color: ${({ theme }) => theme.color.authorizationBox};
  border-radius: 4px;
  padding: 50px 98px;
  margin: 60px 0;
  box-shadow: 0 2px 80px 0 rgba(37, 38, 49, 0.05);
`;
const ProgressBarBox = styled.div`
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  width: 270px;
`;

export default function AuthorizationTemplate({
  children,
  title = "WIKĘD",
  step,
}) {
  document.title = title;
  // const dispatch = useThemeDispatch();
  // const { theme } = useThemeState();

  return (
    <Template>
      {/* <LogoImage src={Logo} alt="MonterGo" width={90} /> */}
      <LogoBox>
        <DarkLogo />
      </LogoBox>
      <CenterBox>{children}</CenterBox>
      {step && (
        <ProgressBarBox>
          <ProgressBar step={step} />
        </ProgressBarBox>
      )}
      {/* <div onClick={() => dispatch({ type: "mainTheme" })}>Main theme</div>
      <div onClick={() => dispatch({ type: "lightTheme" })}>Light theme</div> */}
    </Template>
  );
}
