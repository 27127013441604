import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../../components/toastify/MainToast";

import * as graphql from "../../../graphql/settings/products";
import * as graphqlRates from "../../../graphql/rates";
import {
  LIST_PRODUCTS_STARTED,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAILURE,
  CREATE_PRODUCT_STARTED,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_STARTED,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  GET_PRODUCT_STARTED,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
} from "../../types";
import { deleteRates } from "../teamActions";

/* --------------------------- Pobiranie produktu --------------------------- */
const getProductSuccess = (product) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: product,
});
const getProductStarted = () => ({
  type: GET_PRODUCT_STARTED,
});
const getProductFailure = (error) => ({
  type: GET_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch(getProductStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getProduct, { id })
    );

    const product = data.getProduct;

    dispatch(getProductSuccess(product));
  } catch (err) {
    dispatch(getProductFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    console.log(err);
  }
};

/* -------------------------- Listowanie produktów -------------------------- */
const listProductsSuccess = (products) => ({
  type: LIST_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});
const listProductsStarted = () => ({
  type: LIST_PRODUCTS_STARTED,
});
const listProductsFailure = (error) => ({
  type: LIST_PRODUCTS_FAILURE,
  payload: {
    error,
  },
});

export const listProducts = (variables) => async (dispatch) => {
  try {
    dispatch(listProductsStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.listProducts, variables)
    );

    const products = data.listProducts.items;

    dispatch(listProductsSuccess(products));
  } catch (err) {
    dispatch(listProductsFailure(err));
  }
};

/* --------------------------- Dodawanie produktu --------------------------- */
const createProductSuccess = (product) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});
const createProductStarted = () => ({
  type: CREATE_PRODUCT_STARTED,
});
const createProductFailure = (error) => ({
  type: CREATE_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const createProduct = (input, callback) => async (dispatch) => {
  try {
    dispatch(createProductStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.createProduct, { input })
    );

    const product = data.createProduct;

    dispatch(createProductSuccess(product));
    toast.success(<MainToast message="Produkt dodany pomyślnie" />);
    callback && callback();
  } catch (err) {
    dispatch(createProductFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};

/* ---------------------------- Usuwnaie produktu --------------------------- */
const deleteProductSuccess = (id) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: id,
});
const deleteProductStarted = (id) => ({
  type: DELETE_PRODUCT_STARTED,
  payload: id,
});
const deleteProductFailure = (error) => ({
  type: DELETE_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch(deleteProductStarted(id));

    // TODO: Usuwanie relacji produktu z zespołami
    const { data: ratesData } = await API.graphql(
      graphqlOperation(graphqlRates.listRates, {
        filter: { productId: { eq: id } },
      })
    );
    await Promise.all(ratesData.listRates.items.map(deleteRates));

    await API.graphql(
      graphqlOperation(graphql.deleteProduct, { input: { id } })
    );

    dispatch(deleteProductSuccess(id));
    toast.success(<MainToast message="Produkt usunięty pomyślnie" />);
  } catch (err) {
    dispatch(deleteProductFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};

/* ---------------------------- Edycja produktu --------------------------- */
const updateProductSuccess = (product) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
});
const updateProductStarted = () => ({
  type: UPDATE_PRODUCT_STARTED,
});
const updateProductFailure = (error) => ({
  type: UPDATE_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const updateProduct = (input) => async (dispatch) => {
  try {
    dispatch(updateProductStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.updateProduct, { input })
    );

    const product = data.updateProduct;
    dispatch(updateProductSuccess(product));
    toast.success(<MainToast message="Zapisano pomyślnie" />);
  } catch (err) {
    dispatch(updateProductFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
  }
};
