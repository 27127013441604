import styled from "styled-components";
import { device } from '../../pages/log/mediaQueries';

export const TaskListItemWrapper = styled.div`
    display: flex;
    color: ${({ theme }) => theme.color.black};
    font-size: 14px;
    margin-top: 32px;

    :hover {
        cursor: pointer;
    }
`;

export const TaskHoursContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 82px;
    margin-right: 32px;
`;

export const TaskInformationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
`;

export const TaskStartHour = styled.span`
    font-size: 18px;
    margin-bottom: 8px;
`;

export const TaskEndHour = styled.span`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: ${({ theme }) => theme.color.font200};
`;

export const TaskEndHourHeader = styled.span`
    color: ${({ theme }) => theme.color.black};
    margin-bottom: 8px;
`;

export const TaskEndHourText = styled(TaskEndHour)`
    margin-bottom: 2px;
`;

export const TaskTitleContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.weight.medium};
    margin: 16px 0 8px 0;

    @media ${device.largeDevices} {
        margin: 0 0 8px 0;
    }
`;

export const TaskTitle = styled.span`
    margin-left: 8px;
`;

export const TaskDescription = styled.span`
    font-weight: ${({ theme }) => theme.weight.light};
    color: ${({ theme }) => theme.color.font200};
    max-width: 500px;
    margin: 8px 0;
    padding-right: 16px;
    word-wrap: break-word;
`;

export const TaskButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TaskButtonsClientNameAndTeamNameContainer = styled(TaskButtonsContainer)`
    
    @media ${device.largeDevices} {
        flex-direction: row;
    }
`;

export const TaskButtonAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px RGB(242, 244, 246) solid; 
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.color.font200};

    :hover {
        color: ${({ theme }) => theme.color.blue};
        border-color: ${({ theme }) => theme.color.blue};
    }
`;

export const TaskButtonActionArrow = styled(TaskButtonAction)`
    margin-left: 8px;
    margin-right: 0;

`;

export const TaskInformationAndStatus = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    border-left: 2px RGB(242, 244, 246) solid; 
    padding-left: 16px;
    
    @media ${device.largeDevices} {
        flex-direction: row;
    }
`;

export const IconTaskText = styled.span`
    margin-right: 16px;
`;

export const IconTask = styled.div`
    margin-right: 8px;
    color: ${({ theme }) => theme.color.font200};
    font-size: 18px;
`;


