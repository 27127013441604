import React from "react";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { routes } from "../routes";

import { InputElement } from "./Input";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.mainBorder};
`;
const RowElement = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;

  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.mainBorder};
  }
`;
const Product = styled.div`
  width: 50%;
  padding: 16px 16px 15px;
  border-right: 1px solid ${({ theme }) => theme.color.mainBorder};
  font-weight: ${({ theme }) => theme.weight.medium};
`;
const Price = styled.div`
  width: 50%;
  display: flex;
  padding: 16px 16px 15px;
`;
const Unit = styled.div`
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.font200};
`;
const PriceInput = styled(InputElement)`
  font-weight: ${({ theme }) => theme.weight.regular};
  margin-right: 10px;
  border: none;
  height: 21px;
  font-size: 14px;
  padding: 0;
`;
const EmptyButton = styled.div`
  color: ${({ theme }) => theme.color.primaryColor};
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;

const ProductCountInput = ({ products, form }) => {
  const history = useHistory();
  const { register } = form;

  return isEmpty(products) ? (
    <EmptyButton
      onClick={() =>
        history.push(routes.settings.products, { isCreateOpen: true })
      }
    >
      Dodaj produkt
    </EmptyButton>
  ) : (
    <Container>
      {products.map((product) => {
        if (product.id) {
          const { id, name, unit } = product;
          return (
            <RowElement key={id}>
              <Product>{name}</Product>
              <Price>
                <PriceInput
                  placeholder="Podaj wartość"
                  {...register(`product.${id}`, {
                    valueAsNumber: true,
                  })}
                />
                <Unit>{unit}</Unit>
              </Price>
            </RowElement>
          );
        }
      })}
    </Container>
  );
};

export default ProductCountInput;
