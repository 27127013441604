const size = {
    smallDevices: '576px',
    mediumDevices: '768px',
    largeDevices: '1080px',
    extraDevices: '1450px',
}

export const device = {
    smallDevices: `(min-width: ${size.smallDevices})`,
    mediumDevices: `(min-width: ${size.mediumDevices})`,
    largeDevices: `(min-width: ${size.largeDevices})`,
    extraDevices: `(min-width: ${size.extraDevices})`
};

