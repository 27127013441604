import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";

import Loader from "../Loader";

const Box = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;
const Table = styled.table`
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0 6px;
  text-align: left;
  margin: none;

  thead {
    th {
      height: 50px;
      background-color: ${({ theme }) => theme.color.primaryBlue};
      color: white;
      font-weight: ${({ theme }) => theme.weight.bold};
      font-size: 16px;
      white-space: nowrap;
    }
  }

  tbody {
    td,
    th {
      padding: 8px 16px;
    }

    td {
      font-size: 14px;
      color: ${({ theme }) => theme.color.font100};
      font-weight: ${({ theme }) => theme.weight.regular};
      height: 80px;
      white-space: nowrap;
    }

    tr {
      background-color: white;

      &,
      .rowHover {
        transition: 0.14s ease-in-out;
      }

      :hover {
        box-shadow: ${({ theme }) => theme.shadow.rowHover};

        .rowHover {
          color: ${({ theme }) => theme.color.primaryColor};
        }
      }
    }

    td:first-of-type {
      border-radius: 4px 0 0 4px;
      padding-left: 24px;
    }
    td:last-of-type {
      border-radius: 0 4px 4px 0;
      padding-right: 24px;
    }
  }
`;

const CenterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;

export default function DataTable({ data, columns, loading }) {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  let tableInfo;
  if (!rows || loading) {
    tableInfo = (
      <CenterBox>
        <Loader />
      </CenterBox>
    );
  } else if (rows.length === 0) {
    tableInfo = (
      <CenterBox>
        <p>Brak danych</p>
      </CenterBox>
    );
  } else if (rows.length > 0) {
    tableInfo = null;
  }

  return (
    <Box>
      <Table {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {!tableInfo &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {tableInfo}
    </Box>
  );
}
