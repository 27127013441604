import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import Loader from "./Loader";
import Tile from "./Tile";

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 30px;
`;
const Container = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  grid-auto-rows: 350px;
  grid-gap: 31px 28px;
  overflow-y: auto;
`;

const TilesGridTemplate = ({
  data,
  loading,
  handleClick,
  dropdownOptions,
  loadingDelete,
}) => {
  const checkedPermissionDropdownOptions = (id) =>
    dropdownOptions(id).filter(({ permission }) => permission);

  let tilesRender;
  if (loading) {
    tilesRender = (
      <Center>
        <Loader loading={loading} />
      </Center>
    );
  } else {
    if (isEmpty(data)) {
      tilesRender = <Center>Brak danych</Center>;
    } else {
      tilesRender = (
        <Container>
          {data.map((tile) => (
            <Tile
              key={tile.id}
              data={tile}
              onClick={() => handleClick(tile.id)}
              dropdownOptions={checkedPermissionDropdownOptions(tile.id)}
              loadingDelete={loadingDelete}
            />
          ))}
        </Container>
      );
    }
  }

  return tilesRender;
};

export default TilesGridTemplate;
