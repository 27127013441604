import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import _ from "lodash";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { useDispatch, useSelector } from "react-redux";
import { getTeam, updateTeam } from "../../redux/actions/teamActions";
import { teamTypes } from "../../util/variables";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditTeam = ({ setIsOpen, id }) => {
  const form = useForm();
  const { unregister, handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const teamState = useSelector(({ team }) => team);

  useEffect(() => {
    dispatch(getTeam(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(teamState.team)) {
      const { name, type, rates } = teamState.team;

      const members = teamState.team.members.items.reduce((acc, item) => {
        const element = item.user?.id
          ? { id: item.user?.id, name: item.user?.fullName }
          : null;
        return [...acc, element];
      }, []);

      setValue("name", name);
      setValue("type", teamTypes.filter(({ value }) => value === type)[0]);
      setValue(
        "members",
        members.filter((item) => item !== null)
      );

      unregister("price");
      rates.items.forEach(({ amount, product }) => {
        setValue(`price.${product?.id}`, amount);
      });
    }
  }, [teamState.team, setValue, unregister]);

  const onSubmit = (data) => {
    let price = [];
    if (data.price) {
      for (let [key, value] of Object.entries(data.price)) {
        price = [...price, { id: key, value }];
      }
    }

    const variables = {
      ...data,
      id,
      type: data.type.value,
      price,
    };
    dispatch(updateTeam(variables));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={teamState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={teamState.loadingGet}
            loader={teamState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

EditTeam.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EditTeam;
