import React from "react";
const Folder_small = props => (
    <svg
        width={34}
        height={40}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <rect id="a" x={0} y={0} width={34} height={40} rx={4} />
        </defs>
        <g fillRule="nonzero" fill="none">
            <use fill="#FFF" xlinkHref="#a" />
            <use fillOpacity={0.08} fill="#2CE5F6" xlinkHref="#a" />
            <path
                d="M13.5 18.625a.142.142 0 0 0-.136.104L10.5 25.875v.25c0 .183-.012.25.141.25h11.19a.635.635 0 0 0 .613-.453L25 18.875v-.25H13.5z"
                fill="#2CE5F6"
            />
            <path
                d="M13.183 18.125H23.5v-1.567a.684.684 0 0 0-.683-.683h-6.188l-1.25-1.75h-4.696a.684.684 0 0 0-.683.683v10.449l2.57-6.68a.634.634 0 0 1 .613-.452z"
                fill="#2CE5F6"
            />
        </g>
    </svg>
);
export default Folder_small;