import {
  SEARCH_CLIENTS_STARTED,
  SEARCH_CLIENTS_SUCCESS,
  SEARCH_CLIENTS_FAILURE,
  LIST_CLIENTS_STARTED,
  LIST_CLIENTS_SUCCESS,
  LIST_CLIENTS_FAILURE,
  CREATE_CLIENT_STARTED,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  GET_CLIENT_STARTED,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  UPDATE_CLIENT_STARTED,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from "../types";

const initialState = {
  loading: false,
  loadingCreate: false,
  loadingGet: false,
  loadingUpdate: false,
  loadingDelete: false,
  nextToken: null,
  total: null,
  clients: [],
  client: {},
  error: null,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLIENT_STARTED:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        error: null,
        clients: [action.payload, ...state.clients],
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload.error,
      };

    case SEARCH_CLIENTS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clients: action.payload.clients,
        nextToken: action.payload.nextToken,
        total: action.payload.total,
      };
    case SEARCH_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case LIST_CLIENTS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clients: action.payload,
      };
    case LIST_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_CLIENT_STARTED:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        error: null,
        clients: state.clients.filter((item) => item.id !== action.payload),
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        error: action.payload.error,
      };

    case GET_CLIENT_STARTED:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        error: null,
        client: action.payload,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        loadingGet: false,
        error: action.payload.error,
      };

    case UPDATE_CLIENT_STARTED:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        error: null,
        clients: state.clients.map((client) =>
          client.id === action.payload.id
            ? { ...client, ...action.payload }
            : client
        ),
        client: action.payload,
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default clientReducer;
