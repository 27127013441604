import React from "react";
import styled from "styled-components";

import SmallLogo from "../icons/SmallLogo";
import LoadingLogo from "../icons/LoadingLogo";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.mainBackground};
`;
const LogoBox = styled.div`
  @keyframes pulse {
    0% {
      transform: scale(0.98);
      opacity: 0.75;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.98);
      opacity: 0.75;
    }
  }

  animation: pulse 1.5s infinite ease-in-out;
`;

const PermissionLoading = () => {
  return (
    <Container>
      <LogoBox>
        <LoadingLogo width={200} height={100} />
      </LogoBox>
    </Container>
  );
};

export default PermissionLoading;
