import React from "react";
const Image_big = (props) => (
        <svg width={106} height={124} {...props}>
            <g fill="none" fillRule="evenodd">
                <rect fill="#F1F5FF" width={105.4} height={124} rx={4} />
                <path
                    d="M35 38v47h36V49.92l-.506-.565-10.8-10.847L59.13 38H35zm3.6 3.615h18v10.847h10.8v28.923H38.6v-39.77zm21.6 2.599l4.612 4.632H60.2v-4.632zm1.969 13.67a1.807 1.807 0 0 0 0 3.616 1.807 1.807 0 0 0 0-3.615zM49.4 60.767l-1.294 1.242-7.2 7.231 2.588 2.599 5.906-5.932 4.106 4.124 1.294 1.243 1.294-1.243 2.306-2.316 4.106 4.124 2.588-2.599-5.4-5.423-1.294-1.243-1.294 1.243-2.306 2.316-4.106-4.124-1.294-1.242z"
                    fill="#4D7CFE"
                    fillRule="nonzero"
                />
            </g>
        </svg>
);


export default Image_big;