import { API, graphqlOperation } from "aws-amplify";
import * as graphql from "../../graphql/logs";
import {
    SEARCH_TICKETS_LOG_STARTED,
    SEARCH_TICKETS_LOG_SUCCESS,
    SEARCH_TICKETS_LOG_FAILURE,
    SEARCH_LIST_STATUS_STARTED,
    SEARCH_LIST_STATUS_SUCCESS,
    SEARCH_LIST_STATUS_FAILURE,
    SEARCH_REPORTS_PENDING_STARTED,
    SEARCH_REPORTS_PENDING_SUCCESS,
    SEARCH_REPORTS_PENDING_FAILURE,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS,
    SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE
  } from "../types";

const searchTicketsLogSuccess = (reports, nextToken) => ({
    type: SEARCH_TICKETS_LOG_SUCCESS,
    payload: {
      reports,
      nextToken,
    },
  });
  
  const searchTicketsLogStarted = () => ({
    type: SEARCH_TICKETS_LOG_STARTED,
  });
  
  const searchTicketsLogFailure = (error) => ({
    type: SEARCH_TICKETS_LOG_FAILURE,
    payload: {
      error,
    },
  });
  
  export const searchTicketsLog = (variables) => async (dispatch) => {
    try {
      dispatch(searchTicketsLogStarted());
      const { data } = await API.graphql(
        graphqlOperation(graphql.searchTicketsForLog, variables)
      );
      const nextToken = data.searchTickets.nextToken;
      const reports = data.searchTickets.items;

      console.log(reports);
  
      dispatch(searchTicketsLogSuccess(reports, nextToken));
    } catch (err) {
      dispatch(searchTicketsLogFailure(err));
    }
  };

  const searchListStatusSuccess = (statusList, nextToken) => ({
    type: SEARCH_LIST_STATUS_SUCCESS,
    payload: {
      statusList,
      nextToken,
    },
  });
  
  const searchListStatusStarted = () => ({
    type: SEARCH_LIST_STATUS_STARTED,
  });
  
  const searchListStatusFailure = (error) => ({
    type: SEARCH_LIST_STATUS_FAILURE,
    payload: {
      error,
    },
  });

  export const searchListStatus = (variables) => async (dispatch) => {
    try {
      dispatch(searchListStatusStarted());
      const { data } = await API.graphql(
        graphqlOperation(graphql.searchStatusList, variables)
      );
      const nextToken = data.searchTickets.nextToken;
      const statusList = data.searchTickets.items;
      dispatch(searchListStatusSuccess(statusList, nextToken));
    } catch (err) {
      dispatch(searchListStatusFailure(err));
    }
  };

  const searchReportsPendingSuccess = (reportsPending, nextToken) => ({
    type: SEARCH_REPORTS_PENDING_SUCCESS,
    payload: {
      reportsPending,
      nextToken,
    },
  });
  
  const searchReportsPendingStarted = () => ({
    type: SEARCH_REPORTS_PENDING_STARTED,
  });
  
  const searchReportsPendingFailure = (error) => ({
    type: SEARCH_REPORTS_PENDING_FAILURE,
    payload: {
      error,
    },
  });

  export const searchReportsPending = (variables) => async (dispatch) => {
    try {
      dispatch(searchReportsPendingStarted());
      const { data } = await API.graphql(
        graphqlOperation(graphql.searchReportPending, variables)
      );
      const nextToken = data.searchTickets.nextToken;
      const reportsPending = data.searchTickets.items;
      dispatch(searchReportsPendingSuccess(reportsPending, nextToken));
    } catch (err) {
      dispatch(searchReportsPendingFailure(err));
    }
  };

  const searchReportsDatesForCalendarSuccess = (reportsDates, nextToken) => ({
    type: SEARCH_REPORTS_DATE_FOR_CALENDAR_SUCCESS,
    payload: {
      reportsDates,
      nextToken,
    },
  });
  
  const searchReportsDatesForCalendarStarted = () => ({
    type: SEARCH_REPORTS_DATE_FOR_CALENDAR_STARTED,
  });
  
  const searchReportsDatesForCalendarFailure = (error) => ({
    type: SEARCH_REPORTS_DATE_FOR_CALENDAR_FAILURE,
    payload: {
      error,
    },
  });

  export const searchReportsDatesForCalendar = (variables) => async (dispatch) => {
    try {
      dispatch(searchReportsDatesForCalendarStarted());
      const { data } = await API.graphql(
        graphqlOperation(graphql.searchReportsDatesForCalendar, variables)
      );
      const nextToken = data.searchTickets.nextToken;
      const reportsDates = data.searchTickets.items;
      dispatch(searchReportsDatesForCalendarSuccess(reportsDates, nextToken));
    } catch (err) {
      dispatch(searchReportsDatesForCalendarFailure(err));
    }
  };

  