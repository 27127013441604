import React, { useRef, useState } from "react";
import styled from "styled-components";
import { TasksIcon, UsersIcon, UserTieIcon } from "react-line-awesome";

import DropdownMenu from "./DropdownMenu";
import SearchInput from "./SearchInput";

const DropdownContainer = styled.div`
  margin-right: 24px;
`;
const DropdownList = styled.div``;
const ListElement = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font100};
  height: 54px;
  padding: 0 14px;
  transition: background 0.16s ease-in-out;

  :not(:last-of-type) {
    ::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 24px);
      height: 1px;
      background-color: ${({ theme }) => theme.color.mainBorder};
    }
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.mainBackground};
  }
`;
const Icon = styled.div`
  font-size: 22px;
`;
const Text = styled.div`
  font-size: 14px;
  margin-left: 17px;
  white-space: nowrap;
`;

export default function Search() {
  const dropdownContainer = useRef(null);
  const [searchValue, setSearchValue] = useState("");

  return (
    <DropdownContainer ref={dropdownContainer}>
      <DropdownMenu
        icon={
          <SearchInput
            dropdownContainerRef={dropdownContainer}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        }
        notificationOptions={{ allowNotification: false }}
        dropdownOptions={{
          offset: 0,
          arrowOffset: 20,
          width: 442,
          offsetY: -19,
        }}
        closeFns={() => {}}
      >
        <DropdownList>
          <ListElement>
            <Icon>
              <TasksIcon className="la-1x" />
            </Icon>
            <Text>Wyszukiwanie zgłoszeń</Text>
          </ListElement>
          <ListElement>
            <Icon>
              <UserTieIcon className="la-1x" />
            </Icon>
            <Text>Wyszukiwanie klientów</Text>
          </ListElement>
          <ListElement>
            <Icon>
              <UsersIcon className="la-1x" />
            </Icon>
            <Text>Wyszukiwanie zespołów</Text>
          </ListElement>
        </DropdownList>
      </DropdownMenu>
    </DropdownContainer>
  );
}
