import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";

import Loader from "../Loader";

const Box = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  text-align: left;
  margin: 0;

  thead {
    th {
      height: 50px;
      color: ${({ theme }) => theme.color.font200};
      font-weight: ${({ theme }) => theme.weight.regular};
      font-size: 14px;
      white-space: nowrap;
      padding: 13px 15px;
      border-bottom: 2px solid ${({ theme }) => theme.color.tableHeaderLine};
    }
  }

  tbody {
    td,
    th {
      padding: 18px 15px;
    }

    td {
      font-size: 14px;
      color: ${({ theme }) => theme.color.font300};
      font-weight: ${({ theme }) => theme.weight.regular};
      height: 64px;
      letter-spacing: 0.3px;
    }

    tr {
      background-color: white;

      &,
      .rowHover {
        transition: 0.14s ease-in-out;
      }

      :hover {
        background-color: ${({ theme }) => theme.color.mainBackgroundOpacity10};

        .rowHover {
          color: ${({ theme }) => theme.color.primaryColor};
        }
      }
    }

    td {
      border-bottom: 1px solid ${({ theme }) => theme.color.secondaryBorder};
    }
  }

  td:last-of-type {
    width: 100px;
    color: ${({ theme }) => theme.color.font100};
  }
`;

const CenterBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const NoDataTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.color.font300};
  margin-bottom: 8px;
`;
const NoDataSubtitle = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.weight.light};
  color: ${({ theme }) => theme.color.font100};
`;

const DataTableSecondary = ({
  data,
  columns,
  loading,
  headless,
  noDataContent,
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable({
      columns,
      data,
    });

  let tableInfo;
  if (!rows || loading) {
    tableInfo = (
      <CenterBox>
        <Loader />
      </CenterBox>
    );
  } else if (rows.length === 0) {
    tableInfo = (
      <CenterBox>
        <NoDataTitle>{noDataContent?.title}</NoDataTitle>
        <NoDataSubtitle>{noDataContent?.subtitle}</NoDataSubtitle>
      </CenterBox>
    );
  } else if (rows.length > 0) {
    tableInfo = null;
  }

  return (
    <Box>
      <Table {...getTableProps()}>
        {headless || (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody {...getTableBodyProps()}>
          {!tableInfo &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {tableInfo}
    </Box>
  );
};

export default DataTableSecondary;
