import React from "react";
const File_small = (props) => (
    <svg width={34} height={40} {...props}>
          <g fill="none" fillRule="evenodd">
              <rect fill="#FFF2DE" width={34} height={40} rx={1.28} />
              <path
                  d="M11 12v15h12V15.804l-.169-.18-3.6-3.462-.187-.162H11zm1.2 1.154h6v3.461h3.6v9.231h-9.6V13.154zm7.2.83l1.538 1.478H19.4v-1.479z"
                  fill="#FFAB2C"
                  fillRule="nonzero"
              />
          </g>
      </svg>

);

export default File_small;