import React from "react";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";

import { functionTypes, usePermissionState } from "../context/permission";
import { dec2bin } from "../helpers/functions";
import { getFirstAvailableRoute, routes } from "../routes";

import LowPermissionPage from "../pages/LowPermissionPage";
import PermissionLoading from "../pages/PermissionLoading";

export default function PrivateRout({ permission, redirectTo, ...rest }) {
  const permissionState = usePermissionState();

  const isAllowed = () => {
    if (permission.type === "GUEST") return true;
    if (permission.type === "OPEN") return true;
    if (permission.type === "404" && permissionState.cognitoUser) return true;

    const permissionDec = permissionState[permission.name];

    const binPermission = dec2bin(permissionDec).split("");
    const functionTypeIndex = Object.keys(functionTypes).indexOf(
      permission.type
    );

    return !!+binPermission[functionTypeIndex];
  };

  if (_.isEmpty(permissionState)) {
    return <PermissionLoading />;
  } else {
    if (isAllowed()) {
      if (permissionState.cognitoUser && permission.type === "GUEST") {
        const cognitoUserType =
          permissionState.cognitoUser.signInUserSession.accessToken.payload[
            "cognito:groups"
          ][0];
        if (cognitoUserType === "Admin") {
          return <Redirect to={getFirstAvailableRoute(permissionState)} />;
        } else if (cognitoUserType === "Root") {
          return <Redirect to={routes.companies} />;
        } else if (cognitoUserType === "User") {
          return <Redirect to={getFirstAvailableRoute(permissionState)} />;
        } else {
          return <div>Ups! Coś poszło nie tak</div>;
        }
      } else {
        return <Route {...rest} />;
      }
    } else {
      if (permissionState.cognitoUser) {
        if (redirectTo) {
          return <Redirect to={redirectTo} />;
        } else {
          return <LowPermissionPage />;
        }
      } else {
        return <Redirect to={routes.login} />;
      }
    }
  }
}
