import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PencilAltIcon } from "react-line-awesome";
import { useDispatch, useSelector } from "react-redux";

import { usePermissionState } from "../../context/permission";
import { useConfirmModalDispatch } from "../../context/ModalContext";

import {
  deleteTypeItem,
  listTypeItems,
} from "../../redux/actions/settings/notificationTypeActions";

import { Title } from "../../components/Title";
import AddButton from "../../components/AddButton";
import DataTableSecondary from "../../components/dataTable/DataTableSecondary";
import RowDeleteLoader from "../../components/RowDeleteLoader";
import SideModal from "../../components/modal/SideModal";
import CreateNotificationType from "../../components/settings/notificationType/CreateNotificationType";
import EditTypeItem from "../../components/settings/notificationType/EditTypeItem";
import usePermissionObject from "../../hooks/usePermissionObject";

const IconBox = styled.div`
  position: relative;
  display: flex;
  font-size: 22px;
  cursor: pointer;
  transition: 0.14s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;
const FlexEndCell = styled.div`
  display: flex;

  & > * {
    display: flex;
  }
`;
const Medium = styled.div`
  font-weight: ${({ theme }) => theme.weight.medium};
  cursor: pointer;
`;
const TruncateBox = styled.div``;

const Container = styled.div`
  padding: 31px 31px 36px 29px;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;
const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 0;
  font-size: 20px;
`;

const NotificationTypes = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <Medium className="rowHover">{value}</Medium>
        ),
      },
      {
        Header: "Rodzaje typu zgłoszenia",
        accessor: "types",
      },
      {
        Header: "Działania",
        accessor: "actions",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const permissionObject = usePermissionObject("settings");

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState(null);

  const openEdit = (id) => {
    setIsEditOpen(true);
    setCurrentOpenId(id);
  };

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const modalDispatch = useConfirmModalDispatch();

  const dispatch = useDispatch();
  const typeItemState = useSelector((state) => state.typeItem);
  const typeItems = typeItemState.typeItems;

  const listTypeItemsVariables = useMemo(
    () => ({
      filter: { companyId: { eq: companyId } },
    }),
    [companyId]
  );

  useEffect(() => {
    dispatch(listTypeItems(listTypeItemsVariables));
  }, [dispatch, listTypeItemsVariables]);

  const notifications = useMemo(() => {
    return typeItems.map((item) => {
      const { id, name } = item;
      const types = item.types.items?.reduce(
        (acc, item) => (acc = [...acc, item.type.name]),
        []
      );
      const isDeleteLoading = typeItemState.loadingDelete === id;

      const handleDelete = () => {
        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć typ zgłoszenia?",
            buttonText: "Usuń",
            action: () => dispatch(deleteTypeItem(id)),
          },
        });
      };

      return {
        ...item,
        name: (
          <Medium className="rowHover" onClick={() => openEdit(id)}>
            {name}
          </Medium>
        ),
        types: <TruncateBox>{types.join(", ")}</TruncateBox>,
        actions: (
          <>
            {permissionObject.edit && (
              <IconBox onClick={() => openEdit(id)}>
                <PencilAltIcon />
              </IconBox>
            )}
            {permissionObject.del && (
              <IconBox onClick={handleDelete}>
                <RowDeleteLoader loading={isDeleteLoading} />
              </IconBox>
            )}
          </>
        ),
      };
    });
  }, [
    dispatch,
    modalDispatch,
    permissionObject,
    typeItems,
    typeItemState.loadingDelete,
  ]);

  return (
    <Container>
      <HeaderBox>
        <StyledTitle>Typy zgłoszeń</StyledTitle>
        {permissionObject.create && (
          <AddButton
            text="Dodaj typ zgłoszeń"
            onClick={() => setIsCreateOpen(true)}
          />
        )}
      </HeaderBox>
      <DataTableSecondary
        data={notifications}
        columns={columns}
        loading={typeItemState.loading}
        noDataContent={{
          title: "Brak dostępnych typów",
          subtitle: "Dodaj nowe typy, aby wyświetlić je w tej sekcji",
        }}
      />

      {permissionObject.create && (
        <SideModal
          title="Nowy typ zgłoszenia"
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
        >
          <CreateNotificationType setIsOpen={setIsCreateOpen} />
        </SideModal>
      )}

      {permissionObject.edit && (
        <SideModal
          title="Edycja typu zgłoszenia"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        >
          <EditTypeItem setIsOpen={setIsEditOpen} id={String(currentOpenId)} />
        </SideModal>
      )}
    </Container>
  );
};

export default NotificationTypes;
