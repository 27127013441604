import React, { useState, useMemo, useEffect, useCallback } from "react";
import styled from "styled-components";
import { BuildingIcon, TrashIcon, UserIcon } from "react-line-awesome";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";

import * as clientGraphql from "../../graphql/clients";

import SingleTab from "../../components/SingleTab";

import MainTemplate from "../../templates/MainTemplate";
import { routes } from "../../routes";
import PrivateRout from "../../auth/PrivateRout";
import { functionTypes } from "../../context/permission";

import Buildings from "./Buildings";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import usePermissionObject from "../../hooks/usePermissionObject";
import { useDispatch, useSelector } from "react-redux";
import SideModal from "../../components/modal/SideModal";
import EditClient from "../../components/clients/EditClient";
import { deleteClient } from "../../redux/actions/clientActions";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
`;
const SideTabs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 292px;
  flex-shrink: 1;
  border-left: 1px solid ${({ theme }) => theme.color.secondaryBorder};
`;
const MainContent = styled.div`
  width: calc(100% - 292px);
`;

const Client = ({ match }) => {
  const history = useHistory();
  const permissionObject = usePermissionObject("clients");
  const modalDispatch = useConfirmModalDispatch();

  const [isEditOpen, setIsEditOpen] = useState(false);

  const dispatch = useDispatch();

  const clientState = useSelector(({ client }) => client);
  const { loadingDelete } = clientState;

  const [client, setClient] = useState({});
  const clientId = match.params.id;
  const { home, buildings } = routes.client;

  useEffect(() => {
    const checkIsClientExist = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(clientGraphql.getClient, { id: clientId })
        );
        if (isEmpty(data.getClient)) {
          history.push(routes.clients);
        } else {
          setClient(data.getClient);
        }
      } catch (err) {
        console.log("Get client: ", err);
      }
    };

    checkIsClientExist();
  }, [clientId, history, clientState.client]);

  const buildingsPath = `${routes.client.home}/${
    clientId + routes.client.buildings
  }`;

  const dropdownOptions = useCallback(
    (id) => {
      const handleDelete = () => {
        const callback = () => {
          setIsEditOpen(false);
          history.push(routes.clients, { deletedId: id });
        };

        modalDispatch({
          type: "OPEN",
          payload: {
            title: "Potwierdzenie usunięcia",
            content: "Na pewno chcesz usunąć klienta?",
            buttonText: "Usuń",
            action: () => {
              dispatch(deleteClient(id, callback));
            },
          },
        });
      };

      return [
        {
          name: "usuń",
          icon: TrashIcon,
          handler: handleDelete,
          isEditDisplay: true,
          permission: permissionObject.edit,
        },
      ];
    },
    [modalDispatch, dispatch, permissionObject, history]
  );

  const tabs = useMemo(
    () => [
      {
        id: 1,
        tab: {
          title: "Budynki",
          subtitle: "Podgląd dostępnych budynków",
          icon: BuildingIcon,
        },
        route: `${home}/:id${buildings}`,
        to: buildingsPath,
        content: Buildings,
      },
    ],
    [home, buildings, buildingsPath]
  );

  return (
    <MainTemplate title={client.name}>
      <Container>
        <MainContent>
          <Switch>
            {tabs.map((tab) => (
              <PrivateRout
                key={tab.id}
                path={tab.route}
                component={tab.content}
                permission={{
                  name: "clients",
                  type: functionTypes.READ,
                }}
              />
            ))}
            <Redirect to={buildingsPath} />
          </Switch>
        </MainContent>
        <SideTabs>
          <TabsContainer>
            {tabs.map(({ id, tab, to }) => (
              <SingleTab
                key={id}
                to={to}
                activeClassName="active"
                title={tab.title}
                subtitle={tab.subtitle}
                icon={tab.icon}
                alt="true"
              />
            ))}
          </TabsContainer>
          {permissionObject.edit && (
            <BottomEditButtonBox onClick={() => setIsEditOpen(true)}>
              <Icon>
                <UserIcon />
              </Icon>
              <Text>Zmień dane klienta</Text>
            </BottomEditButtonBox>
          )}
        </SideTabs>
      </Container>

      {permissionObject.edit && (
        <SideModal
          title="Edycja klienta"
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          dropdownOptions={dropdownOptions}
          loadingDelete={loadingDelete}
          id={String(clientId)}
        >
          <EditClient setIsOpen={setIsEditOpen} id={String(clientId)} />
        </SideModal>
      )}
    </MainTemplate>
  );
};

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomEditButtonBox = styled.div`
  display: flex;
  align-items: center;
  height: 62px;
  padding: 21px 0 19px;
  margin: 0 26px;
  border-top: 1px solid ${({ theme }) => theme.color.secondaryBorder};
  color: ${({ theme }) => theme.color.primaryColor};
  transition: transform 0.18s ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(0.99);
  }
`;
const Icon = styled.div`
  font-size: 18px;
  margin-right: 16px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.regular};
`;

export default Client;
