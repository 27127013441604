import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import MainToast from "../../components/toastify/MainToast";

import * as graphql from "../../graphql/users";
import {
  CREATE_USER_STARTED,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LIST_USERS_STARTED,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  SEARCH_USERS_STARTED,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_FULL_STARTED,
  DELETE_USER_FULL_SUCCESS,
  DELETE_USER_FULL_FAILURE,
} from "../types";

const createFullUserStarted = () => ({
  type: CREATE_USER_STARTED,
});
const createFullUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});
const createFullUserFailure = (error) => ({
  type: CREATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const createFullUser = (input, callback) => async (dispatch) => {
  try {
    dispatch(createFullUserStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.createFullUser, {
        ...input,
      })
    );
    const user = data.createFullUser;

    dispatch(createFullUserSuccess(user));
    toast.success(<MainToast message="Użytkownik stworzony pomyślnie" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    if (err.errors[0].errorType === "Lambda:Unhandled") {
      toast.error(<MainToast message="Email jest już zajęty" />);
    } else {
      toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    }
    dispatch(createFullUserFailure(err));
  }
};

const listUsersSuccess = (users) => ({
  type: LIST_USERS_SUCCESS,
  payload: users,
});
const listUsersStarted = () => ({
  type: LIST_USERS_STARTED,
});
const listUsersFailure = (error) => ({
  type: LIST_USERS_FAILURE,
  payload: {
    error,
  },
});

export const listUsers = (variables) => async (dispatch) => {
  try {
    dispatch(listUsersStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.listUsers, variables)
    );
    const users = data.listUsers.items;

    dispatch(listUsersSuccess(users));
  } catch (err) {
    console.log(err);
    dispatch(listUsersFailure(err));
  }
};

const searchUsersSuccess = (users, nextToken, total) => ({
  type: SEARCH_USERS_SUCCESS,
  payload: {
    users,
    nextToken,
    total,
  },
});
const searchUsersStarted = () => ({
  type: SEARCH_USERS_STARTED,
});
const searchUsersFailure = (error) => ({
  type: SEARCH_USERS_FAILURE,
  payload: {
    error,
  },
});

export const searchUsers = (variables) => async (dispatch) => {
  try {
    dispatch(searchUsersStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.searchUsers, variables)
    );
    const nextToken = data.searchUsers.nextToken;
    const total = data.searchUsers.total;
    const users = data.searchUsers.items;

    dispatch(searchUsersSuccess(users, nextToken, total));
  } catch (err) {
    console.log(err);
    dispatch(searchUsersFailure(err));
  }
};

const deleteUserFullSuccess = (id) => ({
  type: DELETE_USER_FULL_SUCCESS,
  payload: id,
});
const deleteUserFullStarted = (id) => ({
  type: DELETE_USER_FULL_STARTED,
  payload: id,
});
const deleteUserFullFailure = (error) => ({
  type: DELETE_USER_FULL_FAILURE,
  payload: {
    error,
  },
});

export const deleteUserFull = (id) => async (dispatch) => {
  try {
    dispatch(deleteUserFullStarted(id));
    await API.graphql(
      graphqlOperation(graphql.deleteUserFull, {
        id,
      })
    );

    dispatch(deleteUserFullSuccess(id));
    toast.success(<MainToast message="Użytkownik usunięty pomyślnie" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak!" />);
    dispatch(deleteUserFullFailure(err.errors));
  }
};

const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: user,
});
const getUserStarted = () => ({
  type: GET_USER_STARTED,
});
const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  payload: {
    error,
  },
});

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch(getUserStarted());
    const { data } = await API.graphql(
      graphqlOperation(graphql.getUser, {
        id,
      })
    );
    const user = data.getUser;

    dispatch(getUserSuccess(user));
  } catch (err) {
    console.log(err);
    dispatch(getUserFailure(err.errors));
  }
};

const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});
const updateUserStarted = () => ({
  type: UPDATE_USER_STARTED,
});
const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const updateUser = (variables) => async (dispatch) => {
  try {
    dispatch(updateUserStarted());
    const { input } = variables;
    const email = input.email;
    delete input.email;

    const { data } = await API.graphql(
      graphqlOperation(graphql.updateUser, { input })
    );

    dispatch(updateUserSuccess({ ...data.updateUser, email }));
    toast.success(<MainToast message="Zaktualizowano użytkownika" />);
  } catch (err) {
    console.log(err);
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    dispatch(updateUserFailure(err.errors));
  }
};
