import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";

import { Button } from "../Button";
import Submit from "../Submit";
import FormFields from "./FormFields";
import { createFullUser } from "../../redux/actions/userActions";
import { usePermissionState } from "../../context/permission";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const CreateUser = ({ setIsOpen }) => {
  const form = useForm();
  const { handleSubmit } = form;

  const dispatch = useDispatch();
  const { loadingCreate } = useSelector((state) => state.user);

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const onSubmit = (data) => {
    const language = "PL";
    const { location } = data.address.geometry;

    const variables = {
      email: data.email,
      fullName: data.name,
      phone: parsePhoneNumber(data.phone, language).number,
      roleId: data.role.value,
      address: data.address.formatted_address,
      latitude: location.lat,
      longitude: location.lng,
      companyId,
    };

    dispatch(createFullUser(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} />
      <Buttons>
        <ButtonBox>
          <Submit value="dodaj" loader={loadingCreate} />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

CreateUser.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateUser;
