import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DollyIcon, UsersIcon } from "react-line-awesome";

import FormSectionTitle from "../FormSectionTitle";
import Input from "../Input";
import Select from "../Select";
import { Controller } from "react-hook-form";
import { usePermissionState } from "../../context/permission";
import { teamTypes } from "../../util/variables";
import TagSelect from "../TagSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listUsers } from "../../redux/actions/userActions";
import { listProducts } from "../../redux/actions/settings/productActions";
import RateInput from "../RateInput";
import _ from "lodash";

const FormSection = styled.div`
  margin-bottom: 26px;
`;

const FormFields = ({ form, loading }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const { cognitoUser } = usePermissionState();
  const companyId = cognitoUser.databaseUser.companyId;

  const dispatch = useDispatch();
  const { userState, productState } = useSelector(({ user, product }) => ({
    userState: user,
    productState: product,
  }));

  useEffect(() => {
    dispatch(listUsers({ filter: { companyId: { eq: companyId } } }));
    dispatch(
      listProducts({
        filter: { companyId: { eq: companyId } },
      })
    );
  }, [companyId, dispatch]);

  const suggestions = useMemo(
    () =>
      userState.usersSimple
        .map((item) => ({
          id: item.id,
          name: item.fullName,
        }))
        .filter((item) => !_.some(watch("members"), item)),
    [userState.usersSimple, watch("members")]
  );
  return (
    <>
      <FormSection>
        <FormSectionTitle title="Informacje o zespole" icon={UsersIcon} />
        <Input
          label="Nazwa zespołu"
          placeholder="Wprowadź nazwę zespołu"
          error={errors?.name?.message}
          isLoading={loading}
          {...register("name", {
            required: { value: true, message: "Pole nie może być puste" },
          })}
        />
        <Controller
          render={({ ref, ...rest }) => (
            <Select
              isLoading={loading}
              items={teamTypes}
              label="Typ zespołu"
              placeholder="Wybierz typ zespołu"
              error={errors?.type?.message}
              {...rest}
            />
          )}
          rules={{
            required: { value: true, message: "Wybierz z listy" },
          }}
          control={control}
          name="type"
        />
        <Controller
          render={({ ref, ...rest }) => (
            <TagSelect
              disabled={loading}
              allowNew={false}
              label="Osoby"
              placeholderText="Dodaj członka zespołu"
              suggestions={suggestions}
              error={errors?.members?.message}
              {...rest}
            />
          )}
          control={control}
          name="members"
          rules={{
            required: { value: true, message: "Pole nie może być puste" },
          }}
        />
      </FormSection>
      <FormSection>
        <FormSectionTitle title="Stawki" icon={DollyIcon} />
        <RateInput products={productState.products} form={form} />
      </FormSection>
    </>
  );
};

FormFields.propTypes = {
  form: PropTypes.object,
};

export default FormFields;
