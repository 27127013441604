import React from "react";
import styled, { css } from "styled-components";

import { useSideBarState } from "../context/sideBar";
import SideBar from "../components/navigation/SideBar";
import SideBarMobile from "../components/navigation/SideBarMobile";
import TopBar from "../components/navigation/TopBar";

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.mainBackground};
  display: flex;
`;
const Content = styled.div`
  background-color: ${({ theme }) => theme.color.mainBackground};
  padding: 24px 30px 43px 26px;
  transition: 0.24s ease-in-out;

  ${({ isOpen }) =>
    isOpen
      ? css`
          width: calc(100vw - 243px);
        `
      : css`
          width: calc(100vw - 80px);
        `}

  @media (max-width: 900px) and (min-width: 601px) {
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px) {
    height: 100%;
    width: 100vw;
  }

  @media (max-height: 400px) {
    padding-bottom: 15px;
  }
`;

const MainBox = styled.div``;
const SideBox = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  transition: 0.24s ease-in-out;

  ${({ isOpen }) =>
    isOpen
      ? css`
          width: 243px;
        `
      : css`
          width: 80px;
        `}
`;

export default function MainTemplate({ children, title, handleAdd }) {
  document.title = `${title} - ROTO` || "ROTO";
  const isOpen = useSideBarState();
  const isTablet = isOpen && window.screen.width < 900;
  const isMobile = window.screen.width < 600 && window.screen.height > 400;

  const renderSidebar = () => {
    if (isTablet) {
      return <SideBarMobile />;
    } else if (isMobile) {
      return <SideBarMobile />;
    } else return <SideBar />;
  };

  return (
    <Container>
      <SideBox isOpen={isOpen}>{renderSidebar()}</SideBox>
      <MainBox>
        <TopBar title={title} handleAdd={handleAdd} />
        <Content isOpen={isOpen}>{children}</Content>
      </MainBox>
    </Container>
  );
}
