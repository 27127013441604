import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";

import MainToast from "../../../components/toastify/MainToast";
import * as graphql from "../../../graphql/settings/notificationTypes";
import * as graphqlFields from "../../../graphql/settings/fields";
import {
  CREATE_TYPE_ITEM_STARTED,
  CREATE_TYPE_ITEM_SUCCESS,
  CREATE_TYPE_ITEM_FAILURE,
  LIST_TYPE_ITEMS_STARTED,
  LIST_TYPE_ITEMS_SUCCESS,
  LIST_TYPE_ITEMS_FAILURE,
  GET_TYPE_ITEM_STARTED,
  GET_TYPE_ITEM_SUCCESS,
  GET_TYPE_ITEM_FAILURE,
  UPDATE_TYPE_ITEM_STARTED,
  UPDATE_TYPE_ITEM_SUCCESS,
  UPDATE_TYPE_ITEM_FAILURE,
  DELETE_TYPE_ITEM_STARTED,
  DELETE_TYPE_ITEM_SUCCESS,
  DELETE_TYPE_ITEM_FAILURE,
} from "../../types";
import { isEmpty, pick } from "lodash";

const createTypeWithRelation = (typeItemId) => async (type) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(graphql.createType, { input: { name: type } })
    );
    const typeId = data.createType.id;

    await API.graphql(
      graphqlOperation(graphql.createTypeConnector, {
        input: { typeId, typeItemId },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const createType = async (type) => {
  try {
    if (type.id) return type.id;
    const { data } = await API.graphql(
      graphqlOperation(graphql.createType, { input: { name: type.name } })
    );
    return data.createType.id;
  } catch (err) {
    console.log(err);
  }
};

const deleteExistingRelations = () => async (type) => {
  try {
    await API.graphql(
      graphqlOperation(graphql.deleteTypeConnector, {
        input: { id: type.id },
      })
    );
    await API.graphql(
      graphqlOperation(graphql.deleteType, {
        input: { id: type.type.id },
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const createNotificationTypeSuccess = (type) => ({
  type: CREATE_TYPE_ITEM_SUCCESS,
  payload: type,
});
const createNotificationTypeStarted = () => ({
  type: CREATE_TYPE_ITEM_STARTED,
});
const createNotificationTypeFailure = (error) => ({
  type: CREATE_TYPE_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const createNotificationType =
  (variables, callback) => async (dispatch) => {
    const { types } = variables;
    const input = pick(variables, [
      "companyId",
      "name",
      "attachments",
      "customerOrder",
      "description",
      "icon",
      "measurementCard",
      "notes",
      "producerOrder",
      "specification",
      "photos",
      "protocol",
    ]);

    try {
      dispatch(createNotificationTypeStarted());

      const { data } = await API.graphql(
        graphqlOperation(graphql.createTypeItem, { input })
      );
      const typeItemId = data.createTypeItem.id;

      await Promise.all(types.map(createTypeWithRelation(typeItemId)));

      // Get item with relation
      const { data: response } = await API.graphql(
        graphqlOperation(graphql.getTypeItem, { id: typeItemId })
      );
      const typeItem = response.getTypeItem;

      dispatch(createNotificationTypeSuccess(typeItem));
      toast.success(<MainToast message="Nowy typ dodany pomyślnie" />);
      callback && callback();
    } catch (err) {
      console.log(err);
      dispatch(createNotificationTypeFailure(err));
      toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
    }
  };

const listTypeItemsSuccess = (typeItems) => ({
  type: LIST_TYPE_ITEMS_SUCCESS,
  payload: typeItems,
});
const listTypeItemsStarted = () => ({
  type: LIST_TYPE_ITEMS_STARTED,
});
const listTypeItemsFailure = (error) => ({
  type: LIST_TYPE_ITEMS_FAILURE,
  payload: {
    error,
  },
});

export const listTypeItems = (variables) => async (dispatch) => {
  try {
    dispatch(listTypeItemsStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.listTypeItems, variables)
    );
    const typeItems = data.listTypeItems.items;

    dispatch(listTypeItemsSuccess(typeItems));
  } catch (err) {
    console.log(err);
    dispatch(listTypeItemsFailure(err));
  }
};

const getTypeItemSuccess = (typeItem) => ({
  type: GET_TYPE_ITEM_SUCCESS,
  payload: typeItem,
});
const getTypeItemStarted = () => ({
  type: GET_TYPE_ITEM_STARTED,
});
const getTypeItemFailure = (error) => ({
  type: GET_TYPE_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const getTypeItem = (id) => async (dispatch) => {
  try {
    dispatch(getTypeItemStarted());

    const { data } = await API.graphql(
      graphqlOperation(graphql.getTypeItem, { id })
    );
    const typeItem = data.getTypeItem;

    dispatch(getTypeItemSuccess(typeItem));
  } catch (err) {
    console.log(err);
    dispatch(getTypeItemFailure(err));
  }
};

const updateTypeItemSuccess = (typeItem) => ({
  type: UPDATE_TYPE_ITEM_SUCCESS,
  payload: typeItem,
});
const updateTypeItemStarted = () => ({
  type: UPDATE_TYPE_ITEM_STARTED,
});
const updateTypeItemFailure = (error) => ({
  type: UPDATE_TYPE_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const updateTypeItem = (variables, callback) => async (dispatch) => {
  const input = pick(variables, [
    "id",
    "companyId",
    "name",
    "icon",
    "attachments",
    "customerOrder",
    "description",
    "measurementCard",
    "notes",
    "producerOrder",
    "specification",
    "photos",
    "protocol",
  ]);

  try {
    dispatch(updateTypeItemStarted());

    const newTypesId = await Promise.all(variables.types.map(createType));
    const { data: typeItem } = await API.graphql(
      graphqlOperation(graphql.updateTypeItemAll, {
        input: { ...input, icon: input.icon.value, types: newTypesId },
      })
    );

    dispatch(updateTypeItemSuccess(typeItem.updateTypeItemAll));
    toast.success(<MainToast message="Zapisano zmiany" />);
    callback && callback();
  } catch (err) {
    console.log(err);
    dispatch(updateTypeItemFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};

const deleteTypeItemSuccess = (id) => ({
  type: DELETE_TYPE_ITEM_SUCCESS,
  payload: id,
});
const deleteTypeItemStarted = (id) => ({
  type: DELETE_TYPE_ITEM_STARTED,
  payload: id,
});
const deleteTypeItemFailure = (error) => ({
  type: DELETE_TYPE_ITEM_FAILURE,
  payload: {
    error,
  },
});

export const deleteTypeItem = (id) => async (dispatch) => {
  try {
    dispatch(deleteTypeItemStarted(id));

    // Sprawdzanie relacji z polami
    const { data: fieldsData } = await API.graphql(
      graphqlOperation(graphqlFields.listFieldItems, {
        filter: { typeItemId: { eq: id } },
      })
    );
    if (!isEmpty(fieldsData.listFieldItems.items)) {
      dispatch(deleteTypeItemFailure("Tyżyty w innym module"));
      toast.warning(<MainToast message="Typ jest użyty w innym module" />);
      return;
    }

    const { data } = await API.graphql(
      graphqlOperation(graphql.deleteTypeItem, {
        input: { id },
      })
    );

    const types = data.deleteTypeItem.types.items;
    await Promise.all(types.map(deleteExistingRelations()));

    dispatch(deleteTypeItemSuccess(id));
    toast.success(<MainToast message="Usunięto pomyślnie" />);
  } catch (err) {
    console.log(err);
    dispatch(deleteTypeItemFailure(err));
    toast.error(<MainToast message="Ups! Coś poszło nie tak" />);
  }
};
