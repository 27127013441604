import React from "react";
import styled from "styled-components";

const AdditionalOption = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  border-top: 1px solid ${({ theme }) => theme.color.mainBorder};
  padding: 0 18px;
  font-weight: ${({ theme }) => theme.weight.medium};
  font-size: 14px;
  cursor: pointer;
`;

const AdditionalDropdownOption = ({ text, ...rest }) => {
  return <AdditionalOption {...rest}>{text}</AdditionalOption>;
};

export default AdditionalDropdownOption;
