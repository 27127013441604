import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  getFieldItem,
  updateFieldItem,
} from "../../../redux/actions/settings/fieldActions";

import { Button } from "../../Button";
import Submit from "../../Submit";
import FormFields from "./FormFields";

const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
const ButtonBox = styled.div`
  width: 126px;
`;

const EditTypeItem = ({ setIsOpen, id }) => {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const dispatch = useDispatch();
  const fieldItemState = useSelector(({ fieldItem }) => fieldItem);

  useEffect(() => {
    dispatch(getFieldItem(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(fieldItemState.fieldItem)) {
      const { name, typeItem } = fieldItemState.fieldItem;
      const types = fieldItemState.fieldItem.fields.items.reduce(
        (acc, item) => {
          const element = { id: item.field.id, name: item.field.name };

          return [...acc, element];
        },
        []
      );

      setValue("name", name);
      setValue("type", { name: typeItem.name, value: typeItem.id });
      setValue("types", types);
    }
  }, [fieldItemState.fieldItem, setValue]);

  const onSubmit = (data) => {
    const variables = {
      ...data,
      id,
      types: data.types.reduce((acc, item) => (acc = [...acc, item.name]), []),
      typeItemId: data.type.value,
    };

    dispatch(updateFieldItem(variables, () => setIsOpen(false)));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFields form={form} loading={fieldItemState.loadingGet} edit />
      <Buttons>
        <ButtonBox>
          <Submit
            value="Zapisz"
            disabled={fieldItemState.loadingGet}
            loader={fieldItemState.loadingUpdate}
          />
        </ButtonBox>
        <ButtonBox>
          <Button dark onClick={() => setIsOpen(false)}>
            Anuluj
          </Button>
        </ButtonBox>
      </Buttons>
    </Form>
  );
};

export default EditTypeItem;
