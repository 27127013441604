import React from "react";
import { PlusIcon } from "react-line-awesome";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${({ theme }) => theme.color.primaryColor};
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.18s ease-in-out;

  :hover {
    transform: scale(0.98);
  }
`;
const IconBox = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.color.white};
`;

const CircleAddButton = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <IconBox>
        <PlusIcon />
      </IconBox>
    </Container>
  );
};

export default CircleAddButton;
