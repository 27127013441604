import React, {useEffect} from "react";
import styled from "styled-components";
import {ChevronCircleLeftIcon, TrashAltIcon} from "react-line-awesome";

import {deleteRootFile, getRootFile} from "../../redux/actions/rootfileActions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import {useConfirmModalDispatch} from "../../context/ModalContext";
import {usePermissionState} from "../../context/permission";
import usePermissionObject from "../../hooks/usePermissionObject";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.color.tableHeaderLine};
`;

const ButtonGray = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.font200};
  font-size: 12px;
  cursor: pointer;
  //background-color: ${({ theme }) => theme.color.white};
`;

const IconWrapper = styled.div`
    color: ${({theme}) => theme.color.font200};
    font-size: 18px;
    margin-right: 2px;
`

export default function FileDetailHeader({id, path}) {
    const { cognitoUser } = usePermissionState();
    const permissionObject = usePermissionObject("library");
    const dispatch = useDispatch();
    const history = useHistory();
    const modalDispatch = useConfirmModalDispatch();

    const handleBackClick = () => {
        history.push(routes.library.home)
    }

    const handleDelete = () => {
        modalDispatch({
            type: "OPEN",
            payload: {
                title: "Potwierdzenie usunięcia",
                content:
                    "UWAGA! Plik zostanie usunięty. Na pewno chcesz usunąć?",
                buttonText: "Usuń",
                action: () => {dispatch(deleteRootFile(id, path)); handleBackClick()},
            },
        });
    };



    return (
        <Header>
            <ButtonGray onClick={() => handleBackClick()}>
                <IconWrapper>
                    <ChevronCircleLeftIcon />
                </IconWrapper>
                wróć
            </ButtonGray>
            {permissionObject.del &&
            <ButtonGray onClick={() => handleDelete()}>
                <IconWrapper>
                    <TrashAltIcon />
                </IconWrapper>
                usuń
            </ButtonGray>}
        </Header>
    );
}
