import { useCallback, useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import { debounce, differenceBy } from "lodash";
import { googleAutocomplete } from "../graphql/global";
import { usePermissionState } from "../context/permission";

const state = {
  idle: "idle",
  isLoading: "isLoading",
  hasLoaded: "hasLoaded",
  hasError: "hasError",
};

const machine = createMachine({
  id: "searchable-dropdown",
  initial: state.idle,
  states: {
    [state.idle]: {
      on: {
        GET_ITEMS: state.isLoading,
      },
    },
    [state.isLoading]: {
      on: {
        GET_ITEMS_SUCCESS: state.hasLoaded,
        GET_ITEMS_ERROR: state.hasError,
      },
    },
    [state.hasLoaded]: {
      on: {
        GET_ITEMS: state.isLoading,
      },
    },
    [state.hasError]: {
      on: {
        GET_ITEMS: state.isLoading,
      },
    },
  },
});

export const useGetSearchDropdownItems = (config) => {
  const {
    searchValue,
    selectedItems,
    schema,
    items,
    filter,
    googleAutocompleteMethod,
    waitFor = true,
  } = config;

  const [currentMachine, send] = useMachine(machine);

  const [dropdownItems, setDropdownItems] = useState([]);

  const getItemsFromAPI = async (searchValue = "", selectedItems) => {
    try {
      send("GET_ITEMS");
      if (schema) {
        const nameFilter =
          searchValue.length > 0
            ? { name: { matchPhrasePrefix: searchValue } }
            : { name: { ne: "" } };

        const { data } = await API.graphql(
          graphqlOperation(schema, {
            limit: 15,
            filter: { ...filter, ...nameFilter },
          })
        );
        const { items } = data[Object.keys(data)[0]];

        let itemsWithSelected;
        if (selectedItems) {
          itemsWithSelected = [
            ...selectedItems,
            ...differenceBy(items, selectedItems, "id"),
          ];
        }

        if (searchValue.length > 0 || !selectedItems) setDropdownItems(items);
        else setDropdownItems(itemsWithSelected);
      } else {
        setDropdownItems(items);
      }

      send("GET_ITEMS_SUCCESS");
    } catch (err) {
      console.error(err);
      send("GET_ITEMS_ERROR");
    }
  };

  const getGooglePlace = async (searchValue = "") => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(googleAutocomplete, {
          input: searchValue,
          params: '{"componentRestrictions":{"country":"pl"}}',
        })
      );
      const streetList = JSON.parse(
        JSON.parse(data.googleAutocomplete)
      ).predictions.map((place) => ({
        id: place.place_id,
        name: place.description,
        placeId: place.place_id,
      }));

      setDropdownItems(streetList);

      send("GET_ITEMS_SUCCESS");
    } catch (err) {
      console.error(err);
      send("GET_ITEMS_ERROR");
    }
  };

  useEffect(() => {
    getItemsFromAPI(searchValue, selectedItems);
    // eslint-disable-next-line
  }, [filter]);

  const debouncedSearchValue = useCallback(
    debounce((searchValue, selectedItems) => {
      if (googleAutocompleteMethod) getGooglePlace(searchValue);
      else getItemsFromAPI(searchValue, selectedItems);
    }, 300),
    [googleAutocompleteMethod]
  );

  useEffect(() => {
    debouncedSearchValue(searchValue, selectedItems);
  }, [searchValue, debouncedSearchValue]);

  return [
    dropdownItems,
    currentMachine,
    { getItemsFromAPI, getGooglePlace, state },
  ];
};
