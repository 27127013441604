/* -------------------------------- Mutations ------------------------------- */
export const createMember = /* GraphQL */ `
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
    }
  }
`;

export const updateMember = /* GraphQL */ `
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      id
    }
  }
`;

export const deleteMember = /* GraphQL */ `
  mutation deleteMember($input: DeleteMemberInput!) {
    deleteMember(input: $input) {
      id
    }
  }
`;
